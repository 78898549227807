<template>

<div class="table">

	<div class="table-empty" v-if="empty">

		{{ emptyMessage }}

	</div>

	<template v-if="!empty">

		<slot></slot>

	</template>

</div>

</template>

<script>

export default {

	props: ['empty', 'emptyMessage']

}

</script>

<style scoped>

.table {
	background-color: #fff;
	box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px;
	border-radius: 4px;
	min-width: 1000px;
}

.table-empty {
	padding: 40px;
	text-align: center;
	font-size: 16px;
	font-weight: 400;
	color: #a7b6c6;
}

</style>
