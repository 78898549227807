<template>

<div class="row" :class="{'is-selected': selected, 'no-click': noClick, 'is-top': align === 'top', 'is-danger': danger, 'is-success': success, 'is-warning': warning}" :style="{gridTemplateColumns: layout}" v-on:click="$emit('click')">

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['layout', 'selected', 'noClick', 'danger', 'success', 'align', 'warning']

}

</script>

<style scoped>

.row {
	display: grid;
	grid-gap: 10px;
	align-items: center;
	padding: 0px 10px;
	cursor: pointer;
}

.row.no-click {
	cursor: initial;
}

.row.is-top {
	align-items: flex-start;
}

.row.is-top >>> .cell {
	padding-top: 10px!important;
}

.row.is-top >>> .cell.action,
.row.is-top >>> .cell.select {
	padding-top: 5px!important;
}

.row:nth-child(even) {
	background-color: #fafafa;
}

.row:hover {
	background-color: #ffbe7a;
}

.row.is-success {
	background-color: #c2efda;
}

.row.is-warning {
	background-color: #fff3e7;
}

.row.is-danger {
	background-color: #F2DEDE;
}

.row.is-selected {
	background-color: #d9eaff;
}

</style>
