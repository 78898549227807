import Vue from 'vue'

var pubsub = new Vue()

export default {

	$on: function(name, handler, silent) {

		if (!silent) console.log('PubSub: Started listening to ' + name)

		pubsub.$on(name, handler)

	},

	$off: function(name, handler, silent) {

		if (!silent) console.log('PubSub: Stopped listening to ' + name)

		pubsub.$off(name, handler)

	},

	$emit: function(name, data) {

		console.log('PubSub: Emitted ' + name, data)

		pubsub.$emit(name, data)

	}

}