<template>

<div class="checklist">

	<div class="checklist-button" v-tooltip="tooltip" v-on:click="onButtonClick">

		<div class="checklist-button-text">{{ text }}</div>

		<div class="checklist-button-count" v-if="active">{{ value.length }}</div>

		<app-icon class="checklist-button-icon" icon="caret-down" />

	</div>

	<app-panel v-if="expanded" v-on:close="onButtonClick">
		
		<app-panel-title :text="text" />

		<div class="checklist-items" :data-columns="columns">

			<app-panel-check v-for="option in options" :active="$_.contains(value, option[optionValueKey])" :key="option[optionValueKey]" :text="option.text" v-on:toggle="onToggle(option[optionValueKey])" />

		</div>

	</app-panel>

</div>

</template>

<script>

export default {

	props: ['value', 'text', 'valueKey', 'tooltip', 'options', 'columns'],

	data: function() {

		return {
			expanded: false
		}

	},

	computed: {

		optionValueKey: function() {

			return this.valueKey || 'value'

		},

		active: function() {

			return this.value.length

		}

	},

	methods: {

		onButtonClick: function() {

			this.expanded = !this.expanded

			this.$pubsub.$emit('offset', this.expanded)

		},

		onToggle: function(id) {

			var value = this.$util.copy(this.value)

			this.$util.array.toggle(value, id)

			this.$emit('input', value)

		}

	}

}

</script>

<style scoped>

.checklist-button {
	height: 32px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 10px;
	cursor: pointer;
	color: #687589;
}

.checklist-button-count {
	border-radius: 4px;
	background-color: #ffbe7a;
    color: #4d5b6e;
	font-size: 10px;
	height: 14px;
	min-width: 14px;
	text-align: center;
	line-height: 14px;
	margin-left: 10px;
}

.checklist-button:hover {
	color: #333;
}

.checklist-button-text {
	font-size: 12px;
}

.checklist-button-icon {
	font-size: 12px;
	margin-left: 10px;
}

.checklist-items[data-columns="2"] {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 10px;
}

.checklist-items[data-columns="2"] >>> .check {
	width: 50%;
	padding: 0px 10px;
}

.checklist-items[data-columns="2"] >>> .check:hover {
	margin: 0px 0px 4px 0px;
	padding: 0px 10px;
}

.checklist-items[data-columns="2"] >>> .check:hover .check-box {
	color: #4d5b6e;
}

.checklist-items[data-columns="2"] >>> .check:first-child {
	margin-top: 0px;
}

</style>
