<template>

<div class="head" :class="{'has-tabs': tabs, 'is-app': isApp}">

	<app-icon class="head-menu" icon="menu" v-if="isApp" v-on:click="onMenuClick" />
	<app-icon class="head-action" :icon="appAction" v-if="isApp" v-on:click="$emit('action')" />

	<app-content-head-title v-if="title" :back="back" :backParams="backParams" :back-path="backPath" :title="title" :subtitle="subtitle" :image="image" />
	<app-content-head-title v-if="mobileTitle && !isApp && window.is.mobile" :title="mobileTitle" :subtitle="subtitle" />

	<app-content-head-tabs :tabs="tabs" v-if="tabs" />

	<app-content-head-actions v-if="hasActions && !readonly">

		<slot></slot>
		
	</app-content-head-actions>

</div>

</template>

<script>

export default {

	props: ['tabs', 'title', 'isApp', 'mobileTitle', 'readonly', 'appAction', 'back', 'backParams', 'backPath', 'subtitle', 'image'],

	computed: {

		hasActions: function() {

			return !!this.$slots.default

		}

	},

	methods: {

		onMenuClick: function() {

			this.$pubsub.$emit('toggle.menu')

		}

	}

}

</script>

<style scoped>

.head {
	width: 100%;
	background-color: #fff;
	height: 80px;
	padding-right: 20px;
	display: flex;
	flex-direction: row;
	flex-shrink: 0;
	border-bottom: 1px solid #eee;
}

.is-tablet .head {
	height: 64px;
	padding-right: 0px;
}

.head.is-app,
.is-mobile .head {
	height: 48px;
	padding-right: 0px;
}

.is-mobile .head.has-tabs {
	height: 80px;
	padding-bottom: 32px;
	padding-right: 0px;
}

.head.is-app {
	background-color: #2f3a4a;
	border-bottom: 0px;
}

.head.is-app >>> .title {
	padding: 0px;
	justify-content: center;
}

.head.is-app >>> .title-text-title {
	color: #fff;
	font-size: 16px;
}

.head-menu {
	width: 48px;
	height: 48px;
	color: #fff;
	font-size: 24px;
	position: absolute;
	left: 0px;
	top: 0px;
	text-align: center;
	line-height: 48px;
	cursor: pointer;
	z-index: 2;
}

.head-action {
	width: 48px;
	height: 48px;
	color: #fff;
	font-size: 24px;
	position: absolute;
	right: 0px;
	top: 0px;
	text-align: center;
	line-height: 48px;
	cursor: pointer;
	z-index: 2;
}

</style>
