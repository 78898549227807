<template>

<div class="foot" :class="{'is-forced': force}">

	<div class="foot-links">

		<div class="foot-links-item"><a href="https://cardboardevents.com/privacy" target="_blank">Privacy Policy</a></div>
		<div class="foot-links-item"><a href="https://cardboardevents.com/terms" target="_blank">Terms of Use</a></div>
		<div class="foot-links-item"><a href="mailto:support@cardboardevents.com">Contact Support</a></div>

	</div>

	<div class="foot-text">

		&#169; 2023 Premier Board Game Events LLC

	</div>

</div>

</template>

<script>

export default {

	props: ['force']

}

</script>

<style scoped>

.foot {
	position: fixed;
	left: 320px;
	color: #fff;
	bottom: 0px;
	right: 0px;
	height: 32px;
	display: flex;
	justify-content: space-between;
	line-height: 32px;
	font-weight: 300;
	font-size: 13px;
	padding: 0px 20px;
	z-index: 9;
	background-color: #2f3a4a;
	transition: left 100ms ease-in-out;
}

.is-offset .foot,
.is-fullscreen .foot {
	left: 0px;
}

.is-device .is-fullscreen .foot:not(.is-forced) {
	display: none;
}

.is-device .foot {
	position: relative;
	left: auto;
	right: auto;
	flex-grow: 1;
	background-color: transparent;
	margin-top: 20px;
	margin-bottom: -10px;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	height: auto;
	bottom: auto;
	padding: 10px 10px;
}

.is-device .foot.is-forced {
	background-color: #2f3a4a;
	margin-top: 40px;
	margin-bottom: 0px;
}

.foot-links {
	display: flex;
}

.foot-links-item {
	margin-right: 20px;
}

.is-mobile .foot-links-item {
	margin-right: 10px;
}

.foot-links-item a:hover {
	text-decoration: underline;
}

</style>