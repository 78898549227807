import _ from 'underscore'
import Vue from 'vue'
import api from '@/api'
import constants from '@/constants'
import axios from 'axios'
import live from '@/live'

export default {

	namespaced: true,

	state: {
		checking: true,
		organisation: false,
		convention: false,
		authority: false
	},

	getters: {

		key: function(state) {

			var key = []

			if (state.organisation) key.push(state.organisation.id)
			if (state.convention) key.push(state.convention.id)

			return key.join('-')

		},

		checking: function(state) {

			return state.checking
			
		},

		organisation: function(state) {

			return state.organisation
			
		},

		convention: function(state) {

			return state.convention
			
		},

		authority: function(state) {

			return state.authority
			
		},

		'authority/permission': function(state) {

			return function(permission) {

				if(!state.authority) return false

				var parts = permission.toLowerCase().split('/')

				// authorise all if user is founder
				if (state.authority.type === constants.team.members.type.founder) return true

				// get permission level
				var level = parts[0]

				if(level === 'org') level = 'organisation'
				if(level === 'con') level = 'convention'

				// get permission group
				var group = (parts.length > 1) ? parts[1] : false

				// reject all if organisation level permission but user is convention level
				if (level === 'organisation' && state.authority.type === constants.team.members.type.convention) return false

				// accept all if simply checking for level access
				if (level === 'organisation' && !group && state.authority.type !== constants.team.members.type.convention) return true
				if (level === 'convention' && !group) return true

				// default permission is access
				var name = (parts.length >= 3) ? parts[2] : 'access'

				return state.authority.permissions[level][group][name] || state.authority.permissions[level][group]['access'] === constants.team.members.access.full

			}

		}

	},

	mutations: {

		checking: function(state, value) {

			state.checking = value

		},

		organisation: function(state, value) {

			axios.defaults.headers.common['bigbox-organisation'] = value.id

			Vue.set(state, 'organisation', value)

		},

		convention: function(state, value) {

			axios.defaults.headers.common['bigbox-convention'] = value.id

			Vue.set(state, 'convention', value)

		},

		authority: function(state, value) {

			axios.defaults.headers.common['bigbox-authority'] = (value) ? value.id : false

			Vue.set(state, 'authority', value)

		},

		'count/announcements': function(state, value) {

			state.convention.count.announcements += value

		}

	},

	actions: {

		init: async function(context) {

			await context.dispatch('$init');

		},

		$init: function(context) {

			if (process.env.VUE_APP_TYPE === 'manager') {

				live.$on('convention/admin/update', function(json) {
	
					context.commit('convention', json)
	
				})
	
				live.$on('organisation/admin/update', function(json) {
	
					context.commit('organisation', json)
	
				})

			}

			live.$on('convention/announcement/add', function() {

				context.commit('count/announcements', 1)

			})

			live.$on('convention/announcement/remove', function() {

				context.commit('count/announcements', -1)

			})

		},

		$deinit: function() {

			live.$off('convention/update')
			live.$off('organisation/update')

			live.$off('convention/announcement/add')
			live.$off('convention/announcement/remove')

		},

		analyse: async function(context, params) {

			var currentKey = context.getters['key']
			var newKey = []
			var organisation, convention

			if (params.organisation) {

				organisation = _.findWhere(context.rootGetters['session/organisations'], {
					slug: params.organisation
				})

			}

			if (params.convention && organisation) {

				convention = _.findWhere(context.rootGetters['session/conventions'], {
					slug: params.convention,
					organisation: organisation.id
				})

			} 
			
			if(organisation) newKey.push(organisation.id)
			if(convention) newKey.push(convention.id)

			newKey = newKey.join('-')

			console.log('Context Comparison', currentKey, newKey)

			if (currentKey !== newKey && organisation) {

				context.commit('checking', true)

				await api.get('context', {
					organisation: organisation.id,
					convention: (convention) ? convention.id : false
				}).then(async function(data) {

					context.commit('organisation', data.organisation)
					context.commit('convention', data.convention)
					context.commit('authority', data.authority)

					context.commit('checking', false)

				}, function() {

					context.commit('organisation', false)
					context.commit('convention', false)
					context.commit('authority', false)

					context.commit('checking', false)

				})

			} else if (!organisation) {

				context.commit('organisation', false)
				context.commit('convention', false)
				context.commit('authority', false)

				context.commit('checking', false)

			}

		}

	}

}
