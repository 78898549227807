<template>

<div class="button" v-tooltip="'Click to toggle'" :class="{'is-active': value}" v-on:click="$emit('click')">

	<div class="button-text">{{ text }}</div>
	<app-icon icon="filter" class="button-icon" />

</div>

</template>

<script>

export default {

	props: ['value', 'text']

}

</script>

<style scoped>

.button {
	display: flex;
	padding: 0px 10px;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	color: #687589;
}

.button-text {
	font-size: 12px;
	padding-right: 5px;
}

.button-icon {
	font-size: 16px;
	color: #687589;
	margin-top: 2px;
}

.button:hover {
	color: #333;
}

.button:hover .button-icon {
	color: #333;
}

.button.is-active .button-icon {
	color: #ffbe7a;
}

</style>
