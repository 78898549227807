<template>

<app-input :wide="wide" :messages="messages" v-on:toggle="onToggle" :prefix="prefix" :toggle="toggle" :locked="locked" :label="label" :stacked="stacked" :no-line="noLine" :notes="notes" :disabled="disabled" :validation="validation">

	<div class="text-wrapper is-active">

		<div class="text-prefix">
			+1 (US)
		</div>

		<input ref="input" v-on:keyup.enter="$emit('enter')" v-if="!autogrow" :type="(password) ? 'password' : 'text'" class="text" v-bind:value="value" :maxlength="maxlength" v-on:input="$emit('input', $event.target.value)" :placeholder="placeholder" />
		<textarea ref="input" v-if="autogrow" v-autogrow class="text" v-bind:value="value" v-on:input="$emit('input', $event.target.value)" :placeholder="placeholder" />

	</div>

</app-input>

</template>

<script>

export default {

	props: ['label', 'wide', 'messages', 'locked', 'password', 'textPrefix', 'toggle', 'prefix', 'validation', 'numeric', 'autogrow', 'disabled', 'value', 'noLine', 'stacked', 'placeholder', 'notes', 'maxlength'],

	methods: {

		onToggle: function() {

			this.$emit('toggle')
			if (!this.toggle) this.$refs.input.focus()

		}

	}

}

</script>

<style scoped>

.text {
	line-height: 36px;
	font-size: 14px;
	width: 100%;
	padding: 0px 10px;
	color: #333;
	background-color: #fff;
	border-radius: 10px;
	border: 2px solid #eee;
}

textarea.text {
	line-height: 20px;
	box-sizing: initial;
	width: calc(100% - 24px);
	padding: 10px;
	resize: none;
}

.text:focus {
	border-color: #4082d3;
}

.is-device .text {
	font-size: 14px;
}

.text-wrapper.is-active {
	display: flex;
}

.text-prefix {
	line-height: 36px;
	font-size: 14px;
	padding: 0px 10px;
	color: #333;
	background-color: #eee;
	border-radius: 12px 0px 0px 12px;
	border: 2px solid #eee;
	flex-grow: 0;
	letter-spacing: 1px;
}

.text-wrapper.is-active .text {
	width: auto;
	flex-grow: 1;
	border-radius: 0px 12px 12px 0px;
}

</style>
