<template>

<app-input v-on:toggle="onToggle" :prefix="prefix" :toggle="toggle" :label="label" :stacked="stacked" :no-line="noLine" :notes="notes" :locked="locked" :disabled="disabled" :validation="validation">

	<v-date-picker v-model="date" :mode="pickerMode">

		<template v-slot="{ inputValue, inputEvents }">

			<input refs="input" class="text" :value="inputValue" v-on="inputEvents" />

		</template>

	</v-date-picker>

</app-input>

</template>

<script>

export default {

	props: ['label', 'password', 'time', 'toggle', 'prefix', 'mode', 'locked', 'validation', 'numeric', 'autogrow', 'disabled', 'value', 'noLine', 'stacked', 'placeholder', 'notes', 'maxlength'],

	data: function() {

		return {
			date: new Date()
		}

	},

	created: function() {

		if (this.value) this.date = this.$moment.unix(this.value).toDate()

	},

	watch: {

		date: function(n) {

			this.$emit('input', this.$moment(n).unix())

		}

	},

	computed: {

		pickerMode: function() {

			if (this.mode) {

				return this.mode

			} else if (this.time) {

				return 'datetime'

			} else {

				return 'date'

			}

		}

	},

	methods: {

		onToggle: function() {

			this.$emit('toggle')
			if (!this.toggle) this.$refs.input.focus()

		}

	}

}

</script>

<style scoped>

.text {
	line-height: 36px;
	font-size: 14px;
	width: 100%;
	padding: 0px 10px;
	color: #333;
	background-color: #fff;
	border-radius: 10px;
	border: 2px solid #eee;
}

textarea.text {
	line-height: 20px;
	box-sizing: initial;
	width: calc(100% - 24px);
	padding: 10px;
	resize: none;
}

.text:focus {
	border-color: #4082d3;
}

.is-device .text {
	font-size: 14px;
}

.is-mobile .text {
	padding: 0px 10px;
}

</style>
