<template>

<div class="event item">

	<com-row v-if="isList" :event="event" />
	<com-card v-if="isGrid" :loading="loading" :event="event" />

</div>

</template>

<script>

export default {

	props: ['isList', 'isGrid', 'event', 'loading'],
	
	components: {
		'com-row': () => import('./event/Row'),
		'com-card': () => import('./event/Card')
	}

}

</script>

<style scoped>

</style>
