<template>

<app-table-cell class="text" :border="border" :class="{'is-empty': canEmpty && !text, 'has-icon': icon}" :align="align">

	<app-icon :icon="icon" v-if="icon" class="text-icon" v-tooltip="iconTooltip" />

	<template v-if="text || !canEmpty"><span v-tooltip="tooltip">{{ (alt) ? text || alt : text }}</span><slot></slot></template><template v-if="canEmpty && !text">n/a</template><small v-if="subtext && text">{{ subtext }}</small>


</app-table-cell>

</template>

<script>

export default {

	props: ['text', 'alt', 'border', 'icon', 'iconTooltip', 'tooltip', 'subtext', 'align', 'canEmpty']

}

</script>

<style scoped>

.text.is-empty {
	color: #ddd;
}

.text {
	flex-direction: column;
	align-items: flex-start;
}

.text-icon {
	margin-right: 5px;
	color: #4082d3;
}

.text.has-icon {
	flex-direction: row;
	align-items: center;
}

.text small {
	font-size: 11px;
	opacity: 0.6;
	margin-top: 2px;
}

</style>
