var name = 'from', minX = 0, maxX = 0, position = 0, el = false, configs = {}, configName

function calculatePosition(x, config) {

	var currentX = x - minX
	var size = maxX - minX
	var currentPercent, currentValue

	currentX = (currentX < 0) ? 0 : currentX
	currentX = (currentX > size) ? size : currentX 

	currentPercent = (100 / size) * currentX
	currentValue = (((config.max - config.min) / 100) * currentPercent) + config.min

	if (config.step < 1) {

		var decimals = config.step.toString().split('.')[1].length

		currentValue = currentValue.toFixed(decimals)

		currentValue = (currentValue === config.min.toFixed(decimals)) ? config.min : currentValue
		currentValue = (currentValue === config.max.toFixed(decimals)) ? config.max : currentValue

	} else if (config.step > 1) {

		currentValue = (Math.floor(currentValue / config.step) * config.step) + ((currentValue % config.step > config.step / 2) ? config.step : 0)

	} else {

		currentValue = Math.round(currentValue)

	}

	if (config.valueMax) {

		currentValue = (currentValue > config.valueMax) ? config.valueMax : currentValue

	}

	if (config.valueMin) {

		currentValue = (currentValue < config.valueMin) ? config.valueMin : currentValue

	}

	position = (100 / (config.max - config.min)) * (currentValue - config.min)
	

}

function onStart(e) {

	e.stopPropagation()

	el = this.el
	name = this.handle
	configName = this.name

	window.addEventListener('touchmove', onMove)
	window.addEventListener('mousemove', onMove)
	window.addEventListener('touchend', onStop)
	window.addEventListener('mouseup', onStop)

	minX = el.parentElement.getBoundingClientRect().left
	maxX = el.parentElement.getBoundingClientRect().right

	calculatePosition(e.x || e.touches[0].clientX, configs[configName])

	el.dispatchEvent(new CustomEvent('started', {
		detail: {
			name: name,
			value: position
		}
	}))

}

function onMove(e) {

	calculatePosition(e.x || e.touches[0].clientX, configs[configName])

	el.style.left = position.toString() + '%'

	el.dispatchEvent(new CustomEvent('moving', {
		detail: {
			name: name,
			value: position
		}
	}))

}

function onStop() {

	el.dispatchEvent(new CustomEvent('finished', {
		detail: {
			name: name,
			value: position
		}
	}))

	window.removeEventListener('mousemove', onMove)
	window.removeEventListener('touchmove', onMove)
	window.removeEventListener('mouseup', onStop)
	window.removeEventListener('touchend', onStop)

}

export default {

	bind: function(el, binding) {

		configs[binding.value.config.name] = binding.value.config

		el.addEventListener('mousedown', onStart.bind({
			el: el,
			handle: binding.value.name,
			name: binding.value.config.name
		}))

		el.addEventListener('touchstart', onStart.bind({
			el: el,
			handle: binding.value.name,
			name: binding.value.config.name
		}))

	},

	inserted: function() {

	},

	update: function(el, binding) {

		configs[binding.value.config.name] = binding.value.config

	},

	componentUpdated: function() {

	},

	unbind: function(el, binding) {

		el.removeEventListener('mousedown', onStart.bind({
			el: el,
			handle: binding.value.name,
			name: binding.value.config.name
		}))

	}

}