<template>

<div class="image">

	<img :src="item.image" />

</div>
	
</template>

<script>

export default {

	props: ['item']

}

</script>

<style scoped>

.image img {
	display: block;
	width: 100%;
	height: auto;
}

</style>