<template>

<div class="capture">

	<app-blocks-common-form :item="item" :endpoint="endpoint" :params="params" />

</div>
	
</template>

<script>

export default {

	props: ['item'],

	computed: {

		params: function() {

			if (process.env.VUE_APP_TYPE === 'register') {

				return {
					page: this.$store.getters['register/page'].id
				}

			} else {

				return {
					block: this.item.id
				}

			}

		},

		endpoint: function() {

			if (process.env.VUE_APP_TYPE === 'app') {

				return 'convention/information/capture'

			} else {

				return 'capture'

			}

		}

	}

}

</script>

<style scoped>

.capture {
	flex-direction: column;
	padding: 10px 40px;
}

.is-mobile .capture {
	padding: 10px 10px;
}

</style>