import Vue from 'vue'
import _ from 'underscore'
import api from '@/api'

export default {

	namespaced: true,

	state: {
		data: {},
		thirdparty: {},
		addons: [],
		badges: [],
		editing: false,
		status: false,
		sale: false,
		reference: false
	},

	getters: {

		organisation: function(state) {

			return state.data.organisation
			
		},

		convention: function(state) {

			return state.data.convention
			
		},

		blocks: function(state) {

			return state.data.blocks
			
		},

		page: function(state) {

			return state.data.page
			
		},

		badges: function(state) {

			return state.badges

		},

		thirdparty: function(state) {

			return state.thirdparty

		},

		preview: function(state) {

			return state.data.preview

		},

		status: function(state) {

			return state.status

		},

		sale: function(state) {

			return state.sale

		},

		reference: function(state) {

			return state.reference

		},

		addons: function(state) {

			return state.addons

		},

		editing: function(state) {

			return state.editing

		},

		intent: function(state) {

			var intent = {}
			var id

			_.each(state.badges, function(item) {
				
				id = item.badge.id

				intent[id] = intent[id] || 0
				intent[id]++

			})

			_.each(state.addons, function(item) {

				id = (item.variant) ? item.variant.id : item.item.id

				intent[id] = intent[id] || 0
				intent[id] += item.quantity

			})

			return intent

		}

	},

	mutations: {

		set: function(state, data) {

			Vue.set(state, 'data', data)

		},

		thirdparty: function(state, data) {

			Vue.set(state, 'thirdparty', data)

		},

		badges: function(state, data) {

			Vue.set(state, 'badges', data)

			var badges = _.pluck(state.badges, 'id')

			var addons = _.filter(state.addons, function(addon) {

				return _.contains(badges, addon.badge)

			})

			Vue.set(state, 'addons', addons)

		},

		addons: function(state, data) {

			Vue.set(state, 'addons', data)

		},

		editing: function(state, data) {

			state.editing = data

		},

		status: function(state, data) {

			state.status = data

		},

		sale: function(state, data) {

			state.sale = data

		},

		reference: function(state, data) {

			state.reference = data

		},

		preview: function(state, data) {

			state.data.preview = data

		}

	},

	actions: {

		retrieve: function(context, secret) {

			return api.get('intent', {
				page: context.getters['page'].id,
				secret: secret
			}).then(function(json) {

				context.commit('sale', json.sale)
				context.commit('status', json.status)
				context.commit('reference', json.reference)
				context.commit('badges', json.data.badges)
				context.commit('addons', json.data.addons)
				context.commit('thirdparty', json.data.thirdparty)
				context.commit('preview', json.data.preview)

			})

		}

	}

}
