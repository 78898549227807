<template>

<div class="button" :class="{'is-disabled': disabled, 'is-locked': locked, 'is-fullwidth': fullWidth, 'has-options': options, 'is-loading': loading || localStatus === $constants.status.loading, [clsTheme]: true}" v-on:click="onClick">

	<div class="button-text">{{ localText }} <app-icon icon="caret-down" v-if="options" /></div>

	<app-icon icon="loading" class="button-loading" v-if="loading || localStatus === $constants.status.loading" />

	<div class="button-options" v-if="is.expanded">

		<div class="button-options-item" :class="{['theme-' + option.status]: true}" v-for="option in options" :key="option[optionValue]" v-on:click.stop="onOptionClick(option[optionValue])"><app-icon icon="check" v-if="option.status === 'green'" />{{ option[optionText] }}</div>

	</div>

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['text', 'statusText', 'fullWidth', 'locked', 'disabled', 'loading', 'theme', 'status', 'options', 'optionValue', 'optionText'],

	data: function() {

		return {
			localStatus: false,
			is: {
				expanded: false
			}
		}

	},

	computed: {

		localText: function() {

			if (this.localStatus === this.$constants.status.success) {

				return (this.statusText) ? this.statusText : 'Saved'

			} else if (this.localStatus === this.$constants.status.failed) {

				return (this.statusText) ? this.statusText : 'Failed'

			} else {

				return this.text

			}

		},

		clsTheme: function() {

			if (this.localStatus === this.$constants.status.success) {

				return 'theme-green'

			} else if (this.localStatus === this.$constants.status.failed) {

				return 'theme-red'

			} else {

				return 'theme-' + this.theme

			}

		}

	},

	created: function() {

		this.localStatus = this.status

	},

	beforeDestroy: function() {

		clearTimeout(this.timer)

	},

	watch: {

		status: function(n) {

			clearTimeout(this.timer)

			this.localStatus = n

			if (this.$_.contains([this.$constants.status.success, this.$constants.status.failed], this.localStatus)) {

				this.timer = this.$_.delay(this.resetStatus.bind(this), 3000)

			}

		}

	},

	methods: {

		resetStatus: function() {

			this.localStatus = false

		},

		onOptionClick: function(value) {

			this.$emit('option', value)

			this.is.expanded = false

		},

		onClick: function() {

			if (this.options) {

				this.is.expanded = !this.is.expanded

			} else {

				this.$emit('click')

			}

		}

	}

}

</script>

<style scoped>

.button {
	display: block;
	margin: 0px auto;
	padding: 0px 10px;
	width: 120px;
	line-height: 40px;
	background-color: #4082d3;
	border-radius: 4px;
	color: #fff;
	cursor: pointer;
	font-size: 14px;
	user-select: none;
	font-weight: 400;
	letter-spacing: 1px;
	text-align: center;
	transition: all 100ms linear;
}

.button.is-fullwidth {
	margin-top: 20px;
	width: 100%;
}

.button.theme-red {
	background-color: #c55b5b;
}

.button.theme-white {
	background-color: #ffffff;
	color: #4082d3;
	font-weight: 400;
}

.button.theme-darkblue {
	background-color: #043c80;
}

.button.theme-darkgreen {
	background-color: #0b7343;
}

.button.theme-green {
	background-color: #4faa80;
}

.button.theme-yellow {
	background-color: #ffbe7a;
}

.button.theme-grey {
	background-color: #4d5b6e;
}

.button.theme-plain {
	background-color: transparent;
	color: #343434;
}

.button.is-loading {
	pointer-events: none;
}

.button.is-loading .button-text {
	opacity: 0;
}

.button-loading {
	font-size: 16px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.button.is-locked {
	pointer-events: none;
}

.button.is-disabled {
	opacity: 0.5;
	pointer-events: none;
}

.button:hover {
	background-color: #236dc9;
}

.button.theme-darkblue:hover {
	background-color: #032b5b;
}

.button.theme-darkgreen:hover {
	background-color: #075531;
}

.button.theme-red:hover {
	background-color: #c94141;
}

.button.theme-white:hover {
	background-color: #fff;
}

.button.theme-green:hover {
	background-color: #3e8865;
}

.button.theme-yellow:hover {
	background-color: #d49959;
}

.button.theme-grey:hover {
	background-color: #455160;
}

.button.theme-plain:hover {
	background-color: rgba(0, 0, 0, 0.2);
}

.button.has-options .button-text {
	display: flex;
	justify-content: space-between;
}

.button-options {
	position: absolute;
	left: 0px;
	top: 41px;
	background-color: #4082d3;
	border-radius: 4px;
	min-width: 100%;
	z-index: 10;
	max-height: 240px;
    overflow: auto;
}

.button-options-item {
	white-space: nowrap;
	text-align: left;
	font-size: 14px;
	line-height: 18px;
	padding: 5px 10px;
	align-items: center;
	display: flex;
}

.button-options-item >>> .icon {
	margin-right: 10px;
}

.button-options-item:hover {
	background-color: #236dc9;
}

.button-options-item.theme-green { 
	background-color: #4faa80;
}

.button-options-item.theme-green:hover { 
	background-color: #3e8865;
}

</style>
