import Vue from 'vue'

export default {

	props: ['staticCollection'],

	data: function() {

		return {
			scrolling: false,
			gatekeep: false,
			parent: false,
			references: {},
			refresh: [],
			responsive: {},
			is: {
				failed: false,
				loading: true,
				initialised: false
			},
			filter: {
				search: ''
			},
			params: {},
			pagination: {
				perpage: 24,
				page: 1
			},
			selected: [],
			index: [],
			filterTemplate: false,
			total: 0,
			collection: [],
			changedLive: ''
		}

	},

	created: function() {

		this.initiate()

	},

	beforeDestroy: function() {

		this.$_.each(this.refresh, function(name) {

			this.$live.$unlisten(name, this.loadRefresh)

		}.bind(this))

		this.$_.each(this.collection, function(item) {

			this.$_.each(this.responsive, function(handler, name) {

				this.$live.$off(name.replace(':id', item.id))

			}.bind(this))

		}.bind(this))

		this.$live.$off(this.changedLive + '/add', this.onLiveAdd.bind(this))
		this.$live.$off(this.changedLive + '/update', this.onLiveUpdate.bind(this), this._uid)
		//this.$live.$off(this.changedLive + '/delete', this.onLiveDelete.bind(this))

	},

	beforeRouteLeave: function(to, from, next) {

		this.$store.commit('stash', {
			name: this.stashName,
			data: {
				filter: this.$util.copy(this.filter),
				page: (this.pagination) ? this.pagination.page : 1
			}
		})

		next()

	},

	watch: {

		$path: function() {

			if (this.pagination) this.pagination.page = parseInt(this.$route.params.pagination) || 1

		},

		pagination: {
			deep: true,
			handler: function() {

				if (this.is.initialised) {

					this.load()

				}

			}
		},

		filter: {
			deep: true,
			handler: function() {

				if (this.is.initialised) {

					if (this.pagination) this.pagination.page = 1

					this.load(true)

					if (this.pagination) this.$router.push({ name: this.$route.meta.page.replace('.Page', ''), replace: true , query: this.$route.query })
					
				}

			}
		}

	},

	computed: {

		stashName: function() {

			return [this.$route.name.replace('.Page', '').replace(/\./g, '/')].join('/').toLowerCase()

		},

		sortedIndex: function() {

			return this.$_.pluck(this.collection, 'id')

		},	

		selectedIndex: function() {

			return (this.selected.length) ? this.selected : this.index

		}

	},

	methods: {

		initiate: function() {

			if (this.filterTemplate) {

				Vue.set(this, 'filter', this.$constants.filters[this.filterTemplate])
	
			}
	
			if (this.$route.params.filter) {
	
				this.$_.each(this.$route.params.filter, function(value, key) {
	
					Vue.set(this.filter, key, value)
	
				}.bind(this))
	
			}
	
			if (this.pagination) this.pagination.page = parseInt(this.$route.params.pagination) || 1
	
			if (this.$store.getters['stash'][this.stashName]) {
	
				Vue.set(this, 'filter', this.$util.copy(this.$store.getters['stash'][this.stashName].filter))
				if (this.pagination) this.pagination.page = this.$store.getters['stash'][this.stashName].page
	
			}
	
			if (this.onSetupFilter) this.onSetupFilter()
	
			this.load()
	
			this.changedLive = this.$endpoint(this.live)
	
			this.$_.each(this.refresh, function(name) {
	
				this.$live.$listen(name, this.loadRefresh)
	
			}.bind(this))
	
			this.$live.$on(this.changedLive + '/add', this.onLiveAdd.bind(this))
			this.$live.$on(this.changedLive + '/update', this.onLiveUpdate.bind(this), this._uid)
			//this.$live.$on(this.changedLive + '/delete', this.onLiveDelete.bind(this))

		},

		onLiveAdd: function(e) {

			this.$util.collection.add(this.collection, e)

		},

		onLiveUpdate: function(e) {

			this.$util.collection.update(this.collection, e)

		},

		onLiveDelete: function(e) {

			this.$util.collection.remove(this.collection, e)

		},

		getParams: function() {

			var params = {}

			if (this.pagination) {

				this.$_.each(this.pagination, function(value, key) {

					if (value) params[key] = value

				})

			}

			if (this.filter) {

				this.$_.each(this.filter, function(value, key) {

					if (value) params[key] = value

				})

			}

			if (this.params) {

				this.$_.each(this.params, function(value, key) {

					if (value) params[key] = value

				})

			}

			if (this.$route.meta.params) {

				this.$_.each(this.$route.meta.params, function(value, key) {

					params[key] = value

				})

			}

			return params

		},

		loadRefresh: function() {

			this.selected = []

			this.load(true)

		},

		load: async function(reload) {

			if (this.staticCollection) {

				this.collection = this.staticCollection

				this.is.loading = false
				this.is.initialised = true

				return

			}

			reload = reload || false

			if (!reload) this.is.loading = true

			if (this.gatekeep && !reload) {

				await this.$api.get([this.$endpoint(this.gatekeep), this.$route.params[this.$route.meta.parentKey]]).then(function(json) {

					this.parent = json.item

					this.$_.each(this.references, function(value, key) {

						if (json[key]) Vue.set(this.references, key, json[key])

					}.bind(this))

				}.bind(this), function() {

					if (this.onLoadFailed) this.onLoadFailed()

					this.is.failed = true
					this.is.loading = false
					this.is.initialised = true

				}.bind(this))

			}

			var params = this.getParams()

			if(!this.inline) this.$api.cancel()

			this.$api.get(this.$endpoint(this.endpoint), params).then(function(json) {

				this.$_.each(this.collection, function(item) {

					this.$_.each(this.responsive, function(handler, name) {

						this.$live.$off(name.replace(':id', item.id), handler)

					}.bind(this))

				}.bind(this))

				if (this.scrolling && !reload && this.pagination.page !== 1) {

					this.$_.each(json.collection, function(item) {

						this.collection.push(item)

					}.bind(this))

				} else {

					this.collection = json.collection
					
				}

				this.total = json.total
				this.index = json.index

				this.$_.each(json.collection, function(item) {

					this.$_.each(this.responsive, function(handler, name) {

						this.$live.$on(name.replace(':id', item.id), handler)

					}.bind(this))

				}.bind(this))

				this.$_.each(this.references, function(value, key) {

					if (json[key]) Vue.set(this.references, key, json[key])

				}.bind(this))

				this.is.loading = false
				this.is.initialised = true

				if(this.afterLoad) this.afterLoad()

			}.bind(this), function() {

				if (this.onLoadFailed) this.onLoadFailed()

				this.is.failed = true
				this.is.loading = false
				this.is.initialised = true

			}.bind(this))

		},

		onSorted: function(newIndex) {

			this.is.loading = true

			this.$api.put(this.$endpoint(this.endpoint), {
				old: this.selectedIndex,
				new: newIndex
			}).then(function() {

				var newOrder = []

				this.$_.each(newIndex, function(key) {

					newOrder.push(this.$util.collection.get(this.collection, key))

				}.bind(this))

				Vue.set(this, 'collection', newOrder)
				Vue.set(this, 'index', newIndex)

				this.is.loading = false

			}.bind(this))
			
		},

		onDeleteClick: function(id) {

			this.$router.push({
				name: this.$endpoint(this.edit || this.$route.meta.edit).replace(/.Edit$/, '.Delete'),
				params: {
					id: id || this.selected.join(',')
				}
			}) 
			
		},

		onCreateClick: function() {

			this.$router.push({
				name: this.$endpoint(this.edit || this.$route.meta.edit),
				params: {
					id: 'new'
				}
			})

		},

		onSelectToggle: function(id) {

			this.$util.array.toggle(this.selected, id)

		},

		isSelected: function(id) {

			return this.$_.contains(this.selected, id)

		},

		onEditClick: function(id) {

			this.$router.push({
				name: this.$endpoint(this.edit || this.$route.meta.edit),
				params: {
					id: id
				}
			})

		},

		onScrollLoad: function() {

			if (!this.is.loading) {

				this.pagination.page++

			}

		}

	}

}