<template>

<app-panel v-on:close="$emit('close')">

	<app-panel-title :text="title || 'Find items'" />

	<app-panel-content>
		
		<app-input-text v-model="search" :placeholder="placeholder || 'Search by name or tag...'" />

	</app-panel-content>

	<app-panel-check v-for="suggestion in $_.sortBy(suggestions, 'text')" :key="suggestion.id" :caret="true" :text="suggestion.text" :subtext="suggestion.subtext" :active="$_.contains(selected, suggestion.id)" v-on:toggle="onSuggestionToggle(suggestion.id)" />

	<app-panel-content :space="true">
		
		<app-button text="Confirm" v-if="suggestions.length" :disabled="!selected.length" v-on:click="$emit('save', selected)" />

	</app-panel-content>

</app-panel>

</template>

<script>

import { validationMixin } from 'vuelidate'

export default {

	mixins: [validationMixin],

	props: ['api', 'exclude', 'title', 'placeholder', 'params'],

	data: function() {

		return {
			search: '',
			selected: [],
			suggestions: []
		}

	},

	watch: {

		search: function() {

			this.onSearchChange()

		}

	},

	created: function() {

		this.$pubsub.$emit('offset', true)

	},

	beforeDestroy: function() {

		this.$pubsub.$emit('offset', false)

	},

	methods: {

		onSearchChange: function() {

			this.selected = []

			if (this.search) {

				var api = this.$_.isArray(this.api) ? this.api : [this.api]

				var suggestions = []

				this.$_.each(api, async function(endpoint) {

					var params = {
						text: this.search,
						exclude: this.exclude
					}

					if (this.params) {

						this.$_.each(this.params, function(value, key) {

							params[key] = value

						})

					}

					await this.$api.post(['suggest', endpoint], params).then(function(json) {
						
						this.$_.each(json.suggestions, function(item) {

							suggestions.push(item)

						}.bind(this))

					}.bind(this))

				}.bind(this))

				this.suggestions = suggestions

			} else {

				this.suggestions = []

			}

		},

		onSuggestionToggle: function(id) {

			this.$util.array.toggle(this.selected, id)

		}

	}

}

</script>

<style scoped>

</style>
