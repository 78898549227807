<template>

<app-table-cell-text class="date">

	<span>{{ item.date | formatDate($hourFormat) }}</span><span> on </span><span>{{ item.date | formatDate('dddd') }}</span>

</app-table-cell-text>

</template>

<script>

export default {

	props: ['item']

}

</script>

<style scoped>

.date {
	flex-direction: row!important;
}

.date span {
	margin-right: 4px;
}

</style>