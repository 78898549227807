<template>

<div class="head" :style="{gridTemplateColumns: layout}" :class="{'is-fixed': isFixed}">

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['layout', 'isFixed']
	
}

</script>

<style scoped>

.head {
	display: grid;
	grid-gap: 10px;
	padding: 0px 10px;
	border-bottom: 1px solid #f2f2f2;
}

.head.is-fixed {
	position: fixed;
    left: 0px;
    top: calc(var(--head-height) + 33px);
    width: 100%;
    z-index: 4;
    background-color: #fff;
}

</style>
