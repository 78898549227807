<template>

<app-table-cell class="action" :align="align" :class="{'is-large': large}" v-on:click.stop.native="$emit('click')">

	<div class="action-button" :data-theme="theme">

		<app-icon :icon="icon" />

	</div>

</app-table-cell>

</template>

<script>

export default {

	props: ['icon', 'align', 'theme', 'large']

}

</script>

<style scoped>

.action {
	text-align: right;
	padding: 0px;
}

.action.is-large {
	padding: 10px 0px;
}

.action-button {
	height: 24px;
	width: 24px;
	cursor: pointer;
	font-size: 16px;
	border-radius: 4px;
	line-height: 26px;
	background-color: #4082d3;
	text-align: center;
	color: #fff;
}

.action-button:hover {
	background-color: #236dc9;
}

.action-button[data-theme="white"] {
	background-color: #fff;
	border-color: #ffbe7a;
	color: #687589;
}

.action-button[data-theme="white"]:hover {
	border-color: #fff;
	color: #ffbe7a;
}

.action-button[data-theme="red"] {
	background-color: #c55b5b;
	border-color: #c55b5b;
	color: #fff;
}

.action-button[data-theme="green"] {
	background-color: #3e8865;
	border-color: #3e8865;
	color: #fff;
}

.action-button[data-theme="yellow"] {
	background-color: #ffbe7a;
	border-color: #ffbe7a;
	color: #fff;
}

.action.is-large .action-button {
	font-size: 16px;
	width: 32px;
	height: 32px;
	border-radius: 4px;
	line-height: 30px;
	border: 1px solid #eee;
	text-align: center;
}

</style>
