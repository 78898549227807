<template>

<div class="foot" v-if="!isScroller || pagination.page < pages">

	<div class="foot-total" v-if="!isScroller">

		{{ total }} result<template v-if="total > 1">s</template>

	</div>

	<div class="foot-scroller" v-if="isScroller">

		<app-icon icon="loading" />

	</div>

	<div class="foot-pages" v-if="pages > 1 && !isScroller">

		<template v-if="pages > 10 && pagination.page > 4">

			<router-link :to="{ name: $route.meta.page, params: { pagination: 1 } }" class="foot-pages-item is-end" :key="1">1</router-link>
			<div class="foot-pages-gap" v-if="pagination.page > 5">...</div>

		</template>
		
		<router-link :to="{ name: $route.meta.page, params: { pagination: i } }" class="foot-pages-item" :class="{'is-current': pagination.page === i}" v-for="i in limitedPages" :key="i">{{ i }}</router-link>

		<template v-if="pages > 10 && pagination.page < pages - 3">

			<div class="foot-pages-gap" v-if="pagination.page < pages - 4">...</div>
			<router-link :to="{ name: $route.meta.page, params: { pagination: pages } }" class="foot-pages-item is-end" :key="pages">{{ pages }}</router-link>
		
		</template>

	</div>

</div>

</template>

<script>

export default {

	props: ['pagination', 'total', 'isScroller'],

	computed: {

		limitedPages: function() {

			if (this.pages > 10) {

				var start = (this.pagination.page > 3) ? this.pagination.page - 3 : 1
				var end = (this.pagination.page < this.pages - 3) ? this.pagination.page + 3 : this.pages

				var pages = []

				for(var i=start; i<=end; i++) {

					pages.push(i)

				} 

				return pages

			} else {

				return this.pages

			}

		},

		pages: function() {

			return Math.ceil(this.total / this.pagination.perpage)

		}

	}
	
}

</script>

<style scoped>

.foot {
	display: flex;
	padding: 0px 10px;
	border-top: 1px solid #f2f2f2;
	padding: 10px 0px;
	align-items: center;
}

.foot-total {
	color: #687589;
	font-size: 14px;
	padding: 0px 10px;
	flex-shrink: 0;
}

.foot-pages {
	display: flex;
	justify-content: flex-end;
	flex-grow: 1;
	padding-right: 10px;
}

.foot-pages-gap {
	font-size: 14px;
	padding: 0px 4px;
	text-align: center;
	margin-left: 5px;
	height: 22px;
	color: #999;
	min-width: 22px;
}

.foot-pages-item {
	font-size: 14px;
	color: #687589;
	margin-left: 5px;
	background-color: #eee;
	border-radius: 4px;
	min-width: 22px;
	padding: 0px 4px;
	height: 22px;
	text-align: center;
	line-height: 22px;
}

.foot-pages-item:hover {
	background-color: #687589;
	color: #fff;
}

.foot-pages-item.is-current {
	background-color: #ffbe7a;
	color: #fff;
}

.foot-scroller {
	width: 100%;
	display: flex;
	justify-content: center;
	color: #4082d3;
}

</style>
