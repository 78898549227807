import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import cookies from 'js-cookie'
import moment from 'moment'

Vue.use(Vuex)

import modSession from './store/session'
import modPlatform from './store/platform'
import modContext from './store/context'
import modRegister from './store/register'
import modBasket from './store/basket'

var store = new Vuex.Store({

	modules: {
		session: modSession,
		platform: modPlatform,
		context: modContext,
		register: modRegister,
		basket: modBasket
	},

	state: {
		initiated: false,
		key: '',
		time: moment().utc().unix(),
		stash: []
	},

	getters: {

		initiated: function(state) {

			return state.initiated

		},

		key: function(state) {

			return state.key

		},

		time: function(state) {

			return state.time

		},

		stash: function(state) {

			return state.stash

		}

	},

	mutations: {

		initiated: function(state, value) {

			state.initiated = value

		},

		stash: function(state, e) {

			state.stash[e.name] = e.data

		},

		key: function(state, value) {

			state.key = value

			if (value) {
				
				cookies.set(process.env.VUE_APP_COOKIE, value)

			} else {

				cookies.remove(process.env.VUE_APP_COOKIE)

			}

			axios.defaults.headers.common['Authorization'] = value

		},

		time: function(state, value) {

			state.time = value

		}

	},

	actions: {

		init: async function(context) {

			if (!context.getters['initiated']) {

				if (cookies.get(process.env.VUE_APP_COOKIE) !== undefined) context.commit('key', cookies.get(process.env.VUE_APP_COOKIE))

				await context.dispatch('context/init', store)

				await context.dispatch('session/init', store).catch(function() {

					context.commit('key', undefined)

				})

				context.commit('initiated', true)
					
			}

		}

	}

})

export default store