<template>

<div class="check" :class="{'is-disabled': disabled}" :data-theme="theme" v-on:click="onClick">

	<div class="check-text">{{ text }}<small v-if="subtext">{{ subtext }}</small></div>

	<div v-if="active || !hideInactive" class="check-box" :class="{'is-active': active}"><app-icon :icon="icon || 'check'" /></div>

</div>

</template>

<script>

export default {

	props: ['text', 'subtext', 'disabled', 'theme', 'hideInactive', 'icon', 'active'],

	methods:  {

		onClick: function() {

			if (!this.disabled) this.$emit('toggle')

		}

	}

}

</script>

<style scoped>

.check {
	display: flex;
	flex-direction: row;
	min-height: 40px;
	align-items: center;
	color: #bfc4cb;
	padding: 0px 20px;
	cursor: pointer;
	margin-bottom: 4px;
	user-select: none;
}

.check.is-disabled {
	pointer-events: none;
	opacity: 0.75;
}

.check:first-child {
	margin-top: 20px;
}

.is-desktop .check:hover {
	background-color: #626e7f;
	color: #fff;
    border-radius: 4px;
    margin-left: 10px;
    margin-right: 10px;
	padding: 0px 10px;
}

.check-text {
	font-size: 14px;
	font-weight: 300;
	color: #bfc4cb;
	flex-grow: 1;
}

.check-text small {
	display: block;
	font-size: 12px;
	margin-top: 2px;
}

.check-box {
	width: 24px;
	height: 24px;
	background-color: #5f6d80;
	border-radius: 4px;
	text-align: center;
	font-size: 14px;
	color: #535d6a;
	line-height: 24px;
	cursor: pointer;
}

.is-desktop .check:hover .check-box {
	background-color: #626e7f;
	color: #626e7f;
}

.check-box.is-active,
.is-desktop .check:hover .check-box.is-active {
	background-color: #ffbe7a;
    color: #fff;
}

.check[data-theme="green"] .check-box.is-active,
.is-desktop .check[data-theme="green"]:hover .check-box.is-active {
	background-color: #4faa80;
    color: #fff;
}

</style>
