<template>

<div class="compose">

	<mentionable class="compose-mentionable" :keys="['@']" insert-space :items="items" @search="onSearch($event)">
		
		<input v-model="text" placeholder="Enter message..." class="compose-input" v-on:keydown="onKeyDown" />

		<template #no-result>
			<div class="mention-item is-empty">{{ searching ? 'Searching attendees...' : 'No matches found' }}</div>
		</template>
		
	</mentionable>

	<app-icon icon="compose" class="compose-button" v-on:click.native="onSubmit" v-if="!submitting" :class="{'is-disabled': text === '' || submitting}" />
	<app-icon icon="loading" class="compose-button" v-if="submitting" />

</div>

</template>

<script>

import { Mentionable } from 'vue-mention'

export default {

	props: ['loading', 'submitting'],
	
	components: {
		Mentionable
	},

	data: function() {

		return {
			items: [],
			searching: false,
			text: ''
		}

	},

	watch: {

		submitting: function(n) {

			if (!n) this.text = ''

		}

	},

	methods: {

		onSearch: async function(text) {

			this.searching = true

			await this.$api.get('convention/messages/mentions', {
				text: text
			}).then(function(response) {

				this.items = response.mentions

			}.bind(this))


			this.searching = false

		},

		onKeyDown: function(e) {

			if (e.key === 'Enter') {
				
				this.onSubmit()
				e.preventDefault()

			}

		},

		onSubmit: function() {

			if (this.text) this.$emit('submit', this.text)

		}

	}

}

</script>

<style scoped>

.compose {
	display: flex;
}

.compose-mentionable {
	flex-grow: 1;
}

.compose-input {
	height: 48px;
	padding: 12px;
	font-size: 16px;
	line-height: 20px;
	width: 100%;
	color: #687589;
}

.is-device .compose-input {
	font-size: 14px;
}

.compose-button {
	width: 40px;
	line-height: 40px;
	text-align: center;
	margin: 4px;
	border-radius: 4px;
	color: #fff;
	background-color: #4082d3;
	cursor: pointer;
	transition: all 100ms linear;
}

.compose-button.is-disabled {
	pointer-events: none;
	opacity: 0.75;
}

</style>
