<template>

<router-link :to="{name: tab.name, params: tab.params}" class="tab" :class="{'is-active': isActive}">

	{{ tab.text }}

	<div class="tab-count" v-if="tab.count">{{ tab.count }}</div>
		
</router-link>

</template>

<script>

export default {

	props: ['tab'],

	computed: {

		isActive: function() {

			return this.tab.name === this.$route.name || this.tab.name === this.$route.meta.default

		}

	}

}

</script>

<style scoped>

.tab {
	color: #687589;
	font-size: 14px;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-weight: 400;
	padding: 0px 20px;
	line-height: 80px;
	height: 80px;
	cursor: pointer;
	display: flex;
	align-items: center;
}

.is-tablet .tab {
	padding: 0px 10px;
	line-height: 64px;
	font-size: 14px;
	height: 64px;
	margin-right: 10px;
}

.is-mobile .tab {
	padding: 0px;
	line-height: 31px;
	font-size: 11px;
	height: 31px;
	margin-right: 0px;
	flex-grow: 1;
	letter-spacing: 0px;
	flex-shrink: 0;
	text-align: center;
}

.tab:hover {
	color: #333;
}

.tab.is-active {
	color: #333;
	height: 80px;
	font-weight: 600;
	background-color: #f2f7ff;
	border: 1px solid #eee;
    border-bottom: 0px;
}

.is-tablet .tab.is-active {
	height: 64px;
}

.is-mobile .tab.is-active {
	height: 32px;
}

.tab-count {
	background-color: #687589;
	color: #fff;
	height: 20px;
	font-size: 14px;
	border-radius: 4px;
	min-width: 20px;
	text-align: center;
	line-height: 20px;
	margin-left: 10px;
}

.tab.is-active .tab-count {
	background-color: #ffbe7a;
	color: #fff;
}

</style>
