<template>

<div class="banner" v-bgimage="item.image">

</div>
	
</template>

<script>

export default {

	props: ['item']

}

</script>

<style scoped>

.banner {
	height: 240px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

</style>