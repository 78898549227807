import Vue from "vue"

export default {

	computed: {

		$canVolunteer: function() {

			return (!this.$session.volunteers) ? this.$settings.volunteers.visibility === this.$constants.volunteers.settings.visibility.any : this.$settings.volunteers.visibility === this.$constants.volunteers.settings.visibility.any || (this.$settings.volunteers.visibility === this.$constants.volunteers.settings.visibility.whitelisted && this.$session.volunteers.whitelisted)


		},

		$session: function() {

			return this.$store.getters['session/get']

		},

		$isBadgeActive: function() {

			return this.$store.getters['session/badge'].status === this.$constants.badge.status.active

		},

		$isAdmin: function() {

			return this.$store.getters['session/badge'].admin

		},

		$isModerator: function() {

			return this.$store.getters['session/badge'].moderator

		},

		$hourFormat: function() {

			return (this.$store.getters['session/settings'].timeformat) ? 'H:mma' : 'h:mma'

		},

		$platform: function() {

			return this.$store.getters['platform/get']

		},

		$con: function() {

			return this.$store.getters['context/convention']

		},

		$badge: function() {

			return this.$store.getters['session/badge']

		},

		$convention: function() {

			return this.$store.getters['context/convention']

		},

		$permissions: function() {

			return (this.$store.getters['context/convention']) ? this.$store.getters['context/convention'].permissions : {}

		},

		$settings: function() {

			return (this.$store.getters['context/convention']) ? this.$store.getters['context/convention'].settings : {}

		},

		$authority: function() {

			return this.$store.getters['context/authority']

		},

		$org: function() {

			return this.$store.getters['context/organisation']

		},

		$organisation: function() {

			return this.$store.getters['context/organisation']

		},

		$path: function() {

			return this.$route.path

		},

		$keyPath: function() {

			return this.$store.getters['context/convention'].id + '/' + (this.$route.meta.keyPath || this.$route.path)

		},

		$loggedin: function() {

			return this.$store.getters['key']

		},

		$id: function() {

			return this.$store.getters['session/id']

		},

		$time: function() {

			return this.$store.getters['time']

		}

	},

	methods: {

		$setup: function(feature) {

			feature = this.$_.isArray(feature) ? feature : [feature]

			var enabled = false

			this.$_.each(feature, function(name) {

				if (!enabled) enabled = this.$store.getters['context/convention'].setup.service[name.replace('service/', '')]

			}.bind(this))

			return enabled

		},

		$authorised: function(permissions, level) {

			level = level || false

			if (this.$store.getters['context/authority'].type === this.$constants.team.members.type.founder && (!level || level === 'write')) return true
			if (this.$store.getters['context/authority'].type === this.$constants.team.members.type.founder && level === 'read') return false

			permissions = this.$_.isArray(permissions) ? permissions : [permissions]

			var authorised = false

			this.$_.each(permissions, function(permission) {

				if (!authorised) authorised = this.$store.getters['context/authority/permission'](permission)

			}.bind(this))

			if (level === 'write') {

				return authorised === 3
				
			} else if (level === 'read') {
	
				return authorised == 1

			} else {
				
				return authorised 

			}

		},

		$filter: function(name, value, args) {

			return this.$options.filters[name](value, args);

		},

		$layout: function(columns) {

			return this.$_.filter(this.$_.map(columns, function(value) {

				if (this.$_.isArray(value)) {

					return (value[1]) ? value[0] : false

				} else {

					return value

				}

			}.bind(this)), function(value) {

				return value !== false

			}).join(' ')

		},

		$endpoint: function(path) {

			if(!path) return path

			this.$_.each(this.$route.params, function(value, key) {

				path = path.replace(':' + key, value)

			})

			return path

		},

		$present: function(e) {

			this.$pubsub.$emit('present', e)

		},

		$notify: function(config) {

			if (this.$_.isArray(config.message)) config.message = config.message.join(' ')

			config.message = config.message.replace(' .', '.')

			Vue.$toast.open(config)

		},

		$action: function(name, dispatch, params) {

			if (!this.is[name]) {

				this.is[name] = true

				this.$store.dispatch(dispatch, params).then(function() {

					this.is[name] = false

				}.bind(this), function() {

					this.is[name] = false

				}.bind(this))

			}

		},

		$countdown: function(deadline, asSeconds) {

			asSeconds = asSeconds || false

			var difference = deadline - this.$time

			if (difference < 0) difference = 0

			if (asSeconds) return difference

			var minutes = Math.floor(difference / 60)
			var seconds = difference - (minutes * 60)

			if (difference === 0) {

				return 'a moment'

			} else if(minutes > 60) {

				var hours = Math.floor(minutes / 60);
				minutes -= hours * 60

				return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')

			} else {

				return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')
				
			}

		}

	}

}