<template>

<div class="modal" v-if="active">

	<div class="modal-box">

		<div class="modal-box-head"><app-icon icon="paperwork" /></div>

		<div class="modal-box-content">
		
			<div class="modal-box-content-text"><p v-html="text" /></div>

			<div class="modal-box-content-contract" v-html="contract" v-if="!loading" />
			<div class="modal-box-content-contract" v-if="loading"><app-icon icon="loading" /></div>

			<div class="modal-box-content-button">

				<app-button :disabled="loading" :loading="saving" v-on:click="onAcceptClick" text="Accept" />
				<app-button :disabled="saving || loading" v-on:click="onDeclineClick" theme="plain" text="Decline" />

			</div>

			<p class="modal-box-content-foot">By clicking accept you agree to the terms of the SaaS Agreement for cardboardevents.com</p>
		
		</div>

	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			contract: '',
			loading: true,
			accept: false,
			saving: false
		}

	},

	computed: {

		text: function() {

			var text = ['Hi! We\'ve updated our <b>SaaS (Software as a Service) Agreement</b>. Please take a moment to read it before continuing to use the platform for your conventions.']

			return text.join('')

		},

		active: function() {

			return this.$org && this.$store.getters['context/authority'].type === this.$constants.team.members.type.founder && !this.$store.getters['session/accepted'].saas

		}

	},
	
	created: function() {

		this.$api.get('session/contract').then(function(json) {

			this.contract = json.html

			this.loading = false

		}.bind(this))

	},

	methods: {

		onAcceptClick: async function() {

			this.saving = true

			await this.$api.post('session/contract')

		},

		onDeclineClick: function() {

			this.$router.push({
				name: 'Logout'
			})

		}

	}

}

</script>

<style>

.modal {
	position: fixed;
	left: 0px;
	top: 0px;
	bottom: 0px;
	right: 0px;
	background-color: rgba(0, 0, 0, 0.75);
	z-index: 10001;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-box {
	max-width: 480px;
	width: calc(100% - 20px);
}

.modal-box-head {
	height: 160px;
	color: #fff;
	border-radius: 4px 4px 0px 0px;
	background-color: #4082d3;
	line-height: 160px;
	text-align: center;
	font-size: 96px;
}

.modal-box-content {
	padding: 20px;
	background-color: #fff;
	border-radius: 0px 0px 4px 4px;
}

.modal-box-content-text {
	margin-bottom: 20px;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
}

.modal-box-content-text a {
	color: #387DD8;
	font-weight: 500;
}

.modal-box-content-text a:hover {
	text-decoration: underline;
}

.modal-box-content-text p {
	margin-bottom: 20px;
}

.modal-box-content-text p:last-child {
	margin-bottom: 0px;
}

.modal-box-content-date {
	text-align: center;
	margin: 20px 0px;
	font-size: 12px;
	color: #666;
}

.modal-box-content-button {
	display: flex;
	justify-content: center;
}

.modal-box-content-button .button {
	margin: 0px 10px!important;
}

.modal-box-content-foot {
	font-size: 12px;
	text-align: center;
	line-height: 16px;
	margin-top: 20px;
	color: #333;
}

.modal-box-content-contract {
	width: calc(100% + 40px);
	margin-left: -20px;
	padding: 10px;
	font-size: 12px;
	line-height: 16px;
	height: 400px;
	overflow: auto;
	margin-bottom: 20px;
	border-bottom: 1px solid #ccc;
	border-top: 1px solid #ccc;
}

.modal-box-content-contract p {
	margin-bottom: 10px;
}

.is-mobile .modal-box-content-contract {
	height: 200px;
}

.modal-box-content-contract .icon {
	font-size: 32px;
	color: #4082d3;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

</style>
