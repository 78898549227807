<template>

<div class="geolocation" :class="{'is-loading': is.loading, 'is-geolocated': position}">

	<app-icon icon="loading" class="geolocation-loading" />

	<div v-tooltip="'Close geolocation'" class="geolocation-close" v-on:click="$emit('close')"><app-icon icon="close" /></div>

	<div class="geolocation-info" v-if="!position">

		<b>How to use geolocation</b>Once you find a table for your event and are standing in that location, confirm the geolocation here so that other players can more easily find you.

	</div>

	<div class="geolocation-info" v-if="position && !host">

		<b>Finding your event</b>The host has placed a <span class="is-event" /> marker where the event will take place, you are shown as a <span class="is-you" /> marker. 

	</div>

	<div class="geolocation-info" v-if="position && host">

		<b>Placing your event</b>You have placed a <span class="is-event" /> marker where the event will take place, you are shown as a <span class="is-you" /> marker. 

	</div>

	<div class="geolocation-marker">
		<div class="geolocation-marker-distance" v-if="position">{{ distance }} meter<template v-if="distance !== 1">s</template> away</div>
	</div>

	<div class="geolocation-actions" v-if="host">

		<app-button text="Confirm" theme="yellow" v-on:click="$emit('confirm', geolocation)" v-if="!position" />
		<app-button text="Remove" theme="red" v-on:click="$emit('cancel')" v-if="position" />

	</div>

	<div ref="map" class="geolocation-map" :style="{transform: 'translate(-50%, -50%) rotate(' + rotation + 'deg)'}"></div>

</div>

</template>

<script>

export default {
	
	props: ['position', 'host'],

	data: function() {

		return {
			map: false,
			marker: false,
			line: false,
			rotation: 0,
			watch: false,
			is: {
				loading: true
			},
			geolocation: {
				lng: 0,
				lat: 0
			}
		}

	},

	created: function() {

		this.$nextTick(function() {

			// eslint-disable-next-line
			this.map = new google.maps.Map(this.$refs.map, {
				center: { lat: -34.397, lng: 150.644 },
				zoom: 22,
				fullscreenControl: false,
				disableDefaultUI: true,
				zoomControl: false,
				scaleControl: false,
				scrollwheel: false,
				draggable: false,
				disableDoubleClickZoom: true
			})

			if (this.position) {

				// eslint-disable-next-line
				this.marker = new google.maps.Marker({
					position: this.position,
					map: this.map,
					icon: {
						// eslint-disable-next-line
						path: google.maps.SymbolPath.CIRCLE,
						scale: 14,
						fillOpacity: 1,
						strokeWeight: 2,
						fillColor: '#ffbe7a',
						strokeColor: '#ffffff',
					},
				})

				// eslint-disable-next-line
				this.line = new google.maps.Polyline({
					path: [],
					strokeColor: "#dd4848",
					strokeOpacity: 1.0,
					strokeWeight: 6,
					map: this.map
				})

			}

			if (navigator.geolocation) {

				this.watch = navigator.geolocation.watchPosition(function(position) {

					if (position.coords.accuracy < 20) {

						this.geolocation = {
							lat: position.coords.latitude,
							lng: position.coords.longitude,
						}

						this.map.setCenter(this.geolocation)
						this.map.setZoom(22)

						if (position.coords.heading) {

							this.rotation = position.coords.heading.toString()

						}

						if (this.line) {
							
							this.line.setPath([
								// eslint-disable-next-line
								new google.maps.LatLng(this.position.lat, this.position.lng),
								// eslint-disable-next-line
								new google.maps.LatLng(this.geolocation.lat, this.geolocation.lng)
							])

						}

						this.is.loading = false
						
					}

				}.bind(this), function() {}, {
					enableHighAccuracy: true,
					timeout: 5000,
					maximumAge: 10000
				})
			
			}

		}.bind(this))

	},

	beforeDestroy: function() {

		if (this.watch) navigator.geolocation.clearWatch(this.watch)

	},

	computed: {

		distance: function() {

			var mk1 = this.geolocation
			var mk2 = this.position

			var R = 3958.8; // Radius of the Earth in miles
			var rlat1 = mk1.lat * (Math.PI/180); // Convert degrees to radians
			var rlat2 = mk2.lat * (Math.PI/180); // Convert degrees to radians
			var difflat = rlat2-rlat1; // Radian difference (latitudes)
			var difflon = (mk2.lng-mk1.lng) * (Math.PI/180); // Radian difference (longitudes)
			var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2)*Math.sin(difflat/2)+Math.cos(rlat1)*Math.cos(rlat2)*Math.sin(difflon/2)*Math.sin(difflon/2)));
			
			return (d * 1609.344).toFixed(1)

		}

	}

}

</script>

<style scoped>

.geolocation {
	background-color: #fff;
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 100;
}

.geolocation.is-loading .geolocation-marker,
.geolocation.is-loading .geolocation-info,
.geolocation.is-loading .geolocation-actions,
.geolocation.is-loading .geolocation-map {
	opacity: 0
}

.geolocation-loading {
	position: absolute;
	z-index: 2;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 48px;
	color: #ffbe7a;
	display: none;
}

.geolocation.is-loading .geolocation-loading {
	display: block;
}

.geolocation-map {
	position: absolute;
	width: 3000px;
	height: 3000px;
	top: 50%;
	left: 50%;
	transition: all 100ms linear;
	transform: translate(-50%, -50%) rotate(0deg);
	z-index: 1;
}

.geolocation-close {
	position: absolute;
	z-index: 2;
	width: 40px;
	height: 40px;
	font-weight: 400;
	top: 10px;
	right: 10px;
	cursor: pointer;
	background-color: #ffbe7a;
    color: #4d5b6e;
	border-radius: 50%;
	line-height: 42px;
	text-align: center;
	font-size: 24px;
}

.geolocation-close:hover {
	background-color: #d49959;
}

.geolocation-info {
	position: absolute;
	z-index: 2;
	top: 10px;
	padding: 10px;
	left: 10px;
	cursor: pointer;
	background-color: #ffbe7a;
    color: #4d5b6e;
	font-size: 14px;
	line-height: 18px;
	max-width: 250px;
}

.geolocation-info b {
	font-weight: 500;
	display: block;
}

.geolocation-info span {
	transform: translateY(2px);
}

.geolocation-info span.is-event {
	width: 12px;
	height: 12px;
	background-color: #ffbe7a;
	border: 2px solid #fff;
	border-radius: 50%;
	display: inline-block;
}

.geolocation-info span.is-you {
	width: 12px;
	height: 12px;
	background-color: #236dc9;
	border: 2px solid #fff;
	border-radius: 50%;
	display: inline-block;
}

.geolocation-actions {
	position: absolute;
	display: flex;
	flex-direction: row;
	z-index: 2;
	bottom: 10px;
	left: 50%;
	transform: translateX(-50%);
}

.geolocation-actions >>> .button {
	margin: 0px 10px;
}

.geolocation-actions-item:hover {
	background-color: #d49959;
}

.geolocation-marker {
	position: absolute;
	z-index: 2;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 30px;
	height: 30px;
	border-radius: 50%;
    background-color: #fff;
}

.geolocation-marker-distance {
	background-color: #236dc9;
	color: #fff;
	width: 160px;
	font-size: 14px;
	text-align: center;
	font-weight: 400;
	left: 50%;
	top: -16px;
	transform: translate(-50%, 0px);
	line-height: 24px;
	border-radius: 4px;
}

.geolocation-marker-distance:before {
	content: '';
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 8px 8px 8px;
	border-color: transparent transparent #236dc9 transparent;
	position: absolute;
	left: 50%;
	top: -8px;
	margin-left: -8px;
}

.geolocation-marker:before {
    content: '';
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 45px;
    background-color: #01a4e9;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}
  
.geolocation-marker:after {
    content: '';
    position: absolute;
    left: 0; 
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #236dc9;
	border-radius: 15px;
	box-shadow: 0 0 8px rgba(0,0,0,.3);
	animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
}

@keyframes pulse-ring {
	0% {
		transform: scale(.33);
	}
	80%, 100% {
		opacity: 0;
	}
}

@keyframes pulse-dot {
	0% {
		transform: scale(.8);
	}
	50% {
		transform: scale(1);
	}
	100% {
		transform: scale(.8);
	}
}

</style>
