<template>

<app-page-outer class="basket" :class="{'is-active': hasItems, 'is-expanded': is.expanded, 'is-loading': is.loading}">

	<com-bar v-if="!options.single" v-show="!is.loading" v-on:review="onReviewClick" v-on:close="onCancelClick" :expanded="is.expanded" />

	<com-review v-show="!is.loading" v-if="is.expanded" v-on:cancel="onCancelClick" v-on:loading="onLoadingChange" />

	<app-icon class="basket-loading" icon="loading" v-if="is.loading && is.expanded" />
	<div class="basket-loading-text" v-if="is.loading && is.expanded && is.loading !== true">{{ is.loading }}</div>

</app-page-outer>
		
</template>

<script>

import comBar from './basket/Bar'
import comReview from './basket/Review'

export default {

	components: {
		'com-bar': comBar,
		'com-review': comReview
	},

	data: function() {

		return {
			is: {
				expanded: false,
				loading: false
			}
		}

	},

	created: function() {

		this.$pubsub.$on('basket.review', this.onReviewClick)

	},

	beforeDestroy: function() {

		this.$pubsub.$off('basket.review', this.onReviewClick)

	},

	computed: {

		options: function() {

			return this.$store.getters['basket/options']

		},

		hasItems: function() {

			return this.$store.getters['basket/count']

		}

	},

	methods: {
		
		onLoadingChange: function(value) {

			this.is.loading = value

		},

		onReviewClick: function() {

			this.is.expanded = true

		},

		onCancelClick: function() {

			if (this.options.single) {

				this.$store.commit('basket/reset')

			}
			
			this.is.expanded = false

		}

	}
	
}

</script>

<style scoped>

.basket {
	position: fixed;
	bottom: 0px;
	left: 0px;
	width: 100%;
	flex-direction: column;
	justify-content: flex-start;
	height: 64px;
	background-color: #fff;
	transition: all 100ms linear;
	z-index: 10;
	bottom: -64px;
}

.is-mobile .basket {
	height: 80px;
	bottom: -80px;
}

.basket.is-active {
	bottom: 0px;
}

.basket-loading {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 48px;
	color: #ffbe7a;
}

.basket-loading-text {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, 48px);
	font-size: 20px;
	color: #343434;
	font-weight: 500;
}

.is-mobile .basket-loading-text {
	font-size: 16px;
}

.basket.is-expanded {
	height: calc(100vh - var(--vh-offset, 0px));
}

</style>