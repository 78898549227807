<template>

<app-input :prefix="prefix" :label="label" :stacked="stacked" :no-line="noLine" :notes="notes" :disabled="disabled" :locked="locked" :validation="validation">

	<transition-group name="list" tag="div" class="gallery">

		<div class="gallery-item" v-for="(item, index) in value" :key="item.image" v-bgimage="item.image">

			<app-icon icon="caret-left" v-tooltip="'Move left'" class="gallery-item-button" v-on:click.native="onLeftClick(index)" :disabled="index === 0" />
			<app-icon icon="edit" v-tooltip="'Edit image'" class="gallery-item-button" v-on:click.stop.native="onEditClick(index)" />
			<app-icon icon="delete" v-tooltip="'Delete image'" class="gallery-item-button" v-on:click.native="onDeleteClick(index)" />
			<app-icon icon="caret-right" v-tooltip="'Move right'" class="gallery-item-button" v-on:click.native="onRightClick(index)" :disabled="index === value.length - 1" />

		</div>

		<div class="gallery-item is-insert" key="insert" v-tooltip="'Click to insert'" v-on:click="onInsertClick">{{ placeholder }}</div>

	</transition-group>

	<com-image v-if="is.expanded" :item="selectedItem" v-on:close="onPanelClose" v-on:save="onItemSave" />

</app-input>

</template>

<script>

import comImage from './gallery/Image'
import Vue from 'vue'

export default {

	props: ['label', 'password', 'toggle', 'prefix', 'locked', 'validation', 'numeric', 'autogrow', 'disabled', 'value', 'noLine', 'stacked', 'placeholder', 'notes', 'maxlength'],

	components: {
		'com-image': comImage
	},

	data: function() {

		return {
			is: {
				expanded: false
			}
		}

	},

	computed: {

		selectedItem: function() {

			return (this.is.expanded === true || this.is.expanded === false) ? false : this.$util.copy(this.value[this.is.expanded - 1])

		}

	},

	methods: {

		onLeftClick: function(index) {

			if (index > 0) {

				var value = this.$util.copy(this.value)

				var removed = value.splice(index, 1)

				value.splice(index - 1, 0, removed[0])

				this.$emit('input', value)

			}

		},

		onRightClick: function(index) {

			if (index < this.value.length) {

				var value = this.$util.copy(this.value)

				var removed = value.splice(index, 1)

				value.splice(index + 1, 0, removed[0])

				this.$emit('input', value)

			}

		},

		onDeleteClick: function(index) {

			var value = this.$util.copy(this.value)

			value.splice(index, 1)

			this.$emit('input', value)

		},

		onEditClick: function(index) {

			this.is.expanded = index + 1

			this.$pubsub.$emit('offset', this.is.expanded)

		},

		onItemSave: function(model) {

			var value = this.$util.copy(this.value)

			if (this.is.expanded === true) {

				value.push(model)

			} else {

				Vue.set(value, this.is.expanded - 1, model)

			}

			this.$emit('input', value)

			this.onPanelClose()

		},

		onInsertClick: function() {

			if (!this.is.expanded) {

				this.is.expanded = true

				this.$pubsub.$emit('offset', this.is.expanded)

			}

		},

		onPanelClose: function() {

			this.is.expanded = false

			this.$pubsub.$emit('offset', this.is.expanded)

		}

	}

}

</script>

<style scoped>

.list-move,
.list-enter-active,
.list-leave-active {
	transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
	opacity: 0;
}

.list-leave-active {
	position: absolute;
}

.gallery {   
	min-height: 160px;
	display: flex;
	padding: 5px;
}

.gallery-item {
	width: 160px;
	height: 160px;
	border-radius: 10px;
	background-position: 50% 50%;
	background-size: cover;
	margin: 5px;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	user-select: none;
	background-color: #fff;
}

.gallery-item-button {
	width: 32px;
	height: 32px;
	cursor: pointer;
	line-height: 32px;
	text-align: center;
	font-size: 16px;
	z-index: 2;
	color: #fff;
	opacity: 0;
	transition: opacity 100ms linear;
}

.gallery-item:not(.is-insert):before {
	content: '';
	background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.5) 100%);
	position: absolute;
	left: 0px;
	transition: opacity 100ms linear;
	top: 0px;
	width: 160px;
	height: 160px;
	z-index: 1;
	border-radius: 10px;
	opacity: 0;
}


.gallery-item:hover .gallery-item-button,
.gallery-item:hover:before {
	opacity: 1;
}

.gallery-item.is-insert {
	align-items: center;
	border: 2px dashed #eee;
	font-size: 24px;
	text-align: center;
	padding: 10px;
	color: #ddd;
	cursor: pointer;
	font-weight: 500;
}

.gallery-item.is-insert:hover {
	border-color: #ccc;
	color: #ccc;
}

</style>
