<template>

<div class="filters">

	<slot></slot>

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.filters {
	width: 100%;
	background-color: #fff;
	height: 33px;
	display: flex;
	flex-direction: row;
	flex-shrink: 0;
	border-bottom: 1px solid #eee;
}

.filters > div {
	border-right: 1px solid #eee;
	min-width: 64px;
}

.filters > div:last-child {
	border-right: 0px;
}

</style>
