<template>

<app-table-cell-text class="actions">

	<app-events-row-actions-action :loading="is.loading === 'hide'" v-on:click="onHideClick" tooltip="Hide event" icon="hide" v-if="$route.name === 'Convention.Schedule.Personal' && item.session.can.hide" />

	<template v-if="!isTicketed">

	<app-events-row-actions-action :loading="is.loading === 'sit'" :danger="item.session.is.clashing" v-on:click="onSitClick" :tooltip="(item.session.is.clashing) ? 'Schedule clash' : 'Take a seat'" :icon="(item.session.is.clashing) ? 'clash' : 'join'" v-if="item.session.can.join" />
	<app-events-row-actions-action :loading="is.loading === 'stand'" v-on:click="onStandClick" tooltip="Leave your seat" icon="leave" v-if="item.session.can.leave"  />
	
	</template>
	
	<template v-if="isTicketed">

	<app-events-row-actions-action :danger="item.session.is.clashing" v-on:click="onSitClick" :tooltip="(item.session.is.clashing) ? 'Schedule clash' : 'Get ticket'" :icon="(item.session.is.clashing) ? 'clash' : 'ticket'" v-if="item.session.can.join" />
	<app-events-row-actions-action v-on:click="onStandClick" tooltip="Cancel ticket" theme="green" icon="ticket" v-if="item.session.can.leave" />
	
	</template>

	<app-events-row-actions-action :loading="is.loading === 'shortlist'" v-on:click="onShortlistClick" tooltip="Add to shortlist" icon="shortlist" v-if="item.session.can.shortlist" />
	<app-events-row-actions-action :loading="is.loading === 'shortlisted'" v-on:click="onShortlistedClick" tooltip="Remove from shortlist" icon="shortlisted" :active="true" v-if="item.session.can.unshortlist" />
	
</app-table-cell-text>

</template>

<script>

export default {

	props: ['item'],

	data: function() {

		return {
			is: {
				loading: false
			}
		}

	},

	computed: {

		isTicketed: function() {

			return this.item.ticket

		}

	},

	methods: {
		
		onHideClick: function() {

			this.is.loading = 'hide'

			this.$api.post(['convention/schedule/event', this.item.id, 'hide']).then(function() {

				this.is.loading = false

				this.$notify({
					message: ['You have successfully hidden the event.']
				})

				this.$emit('action', {
					name: 'hide',
					id: this.item.id
				})

			}.bind(this))

		},
		
		onSitClick: function() {

			if (this.item.session.is.clashing || this.isTicketed) {

				this.$router.push({
					name: (this.isTicketed) ? 'Convention.Schedule.Event.Ticket' : 'Convention.Schedule.Event.Join',
					params: {
						id: this.item.id
					}
				})

			} else {

				this.is.loading = 'sit'

				this.$api.post(['convention/schedule/event', this.item.id, 'seat']).then(function() {

					this.is.loading = false

					this.$notify({
						message: ['You have successfully taken a seat for the event.']
					})

					this.$emit('action', {
						name: 'sit',
						id: this.item.id
					})

				}.bind(this))
				
			}

		},

		onStandClick: function() {

			if (this.isTicketed) {

				this.$router.push({
					name:'Convention.Schedule.Event.Ticket',
					params: {
						id: this.item.id
					}
				})

			} else {

				this.is.loading = 'stand'

				this.$api.delete(['convention/schedule/event', this.item.id, 'seat']).then(function() {

					this.is.loading = false

					this.$notify({
						message: ['You have successfully left your seat for the event.']
					})

					this.$emit('action', {
						name: 'stand',
						id: this.item.id
					})

				}.bind(this))

			}

		},

		onShortlistClick: function() {

			this.is.loading = 'shortlist'

			this.$api.post(['convention/schedule/event', this.item.id, 'shortlist']).then(function() {

				this.is.loading = false

				this.$notify({
					message: ['You have successfully added the event to your shortlist.']
				})

				this.$emit('action', {
					name: 'shortlist',
					id: this.item.id
				})

			}.bind(this))

		},

		onShortlistedClick: function() {

			this.is.loading = 'shortlisted'

			this.$api.delete(['convention/schedule/event', this.item.id, 'shortlist']).then(function() {

				this.is.loading = false

				this.$notify({
					message: ['You have successfully removed the event from your shortlist.']
				})

				this.$emit('action', {
					name: 'unshortlist',
					id: this.item.id
				})

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.actions {
	flex-direction: row!important;
	justify-content: flex-end;
	flex-shrink: 0;
}

</style>