export default {

	admin: {
		signup: {
			status: {
				pending: 0,
				approved: 1,
				rejected: 2
			}
		}
	},

	assistant: {
		login: {
			types: {
				1: 'Account',
				2: 'Code'
			},
			type: {
				account: 1,
				code: 2
			},
			statuses: {
				1: 'Enabled',
				0: 'Disabled'
			}
		},
		scopes: {
			1: 'Badge Checkin',
			2: 'Library Checkout',
			3: 'Event Tickets',
			4: 'Report Game Issue',
			5: 'Badge Printing'
		},
		scopesIcon: {
			1: 'assistant.scope.checkin',
			2: 'assistant.scope.checkout',
			3: 'assistant.scope.tickets',
			4: 'assistant.scope.reports',
			5: 'assistant.scope.badges'
		},
		scopesRoute: {
			1: 'Checkin.Dashboard',
			2: 'Checkout.Dashboard',
			3: 'Tickets.Dashboard',
			4: 'Reports.Dashboard',
			5: 'Badges.Dashboard'
		}
	},

	input: {
		validation: {
			email: 1,
			phone: 2,
			uniqueemail: 3
		},
		role: {
			firstname: 1,
			lastname: 2,
			email: 3
		},
		type: {
			textbox: 1,
			toggle: 2,
			select: 3,
			checklist: 4
		},
		name: {
			1: 'Textbox',
			2: 'Toggle',
			3: 'Select',
			4: 'Checklist'
		}
	},

	page: {
		type: {
			content: 4,
			redirect: 5
		},
		types: {
			4: 'Content',
			5: 'Redirect'
		}
	},

	block: {
		type: {
			text: 1,
			banner: 2,
			registration: 3,
			payment: 4,
			map: 5,
			video: 6,
			gallery: 7,
			addons: 8,
			capture: 9,
			feature: 10,
			image: 11,
			thirdparty: 12,
			items: 13
		},
		icon: {
			1: 'block.text',
			2: 'block.banner',
			3: 'block.registration',
			4: 'block.payment',
			5: 'block.map',
			6: 'block.video',
			7: 'block.gallery',
			8: 'block.addons',
			9: 'block.capture',
			10: 'block.feature',
			11: 'block.image',
			12: 'block.thirdparty',
			13: 'block.items'
		}
	},

	filters: {
		events: {
			sort: 'start',
			search: '',
			ticket: false,
			layout: 0,
			times: [],
			lobbies: [],
			days: [],
			setup: {
				age: 9,
				experience: 0,
				duration: {
					from: 0,
					to: 360
				},
				seats: {
					total: {
						from: 1,
						to: 10
					},
					available: {
						from: 0,
						to: 9
					}
				}
			},
			game: {
				count: {
					recommended: {
						from: 1,
						to: 10
					},
					best: {
						from: 1,
						to: 10
					}
				},
				weight: {
					from: 0,
					to: 5
				},
				year: {
					from: 1972,
					to: 2022
				},
				language: {
					from: 1,
					to: 5
				}
			},
			rating: {
				seal: {
					excellence: 0,
					approval: 0
				},
				recommended: 0,
				community: {
					from: 0,
					to: 10
				},
				rank: {
					from: 0,
					to: 1000
				}
			},
			other: {
				designers: 0,
				publishers: 0,
				categories: 0,
				mechanics: 0,
				families: 0
			}
		},
		library: {
			sort: 'name',
			search: '',
			layout: 'list',
			game: {
				count: {
					recommended: {
						from: 1,
						to: 10
					},
					best: {
						from: 1,
						to: 10
					}
				},
				weight: {
					from: 0,
					to: 5
				},
				year: {
					from: 1972,
					to: 2022
				},
				language: {
					from: 1,
					to: 5
				}
			},
			rating: {
				community: {
					from: 0,
					to: 10
				},
				rank: {
					from: 0,
					to: 1000
				}
			},
			other: {
				designers: 0,
				publishers: 0,
				categories: 0,
				mechanics: 0,
				families: 0
			}
		}
	},

	notification: {
		type: {
			event: {
				create: 1,
				join: 2,
				leave: 3,
				cancel: 4,
				time: 5,
				location: 6,
				propose: 8,
				accept: 9,
				reject: 10
			},
			buddies: {
				suggestions: 7
			},
			interest: {
				suggestion: 11,
				match: 12
			},
			play: {
				winner: 15
			},
			announcement: 13,
			message: 16,
			badge: {
				registered: 17,
				assigned: 18,
				transferred: 27
			},
			checkout: {
				available: 20,
				unavailable: 21
			},
			contest: {
				scan: 22,
				message: 23,
				register: 24,
				unregister: 25,
				award: 26
			},
			volunteer: {
				accept: 28,
				reject: 29,
				approve: 30
			}
		},
		icon: {
			1: 'notification.event.create',
			2: 'notification.event.join',
			3: 'notification.event.leave',
			4: 'notification.event.cancel',
			5: 'notification.event.time',
			6: 'notification.event.location',
			8: 'notification.event.propose',
			9 :'notification.event.accept',
			10: 'notification.event.reject',
			7: 'notification.buddies.suggestions',
			11: 'notification.interest.suggestion',
			12: 'notification.interest.match',
			13: 'notification.announcement',
			15: 'notification.play.winner',
			16: 'notification.message',
			17: 'notification.badge.registered',
			18: 'notification.badge.assigned',
			19: 'notification.play.won',
			20: 'notification.checkout.available',
			21: 'notification.checkout.unavailable',
			22: 'notification.contest.scan',
			23: 'notification.contest.message',
			24: 'notification.contest.register',
			25: 'notification.contest.unregister',
			26: 'notification.contest.award',
			27: 'notification.badge.transferred',
			28: 'notification.volunteer.accept',
			29: 'notification.volunteer.reject',
			30: 'notification.volunteer.approve',
		},
		mini: {
			28: 'accept.plain',
			29: 'reject.plain',
			30: 'accept.plain',
		},
		theme: {
			28: 'green',
			29: 'red',
			30: 'green'
		}
	},	

	badge: {
		method: {
			registered: 1,
			created: 2,
			unassigned: 3,
			assigned: 4,
			transferred: 5
		},
		status: {
			pending: 0,
			active: 1,
			transferred: 2
		}
	},

	billing: {
		charge: {
			type: {
				1: 'Registered Badge',
				2: 'Created Badge',
				3: 'Sale',
				4: 'Card Processing'
			}
		},
		transaction: {
			type: {
				1: 'Payment',
				2: 'Refund'
			}
		}
	},

	registration: {
		sale: {
			status: {
				1: 'Completed',
				2: 'Refunded',
				3: 'Partial refund',
				refunded: 2,
				completed: 1,
				partial: 3
			},
			type: {
				registration: 1,
				store: 2,
				thirdparty: 3,
				comp: 4,
				ticket: 5
			},
			types: {
				1: 'Registration',
				2: 'Store',
				3: 'Third Party',
				4: 'Admin Comp'
			},
			statusType: {
				1: 'success',
				2: 'danger',
				3: 'warning'
			}
		},
		payment: {
			new: 0,
			success: 1,
			processing: 2,
			failed: 3,
			error: 4
		},
		input: {
			validation: {
				email: 1,
				phone: 2,
				uniqueemail: 3
			},
			role: {
				firstname: 1,
				lastname: 2,
				email: 3
			},
			type: {
				textbox: 1,
				toggle: 2,
				select: 3,
				checklist: 4
			},
			name: {
				1: 'Textbox',
				2: 'Toggle',
				3: 'Select',
				4: 'Checklist'
			}
		},
		discount: {
			failed: {
				'context': 'You cannot use this discount here.',
				'not-found': 'This is not a valid code.',
				'badge-use': 'You have used this code the maximum number of times.',
				'required': 'You have not already purchased the required items.',
				'badge-types': 'Your badge type does is not eligible.',
				'min-spend': 'You have not added the minimum spend required.',
				'from-date': 'This code is not active yet.',
				'to-date': 'This code is no longer active.',
				'all-use': 'This code has been used the maximum number of times by attendees.',
				'applicable-items': 'You have not added any eligible items to your basket.',
				'items-min': 'You have not added the minimum number of elgibile items.',
				'items-max': 'You have exceeded the maximum number of elgibile items.'
			},
			context: {
				registration: 1,
				store: 2,
				thirdparty: 3,
				ticketoffice: 5
			},
			contextName: {
				1: 'Registration Page',
				2: 'Store Category',
				3: 'Third Party Page',
				5: 'Event Ticket Office'
			},
			status: {
				offline: 0,
				online: 1
			},
			statusName: {
				0: 'Offline',
				1: 'Online'
			},
			statusDescription: {
				0: 'The discount cannot be used even if eligible.',
				1: 'The discount can be used when eligible.'
			},
			type: {
				amount: 1,
				percent: 2,
				comp: 3
			},
			typeName: {
				1: 'Amount',
				2: 'Percent',
				3: 'Comp'
			},
			typeDescription: {
				1: 'An amount will be deducted from the total.',
				2: 'A percentage of the eligible total will be deducted.',
				3: 'Gift one or more specified items as comps.'
			},
			eligible: {
				any: 0,
				type: 1,
				tag: 2,
				item: 3
			},
			eligibleName: {
				0: 'Any item',
				1: 'By Type',
				2: 'By Tag',
				3: 'Specific items'
			},
			eligibleDescription: {
				0: 'The discount applies to any items added to the basket.',
				1: 'The discount only applies to items of the selected types.',
				2: 'The discount only applies to items with the selected tags.',
				3: 'The discount only applies to the selected items.',
			}
		},
		page: {
			defaultName: {
				0: 'No',
				1: 'Yes'
			},
			defaultDescription: {
				0: 'This is a page that can only be accessed by sharing the specific page URL.',
				1: 'This is the default landing page when someone visits the convention registration URL. You can only have one homepage.'
			},
			types: {
				1: 'Registration',
				2: 'Data capture',
				3: 'Information',
				6: 'Third Party Sales'
			},
			type: {
				registration: 1,
				capture: 2,
				info: 3,
				thirdparty: 6
			},
			typeName: {
				1: 'Registration',
				2: 'Data capture',
				3: 'Information',
				6: 'Third Party Sales'
			},
			typeDescription: {
				1: 'Allows users to register one or more badges and any applicable add-ons.',
				2: 'Allows users to fill in a form and capture their data (e.g. Exhibitor Sign-up).',
				3: 'Allows the presentation of useful public information (e.g. Accommodation Links).',
				6: 'Allows the sale of items without needing a badge or registration (e.g. Advertising).'
			}
		},
		variant: {
			status: {
				offline: 0,
				online: 1
			},
			statusName: {
				0: 'Offline',
				1: 'Online'
			},
			statusDescription: {
				0: 'The variant does not appear to purchase.',
				1: 'The variant appears and is available, when applicable, to purchase.'
			},
		},
		category: {
			status: {
				offline: 0,
				online: 1
			},
			statusName: {
				0: 'Offline',
				1: 'Online'
			},
			statusDescription: {
				0: 'The category does not appear to browse.',
				1: 'The category appears and is available, when applicable, to browse.'
			},
			restrict: {
				no: 0,
				yes: 1
			},
			restrictName: {
				0: 'Any',
				1: 'Restricted'
			},
			restrictDescription: {
				0: 'Any badge holder is able to see this category.',
				1: 'Only holders of specific badge types are able to see this category.'
			},
		},
		item: {
			event: {
				any: 0,
				specific: 1
			},
			eventName: {
				0: 'Any applicable',
				1: 'Specific'
			},
			eventDescription: {
				0: 'The ticket is generic and can be used to take a seat at any event that accepts this ticket type.',
				1: 'The ticket is for a specific scheduled event and will automatically seat the attendee upon purchase.'
			},
			variants: {
				no: 0,
				yes: 1
			},
			variantsName: {
				0: 'Single price',
				1: 'Multiple prices'
			},
			variantsDescription: {
				0: 'The item has a single fixed price.',
				1: 'The item has variants which may or may not differ in price (e.g. t-shirt sizes).'
			},
			wildcard: {
				no: 0,
				yes: 1
			},
			wildcardName: {
				0: 'Never',
				1: 'Allowed'
			},
			wildcardDescription: {
				0: 'If item is comped then a specific variant must be specified.',
				1: 'If item is comped a variant doesn\'t have to be specified and the attendee can select a specific variant later (e.g. t-shirt size).'
			},
			status: {
				offline: 0,
				online: 1
			},
			statusName: {
				0: 'Offline',
				1: 'Online'
			},
			statusDescription: {
				0: 'The item does not appear to purchase.',
				1: 'The item appears and is available, when applicable, to purchase.'
			},
			restrict: {
				no: 0,
				yes: 1
			},
			restrictName: {
				0: 'All badge types',
				1: 'Restricted badge types'
			},
			restrictDescription: {
				0: 'Any badge holder is eligible to see and purchase this item.',
				1: 'Only holders of specific badge types are eligible to see and purchase this item.'
			},
			type: {
				badge: 1,
				merchandise: 2,
				other: 3,
				ticket: 4
			},
			typeName: {
				1: 'Badge',
				2: 'Merchandise',
				4: 'Ticket',
				3: 'Charge',
			},
			typeDescription: {
				1: 'Represents a price for a specific badge type (e.g. weekend badge).',
				2: 'Represents a price, or prices, of a piece of merchandise (e.g. t-shirt).',
				3: 'Represents a price that can be added to registrations (e.g. processing charge).',
				4: 'Represents a price for an event ticket type.'
			}
		}
	},
	
	games: {
		status: {
			available: 1,
			checkedout: 2
		},
		statusText: {
			1: 'Available',
			2: 'Checked out'
		}
	},

	checkout: {
		loan: {
			status: {
				active: 1,
				returned: 2
			}
		},
		report: {
			status: {
				reported: 1,
				confirmed: 2,
				resolved: 3,
				closed: 4
			},
			statusName: {
				1: 'Reported',
				2: 'Confirmed',
				3: 'Resolved',
				4: 'Closed'
			},
			statusTheme: {
				1: 'red',
				2: 'yellow',
				3: 'green',
				4: 'default'
			}
		}
	},

	store: {
		commit: {
			update: 1,
			add: 2,
			remove: 3
		}
	},

	status: {
		loading: 1,
		success: 2,
		failed: 3
	},

	volunteers: {
		settings: {
			visibility: {
				any: 1,
				whitelisted: 2,
				managed: 3
			},
			visibilityName: {
				1: 'Any',
				2: 'Pre-approved',
				3: 'Admin'
			},
			visibilityDescription: {
				1: 'Anyone can see the volunteer section',
				2: 'Only pre-approved users can see the volunteer section.',
				3: 'No one can see the volunteer section - it is all managed in the backend.'
			},
			signups: {
				off: 0,
				on: 1
			},
			signupsName: {
				0: 'Off',
				1: 'On'
			},
			signupsDescription: {
				0: 'You do not want attendees to be able to register their interest in volunteering.',
				1: 'You want attendees to be able to register their interest in volunteering.'
			},
		},
		whitelist: {
			type: {
				all: 1,
				specific: 2
			},
			typeName: {
				1: 'All',
				2: 'Specific'
			},
			typeDescription: {
				1: 'Once approved can apply to any available volunteer roles.',
				2: 'After approval can only apply to specified volunteer roles.'
			},
		},
		assignment: {
			status: {
				confirmed: 1,
				pending: 2,
				rejected: 3
			}
		},
		slot: {
			status: {
				open: 1,
				full: 2
			}
		},
		role: {
			eligibility: {
				all: 1,
				any: 1,
				vetted: 2,
				review: 2,
				whitelisted: 3,
				preapproved: 3,
				managed: 4,
				assign: 4
			},
			eligibilityName: {
				1: 'Any',
				2: 'Review',
				3: 'Pre-approved',
				4: 'Assign'
			},
			eligibilityDescription: {
				1: 'Any badge holder can volunteer for this role without any approval process.',
				2: 'Any badge holder can volunteer for this role but will be reviewed by an admin before approval.',
				3: 'Only pre-approved badge holders can volunteer for this role.',
				4: 'No one can volunteer for this role directly - an admin must assign them instead.'
			},
			status: {
				open: 1,
				closed: 2
			},
			statusName: {
				1: 'Open',
				2: 'Closed'
			},
			statusDescription: {
				1: 'Open to new volunteers per eligibility rule.',
				2: 'Closed to new volunteers except those assigned by admins.'
			},
			type: {
				schedule: 1,
				rollcall: 2
			},
			typeName: {
				1: 'Schedule',
				2: 'Rollcall'
			},
			typeDescription: {
				1: 'There are multiple scheduled timeslots that can be specifically volunteered for.',
				2: 'There is a single list of volunteers maintained for this role.'
			}
		}
	},

	schedule: {

		settings: {

			contests: {

				enabled: {
					hide: 0,
					auto: 1,
					show: 2
				},

				enabledName: {
					0: 'Hide',
					1: 'Auto',
					2: 'Show'
				},

				enabledDescription: {
					0: 'Contests section never shown even if a contest exists.',
					1: 'Contests section only shown if a contest exists.',
					2: 'Contests section always shown in menu even if no contest exists.'
				}

			},

			lobbies: {

				enabled: {
					hide: 0,
					auto: 1,
					show: 2
				},

				enabledName: {
					0: 'Hide',
					1: 'Auto',
					2: 'Show'
				},

				enabledDescription: {
					0: 'Lobbies section never shown even if a lobby exists.',
					1: 'Lobbies section only shown if a lobby exists.',
					2: 'Lobbies section always shown in menu even if no lobby exists.'
				}

			},

			events: {

				submit: {

					enabled: {
						hidden: 0,
						closed: 1,
						timed: 2,
						open: 3
					},

					enabledName: {
						0: 'Hide',
						1: 'Closed',
						2: 'Timed',
						3: 'Show'
					},

					enabledDescription: {
						0: 'Attendees cannot submit events and there is no submit button.',
						1: 'Attendees cannot submit events and instead the submit page will have a message.',
						2: 'Attendees can submit events during a specific date range.',
						3: 'Attendees can submit events.'
					}

				}	

			}

		},

		type: {

			public: {
				no: 0,
				yes: 1
			},

			publicName: {
				0: 'Private',
				1: 'Public'
			},

			publicDescription: {
				0: 'Only admins, moderators, and any additional permitted attendees can submit events for this type.',
				1: 'All attendees can submit events for this type.'
			},

			default: {
				no: 0,
				yes: 1
			},

			defaultName: {
				0: 'No',
				1: 'Yes'
			},

			defaultDescription: {
				0: 'This is an optional type when submitting an event.',
				1: 'This is the default type that is automatically selected when submitted an event.'
			},

			games: {
				no: 0,
				yes: 1
			},

			gamesName: {
				0: 'No',
				1: 'Yes'
			},

			gamesDescription: {
				0: 'This type is not for playing a game and so some options are hidden when submitting.',
				1: 'This type is for playing a game and so additional options are available when submitting (e.g. Min. Experience).'
			}

		},

		event: {

			status: {
				live: 2,
				proposal: 1
			},

			location: {

			},

			locationName: {
				0: 'Yes',
				1: 'No'
			},

			locationDescription: {
				0: 'You will enter directions or select an official table. You can change the location at a later date.',
				1: 'Your event location is set as Pending and you can update it at a later date.'
			},

			hosting: {

			},

			hostingName: {
				0: 'Yes',
				1: 'No'
			},

			hostingDescription: {
				0: 'You will be automatically seated at the event.',
				1: 'You will not be seated at the event and can submit or join other events at the same time.'
			},

			experience: {},

			experienceName: {
				0: 'All welcome',
				1: 'Learning game',
				2: 'Some teaching',
				3: 'Beginners welcome',
				4: 'Experienced only'
			},

			experienceDescription: {
				0: 'Players with any experience welcome.',
				1: 'Players with any experience welcome with a focus on learning the game.',
				2: 'Players are expected to have read the rules',
				3: 'Players have played the game once or twice.',
				4: 'Players have played the game multiple times and know the rules well.'
			},

			teacher: {

			},

			teacherName: {
				0: 'No',
				1: 'Yes'
			},

			teacherDescription: {
				0: 'Teacher not wanted for this event.',
				1: 'Show label on event listing signaling a teacher is wanted.',
			}

		},

		ticket: {
			scopes: {
				1: 'Specific event',
				0: 'Any event'
			},
			scopeName: {
				1: 'Seated event only',
				0: 'Any applicable event'
			},
			scopeDescription: {
				1: 'Can only be scanned for an event on which they are seated.',
				0: 'Can be scanned for any applicable event and if they happen to already be seated on a different event the seat is tranferred automatically.'
			},
			scope: {
				specific: 1,
				any: 0
			},
			purchaseName: {
				2: 'Pre-sale Required',
				1: 'Purchase Required',
				0: 'Free'
			},
			purchaseDescription: {
				2: 'The ticket cannot be purchased on the event page and can only be purchased or comped ahead of time.',
				1: 'The ticket must be purchased on event page, but could also be received via a seperate purchase or comp ahead of time.',
				0: 'The ticket is free and automatically received when joining a ticketed event.'
			},
			purchase: {
				prepurchase: 2,
				required: 1,
				free: 0
			}
		},

		contest: {
			scan: {
				error: {
					1: 'Invalid',
					2: 'Inactive',
					3: 'Scanned',
					4: 'Limit',
					5: 'Order',
					6: 'Register',
					7: 'Cooldown'
				}
			},
			types: {
				1: 'Open',
				2: 'Register',
				//3: 'Closed'
			},
			type: {
				open: 1,
				register: 2,
				closed: 3
			},
			status: {
				active: 1,
				pending: 2,
				complete: 3,
				registration: 4,
				awarded: 5
			},
			statusText: {
				1: 'Ongoing',
				2: 'Upcoming',
				3: 'Finished',
				4: 'Registration',
				5: 'Finished'
			},
			statusType: {
				1: 'primary',
				2: 'danger',
				3: 'success',
				4: 'warning',
				5: 'success'
			},
			qualification: {
				enabled: {
					0: 'All',
					1: 'Qualify'
				},
				types: {
					1: 'Complete',
					2: 'Threshold',
					3: 'Most'
				},
				type: {
					complete: 1,
					threshold: 2,
					most: 3
				},
				orders: {
					1: 'Any',
					2: 'Strict'
				},
				order: {
					any: 1,
					strict: 2
				}
			}
		},

		room: {

			public: {
				no: 2,
				yes: 1
			},

			publicName: {
				2: 'Private',
				1: 'Public'
			},

			publicDescription: {
				2: 'Only admins, moderators, and any additional permitted attendees can submit events to this room.',
				1: 'All attendees can submit events to this room.'
			},

			status: {
				public: 1,
				private: 2
			},
			statusText: {
				1: 'Public',
				2: 'Private'
			},
			statusType: {
				1: 'primary',
				2: 'danger'
			}
		},

		seat: {
			host: 1,
			blocked: 2,
			joined: 3
		},

		day: {
			1: 'Monday',
			2: 'Tuesday',
			3: 'Wednesday',
			4: 'Thursday',
			5: 'Friday',
			6: 'Saturday',
			7: 'Sunday'
		},
	
		dayShort: {
			1: 'Mon',
			2: 'Tue',
			3: 'Wed',
			4: 'Thu',
			5: 'Fri',
			6: 'Sat',
			7: 'Sun'
		},
	
		age: {
			0: 'Any',
			1: '12+',
			2: '15+',
			3: '18+',
			4: '21+'
		},
	
		ageShort: {
			0: 'Any',
			1: '12+',
			2: '15+',
			3: '18+',
			4: '21+'
		},
	
		experience: {
			0: 'All welcome',
			1: 'Learning game',
			2: 'Some teaching',
			3: 'Beginners welcome',
			4: 'Experienced only'
		},
	
		experienceTooltip: {
			0: false,
			1: 'No experience required',
			2: 'Expected to have read the rules',
			3: 'A game or two played',
			4: 'Multiple games played'
		},
	
		experienceSlider: {
			0: 'Any',
			1: 'None',
			2: 'Beginner',
			3: 'Intermediate',
			4: 'Expert'
		},
	
		experienceShort: {
			0: 'All',
			1: 'Learning game',
			2: 'Some teaching',
			3: 'Beginners',
			4: 'Experienced'
		},
	
		languageSlider: {
			5: 'None',
			4: 'A little',
			3: 'Moderate',
			2: 'A lot',
			1: 'All'
		}

	},

	experience: {

		text: {
			0: 'All welcome',
			1: 'Learning game',
			2: 'Some teaching',
			3: 'Beginners welcome',
			4: 'Experienced only'
		},

		tooltip: {
			0: false,
			1: 'No experience required',
			2: 'Expected to have read the rules',
			3: 'A game or two played',
			4: 'Multiple games played'
		}
	

	},

	team: {
		members: {
			access: {
				none: 0,
				readonly: 1,
				read: 1,
				restricted: 2,
				full: 3
			},
			type: {
				founder: 1,
				organisation: 2,
				convention: 3
			},
			typeName: {
				1: 'Founder',
				2: 'Organisation',
				3: 'Convention'
			}
		}
	}

}