<template>

<div class="divide">

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.divide {
	height: 1px;
	margin-bottom: 20px;
	margin-top: 20px;
	margin-left: -10px;
	width: calc(100% + 20px);
	background-color: #ddd;
}

</style>
