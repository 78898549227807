<template>

<app-content-box :span="span" :data-span="span" :title="(noHead) ? false : title || 'Chat'" :icon="(noHead) ? false : icon || 'messages'" :class="{'is-empty': !messages.length}" :scroll="true" class="messages" :not-padded="true" :flex-grow="true">

	<div class="messages-list" ref="messages">

		<div class="messages-list-empty" v-if="!messages.length && !is.loading">No messages.</div>

		<app-icon icon="loading" class="messages-list-loading" v-if="is.loading && is.initial" />

		<app-widget-chat-message v-for="message in messages" :key="message.id" :message="message" />

	</div>

	<div class="messages-compose" v-if="!locked && $loggedin">

		<app-widget-chat-compose v-on:submit="onComposeSubmit" :submitting="is.submitting" />

	</div>

</app-content-box>

</template>

<script>

export default {

	props: ['id', 'span', 'noHead', 'title', 'url', 'icon', 'locked'],

	data: function() {

		return {
			is: {
				submitting: false,
				loading: false,
				initial: true
			},
			messages: []
		}

	},

	created: function() {

		this.$live.$on('messages/' + this.id + '/message', this.onMessage.bind(this))

		this.load()

	},

	beforeDestroy: function() {

		this.$live.$off('messages/' + this.id + '/message', this.onMessage.bind(this))

	},

	methods: {

		load: function() {

			this.is.loading = true

			this.$api.get(((this.url) ? this.url : 'convention/messages') + '/' + this.id).then(function(response) {

				this.messages = response.collection
				this.is.loading = false
				this.is.initial = false

				this.$nextTick(function() {

					this.$refs.messages.scrollTo(0, 100000000)
			
				}.bind(this))

			}.bind(this))

		},

		onMessage: function(message) {

			console.log(message)
			this.messages.push(message)

			this.$nextTick(function() {
				
				this.$refs.messages.scrollTo(0, 100000000)
			
			}.bind(this))

		},

		onComposeSubmit: function(text) {

			this.is.submitting = true

			this.$api.post(((this.url) ? this.url : 'convention/messages') + '/' + this.id, {
				text: text
			}).then(function() {

				this.is.submitting = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.messages {
	min-height: 400px;
}

.messages[data-span="2"] {
	grid-column: 1 / 3;
	min-height: 300px;
}

.is-mobile .messages {
	grid-column: 1 / 5;
	height: 400px;
}

.is-mobile .messages.is-empty {
	height: 200px;
	grid-column: 1 / 5;
}

.messages-list {
	padding: 5px 0px;
	flex-grow: 1;
	flex-basis: 0;
	overflow-y: auto;
}

.messages-list-loading {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 48px;
	color: #bac5d7;
}

.messages-list-empty {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 24px;
    line-height: 40px;
    text-align: center;
    color: #bac5d7;
    font-weight: 500;
}

.is-mobile .messages-list-empty {
	font-size: 20px;
}

.messages-compose {
	border-top: 1px solid #eee;
	flex-shrink: 0;
	height: 49px;
}

</style>
