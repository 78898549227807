<template>

<div class="action" :class="{'is-divide': divide}">

	<div class="action-button"  :class="{'is-disabled': disabled, 'is-danger': danger, 'is-warning': warning, 'is-active': active, 'is-success': success}" v-on:click.stop="onButtonClick">
		
		<app-icon :icon="icon" class="action-icon" v-if="!loading" />
		<app-icon icon="loading" class="action-icon" v-if="loading" />
		<div class="action-text" v-if="text">{{ text }}</div>
		<div class="action-count" v-if="count">{{ count }}</div>

	</div>

	<component :is="panel" v-if="expanded" :data="data" v-on:close="onButtonClick" />
		
</div>

</template>

<script>

export default {

	props: ['icon', 'component', 'text', 'data', 'divide', 'count', 'value', 'type', 'options', 'config', 'danger', 'loading', 'warning', 'success', 'active', 'disabled'],

	data: function() {

		return {
			expanded: false
		}

	},

	computed: {

		panel: function() {

			return () => import('@/components/page/' + this.component + '.vue')

		}

	},

	methods: {
		
		onButtonClick: function() {

			this.expanded = !this.expanded

			this.$pubsub.$emit('offset', this.expanded)

		}

	}

}

</script>

<style scoped>

.action {
	color: #687589;
	cursor: pointer;
	width: 64px;
}

.action.is-divide {
	border-left: 1px solid #f2f2f2;
	margin-left: 10px;
}

.is-tablet .action {
	width: 64px;
}

.is-mobile .action {
	width: 40px;
	border-left-width: 0px;
}

.action-button {
	width: 80px;
	height: 80px;
	user-select: none;
}

.is-tablet .action-button {
	height: 64px;
	width: 64px;
}

.is-mobile .action-button {
	height: 48px;
	width: 40px;
}

.action-button.is-active {
	color: #ffbe7a;
}

.action-button.is-danger {
	color: #c55b5b;
}

.action-button.is-success {
	color: #4faa80;
}

.action-button.is-warning {
	color: #ffbe7a;
}

.action-button.is-disabled {
	pointer-events: none;
	opacity: 0.25;
}

.action.is-expanded .action-button {
	color: #fff;
    background-color: #ffbe7a;
}

.action-icon {
	position: absolute;
	width: 100%;
	text-align: center;
	top: 18px;
	font-size: 24px;
}

.is-tablet .action-icon {
	top: 12px;
	font-size: 20px;
}

.is-mobile .action-icon {
	top: 14px;
	font-size: 20px;
}

.action-text {
	font-size: 14px;
	text-align: center;
	width: 100%;
	position: absolute;
	top: 52px;
	font-weight: 300;
	overflow: hidden;
	padding: 0px 10px;
	opacity: 0.75;
	font-weight: 400;
}

.is-tablet .action-text {
	top: 40px;
	font-size: 12px;
}

.is-mobile .action-text {
	display: none;
}

.action-count {
	background-color: #ffbe7a;
	color: #4d5b6e;
	width: 24px;
	height: 24px;
	position: absolute;
	text-align: center;   
	right: 3px;
	border-radius: 4px;
    top: 3px;
	font-weight: 400;
	line-height: 24px;
}

.is-tablet .action-count {
	width: 20px;
	height: 20px;
	line-height: 20px;
}

.is-mobile .action-count {
	width: 16px;
	height: 16px;
	line-height: 16px;
}

.action-button:hover {
	color: #333;
}

.action-button:hover .action-text {
	opacity: 1;
}

.action-panel {
	position: fixed;
	top: 160px;
	right: 0px;
	left: 320px;
	bottom: 0px;
	z-index: 1000;
	border: 1px solid #faaf5e;
    background-color: #ffbe7a;
	overflow-y: auto;
}

.is-tablet .action-panel {
	top: 128px;
	left: 0px;
}

.is-mobile .action-panel {
	top: 96px;
	left: 0px;
}

.action-dropdown {
	position: absolute;
	top: 80px;
	right: 0px;
	z-index: 1000;
	border: 1px solid #faaf5e;
    background-color: #ffbe7a;
}

.is-tablet .action-dropdown {
	top: 64px;
}

.is-mobile .action-dropdown {
	top: 96px;
	position: fixed;
	left: 0px;
	right: 0px;
	width: 100%;
	bottom: 0px;
	overflow-y: scroll;
}

.action-dropdown.columns-1 {
	max-width: 240px;
	min-width: 80px;
}

.is-mobile .action-dropdown.columns-1 {
	max-width: 1000px;
}

.action-dropdown.columns-2 {
	width: auto;
}

.action-dropdown.columns-3 {
	width: auto;
}

.action-dropdown.columns-6 {
	width: auto;
}

.action-dropdown.columns-2,
.action-dropdown.columns-3 {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}

.action-dropdown.columns-6 {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
}

.is-mobile .action-dropdown.columns-6 {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
}

.action-dropdown-item {
	font-size: 14px;
	letter-spacing: 1;
	line-height: 20px;
	white-space: nowrap;
	padding: 10px 20px;
	border-bottom: 1px solid #faaf5e;
	color: #fff;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.action-dropdown.columns-2 .action-dropdown-item,
.action-dropdown.columns-3 .action-dropdown-item,
.action-dropdown.columns-6 .action-dropdown-item {
	border-right: 1px solid #faaf5e;
}

.action-dropdown.columns-2 .action-dropdown-item:nth-child(2n),
.action-dropdown.columns-3 .action-dropdown-item:nth-child(3n),
.action-dropdown.columns-6 .action-dropdown-item:nth-child(6n) {
	border-right-width: 0px;
}

.action-dropdown.columns-2 .action-dropdown-item {
	flex-basis: 50%;
}

.action-dropdown.columns-3 .action-dropdown-item {
	flex-basis: 33.3%;
}

.action-dropdown.columns-6 .action-dropdown-item {
	flex-basis: calc(100% / 6);
}

.action-dropdown-item:last-child {
	border-bottom: 0px;
}

.action-dropdown-item:hover {
	background-color: #e69c4d;
}

.action-dropdown-item.is-active,
.action-dropdown-item.is-active:hover {
	background-color: #faaf5e;
}

.action-dropdown-item-icon {
	font-size: 20px;
	margin-right: 20px;
}

</style>
