<template>

<div class="alert" :data-theme="theme" :class="{'is-danger': danger, 'is-success': success}">

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['danger', 'success', 'theme']

}

</script>

<style scoped>

.alert {
	background-color: #4082d3;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	color: #fff;
	font-weight: 400;
	padding: 20px;
}

.alert[data-theme="yellow"] {
	background-color: #ffbe7a;
}

.alert[data-theme="blue"] {
	background-color: #4082d3;
}

.alert[data-theme="red"],
.alert.is-danger {
	background-color: #c55b5b;
}


.alert[data-theme="green"],
.alert.is-success {
	background-color: #4faa80;
}

</style>
