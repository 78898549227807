<template>

<com-screen id="app" :loading="loading">

	<app-accept />
	<app-contract />

	<router-view :key="$keyPath" />

	<template v-slot:menu>

		<com-menu>
		
			<com-foot v-if="!window.is.desktop" />

		</com-menu>

	</template>

	<com-foot v-if="window.is.desktop" />

</com-screen>

</template>

<script>

import comScreen from './screen/Wrapper'
import comFoot from './screen/Foot'
import comMenu from './screen/manager/Menu'

export default {

	name: 'App',

	components: {
		'com-screen': comScreen,
		'com-menu': comMenu,
		'com-foot': comFoot
	},

	computed: {

		loading: function() {

			return !this.$store.getters['initiated'] || this.$store.getters['context/checking']

		}
		
	}

}

</script>

<style>

body {
	overflow: hidden;
}

</style>
