<template>

<app-table-cell-text :text="item.location" />

</template>

<script>

export default {

	props: ['item']

}

</script>

<style scoped>

</style>