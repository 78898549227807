<template>

<app-table-cell class="cell-wrapper">

	<div class="label" :data-type="type">{{ text }}</div>

</app-table-cell>

</template>

<script>

export default {

	props: ['text', 'type']

}

</script>

<style scoped>

.cell-wrapper {
	padding: 0px;
}

.label {
	color: #fff;
	font-size: 14px;
	padding: 3px 6px;
	line-height: 20px;
	font-weight: 400;
	border-radius: 4px;
	background-color: #4082d3;
}

.label[data-type="green"],
.label[data-type="success"] {
	background-color: #4faa80;
}

.label[data-type="red"],
.label[data-type="danger"] {
	background-color: #c55b5b;
}

.label[data-type="yellow"],
.label[data-type="warning"] {
	background-color: #ffbe7a;
	color: #333;
}

</style>
