<template>

<app-content-box class="info" :class="{'is-cancelled': cancelled}" :not-padded="true">

	<div class="info-wrapper">

		<template v-for="(item, index) in items">

			<div class="info-item" :key="index" v-if="!item.disabled">
				
				<div class="info-item-icon"><app-icon :icon="item.icon" /></div>
				<div class="info-item-label">{{ item.label }}</div>
				<div class="info-item-value">{{ item.text }}</div>

			</div>

		</template>

	</div>

</app-content-box>

</template>

<script>

export default {

	props: ['items', 'cancelled']

}

</script>

<style scoped>

.info {
	flex-shrink: 0;
}

.is-mobile .info {
	grid-column: 1 / 5;
}

.info >>> .box-content {
	background-color: #4082d3!important;
	overflow: hidden;
}

.info.is-cancelled >>> .box-content {
	background-color: #c55b5b!important;
	overflow: hidden;
}

.info-item {
	color: #fff;
	border-bottom: 1px solid #eee;
	padding: 20px 40px 20px 70px;
}

.is-device .info-item {
	padding: 10px 20px 10px 50px;
}

.info-item:last-child {
	border-bottom: 0px;
}

.info-item-icon {
	position: absolute;
	top: 25px;
	left: 20px;
	font-size: 32px;
}

.is-device .info-item-icon {
	top: 12px;
	left: 14px;
	font-size: 24px;
}

.info-item-label {
	font-size: 14px;
	margin-bottom: 2px;
}

.is-device .info-item-label {
	font-size: 12px;
}

.info-item-value {
	font-size: 20px;
}

.is-device .info-item-value {
	font-size: 16px;
}

.info.is-cancelled .info-item-value {
	text-decoration: line-through;
}

</style>
