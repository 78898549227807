<template>

<div class="card" :data-theme="theme" :class="{'is-borderless': borderless, 'is-clickable': isClickable}" v-on:click="$emit('click')">

	<div class="card-badge" v-if="badge"><app-icon :icon="badge" /></div>

	<router-link v-if="!notLinked && (image || !icon)" :to="toAuto" v-tooltip.top="tooltip" class="card-image" :class="{'is-fit': fitImage, 'is-greyscale': greyscale}" v-bgimage="image">
		
		<app-icon v-if="imageIcon && !image" :icon="imageIcon" class="card-image-icon" />
		<app-icon v-if="alert" icon="alert" class="card-alert-icon" />

		<div class="card-image-date" v-if="tab">
			<div class="card-image-date-time">{{ tab }}</div>
		</div>

		<div class="card-image-brand" :data-theme="brandTheme" v-if="brand">
			{{ brand }}
		</div>

		<div class="card-image-date" v-if="date">
			<div class="card-image-date-day">{{ date | formatDate('DD') }}</div>
			<div class="card-image-date-time">{{ date | formatDate('MMM') }}</div>
		</div>

		<div class="card-image-date is-position" v-if="position">
			<div class="card-image-date-day">{{ position.current | nth }}</div>
			<div class="card-image-date-time">{{ position.total }} waiting</div>
		</div>

	</router-link>
	
	<div v-if="notLinked && (image || !icon)" class="card-image" v-tooltip.top="tooltip" :class="{'is-fit': fitImage, 'is-greyscale': greyscale}" v-bgimage="image">
		
		<app-icon v-if="imageIcon && !image" :icon="imageIcon" class="card-icon-icon" />
		<app-icon v-if="alert" icon="alert" class="card-alert-icon" />

		<div class="card-image-brand" :data-theme="brandTheme" v-if="brand">
			{{ brand }}
		</div>

		<div class="card-image-date" v-if="tab">
			<div class="card-image-date-time">{{ tab }}</div>
		</div>

		<div class="card-image-brand" :data-theme="brandTheme" v-if="brand">
			{{ brand }}
		</div>

		<div class="card-image-date" v-if="date">
			<div class="card-image-date-day">{{ date | formatDate('ddd') }}</div>
			<div class="card-image-date-time">{{ date | formatDate($hourFormat) }}</div>
		</div>

	</div>

	<div class="card-icon" v-if="icon">

		<slot name="icon"></slot>
	
	</div>

	<div class="card-name" :class="{'is-cropped': !longText}">{{ title }}<small v-if="subtitle">{{ subtitle }}</small></div>

	<div class="card-metrics" v-if="metrics">

		<div class="card-metrics-item" :data-theme="metric.theme" v-for="(metric, index) in metrics" :key="index"><span>{{ metric.value }}</span> {{ metric.label }}<template v-if="metric.value !== 1 && !metric.singular">s</template></div>

	</div>

	<div class="card-actions" v-if="!noActions">

		<slot></slot>

	</div>

</div>

</template>

<script>

export default {

	props: ['toName', 'to', 'alert', 'badge', 'toId', 'isClickable', 'borderless', 'brand', 'brandTheme', 'position', 'imageIcon', 'greyscale', 'tab', 'date', 'metrics', 'icon', 'theme', 'tooltip', 'title', 'subtitle', 'image', 'fitImage', 'longText', 'notLinked', 'noActions'],

	computed: {

		toAuto: function() {

			return (this.to) ? this.to : {
				name: this.toName,
				params: {
					id: this.toId
				}
			}

		}

	}

}

</script>

<style scoped>

.card {
	border-radius: 12px;
	border: 1px solid #eee;
	background-color: #fff;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.card.is-clickable {
	cursor: pointer;
}

.card-badge {
	position: absolute;
	background-color: #4faa80;
	color: #fff;
	border-radius: 50%;
	width: 32px;
	height: 32px;
	right: 4px;
	font-size: 16px;
	top: 4px;
	line-height: 32px;
	text-align: center;
	z-index: 10;
}

.card.is-borderless {
	border: 0px solid #eee;
}

.is-mobile .card {
	width: 100%;
	max-width: 100%;
}

.is-desktop .card:nth-child(4n) {
	margin-right: 0px;
}

.is-tablet .card:nth-child(3n) {
	margin-right: 0px;
}

.card.is-empty {
	border-width: 0px;
	box-shadow: none;
	background-color: rgba(175, 90, 90, 0.1);
}

.card-alert-icon {
	font-size: 32px;
	background-color: #fff;
	border-radius: 50%;
	color: #dd4848;
	position: absolute;
	right: -12px;
	top: -12px;
}

.card-icon {
	height: 192px;
	background-size: cover;
	background-position: 50% 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid #eee;
}

.card[data-theme="blue"] .card-icon {
	background-color: #236dc9;
	color: #fff;
}

.card[data-theme="green"] .card-icon {
	background-color: #3e8865;
	color: #fff;
}

.card[data-theme="yellow"] .card-icon {
	background-color: #ffbe7a;
	color: #d49959;
}

.card-icon-icon {
	font-size: 128px;
	color: #ffbe7a;
}

.card-image {
	height: 192px;
	border-radius: 12px 12px 0px 0px;
	background-size: cover;
	background-position: 50% 50%;
	display: block;
	border-bottom: 1px solid #eee;
}

.card-image.is-greyscale {
	filter: grayscale(100%);
}

.card-image.is-fit {
	background-size: contain;
	background-repeat: no-repeat;
}

.card-image-date {
	position: absolute;
	right: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	top: 0px;
	padding: 10px 10px;
	background-color: #4082d3;
	color: #fff;
	border-radius: 0px 0px 4px 4px;
}

.card-image-brand {
	position: absolute;
	left: 0%;
	width: 100%;
	bottom: 0px;
	font-weight: 500;
	font-size: 16px;
	text-align: center;
	letter-spacing: 1px;
	padding: 10px 0px;
}

.card-image-brand[data-theme="green"] {
	background-color: #3e8865;
	color: #fff;
}

.card-image-brand[data-theme="blue"] {
	background-color: #4082d3;
	color: #fff;
}

.card-image-brand[data-theme="red"] {
	background-color: #dd4848;
	color: #fff;
}

.card-image-date-day {
	font-size: 24px;
}

.card-image-date:not(.is-position) .card-image-date-day {
	text-transform: uppercase;
}

.card-image-date.is-position .card-image-date-day {
	margin-bottom: 4px;
	font-weight: 500;
}

.card-image-date-time {
	font-size: 14px;
}

.card-image-icon {
	font-size: 128px;
	color: #ffbe7a;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.card-name {
	padding: 8px 10px 8px 10px;
	font-size: 16px;
	line-height: 20px;
	color: #707070;
	width: 100%;
	text-align: center;
}

.card-name small {
	display: block;
	margin-top: 2px;
	white-space: normal;
	line-height: 14px;
	font-size: 12px;
}

.card-name.is-cropped {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.card-seats {
	padding: 0px 10px 10px 10px;
	border-bottom: 1px solid #eee;
}

.card-empty {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 20px;
	font-weight: 500;
	text-align: center;
	width: 100%;
	padding: 20px;
	color: rgba(0, 0, 0, 0.15);
}

.card-metrics {
	display: flex;
	border-top: 1px solid #eee;
	flex-direction: row;
	padding: 10px 0px;
}

.card-metrics-item {
	flex-grow: 1;
	flex-shrink: 0;
	font-size: 14px;
	padding: 0px 10px;
	color: #707070;
	text-align: center;
}

.card-metrics-item span {
	background-color: #4082d3;
	color: #fff;
	border-radius: 4px;
	margin-right: 5px;
	padding: 0px 5px;
	font-weight: 500;
}

.card-metrics-item[data-theme="red"] span {
	background-color: #dd4848;
}

.card-metrics-item[data-theme="green"] span {
	background-color: #3e8865;
}

.card-metrics-item[data-theme="yellow"] span {
	background-color: #4082d3;
}

.card-actions {
	display: flex;
	border-top: 1px solid #eee;
	flex-direction: row;
}

</style>
