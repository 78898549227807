<template>

<div class="note" :data-theme="theme" :data-position="position" v-if="visible">

	<slot></slot>

	<app-icon v-if="name" icon="close" class="note-close" v-tooltip="'Close and don\'t tell me again'" v-on:click="onCloseClick" />

</div>

</template>

<script>

import cookies from 'js-cookie'

export default {

	props: ['name', 'position', 'theme'],

	data: function() {

		return {
			visible: false
		}

	},

	created: function() {

		if(!cookies.get('note.' + this.name, false)) {

			this.visible = true

		}

	},

	methods: {

		onCloseClick: function() {

			cookies.set('note.' + this.name, true)
			this.visible = false

		}
	
	}

}

</script>

<style scoped>

.note {
	background-color: #687589;
	font-size: 14px;
	line-height: 18px;
	color: #fff;
	font-weight: 400;
	padding: 10px 40px 10px 10px;
	margin-bottom: 20px;
	border-radius: 4px;
}

.note[data-theme="red"] {
	background-color: #c55b5b;
	color: #fff;
}

.note[data-position="below"] {
	margin-top: 12px;
	margin-bottom: 0px;
}

.note:before {
	display: block;
	content: '';
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 10px 10px 0 10px;
	border-color: #687589 transparent transparent transparent;
	position: absolute;
	left: 10px;
	bottom: -10px;
}

.note[data-position="below"]:before {
	bottom: auto;
	top: -10px;
	border-width: 0px 10px 10px 10px;
	border-color: transparent transparent #687589 transparent;
}

.note[data-theme="red"]:before {
	border-color: #c55b5b transparent transparent transparent;
}

.note[data-theme="red"][data-position="below"]:before {
	border-color: transparent transparent #c55b5b transparent;
}

.note-close {
	position: absolute;
	right: 10px;
	top: 10px;
	line-height: 18px;
	cursor: pointer;
}

</style>
