<template>

<com-screen id="app" :loading="loading">

	<app-accept />
	<app-announcement />

	<router-view :key="$keyPath" />

	<template v-slot:menu>

		<com-menu>
		
			<com-foot v-if="!window.is.desktop" />

		</com-menu>

	</template>

	<template v-slot:content>

		<com-basket />

	</template>

	<com-foot v-if="window.is.desktop" />

</com-screen>

</template>

<script>

import comScreen from './screen/Wrapper'
import comMenu from './screen/app/Menu'
import comFoot from './screen/Foot'
import comBasket from './screen/app/Basket'

export default {

	name: 'App',

	components: {
		'com-screen': comScreen,
		'com-menu': comMenu,
		'com-basket': comBasket,
		'com-foot': comFoot
	},

	data: function() {

		return {
			is: {
				loading: true
			}
		}

	},

	created: async function() {

		if (this.$route.query.payment_intent) {

			this.checkPayment()

		} else {

			this.is.loading = false
			
		}

	},

	computed: {

		loading: function() {

			return !this.$store.getters['initiated'] || this.$store.getters['context/checking'] || this.is.loading

		}
		
	},

	methods: {

		checkPayment: async function() {

			await this.$store.dispatch('basket/retrieve', this.$route.query.payment_intent_client_secret)

			if (this.$store.getters['basket/sale'] && this.$store.getters['basket/status'] !== this.$constants.registration.payment.processing) {

				this.$router.replace({'query': null})

				this.$router.push({
					name: 'Convention.Registration.Purchases.Edit',
					params: {
						id: this.$store.getters['basket/sale']
					}
				})

				this.$notify({
					message: ['Your purchase was completed successfully.']
				})

				this.$store.commit('basket/reset')

				this.is.loading = false

			} else {

				setTimeout(function() { this.checkPayment() }.bind(this), 2000)

			}

		}

	}

}

</script>

<style>

body {
	overflow: hidden;
}

</style>
