<template>

<div class="checklist">

	<div class="checklist-button" v-tooltip="tooltip" v-on:click="onButtonClick">

		<div class="checklist-button-text" v-if="!value || alwaysLabel">{{ text }}</div>

		<div class="checklist-button-text" v-if="value && (!alwaysLabel || alwaysValue)"><template v-if="prefix">{{ prefix }} </template>{{ valueText }}</div>

		<app-icon class="checklist-button-icon" icon="caret-down" />

	</div>

	<app-panel v-if="expanded" v-on:close="onButtonClick">
		
		<app-panel-title :text="title || text" />

		<app-panel-check v-for="option in options" :hide-inactive="true" :active="value === option.value" :key="option.value" :text="option.text" v-on:toggle="onToggle(option.value)" />

	</app-panel>

</div>

</template>

<script>

export default {

	props: ['value', 'text', 'tooltip', 'allowDeselect', 'title', 'prefix', 'alwaysValue', 'alwaysLabel', 'options'],

	data: function() {

		return {
			expanded: false
		}

	},

	computed: {

		active: function() {

			return this.value

		},

		valueText: function() {

			return this.$_.findWhere(this.options, {
				value: this.value
			}).text

		}

	},

	methods: {

		onButtonClick: function() {

			this.expanded = !this.expanded

			this.$pubsub.$emit('offset', this.expanded)

		},

		onToggle: function(id) {

			this.$emit('input', (id === this.value && this.allowDeselect) ? 0 : id)

			this.onButtonClick()

		}

	}

}

</script>

<style scoped>

.checklist-button {
	height: 32px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 10px;
	cursor: pointer;
	color: #687589;
}

.checklist-button-count {
	border-radius: 4px;
	background-color: #ffbe7a;
    color: #4d5b6e;
	font-size: 10px;
	height: 14px;
	min-width: 14px;
	text-align: center;
	line-height: 14px;
	margin-left: 10px;
}

.checklist-button:hover {
	color: #333;
}

.checklist-button-text {
	font-size: 12px;
}

.checklist-button-text:nth-of-type(2):not(.checklist-button-icon) {
	margin-left: 4px;
}

.checklist-button-icon {
	font-size: 12px;
	margin-left: 10px;
}

</style>
