<template>

<div class="addon" :data-layout="layout">
			
	<div class="addon-image" v-tooltip="'Click to enlarge'" v-bgimage="item.image" v-on:click="onEnlargeClick">
				
		<app-icon icon="enlarge" class="addon-image-enlarge" />

	</div>

	<div class="addon-info">

		<div class="addon-name">{{ item.name }}</div>
		<div class="addon-price" v-if="item.count.variants !== false &&  item.count.variants > 1">From {{ item.price | formatMoney }}<template v-if="layout !== 'list'"> ({{ item.count.variants }} options)</template></div>
		<div class="addon-price" v-if="item.count.variants === false || item.count.variants === 1">{{ item.price | formatMoney }}</div>
		<div class="addon-description" v-if="layout !== 'list'">{{ item.description }}</div>

		<app-button v-if="item.count.variants > 1" :text="hasAdded() && !button ? buttonText + 'ed' : buttonText" :theme="hasAdded() ? 'green' : 'blue'" class="addon-button" :options="generateOptions" optionValue="value" optionText="text" v-on:option="onVariantClick($event)" />
		<app-button v-if="item.count.variants === false || item.count.variants === 1" :text="hasAdded() && !button ? buttonText + 'ed' : buttonText" :theme="hasAdded() ? 'green' : 'blue'" class="addon-button" v-on:click="onItemClick" />

	</div>

</div>
	
</template>

<script>

export default {

	props: ['item', 'button', 'layout'],

	data: function() {

		return {
			is: {
				enlarged: false,
				assigning: false
			},
			quantity: {},
			selected: [],
			addons: []
		}

	},

	computed: {

		buttonText: function() {

			return this.button ? this.button : 'Add'

		},

		badges: function() {

			return []

		},

		generateOptions: function() {

			var options = []

			this.$_.each(this.item.variants, function(variant) {

				options.push({
					value: variant.id,
					text: variant.name + ' - ' + this.$options.filters.formatMoney(variant.price),
					status: (this.hasAdded(variant.id)) ? 'green' : 'blue'
				})

			}.bind(this))

			return options

		}

	},

	methods: {

		onEnlargeClick: function() {

			this.$present({
				image: this.item.image
			})

		},

		onVariantClick: function(variant) {

			this.$emit('add', {
				item: this.item.id,
				variant: variant
			})

		},

		onItemClick: function() {

			this.$emit('add', {
				item: this.item.id,
				variant: false
			})

		},

		hasAdded: function(variant) {

			var added = this.$_.filter(this.$store.getters['basket/items'], function(addon) {

				return addon.item.id === this.item.id && (variant === false || addon.variant.id === variant)

			}.bind(this))

			return (added.length) ? added : false

		}

	}
	
}

</script>

<style scoped>

.addon {
	display: flex;
	flex-direction: row;
}

.addon[data-layout="list"] {
	width: 100%;
	border: 1px solid #ddd;
	border-radius: 10px;
	padding: 10px 20px;
	background-color: #fff;
	margin-bottom: 20px;
}

.is-mobile .addon {
	width: 100%;
	margin: 0px 0px 20px 0px;
}

.addon-image {
	width: 240px;
	cursor: pointer;
	height: 240px;
	border: 2px dashed #ccc;
	flex-shrink: 0;
	border-radius: 10px;
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-color: #fff;
}

.addon[data-layout="list"] .addon-image {
	display: none;
}

.is-mobile .addon-image {
	width: 120px;
	height: 120px;
}

.addon-image:hover {
	border-color: #666;
}

.addon-image-enlarge {
	position: absolute;
	left: 0px;
	top: 0px;
	font-size: 24px;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	color: #387DD8;
}

.is-mobile .addon-image-enlarge {
	font-size: 16px;
	width: 24px;
	height: 24px;
	line-height: 24px;
}

.addon-info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 10px 0px 10px 10px;
	flex-grow: 1;
}

.addon[data-layout="list"] .addon-info {
	flex-direction: row;
	padding: 0px;
	display: grid;
	grid-template-columns: auto 120px 120px;
	grid-gap: 20px;
}

.is-mobile .addon-info {
	padding: 0px 0px 0px 10px;
}

.addon-name {
	font-size: 16px;
	color: #343434;
	font-weight: 700;
}

.addon[data-layout="list"] .addon-name {
	font-weight: 400;
	line-height: 40px;
	color: #000;
}

.addon-price {
	font-size: 14px;
	color: #343434;
	font-weight: 400;
	margin-top: 4px;
}

.addon[data-layout="list"] .addon-price {
	font-size: 16px;
	margin: 0px;
	line-height: 40px;
	color: #000;
	text-align: right;
}

.addon-description {
	flex-grow: 1;
	font-size: 14px;
	line-height: 16px;
	color: #343434;
	font-weight: 400;
	margin-top: 10px;
}

.addon-button {
	margin: 10px 0px 0px 0px!important;
}

.addon[data-layout="list"] .addon-button {
	margin: 0px!important;
}

</style>