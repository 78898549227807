<template>

<app-table-cell class="toggle" :class="{'is-active': value}" align="center">

	<app-icon icon="tick" class="toggle-icon" v-if="value" />
	<app-icon icon="cross" class="toggle-icon" v-if="!value" />

</app-table-cell>

</template>

<script>

export default {

	props: ['value', 'align']

}

</script>

<style scoped>

.toggle {
	padding: 0px;
}

.toggle-icon {
	font-size: 20px;
	color: #ccc;
}

.toggle.is-active .toggle-icon {
	color: #4d5b6e;
}

</style>
