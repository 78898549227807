<template>

<app-table-cell-text>

	<div class="cell-wrapper" :class="{'is-cancelled': item.cancelled}">

		<div class="cell-thumb" v-bgimage="item.image"></div>

		<div class="cell-name">

			{{ item.name }}

			<div class="labels" v-if="item.official || item.lobbies">

				<div class="label label-teacher" v-tooltip="'This is an official event.'" v-if="item.official">Official</div>
				<div class="label label-official" v-tooltip="'This event wants a teacher.'" v-if="item.teacher">Teacher wanted</div>

				<div class="label" v-for="lobby in item.lobbies" :key="lobby.id" v-tooltip="'This event belongs to a lobby.'" :style="{backgroundColor: '#' + lobby.color}">{{ lobby.name }}</div>

			</div>
		
		</div>

	</div>

</app-table-cell-text>

</template>

<script>

export default {

	props: ['item']

}

</script>

<style scoped>

.cell-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-left: 42px;
}

.cell-wrapper.is-cancelled .cell-name {
	text-decoration: line-through;
}

.is-mobile .cell-wrapper {
	min-height: 32px;
}

.cell-name {
	overflow: hidden;
	text-overflow: ellipsis;
}

.cell.is-cancelled .cell-name {
	text-decoration: line-through;
}

.cell-thumb {
	position: absolute;
	margin-top: -6px;
	left: 0px;
	margin-right: 10px;
	width: 32px;
	height: 32px;
	border-radius: 4px;
	background-size: cover;
	background-position: 50% 50%;
}

.is-mobile .cell-thumb {
	margin-top: 0px;
}

.labels {
	display: block;
}

.label {
	font-size: 11px;
	color: #fff;
	display: inline-block;
	background-color: black;
	border-radius: 4px;
	line-height: 11px;
	padding: 2px 4px;
	margin: 0px 2px 0px 0px;
}

.label-official {
	background-color: #4082d3;
}

.label-teacher {
	background-color: #31e1f1;
}

</style>