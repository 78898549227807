<template>

<div class="video">

	<iframe :src="item.source" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />

</div>
	
</template>

<script>

export default {

	props: ['item']

}

</script>

<style scoped>

.video iframe {
	width: 100%;
	height: 480px;
}

</style>