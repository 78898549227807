<template>

<app-panel v-on:close="$emit('close')">
		
	<app-panel-title text="Input" />

	<app-panel-content>

		<app-input-text label="Name" placeholder="Enter name" :stacked="true" v-model="model.name" :validation="$v.model.name" />
		<app-input-select :disabled="model.automated" label="Type" placeholder="Select type" :stacked="true" v-model="model.type" :validation="$v.model.type" :options="typeOptions" />
		<app-input-text label="Label" placeholder="Enter label" :stacked="true" v-model="model.label" :validation="$v.model.label" />
		<app-input-text v-if="hasPlaceholder" label="Placeholder" placeholder="Enter placeholder" :stacked="true" v-model="model.placeholder" :validation="$v.model.placeholder" />
		<app-input-text label="Description" :autogrow="true" placeholder="Enter description" :stacked="true" v-model="model.description" :validation="$v.model.description" />
		<app-input-toggle :disabled="model.automated" label="Required" :stacked="true" :row="true" :full-width="true" v-model="model.required" :validation="$v.model.required" />
		<app-input-select :disabled="model.automated" label="Role" :allowDeselect="true" placeholder="Select role" :stacked="true" v-model="model.role" :validation="$v.model.role" :options="allowedRoleOptions" />
		<app-input-select v-if="hasValidation" :disabled="model.automated" :allowDeselect="true" label="Validation" placeholder="Select validation" :stacked="true" v-model="model.validation" :validation="$v.model.validation" :options="allowedValidationOptions" />
		<app-input-options v-if="hasOptions" :stacked="true" placeholder="Add options..." label="Options" v-model="model.options" :validation="$v.model.options" />

	</app-panel-content>

	<app-panel-content :space="true">

		<app-button text="Confirm" :disabled="$v.invalid" v-on:click="$emit('save', model)" />

	</app-panel-content>

</app-panel>

</template>

<script>

import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Vue from 'vue'
import deepmerge from 'deepmerge'

export default {

	mixins: [validationMixin],

	props: ['item', 'usedRoles', 'options'],

	data: function() {

		return {
			model: {
				name: '',
				type: 0,
				label: '',
				required: 0,
				validation: 0,
				placeholder: '',
				description: '',
				role: 0,
				options: [],
				automated: false
			},
			roleOptions: [
				{ value: 1, text: 'First name' },
				{ value: 2, text: 'Last name' },
				{ value: 3, text: 'E-mail' }
			],
			typeOptions: [
				{ value: 1, text: 'Textbox' },
				{ value: 2, text: 'Toggle' },
				{ value: 3, text: 'Select' },
				{ value: 4, text: 'Checklist' }
			],
			validationOptions: [
				{ value: 1, text: 'Valid Email Address' },
				{ value: 3, text: 'Unique Badge Email' }
			]
		}

	},

	validations: {
		model: {
			name: {
				required
			}
		}
	},

	created: function() {

		if (this.$_.isObject(this.item)) Vue.set(this, 'model', deepmerge(this.model, this.item))
		
		if (!this.model.id) this.model.id = this.$util.generateKey()

	},

	computed: {

		allowedValidationOptions: function() {

			if (this.options.validation) {

				return this.$_.filter(this.validationOptions, function(validation) {

					return this.$_.contains(this.options.validation, validation.value) 

				}.bind(this))

			} else {

				return this.validationOptions

			}

		},

		allowedRoleOptions: function() {

			return this.$_.filter(this.roleOptions, function(role) {

				return !this.$_.contains(this.usedRoles, role.value) || role.value === this.item.role

			}.bind(this))

		},

		hasOptions: function() {

			return this.$_.contains([
				this.$constants.registration.input.type.toggle,
				this.$constants.registration.input.type.select,
				this.$constants.registration.input.type.checklist
			], this.model.type)

		},

		hasValidation: function() {

			return this.$_.contains([
				this.$constants.registration.input.type.textbox
			], this.model.type) && this.allowedValidationOptions.length

		},

		hasPlaceholder: function() {

			return this.$_.contains([
				this.$constants.registration.input.type.select,
				this.$constants.registration.input.type.textbox
			], this.model.type)

		}

	}

}

</script>

<style scoped>

</style>
