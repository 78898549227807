<template>

<div class="blocks">

	<div class="blocks-item" v-for="block in blocks" :key="block.id">

		<app-blocks-text :item="block" v-if="block.type === $constants.block.type.text" />
		<app-blocks-banner :item="block" v-if="block.type === $constants.block.type.banner" />
		<app-blocks-map :item="block" v-if="block.type === $constants.block.type.map" />
		<app-blocks-video :item="block" v-if="block.type === $constants.block.type.video" />
		<app-blocks-gallery :item="block" v-if="block.type === $constants.block.type.gallery" />
		<app-blocks-capture :item="block" v-if="block.type === $constants.block.type.capture" />
		<app-blocks-feature :item="block" v-if="block.type === $constants.block.type.feature" />
		<app-blocks-image :item="block" v-if="block.type === $constants.block.type.image" />
		<app-blocks-registration :item="block" v-if="block.type === $constants.block.type.registration" />
		<app-blocks-thirdparty :item="block" v-if="block.type === $constants.block.type.thirdparty" />
		<app-blocks-addons :item="block" v-if="block.type === $constants.block.type.addons" />
		<app-blocks-items :item="block" v-if="block.type === $constants.block.type.items" />

	</div>

</div>
	
</template>

<script>

export default {

	props: ['blocks']

}

</script>

<style scoped>

.blocks {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.blocks-item {
	margin-bottom: 20px;
	max-width: 1100px;
	width: 100%;
}

</style>