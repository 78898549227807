import '@/assets/reset.css'
import 'vue-toast-notification/dist/theme-sugar.css'

import Vue from 'vue'

import typeApp from './components/app.vue'
import typeRegister from './components/register.vue'
import typeManager from './components/manager.vue'
import typeAssistant from './components/assistant.vue'
import typePublic from './components/public.vue'
import typeOffline from './components/offline.vue'
import typeAdmin from './components/admin.vue'
import typeLibrary from './components/library.vue'

import VueToast from 'vue-toast-notification'
import VueTextareaAutogrowDirective from 'vue-textarea-autogrow-directive'
import VCalendar from 'v-calendar'
import vClickOutside from 'v-click-outside'
import _ from 'underscore'
import tooltip from 'v-tooltip'
import moment from 'moment-timezone'
import constants from './constants'
import router from './router'
import store from './store'
import pubsub from './pubsub'
import live from './live'
import api from './api'
import util from './util'

import mixInjection from './mixin/injection'
import mixShortcuts from './mixin/shortcuts'
import mixWindow from './mixin/window'

import routerGuard from './router/guard' 

Vue.config.productionTip = false

var requireComponentGlobal = require.context('./components/common', true, /[A-Z]\w+\.(vue|js)$/)

requireComponentGlobal.keys().forEach(function(filename) {

	var componentConfig = requireComponentGlobal(filename)
	var componentName = 'app' + filename.replace(/\//g, '-').replace('.vue', '').replace('.', '').toLowerCase()

	Vue.component(
		componentName,
		componentConfig.default
	)

})

router.beforeEach(routerGuard)

Vue.directive('bgimage', {

	bind: function (el, binding) {

		if (binding.value !== undefined) {

			var file = (binding.value.indexOf('data:') === 0 || binding.value.indexOf('https:') === 0) ? binding.value : binding.value

			el.style.backgroundImage = (binding.value) ? 'url("' + file + '")' : false

		}

	},

	update: function (el, binding) {

		if (binding.value !== undefined) {

			if(binding.value) {

				var file = (binding.value.indexOf('data:') === 0 || binding.value.indexOf('https:') === 0) ? binding.value : binding.value
	
				el.style.backgroundImage = (binding.value) ? 'url("' + file + '")' : false
				
			} else {

				el.style.backgroundImage = ''

			}

		}

	}

})

Vue.directive('bgcolor', {

	bind: function (el, binding) {

		el.style.backgroundColor = (binding.value) ? '#' + binding.value : false

	},

	update: function (el, binding) {

		el.style.backgroundColor = (binding.value) ? '#' + binding.value : false

	}

})

Vue.filter('capitalise', function (value) {

	return value.charAt(0).toUpperCase() + value.slice(1)

})

Vue.filter('fromNow', function (value) {

	return (value) ? moment.unix(value).local().fromNow() : ''

})

Vue.filter('daysSince', function (value) {

	return (value) ? moment().utc().diff(moment.unix(value).utc(), 'days') : ''

})

Vue.filter('daysBetween', function (from, to) {

	return (from) ? Math.abs(moment.unix(to).utc().diff(moment.unix(from).utc(), 'days')) : ''

})

Vue.filter('formatDate', function (value, format) {

	return (value) ? moment.unix(value).utc().format(format) : ''

})

Vue.filter('localDate', function (value, format) {

	return (value) ? moment.unix(value).utc().local().format(format) : ''

})

Vue.filter('conDate', function (value, format) {

	return (value) ? moment.unix(value).utc().tz('America/Los_Angeles').format(format) : ''

})

Vue.filter('prettyNumber', function (value) {

	return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

})

Vue.filter('round', function (value, decimals, force) {

	force = force || false
	value = parseFloat(value)

	return ((value % 1 !== 0 || force) && value !== undefined) ? value.toFixed(decimals) : value

})

Vue.filter('nth', function (value) {

	var remainder = value % 10

	if (remainder === 1 && value !== 11) return value.toString() + 'st'
	if (remainder === 2 && value !== 12) return value.toString() + 'nd'
	if (remainder === 3 && value !== 13) return value.toString() + 'rd'

	return value.toString() + 'th'

})

Vue.filter('hours', function (value) {

	return value / 60 / 60

})

Vue.use(tooltip)
Vue.use(VueToast)
Vue.use(vClickOutside)
Vue.use(VCalendar)
Vue.use(VueTextareaAutogrowDirective)

Vue.mixin(mixWindow)
Vue.mixin(mixInjection)
Vue.mixin(mixShortcuts)

function updateTime() {

	store.commit('time', moment().utc().unix())
	setTimeout(updateTime, 250)

}

setTimeout(updateTime, 500)

Vue.filter('capitalise', function (value) {

	return value.charAt(0).toUpperCase() + value.slice(1)

})

Vue.filter('fromNow', function (value) {

	return (value) ? moment.unix(value).local().fromNow() : ''

})

Vue.filter('daysSince', function (value) {

	return (value) ? moment().utc().diff(moment.unix(value).utc(), 'days') : ''

})

Vue.filter('daysBetween', function (from, to) {

	return (from) ? Math.abs(moment.unix(to).utc().diff(moment.unix(from).utc(), 'days')) : ''

})

Vue.filter('hoursBetween', function (from, to) {

	return (from) ? Math.abs(moment.unix(to).utc().diff(moment.unix(from).utc(), 'hours')) : ''

})

Vue.filter('formatDate', function (value, format) {

	return (value) ? moment.unix(value).utc().format(format) : ''

})

Vue.filter('formatMoney', function (value, prefix) {

	var formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })

	return ((prefix) ? prefix + ' ' : '') + formatter.format(value)

})

Vue.filter('prettyNumber', function (value) {

	return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

})

Vue.filter('round', function (value, decimals, force) {

	force = force || false
	value = parseFloat(value)

	return ((value % 1 !== 0 || force) && value !== undefined) ? value.toFixed(decimals) : value

})

Vue.filter('nth', function (value) {

	var remainder = value % 10

	if (remainder === 1 && value !== 11) return value.toString() + 'st'
	if (remainder === 2 && value !== 12) return value.toString() + 'nd'
	if (remainder === 3 && value !== 13) return value.toString() + 'rd'

	return value.toString() + 'th'

})

Vue.filter('hours', function (value) {

	return value / 60 / 60

})

var App

if (process.env.VUE_APP_TYPE === 'register') { App = typeRegister }
else if (process.env.VUE_APP_TYPE === 'app') { App = typeApp }
else if (process.env.VUE_APP_TYPE === 'manager') { App = typeManager }
else if (process.env.VUE_APP_TYPE === 'assistant') { App = typeAssistant }
else if (process.env.VUE_APP_TYPE === 'offline') { App = typeOffline }
else if (process.env.VUE_APP_TYPE === 'public') { App = typePublic }
else if (process.env.VUE_APP_TYPE === 'admin') { App = typeAdmin }
else if (process.env.VUE_APP_TYPE === 'library') { App = typeLibrary }

new Vue({
	_,
	moment,
	router,
	constants,
	util,
	store,
	api,
	pubsub,
	live,
	render: h => h(App),
	created: function() {

		pubsub.$emit('window.initiated')

	}
}).$mount('#app')
