<template>

<div class="body" :class="{'has-fixed-header': fixedHeader}">

	<draggable v-if="sortable" v-model="sorted">

		<slot></slot>

	</draggable>

	<template v-if="!sortable">

		<slot></slot>

	</template>

</div>

</template>

<script>

import draggable from 'vuedraggable'

export default {

	props: ['sortable', 'fixedHeader'],

	components: {
		draggable,
	},

	data: function() {

		return {
			sorted: []
		}

	},

	watch: {

		sortable: function(n) {

			this.sorted = n

		},

		sorted: function(n) {

			if (this.sortable) {

				if (n.join(',') !== this.sortable.join(',')) this.$emit('sorted', n)

			}

		}

	},

	created: function() {

		this.sorted = this.sortable

	}

}

</script>

<style scoped>

.body.has-fixed-header {
	padding-top: 33px;
}

.list-move,
.list-enter-active,
.list-leave-active {
	transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
	opacity: 0;
}

.list-leave-active {
	position: absolute;
}

</style>
