<template>

<app-input :wide="wide" :messages="messages" :label="label" :stacked="stacked" :no-line="noLine" :notes="notes" :locked="locked" :disabled="disabled" :validation="validation">

	<input ref="input" type="text" class="account" v-bind:value="value" v-on:input="$emit('input', $event.target.value)" :placeholder="placeholder" />

	<div class="account-note is-blue" v-if="is.checking"><app-icon icon="loading" /> Checking the e-mail address...</div>
	<div class="account-note is-blue" v-if="!is.checking && ((validation.$invalid && !is.exists) || !value || !validation.email || (reverse && !isDirty))"><app-icon icon="warning" /><span>{{ text.info }}</span></div>
	<div class="account-note is-red" v-if="!is.checking && is.checked && is.used"><app-icon icon="warning" /><span>{{ text.added }}</span></div>
	<div class="account-note" :class="{'is-red': reverse, 'is-green': !reverse}" v-if="!is.checking && is.checked && is.exists && !is.used && isDirty"><app-icon :icon="(reverse) ? 'warning' : 'exists'" /><span>{{ text.exists }}</span></div>
	<div class="account-note is-green" v-if="!is.checking && is.checked && is.available && !is.used && isDirty"><app-icon icon="exists" /><span>{{ text.available }}</span></div>
	<div class="account-note is-yellow" v-if="!is.checking && is.checked && !is.exists && !is.available"><app-icon icon="register" /><span>{{ text.register }}</span></div>

</app-input>

</template>

<script>

export default {

	props: ['label', 'value', 'original', 'type', 'locked', 'endpoint', 'reverse', 'wide', 'messages', 'stacked', 'noLine', 'notes', 'disabled', 'validation', 'text'],

	data: function() {

		return {
			placeholder: 'Enter e-mail address',
			is: {
				checked: false,
				checking: false,
				exists: false,
				available: false,
				used: false
			},
			name: '',
			firstname: '',
			lastname: ''
		}

	},

	watch: {

		value: function() {

			this.$nextTick(function() {

				this.checkAccount()

			}.bind(this))

		}

	},

	computed: {

		isDirty: function() {

			return this.original !== this.value

		}

	},

	methods: {

		checkAccount: async function() {

			this.$api.cancel()

			if (!this.validation.email) {

				this.is.checked = false
				this.is.exists = false
				this.is.checking = false
				this.is.available = false
				this.is.used = false

				this.name = ''
				this.firstname = ''
				this.lastname = ''

			} else {

				this.is.checked = false
				this.is.checking = true

				this.$emit('checking', true)

				await this.$api.get(this.endpoint || 'user/exists', {
					email: this.value,
					convention: this.$convention.id,
					type: this.type
				}).then(function(json) {

					if (json.user) {

						this.name = json.user.name
						this.firstname = json.user.firstname
						this.lastname = json.user.lastname

					}

					this.is.checked = true
					this.is.checking = false
					this.is.available = json.available
					this.is.exists = json.exists
					this.is.used = json.used

					this.$emit('checking', false)

				}.bind(this), function(json) {

					if (json !== null) {

						this.name = ''
						this.firstname = ''
						this.lastname = ''

						this.is.checked = true
						this.is.checking = false
						this.is.available = json.available
						this.is.exists = json.exists
						this.is.used = json.used

						this.$emit('checking', false)
							
					}

				}.bind(this))

			}

			this.$emit('exists', {
				exists: this.is.exists,
				available: this.is.available,
				used: this.is.used,
				firstname: this.firstname,
				lastname: this.lastname
			})

		}

	}

}

</script>

<style scoped>

.account {
	line-height: 36px;
	font-size: 14px;
	width: 100%;
	padding: 0px 10px;
	color: #333;
	background-color: #fff;
	border-radius: 12px 12px 0px 0px;
	border: 2px solid #eee;
	border-bottom-width: 0px;
}

.account:focus {
	border-color: #4082d3;
}

.is-device .account {
	font-size: 14px;
}

.is-mobile .account {
	padding: 0px 10px;
}

.account-note {
	color: #fff;
	padding: 10px 20px;
	border-radius: 0px 0px 12px 12px;
	font-size: 14px;
	line-height: 16px;
	align-items: center;
	display: flex;
}

.input.is-stacked .account-note {
	padding: 10px 10px 10px 20px;
}

.account-note >>> .icon {
	font-size: 32px;
	margin-right: 20px;
}

.input.is-stacked .account-note >>> .icon {
	font-size: 24px;
}

.account-note.is-blue {
	background-color: #4082d3;
}

.account-note.is-red {
	background-color: #c55b5b;
}

.account-note.is-green {
	background-color: #4faa80;
}

.account-note.is-yellow {
	background-color: #ffbe7a;
}

</style>
