<template>

<div class="menu" :class="{'is-submenu': isActiveSubmenu}">

	<template v-if="!isActiveSubmenu">

		<com-item route="Dashboard" text="Dashboard" icon="dashboard" />

		<com-title text="Accounts" />

		<com-item route="Conventions.Browse" text="Conventions" icon="convention" />
		<com-item route="Organisations.Browse" text="Organisations" icon="organisation" />
		<com-item route="Users.Accounts" text="Users" icon="users" />

		<com-title text="Administration" />

		<com-item route="Access.Members" text="Access" icon="admin.team" />
		<com-item route="Billing.Overview" text="Billing" icon="billing" />
		<com-item route="Library.Games" text="Library" icon="library" />
		<com-item route="Pages.Browse" text="Pages" icon="pages" />

	</template>
	
	<template v-if="isActiveSubmenu">

		<com-submenu-billing v-if="submenu === 'Billing'" />
		<com-submenu-conventions v-if="submenu === 'Conventions'" />
		<com-submenu-organisations v-if="submenu === 'Organisations'" />
		<com-submenu-library v-if="submenu === 'Library'" />

	</template>

	<div class="menu-sidebar" :class="{'is-active': isActiveSubmenu}">

		<com-icon route="Dashboard" text="Dashboard" icon="dashboard" />
		<com-icon route="Conventions.Browse" text="Conventions" icon="convention" />
		<com-icon route="Organisations.Browse" text="Organisations" icon="organisation" />
		<com-icon route="Users.Accounts" text="Users" icon="users" />
		<com-icon route="Access.Members" text="Access" icon="admin.team" />
		<com-icon route="Billing.Overview" text="Billing" icon="billing" />
		<com-icon route="Library.Games" text="Library" icon="library" />
		<com-icon route="Pages.Browse" text="Pages" icon="pages" />

	</div>

</div>

</template>

<script>

export default {

	components: {

		'com-submenu-billing': () => import('./submenu/Billing'),
		'com-submenu-organisations': () => import('./submenu/Organisations'),
		'com-submenu-conventions': () => import('./submenu/Conventions'),
		'com-submenu-library': () => import('./submenu/Library'),

		'com-title': () => import('../panel/common/Title'),
		'com-icon': () => import('../panel/common/Icon'),
		'com-item': () => import('../panel/common/Item')
		
	},

	computed: {

		isActiveSubmenu: function() {

			return this.submenu

		},

		submenu: function() {

			if (this.$_.contains(this.$route.meta.parents, 'Billing')) {

				return 'Billing'

			} else if (this.$_.contains(this.$route.meta.parents, 'Organisations')) {

				return 'Organisations'

			} else if (this.$_.contains(this.$route.meta.parents, 'Conventions')) {

				return 'Conventions'

			} else if (this.$_.contains(this.$route.meta.parents, 'Library')) {

				return 'Library'

			} else {

				return false

			}

		}

	}

}

</script>

<style scoped>

.menu {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	padding-bottom: 5px;
}

.menu.is-submenu {
	padding-left: 40px;
}

.menu-sidebar {
	position: absolute;
	left: -40px;
	top: 0px;
	bottom: 0px;
	display: flex;
	flex-direction: column;
	background-color: #2f3a4a;
	width: 40px;
	transition: left 100ms linear;
}

.menu-sidebar.is-active {
	left: 0px;
}

</style>
