<template>

<app-input class="suggest" :class="{'has-value': currentValue, 'is-dark': dark, 'is-creatable': canCreate, 'has-image': image || color || icon, 'is-suggesting': suggestions.length}" :locked="locked" :validation="validation" :label="label" :stacked="stacked" :no-line="noLine" v-on:toggle="onToggle" :toggle="toggle" :is-ready="currentValue" :notes="notes" :disabled="disabled">

	<div class="suggest-wrapper">

		<div class="suggest-selected" :class="{'is-below': below}" v-if="!disabled && multiple && (selected.length || is.preloading)">

			<div class="suggest-selected-item" v-tooltip="'Click to remove'" v-on:click.stop="onRemoveClick(item.id)" v-for="(item, index) in selected" :key="index">

				<div class="suggest-search-image" v-bgimage="item.image" v-if="item.image">

					<app-icon icon="noimage" class="suggest-search-image-missing" v-if="!item.image" />

				</div>

				<div class="suggest-search-text">{{ item.text }}</div>

				<div class="suggest-search-button">

					<app-icon icon="removeCircle" />

				</div>

			</div>

			<div class="suggest-selected-item is-preloading" v-for="index in is.preloading" :key="'preloading-' + index">

				<div class="suggest-search-text"><app-icon icon="loading" /></div>

			</div>

		</div>

		<div class="suggest-search" :class="{'is-focus': isFocus}">

			<div class="suggest-search-image" :class="{'has-color': color}" v-if="currentValue && ((image || color) && !icon)" v-bgimage="imageSource" v-bgcolor="colorSource">

				<app-icon icon="noimage" class="suggest-search-image-missing" v-if="!imageSource && image" />

			</div>

			<app-icon :icon="icon[source.type]" class="suggest-search-preicon" :class="{'has-color': color}" v-if="currentValue && ((icon || color) && !image)" />

			<input type="text" ref="input" class="suggest-search-text" v-model="search" :placeholder="placeholder" v-on:keydown="onKeyDown" v-on:focus="isFocus = true" v-on:blur="isFocus = false" />

			<app-icon icon="search" class="suggest-search-icon" v-if="!currentValue" />

			<div class="suggest-search-button" v-if="currentValue" v-tooltip="'Change or remove'" v-on:click="onChangeClick">

				<app-icon icon="customise" />

			</div>

			<div class="suggest-search-button" v-if="canCreate && !currentValue" v-tooltip="'Create'" v-on:click="onCreateClick">

				<app-icon icon="create-cricle" />

			</div>

			<div class="suggest-list" v-if="suggestions.length" v-on:mouseenter="isOver = true" v-on:mouseleave="isOver = false">

				<div class="suggest-list-item" v-for="(suggestion, index) in suggestions" :class="{'is-highlight': index === highlight - 1}" :key="suggestion.id" v-on:click="onSuggestionClick(suggestion.id)">
					
					<app-icon :icon="icon[suggestion.type]" class="suggest-list-item-icon" :class="{'has-color': color}" v-if="icon" />

					{{ suggestion.text }}<small v-if="suggestion.subtext">{{ suggestion.subtext }}</small>
					
				</div>

			</div>

		</div>

	</div>
	
</app-input>

</template>

<script>

import Vue from 'vue'

export default {

	props: ['validation', 'exclude', 'manual', 'defaultValue', 'toggle', 'locked', 'multiple', 'dark', 'below', 'label', 'api', 'disabled', 'params', 'value', 'icon', 'color', 'image', 'noLine', 'store', 'canCreate', 'showAll', 'stacked', 'placeholder', 'notes'],

	data: function() {

		return {
			is: {
				preloading: 0
			},
			isFocus: false,
			isOver: false,
			search: '',
			highlight: 0,
			source: {},
			cache: {},
			internalValue: 0,
			selected: [],
			apiSuggestions: []
		}

	},

	computed: {

		nullValue: function() {

			return (this.defaultValue === undefined) ? false : this.defaultValue

		},

		currentValue: function() {

			return (this.multiple) ? this.internalValue : this.value

		},

		imageSource: function() {

			return (this.image) ? this.source[this.image] : false

		},

		colorSource: function() {

			return (this.color) ? this.source[this.color] : false

		},

		suggestions: function() {

			if (this.currentValue) return []

			if (this.search && (this.isOver || this.isFocus)) {

				return this.apiSuggestions

			} else if (this.showAll && (this.isOver || this.isFocus)) {

				return this.apiSuggestions

			} else {

				return []

			}

		}

	},

	created: function() {

		if (this.value) this.getValueName()

	},

	watch: {

		suggestions: function() {

			this.highlight = 0

		},
	
		value: function(n) {

			if (n) {
				
				this.getValueName()

			} else {

				this.search = ''

			}

		},

		search: async function(n) {

			if (n && !this.currentValue) {

				this.$api.cancel()

				var localParams = this.$util.copy(this.params || {})

				localParams.text = this.search
				if (this.multiple) localParams.exclude = this.$util.copy(this.value)

				if (this.exclude) localParams.exclude = this.exclude

				await this.$api.post(['suggest', this.api], localParams).then(function(json) {

					this.apiSuggestions = json.suggestions

				}.bind(this))

			}

		}

	},

	methods: {

		onToggle: function() {

			this.$emit('toggle')
			if (!this.toggle) this.$refs.input.focus()

		},

		getValueName: async function() {

			if (this.multiple) {

				var selected = []

				this.is.preloading = 0

				this.$_.each(this.value, async function(id) {

					if (this.cache[id]) {

						selected.push(this.cache[id])

					} else {

						this.is.preloading++

						await this.$api.get(['suggest', this.api, id]).then(function(json) {

							selected.push(json.suggestion)
							Vue.set(this.cache, id, json.suggestion)

							this.is.preloading--

						}.bind(this))

					}

				}.bind(this))

				this.selected = selected

			} else {

				await this.$api.get(['suggest', this.api, this.currentValue]).then(function(json) {

					Vue.set(this, 'source', json.suggestion)
					this.search = this.source.text + ((this.source.subtext) ? ' (' + this.source.subtext + ')' : '')
					this.$emit('textchange', this.source.text)
					this.$emit('selected', this.source)

				}.bind(this))

			}

		},

		onKeyDown: function(e) {

			if (e.key === 'Enter') {

				e.preventDefault()

				if (this.suggestions.length) {
					
					this.onSuggestionClick((this.highlight) ? this.suggestions[this.highlight - 1].id : this.suggestions[0].id)
					this.$refs.input.blur()

				}

			} else if (e.key === 'Tab' || e.key === 'ArrowDown') {

				e.preventDefault()

				if (this.highlight < this.suggestions.length) this.highlight++
				
			} else if (e.key === 'ArrowUp') {

				e.preventDefault()

				if (this.highlight > 0) this.highlight--
				
			}

		},

		onChangeClick: function() {

			this.search = ''

			this.$refs.input.focus()
			
			if (!this.multiple) this.$emit('input', this.nullValue)

		},

		onCreateClick: function() {

		},

		onRemoveClick: function(id) {

			var value = this.$util.copy(this.value)

			if (this.multiple) {

				value.splice(value.indexOf(id), 1) 

			} else {

				value = this.nullValue

			}

			this.$emit('input', value)

		},

		onSuggestionClick: function(id) {

			var value

			if (this.multiple) {

				value = this.$util.copy(this.value)

				value.push(id)
				
				this.internalValue = 0
				this.search = ''

			} else {

				value = id

			}

			if (this.manual) {

				this.$emit('selected', value)

			} else {


				this.$emit('input', value)

			}
			
			this.$refs.input.blur()

			this.isOver = false

		}

	}

}

</script>

<style scoped>

.suggest-wrapper {
	display: flex;
	flex-direction: column;
}

.suggest-search {
	width: 100%;
	color: #333;
	display: flex;
	order: 2;
}

.suggest-selected {
	order: 1;
	margin-bottom: 10px;
}

.suggest-selected.is-below {
	order: 3;
	margin-top: 10px;
}

.suggest-selected-item {
	width: 100%;
	color: #333;
	cursor: pointer;
	display: flex;
}

.suggest-selected-item:hover {
	background-color: #eee;
}

.suggest.is-dark .suggest-selected-item {
	padding: 0px 10px;
	margin-top: 0px;
}

.suggest.is-dark .suggest-selected-item:hover {
	background-color: #626e7f;
	color: #fff;
    border-radius: 4px;
	padding: 0px 10px;
}

.suggest-selected.is-below .suggest-selected-item {
	margin-bottom: 0px;
	margin-top: 10px;
}

.suggest.is-dark .suggest-selected.is-below .suggest-selected-item {
	margin-bottom: 0px;
	margin-top: 0px;
}

.suggest-search-image {
	width: 40px;
	height: 40px;
	background-color: #eee;
	background-size: cover;
	border-radius: 12px 0px 0px 12px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.suggest.is-dark .suggest-search-image {
	display: none;
}

.suggest-search-preicon {
	width: 40px;
	height: 40px;
	line-height: 32px;
	text-align: center;
	background-color: #4082d3;
	color: #fff;
	border: 4px solid transparent;
	border-radius: 12px 0px 0px 12px;
}

.suggest-search-image.has-color {
	border-width: 10px 8px 10px 10px;
	width: 48px;
}

.suggest-search-image-missing {
	font-size: 14px;
	color: #999;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.suggest-search-text {
	line-height: 36px;
	font-size: 14px;
	flex-grow: 1;
	padding: 0px 10px;
	color: #333;
	background-color: #fff;
	border-radius: 10px;
	border: 2px solid #eee;
}

.suggest.is-dark .suggest-selected .suggest-search-text {
	background-color: transparent;
    border-color: transparent;
    color: #fff;
	user-select: none;
    font-size: 16px;
	padding-left: 0px;
    color: #bfc4cb;
    font-weight: 300;
}

.suggest-search-icon {
	color: #ddd;
	position: absolute;
	right: 12px;
	top: 12px;
	font-size: 16px;
}

.suggest-selected-item .suggest-search-text,
.suggest.has-value .suggest-search-text {
	border-radius: 12px 0px 0px 12px;
	border-right-width: 0px;
	background-color: #fff;
	pointer-events: none;
	font-weight: 500;
}

.suggest-selected-item .suggest-search-text {
	border: 0px;
	border-radius: 0px;
	background-color: transparent;
}

.suggest-selected-item {
	border: 2px solid #eee;
	border-bottom: 0px;
	border-radius: 0px;
}

.suggest-selected-item:first-child {
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
}

.suggest-selected-item:last-child {
	border-bottom: 2px solid #eee;
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
}

.suggest.is-creatable .suggest-search-text {
	border-radius: 12px 0px 0px 12px;
	border-right-width: 0px;
}

.suggest.has-value.has-image .suggest-selected-item .suggest-search-text,
.suggest.has-value.has-image .suggest-search-text {
	border-radius: 0px 0px 0px 0px;
}

.suggest.is-suggesting .suggest-search-text {
	border-radius: 12px 12px 0px 0px;
}

.suggest.is-suggesting.has-image.has-value .suggest-search-text {
	border-radius: 0px 12px 0px 0px;
}

.suggest-search-text:focus {
	border-color: #4082d3;
}

.suggest-search-button {
	flex-shrink: 0;
	border-left-width: 0px;
	width: 36px;
	line-height: 36px;
	text-align: center;
	color: #4082d3;
	cursor: pointer;
	font-size: 16px;
	transition: all 100ms linear;
	color: #eee;
}

.suggest.has-value .suggest-search-button {
	color: #fff;
	background-color: #4082d3;
    border-color: #4082d3;
	border-radius: 0px 10px 10px 0px;
}

.suggest-selected-item:hover .suggest-search-button {
	color: #ccc;
}

.suggest.is-dark .suggest-selected .suggest-search-button {
	width: 24px;
	height: 24px;
	background-color: #5f6d80;
	border-radius: 4px;
	text-align: center;
	font-size: 14px;
	color: #535d6a;
	line-height: 26px;
	cursor: pointer;
	border: 0px;
	margin-top: 9px;
}

.suggest.is-dark .suggest-selected-item:hover .suggest-search-button {
	background-color: #2f3a4a;
	color: #fff;
}

.suggest-selected-item .suggest-search-button {
	border-radius: 0px;
}

.suggest-list {
	position: absolute;
	z-index: 2;
	left: 0px;
	padding: 4px 0px;
	width: 100%;
	background-color: #4082d3;
	border-radius: 0px 0px 12px 12px;
	top: 40px;
}

.suggest-list-item {
	padding: 4px 10px;
	font-size: 14px;
	color: #fff;
	line-height: 20px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
}

.suggest-list-item:nth-child(even) {
	background-color: rgba(255, 255, 255, 0.1);
}

.suggest-list-item:hover,
.suggest-list-item.is-highlight {
	background-color: rgba(0, 0, 0, 0.15);
}

.suggest-list-item small {
	display: block;
	font-size: 12px;
	line-height: 12px;
}

.suggest-list-item-icon {
	font-size: 16px;
	width: 24px;
}

</style>
