<template>

<div class="thirdparty">

	<app-blocks-common-form :item="item" :skip-confirm="true" v-on:change="onChange" />

</div>
	
</template>

<script>

export default {

	props: ['item'],

	methods: {

		onChange: function(data) {

			this.$store.commit('register/thirdparty', (data.valid) ? data.model : false)

		}

	}

}

</script>

<style scoped>

.thirdparty {
	flex-direction: column;
	padding: 10px 40px;
}

.is-mobile .thirdparty {
	padding: 10px 10px;
}

</style>