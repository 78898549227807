<template>

<app-table-cell class="colour" :style="{backgroundColor: colour}">
    
</app-table-cell>
    
</template>
    
<script>
    
export default {
    
    props: ['colour']
    
}
    
</script>
    
<style scoped>
    
.colour {
    border-radius: 4px;
}
    
</style>