<template>

<div class="action" :data-theme="theme" :class="{'is-active': active, 'is-danger': danger, 'is-disabled': loading || disabled}" v-tooltip="tooltip" v-on:click.stop="$emit('click')">
	
	<app-icon :icon="(loading) ? 'loading' : icon" />

</div>

</template>

<script>

export default {

	props: ['icon', 'tooltip', 'theme', 'active', 'danger', 'disabled', 'loading']

}

</script>

<style scoped>

.action {
	font-size: 16px;
	width: 32px;
	height: 32px;
	border-radius: 4px;
	line-height: 30px;
	border: 1px solid #eee;
	text-align: center;
	margin-left: 4px;
	background-color: #fff;
}

.action.is-danger {
	background-color: #c55b5b;
	border-color: #c55b5b;
	color: #fff;
}

.action[data-theme="green"] {
	background-color: #3e8865;
	border-color: #3e8865;
	color: #fff;
}

.action.is-active {
	background-color: #ffbe7a;
	border-color: #ffbe7a;
	color: #fff;
}

.action.is-disabled {
	pointer-events: none;
	opacity: 0.25;
}

.is-desktop .row:hover .action:not([data-theme]):not(.is-danger) {
	background-color: #fff;
	border-color: #fff;
	color: #ffbe7a;
}

</style>