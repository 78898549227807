<template>

<app-table-cell class="shelf" align="center">

	<div class="shelf-box" :data-status="status">
	
		<div class="shelf-box-value">{{ value }}</div>
		<div class="shelf-box-status">{{ statusText }}</div>

	</div>
	
</app-table-cell>

</template>

<script>

export default {

	props: ['value', 'status'],

	computed: {

		statusText: function() {

			return (this.status === this.$constants.games.status.available) ? 'Available' : 'Taken'

		}

	}

}

</script>

<style scoped>

.shelf-box {
	width: 48px;
	height: 48px;
	border-radius: 4px;
	background-color: #687589;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.shelf-box[data-status="1"] {
	background-color: #4faa80;
}

.shelf-box[data-status="2"] {
	background-color: #c55b5b;
}

.shelf-box-value {
	text-align: center;
	color: #fff;
	font-size: 14px;
	line-height: 14px;
	font-weight: bold;
}

.shelf-box-status {
	text-align: center;
	color: #fff;
	font-size: 10px;
	font-weight: 400;
}

</style>
