<template>

<div class="search">

	<input class="search-input" v-bind:value="value" v-on:input="onChange" type="text" placeholder="Quick search..." />

	<app-icon icon="search" class="search-icon" />

</div>

</template>

<script>

export default {

	props: ['value', 'minLength'],

	methods: {

		onChange: function(e) {

			if (this.minLength) {

				this.$emit('input', (e.target.value.length >= this.minLength) ? e.target.value : '')

			} else {

				this.$emit('input', e.target.value)

			}

		}

	}

}

</script>

<style scoped>

.search {
	flex-grow: 1;
	flex-shrink: 0;
	min-width: 200px;
	display: flex;
	padding-right: 10px;
}

.is-mobile .search {
	width: 100%;
	border-bottom: 1px solid #eee;
}

.search-input {
	flex-grow: 1;
	padding: 0px 20px;
	height: 32px;
	font-size: 12px;
	color: #333;
}

.search-input::placeholder {
	color: #999;
}

.search-icon {
	flex-shrink: 0;
	line-height: 32px;
	font-size: 12px;
	color: #999;
}

</style>
