<template>

<app-panel v-on:close="$emit('close')">
		
	<app-panel-title text="Colour picker" />

	<app-panel-content class="picker" :space="true">
		
		<v-swatches v-model="colour" swatches="text-advanced" inline />

	</app-panel-content>

	<app-panel-content :space="true">

		<app-button text="Confirm" v-on:click="$emit('save', colour.replace('#', ''))" />

	</app-panel-content>

</app-panel>

</template>

<script>

import VSwatches from 'vue-swatches'
import "vue-swatches/dist/vue-swatches.css"

export default {

	components: { 
		VSwatches 
	},

	data: function() {

		return {
			colour: '#ffffff'
		}

	},

	created: function() {
		
		this.colour = this.value

		this.$pubsub.$emit('offset', true)

	},

	beforeDestroy: function() {

		this.$pubsub.$emit('offset', false)

	}

}

</script>

<style scoped>

.picker >>> .vue-swatches__container {
	width: 100%!important;
	background-color: transparent!important;
}

.picker >>> .vue-swatches__swatch {
	width: 26px!important;
	height: 26px!important;
	margin: 1px!important;
	border-radius: 4px!important;
}

</style>
