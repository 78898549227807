import api from '@/api'
import live from '@/live'
import Vue from 'vue'

import modBuddies from './session/buddies'

export default {

	namespaced: true,

	modules: {
		buddies: modBuddies,
	},

	state: {
		data: {}
	},

	getters: {

		get: function(state) {

			return state.data

		},

		id: function(state) {

			return state.data.id

		},

		name: function(state) {

			return state.data.name

		},

		firstname: function(state) {

			return state.data.firstname

		},

		lastname: function(state) {

			return state.data.lastname

		},

		displayname: function(state) {

			return state.data.displayname

		},

		avatar: function(state) {

			return state.data.avatar

		},

		accepted: function(state) {

			return state.data.accepted

		},

		bgg: function(state) {

			return state.data.bgg

		},

		phone: function(state) {

			return state.data.phone

		},

		email: function(state) {

			return state.data.email

		},

		badge: function(state) {

			return state.data.badge

		},

		scope: function(state) {

			return state.data.scope

		},

		organisations: function(state) {

			return state.data.organisations

		},

		teams: function(state) {

			return state.data.teams

		},

		conventions: function(state) {

			return state.data.conventions || []

		},

		announcements: function(state) {

			return state.data.announcements || []

		},

		'count': function(state) {

			return (state.data.count) ? state.data.count : {}

		},

		'count/notifications': function(state) {

			return (state.data.count) ? state.data.count.notifications : 0

		},

		'count/announcements': function(state) {

			return (state.data.count) ? state.data.count.announcements : 0

		},

		'count/purchases': function(state) {

			return (state.data.count) ? state.data.count.purchases : 0

		},

		'count/managed': function(state) {

			return (state.data.count) ? state.data.count.managed : 0

		},

		'count/wildcards': function(state) {

			return (state.data.count) ? state.data.count.wildcards : 0

		},

		'count/buddies': function(state) {

			return (state.data.count) ? state.data.count.buddies : 0

		},

		'count/schedule/shortlisted': function(state) {

			return (state.data.count) ? state.data.count.schedule.shortlisted : 0

		},

		'count/schedule/personal': function(state) {

			return (state.data.count) ? state.data.count.schedule.personal : 0

		},

		'count/schedule/interests': function(state) {

			return (state.data.count) ? state.data.count.schedule.interests : 0

		},

		'count/checkout/notify': function(state) {

			return (state.data.count) ? state.data.count.checkout.notify : 0

		},

		'date/notify': function(state) {

			return state.data.date.notify

		},

		'date/announcement': function(state) {

			return state.data.date.announcement

		},

		settings: function(state) {

			return state.data.settings

		}

	},

	mutations: {

		set: function(state, value) {

			Vue.set(state, 'data', value)

		},

		'date/announcement': function(state, value) {

			state.data.date.announcement = value

		},

		'date/notification': function(state, value) {

			state.data.date.notification = value

		},

		'count/announcements': function(state, value) {

			if (value === 0) {

				state.data.count.announcements = 0

			} else {

				state.data.count.announcements += value
			}

		},

		'count/notifications': function(state, value) {

			if (value === 0) {

				state.data.count.notifications = 0

			} else {

				state.data.count.notifications += value
			}

		}

	},

	actions: {

		login: function(context, params) {

			return new Promise(function(resolve, reject) {
				
				api.post('login', params).then(async function(data) {

					context.commit('key', data.token, { root: true })

					await context.dispatch('init')

					resolve()

				}, function(data) {

					reject(data)

				})

			})

		},

		logout: async function(context) {

			await context.dispatch('$deinit')
			await context.dispatch('buddies/deinit')

			context.commit('key', '', { root: true })
			context.commit('buddies/set', [])

		},

		$init: function(context) {

			live.$on('user/update', function(data) {

				context.commit('set', data)

			})

			live.$on('user/date/announcement', function(data) {

				context.commit('date/announcement', data.date)
				context.commit('count/announcements', 0)

			})

			live.$on('user/date/notification', function(data) {

				context.commit('date/notification', data.date)
				context.commit('count/notifications', 0)

			})

			live.$on('user/notification/add', function(data) {

				if (data.date > context.getters['date/notification']) context.commit('count/notifications', 1)

			})

			live.$on('user/notification/remove', function(data) {

				if (data.date > context.getters['date/notification']) context.commit('count/notifications', -1)

			})

			live.$listen('convention/announcement/add', function(data) {

				if (data.date > context.getters['date/announcement']) context.commit('count/announcements', 1)

			})

			live.$listen('convention/announcement/remove', function(data) {

				if (data.date > context.getters['date/announcement']) context.commit('count/announcements', -1)

			})

		},

		$deinit: function() {

			live.$off('user/update')

			live.$off('user/date/announcement')
			live.$off('user/date/notification')

			live.$off('user/notification/add')
			live.$off('user/notification/remove')

			live.$unlisten('convention/announcement/add')
			live.$unlisten('convention/announcement/remove')

		},

		init: async function(context) {

			if (context.rootGetters['key']) {
				
				await api.get('session').then(async function(data) {

					await context.dispatch('$init')
					await context.dispatch('buddies/init')

					if (data.buddies) context.commit('buddies/set', data.buddies)

				}, function() {

					context.commit('key', '', { root: true })

				})
				
			}

		}

	}

}
