<template>

<com-screen :is-natural="true" id="app" :loading="is.loading" class="screen-register">

	<router-view :key="$path" v-if="!is.loading" />

</com-screen>

</template>

<script>

import comScreen from './screen/Wrapper'

export default {

	name: 'App',

	components: {
		'com-screen': comScreen
	},

	data: function() {

		return {
			is: {
				initiated: false,
				loading: true
			}
		}

	},

	watch: {

		$path: function() {

			if (this.$route.name) this.load()

		}

	},

	created: function() {

		if (this.$route.name) this.load()

	},

	methods: {

		load: function() {

			if (!this.is.initiated) {

				this.$api.get('page', {
					url: window.location.pathname.slice(1)
				}).then(async function(json) {

					this.$store.commit('register/set', json)

					if (!json.preview) this.$live.init()

					this.is.initiated = true

					if (this.$route.query.payment_intent) {

						this.checkPayment()

					} else {

						this.is.loading = false

					}

				}.bind(this))
					
			}

		},

		checkPayment: async function() {

			await this.$store.dispatch('register/retrieve', this.$route.query.payment_intent_client_secret)

			if (this.$store.getters['register/sale'] && this.$store.getters['register/status'] !== this.$constants.registration.payment.processing) {

				this.$router.replace({'query': null})

				this.is.loading = false

			} else {

				setTimeout(function() { this.checkPayment() }.bind(this), 2000)

			}

		}

	}

}

</script>

<style>

body, html {
	min-height: calc(100vh - var(--vh-offset, 0px));
	background: #fff;
	background-image: linear-gradient(-180deg, rgba(255,255,255,0.50) 0%, rgba(0,0,0,0.50) 100%);
	background-blend-mode: lighten;
	overflow: auto;
}

.screen-register .page {
    overflow-y: auto;
}

</style>
