<template>

<div class="gallery">
	
	<div v-tooltip="'Click to enlarge'" v-for="(image, index) in item.images" :key="index" class="gallery-item" v-bgimage="image.image" v-on:click="onEnlargeClick(index)" />

	<div class="gallery-enlarged" v-if="is.enlarged">
		
		<img class="gallery-enlarged-image" v-on:load="onLoaded" :src="item.images[is.enlarged - 1].image" />
		<div class="gallery-enlarged-background" v-show="!is.loading" v-on:load="onLoaded" v-bgimage="item.images[is.enlarged - 1].image" />

		<div class="gallery-enlarged-info" v-if="item.images[is.enlarged - 1].title || item.images[is.enlarged - 1].text">
			<div class="gallery-enlarged-info-title" v-if="item.images[is.enlarged - 1].title">{{ item.images[is.enlarged - 1].title }}</div>
			<div class="gallery-enlarged-info-text" v-if="item.images[is.enlarged - 1].text">{{ item.images[is.enlarged - 1].text }}</div>
		</div>

		<app-icon class="gallery-enlarged-loading" v-if="is.loading" icon="loading" />

		<div class="gallery-enlarged-left" v-on:click="onLeftClick"><app-icon icon="caret-left" /></div>
		<div class="gallery-enlarged-right" v-on:click="onRightClick"><app-icon icon="caret-right" /></div>
		<div class="gallery-enlarged-close" v-on:click="onCloseClick"><app-icon icon="close" /></div>

	</div>

</div>
	
</template>

<script>

export default {

	props: ['item'],

	data: function() {

		return {
			is: {
				enlarged: false,
				loading: false
			}
		}

	},

	methods: {

		onLeftClick: function() {

			this.is.enlarged = (this.is.enlarged > 0) ? this.is.enlarged - 1 : this.is.enlarged = this.item.images.length

		},

		onRightClick: function() {

			this.is.enlarged = (this.is.enlarged < this.item.images.length) ? this.is.enlarged + 1 : 1

		},
		
		onCloseClick: function() {

			this.is.enlarged = false

		},

		onLoaded: function() {

			this.is.loading = false

		},

		onEnlargeClick: function(index) {

			this.is.loading = true

			this.is.enlarged = index + 1

		}

	}
	
}

</script>

<style scoped>

.gallery {
	flex-wrap: wrap;
	display: flex;
	justify-content: center;
	margin: -10px;
}

.gallery-item {
	width: 200px;
	height: 200px;
	background-position: 50% 50%;
	background-size: cover;
	border-radius: 4px;
	cursor: pointer;
	margin: 10px;
}

.gallery-enlarged {
	position: fixed;
	background-color: rgba(0, 0, 0, 0.8);
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 10000;
	display: flex;
	justify-content: center;
	align-items: center;
}

.gallery-enlarged-image {
	opacity: 0;
	position: absolute;
	pointer-events: none;
}

.gallery-enlarged-background {
	width: calc(100% - 128px);
	height: calc(100% - 128px);
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.gallery-enlarged-loading {
	position: absolute;
	left: 50%;
	top: 50%;
	color: #fff;
	font-size: 48px;
	transform: translate(-50%, -50%);
}

.gallery-enlarged-close,
.gallery-enlarged-left,
.gallery-enlarged-right {
	position: absolute;
	top: 50%;
	width: 40px;
	height: 40px;
	background-color: #4082d3;
	margin-top: -20px;
	z-index: 2;
	line-height: 40px;
	text-align: center;
	color: #fff;
	font-size: 24px;
	cursor: pointer;
	transition: width 100ms linear;
}

.gallery-enlarged-close {
	top: 0px;
	margin-top: 0px;
	right: 0px;
}

.gallery-enlarged-left:hover,
.gallery-enlarged-right:hover {
	width: 56px;
}

.gallery-enlarged-left {
	left: 0px;
}

.gallery-enlarged-right {
	right: 0px;
}

.gallery-enlarged-info {
	position: absolute;
	bottom: 0px;
	text-align: center;
	color: #fff;
	padding: 20px;
}

.gallery-enlarged-info-title {
	font-size: 24px;
	font-weight: bold;
}

.gallery-enlarged-info-text {
	font-size: 16px;
	line-height: 20px;
}

</style>