<template>

<div class="body" v-on:scroll="onScroll" :class="{'is-empty': isEmpty, 'is-white': white, 'no-grow': noGrow, 'no-padding': noPadding, 'is-call': isCall, 'is-grid': isGrid && !isEmpty, 'is-loading': loading, [clsGrid]: isGrid}">

	<slot name="persist"></slot>

	<template v-if="!isEmpty">

		<slot></slot>

	</template>

	<div class="body-empty" v-if="isEmpty">

		<app-icon icon="empty" class="body-empty-icon" />

		<div class="body-empty-text">{{ placeholder }}</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['isEmpty', 'placeholder', 'white', 'noGrow', 'noPadding', 'loading', 'isCall', 'isGrid'],

	computed: {

		clsGrid: function() {

			return (this.isGrid) ? 'size-' + ((this.isGrid === true) ? 4 : this.isGrid).toString() : false

		}

	},

	methods: {

		onScroll: function(e) {

			if (Math.ceil(e.target.scrollTop) >= e.target.scrollHeight - e.target.clientHeight) this.$emit('scrolled')

		}

	}

}

</script>

<style scoped>

.body {
	padding: 20px;
	transition: opacity 100ms ease-in-out;
	flex-grow: 1;
	overflow-y: auto;
}

.body.is-white {
	background-color: #fff;
}

.is-mobile .body {
	padding: 10px;
}

.body.no-padding {
	padding: 0px;
}

.body.no-grow {
	flex-grow: 0;
}

.body.is-grid {
	display: grid;
	grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
	grid-gap: 20px;
	grid-auto-rows: minmax(min-content, max-content);
}

.body.is-grid >>> .box-content:not(.is-grid) {
	height: 100%;
	flex-direction: column;
    display: flex;
}

.box:not(.no-title) >>> .box-content:not(.is-grid) {
	height: calc(100% - 26px);
}

.body.is-grid.size-2 {
	grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
}

.body.is-grid.size-3 {
	grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
}

.is-mobile .body.is-grid {
	grid-template-columns: minmax(0, 1fr)!important;
	grid-gap: 10px;
}

.body.is-loading {
	opacity: 0.25;
}

.body.is-empty,
.body.is-call {
	height: calc(100vh - var(--vh-offset, 0px) - 160px);
}

.body.is-call {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.body-empty {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #a7b6c6;
}

.body-empty-icon {
	font-size: 96px;
}

.body-empty-text {
	font-size: 24px;
	font-weight: 400;
	padding: 20px 0px;
}

</style>
