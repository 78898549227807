<template>

<app-input :wide="wide" :messages="messages" :label="label" :stacked="stacked" :no-line="noLine" :locked="locked" :notes="notes" :disabled="disabled" :validation="validation">

	<app-input-suggest class="library-suggest" placeholder="Select game" image="image" api="bgg/game" v-bind:value="value" v-on:input="$emit('input', $event)" />

	<div class="account-note is-blue" v-if="is.checking"><app-icon icon="loading" /> Checking game availability...</div>
	<div class="account-note is-blue" v-if="!is.checking && ((validation.$invalid && !is.exists) || !value || (reverse && !isDirty))"><app-icon icon="warning" /><span>{{ text.info }}</span></div>
	<div class="account-note is-yellow" v-if="!is.checking && is.checked && is.exists &&!is.available && isDirty"><app-icon icon="library.unavailable" /><span>{{ text.unavailable }}</span></div>
	<div class="account-note is-green" v-if="!is.checking && is.checked && is.exists && is.available && isDirty"><app-icon icon="library.available" /><span>{{ text.available }}</span></div>
	<div class="account-note is-red" v-if="!is.checking && is.checked && value && !is.exists"><app-icon icon="library.none" /><span>{{ text.none }}</span></div>

</app-input>

</template>

<script>

export default {

	props: ['label', 'value', 'original', 'locked', 'type', 'endpoint', 'reverse', 'wide', 'messages', 'stacked', 'noLine', 'notes', 'disabled', 'validation', 'text'],

	data: function() {

		return {
			placeholder: 'Search game',
			is: {
				checked: false,
				checking: false,
				exists: false,
				available: false
			},
			name: ''
		}

	},

	watch: {

		value: function() {

			this.check()

		}

	},

	created: function() {

		if (this.value) this.check()

	},

	computed: {

		isDirty: function() {

			return this.original !== this.value

		}

	},

	methods: {

		check: async function() {

			if (this.validation.$invalid || this.value === '') {

				this.is.checked = false
				this.is.exists = false
				this.is.available = false
					
			} else {

				this.is.checked = false
				this.is.checking = true

				this.$api.cancel()

				await this.$api.get(this.endpoint || 'convention/checkout/exists', {
					game: this.value,
					type: this.type
				}).then(function(json) {

					this.is.checked = true
					this.is.checking = false
					this.is.available = json.available
					this.is.exists = json.exists

				}.bind(this), function(json) {

					this.is.checked = true
					this.is.checking = false
					this.is.available = json.available
					this.is.exists = json.exists

				}.bind(this))

			}

			this.$emit('statusChange', {
				unavailable: this.is.exists && !this.is.available,
				available:  this.is.exists && this.is.available,
				none: !this.is.exists
			})

		}

	}

}

</script>

<style scoped>

.library-suggest {
	margin-bottom: 0px;
}

.library-suggest >>> .suggest-search-text {
	border-radius: 10px 10px 0px 0px;
	border-bottom-color: transparent;
}

.library-suggest >>> .input-notes {
	display: none;
}

.library-suggest >>> .suggest-search-image {
	border-radius: 10px 0px 0px 0px;
}

.library-suggest >>> .suggest-search-button {
	border-radius: 0px 10px 0px 0px;
}

.account {
	line-height: 36px;
	font-size: 14px;
	width: 100%;
	padding: 0px 10px;
	color: #333;
	background-color: #fff;
	border-radius: 12px 12px 0px 0px;
	border: 2px solid #eee;
	border-bottom-width: 0px;
}

.account:focus {
	border-color: #4082d3;
}

.is-device .account {
	font-size: 14px;
}

.is-mobile .account {
	padding: 0px 10px;
}

.account-note {
	color: #fff;
	padding: 10px 20px;
	border-radius: 0px 0px 12px 12px;
	font-size: 14px;
	line-height: 16px;
	align-items: center;
	display: flex;
}

.input.is-stacked .account-note {
	padding: 10px 10px 10px 20px;
}

.account-note >>> .icon {
	font-size: 32px;
	margin-right: 20px;
}

.input.is-stacked .account-note >>> .icon {
	font-size: 24px;
}

.account-note.is-blue {
	background-color: #4082d3;
}

.account-note.is-red {
	background-color: #c55b5b;
}

.account-note.is-green {
	background-color: #4faa80;
}

.account-note.is-yellow {
	background-color: #ffbe7a;
}

</style>
