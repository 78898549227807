<template>

<div class="card" :class="{'is-empty': !buddy}">

	<template v-if="buddy">

		<div class="card-image" v-bgimage="buddy.user.avatar">

			<app-icon icon="user" v-if="!buddy.user.avatar" />

		</div>

		<div class="card-name">
			<div class="card-name-title">{{ buddy.name }}</div>
			<div class="card-name-subtitle" v-if="buddy.user.bgg">{{ buddy.user.bgg }}</div>
		</div>

		<div class="card-actions">

			<app-card-action tooltip="Add to Buddies" icon="plus" v-if="!isBuddy" v-on:click="onBuddyClick" :loading="is.saving" />
			<app-card-action :danger="true" tooltip="Remove from Buddies" icon="minus" v-if="isBuddy" v-on:click="onBuddyClick" :loading="is.saving" />

		</div>

	</template>

</div>

</template>

<script>

export default {

	props: ['buddy'],

	data: function() {

		return {
			is: {
				saving: false
			}
		}

	},

	computed: {

		isBuddy: function() {

			return this.$store.getters['session/buddies/is'](this.buddy.user.id)

		}

	},

	methods: {

		onBuddyClick: function() {

			this.$action('saving', 'session/buddies/toggle', this.buddy.user.id)

		}

	}

}

</script>

<style scoped>

.card {
	border-radius: 12px;
	height: 64px;
	border: 1px solid #eee;
	background-color: #fff;
	flex-direction: row;
	display: flex;
	align-items: center;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.card.is-empty {
	border-width: 0px;
	box-shadow: none;
	background-color: rgba(175, 90, 90, 0.1);
}

.card-image {
	height: 64px;
	width: 64px;
	background-size: cover;
	flex-shrink: 0;
	border-radius: 12px 0px 0px 12px;
	background-position: 50% 50%;
	border-right: 1px solid #eee;
	font-size: 24px;
	text-align: center;
	color: #ddd;
	line-height: 64px;
}

.card-name {
	padding: 10px;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.card-name-title {
	color: #333;
	font-weight: 400;
	font-size: 16px;
}

.card-name-subtitle {
	color: #707070;
	font-size: 14px;
	margin-top: 2px;
}

.card-empty {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 20px;
	font-weight: 500;
	text-align: center;
	width: 100%;
	padding: 20px;
	color: rgba(0, 0, 0, 0.15);
}

.card-actions {
	display: flex;
	flex-direction: row;
	padding-right: 10px;
	flex-shrink: 0;
}

</style>
