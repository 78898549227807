<template>

<app-table-cell>

	<div class="status" :data-status="status" v-tooltip="tooltip">

		<app-icon icon="tickOnly" v-if="status === 1" />
		<app-icon icon="crossOnly" v-if="!status" />

	</div>

</app-table-cell>

</template>

<script>

export default {

	props: ['status', 'tooltip']

}

</script>

<style scoped>

.status {
	height: 16px;
	width: 16px;
	border-radius: 50%;
	background-color: #c55b5b;
	color: #fff;
	font-size: 10px;
	text-align: center;
	line-height: 16px;
}

.status[data-status="1"] {
	background-color: #4faa80;
}

.status[data-theme="green"] {
	background-color: #4faa80;
}

.status[data-theme="red"] {
	background-color: #c55b5b;
}

.status[data-theme="yellow"] {
	background-color: #ffbe7a;
}

</style>
