<template>

<div class="panel-content" :class="{'is-space': space, 'is-grow': grow, 'is-border': border}">

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['space', 'grow', 'border']

}

</script>

<style scoped>

.panel-content {
	padding: 0px 20px 20px 20px;
}

.panel-content.is-border {
	border-top: 1px solid rgba(0, 0, 0, 0.5);
}

.panel-content.is-grow {
	flex-grow: 1;
}

.panel-content.is-space {
	padding-top: 20px;
}

.panel-content >>> .input-label {
	color: #bfc4cb;
}

.panel-content >>> .button {
	width: 100%;
}

.panel.theme-green .panel-content >>> .input-label {
	color: #fff;
}

</style>