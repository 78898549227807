<template>

<app-table-cell-text>

	<app-widget-seats :seats="item.seats.total" :indexed="item.seats.indexed" :taken="item.seats.taken" :joined="item.session.is.sitting" :show-full="false" />

</app-table-cell-text>

</template>

<script>

export default {

	props: ['item']

}

</script>

<style scoped>

</style>