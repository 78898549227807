<template>

<div class="cell-wrapper" :class="{'is-center': center, 'is-small': isSmall}">

	<div class="cell-text" v-if="!seats && showFull">Unlimited</div>
	<app-icon class="cell-icon" icon="unlimited" v-if="!seats && !showFull" />

	<template v-if="seats && seats <= 8">

		<div class="cell-seat" v-tooltip="tooltip(seat)" v-for="seat in seats" :class="{'is-taken': seat <= taken, 'is-buddy': seat <= buddies && buddies, 'is-self': joined && seat === 1}" :key="seat"><app-icon icon="user" v-if="seat <= taken" /></div>

	</template>

	<template v-if="seats && seats > 8">

		<div class="cell-counter" v-tooltip="'Your seat'" v-if="joined"><div class="cell-seat is-self"><app-icon icon="user" /></div></div>
		<div class="cell-counter" v-tooltip="'Buddy seats'" v-if="buddies"><div class="cell-seat is-buddy"><app-icon icon="user" /></div><div class="cell-seat-text">x{{ buddies }}</div></div>
		<div class="cell-counter" v-tooltip="'Taken seats'" v-if="taken - buddies - joined"><div class="cell-seat is-taken"><app-icon icon="user" /></div><div class="cell-seat-text">x{{ taken - buddies - joined }}</div></div>
		<div class="cell-counter" v-tooltip="'Available seats'" v-if="taken < seats"><div class="cell-seat"></div><div class="cell-seat-text">x{{ seats - taken }}</div></div>

	</template>

</div>

</template>

<script>

export default {

	props: ['seats', 'taken', 'users', 'indexed', 'joined', 'center', 'showFull', 'isSmall'],

	computed: {

		buddies: function() {

			var buddies = 0

			if (this.$loggedin) {

				this.$_.each(this.indexed, function() {

					// if (this.$store.getters['user/buddies/is'](id)) buddies++

				}.bind(this))

			}

			if (this.joined && buddies) buddies++

			return buddies

		}	

	},

	methods: {

		tooltip: function(seat) {

			if (seat === 1 && this.joined) return 'Your seat'
			if (seat <= this.buddies && this.buddies) return 'Buddy seat'
			if (seat <= this.taken) return 'Seat taken'

			return 'Available seat'

		}

	}

}

</script>

<style scoped>

.cell-wrapper {
	display: flex;
	flex-direction: row;
	vertical-align: middle;
	justify-content: flex-start;
	flex-wrap: wrap;
}

.is-mobile .cell-wrapper {
	min-width: 40px;
}

.cell-wrapper.is-center {
	justify-content: center;
}

.cell-icon {
	font-size: 10px;
	color: #2f3a4a;
	line-height: 10px;
}

.cell-counter {
	display: flex;
	align-items: center;
	margin-right: 8px;
}

.cell-seat {
	width: 20px;
	height: 20px;
	border-radius: 10px;
	margin: 2px 4px 2px 0px;
	background-color: #fff;
	border: 2px dashed #ccc;
	color: #fff;
	text-align: center;
	font-size: 12px;
	line-height: 20px;
}

.cell-seat-text {
	font-size: 14px;
}

.cell-wrapper.is-small .cell-seat {
	width: 4px;
	height: 4px;
	border-radius: 2px;
	background-color: #ccc;
	border-width: 0px;
}

.cell-wrapper.is-small .cell-seat >>> .icon {
	display: none;
}

.cell-seat.is-taken {
	background-color: #2f3a4a!important;
	border-width: 0px;
}

.cell-seat.is-buddy {
	background-color: #4082d3!important;
	border-width: 0px;
}

.cell-seat.is-self {
	background-color: #88d588!important;
	border-width: 0px;
}

</style>
