<template>

<app-input class="select" :wide="wide" :validation="validation" :class="{'has-value': value, 'is-expanded': isExpanded}" :locked="locked" :label="label" :stacked="stacked" :no-line="noLine" :is-ready="value" :notes="notes" :disabled="disabled">

	<div class="select-value" v-on:click="onToggleClick" :data-theme="theme">

		<template v-if="value">{{ valueText }}</template>
		<template v-if="!value">{{ placeholder }}</template>

		<app-icon :icon="icon || 'caret-down'" class="select-value-icon" />
		
	</div>

	<div class="select-list" v-if="isExpanded && !isKeyed">

		<div class="select-list-item" :class="{'is-active': key === value}" v-for="(option, key) in allowedOptions" :key="key" v-on:click="onOptionClick(key)">
			
			<div class="select-list-item-icon" v-if="icons"><app-icon :icon="icons[key]" /></div>
			<div class="select-list-item-text">{{ option }}</div>
			
		</div>

	</div>

	<div class="select-list" v-if="isExpanded && isKeyed">

		<div class="select-list-item" :class="{'is-active': option[valueProperty] === value}" v-for="option in allowedOptions" :key="option[valueProperty]" v-on:click="onOptionClick(option[valueProperty])">
			
			<div class="select-list-item-icon" v-if="icons"><app-icon :icon="icons[option[valueProperty]]" /></div>
			<div class="select-list-item-text">{{ option.text }}</div>
			
		</div>

	</div>
	
</app-input>

</template>

<script>

export default {

	props: ['validation', 'wide', 'label', 'locked', 'icon', 'allowDeselect', 'property', 'theme', 'disabled', 'value', 'icons', 'options', 'noLine', 'store', 'stacked', 'placeholder', 'notes'],

	data: function() {

		return {
			isExpanded: false
		}

	},

	computed: {

		allowedOptions: function() {

			var options = this.$util.copy(this.options)

			if (this.allowDeselect) {

				if (this.isKeyed) {

					options.unshift({
						[this.valueProperty]: 0,
						text: this.placeholder
					})

				} else {

					options[0] = this.placeholder

				}

			}

			return options

		},

		valueProperty: function() {

			return this.property || 'value'

		},

		isKeyed: function() {

			return this.$_.isObject(this.options[this.$_.keys(this.options)[0]])

		},

		valueText: function() {

			var propertyName = this.valueProperty

			return (this.isKeyed) ? this.$_.filter(this.allowedOptions, function(option) {
				
				return option[propertyName].toString() === this.value.toString()
				
			}.bind(this))[0].text : this.allowedOptions[this.value]
			
		}

	},

	methods: {

		onToggleClick: function() {

			this.isExpanded = !this.isExpanded

		},

		onOptionClick: function(value) {
			
			this.isExpanded = false

			if (value === '0') value = 0

			this.$emit('input', value)

		}

	}

}

</script>

<style scoped>

.select-value {
	line-height: 36px;
	font-size: 14px;
	flex-grow: 1;
	padding: 0px 10px;
	color: #333;
	background-color: #fff;
	border-radius: 10px;
	border: 2px solid #eee;
	cursor: pointer;
}

.select-value:hover {
	border-color: #4082d3;
}

.select.is-expanded .select-value {
	border-color: #4082d3;
	border-radius: 12px 12px 0px 0px;
}

.select-value-icon {
	color: #999;
	position: absolute;
	right: 10px;
	font-size: 16px;
	top: 50%;
	line-height: 16px;
	margin-top: -8px;
}

.select.is-expanded .select-value-icon,
.select-value:hover .select-value-icon {
	color: #4082d3;
}

.select-value[data-theme="red"] {
	background-color: #c55b5b;
	border-color: #c55b5b;
	color: #fff;
	font-weight: 400;
}

.select-value[data-theme="yellow"] {
	background-color: #ffbe7a;
	border-color: #ffbe7a;
	color: #fff;
	font-weight: 400;
}

.select-value[data-theme="green"] {
	background-color: #4faa80;
	border-color: #4faa80;
	color: #fff;
	font-weight: 400;
}

.select-value[data-theme="red"] .select-value-icon,
.select-value[data-theme="yellow"] .select-value-icon,
.select-value[data-theme="green"] .select-value-icon {
	color: #fff;
}

.select-value[data-theme="red"]:hover {
	background-color: #c94141;
	border-color: #c94141;
}

.select-value[data-theme="yellow"]:hover {
	background-color: #d49959;
	border-color: #d49959;
}

.select-value[data-theme="green"]:hover {
	background-color: #3e8865;
	border-color: #3e8865;
}

.select-list {
	position: absolute;
	z-index: 2;
	left: 0px;
	padding: 4px 0px;
	max-height: 120px;
	overflow-y: auto;
	width: 100%;
	background-color: #4082d3;
	border-radius: 0px 0px 12px 12px;
}

.select-list-item {
	padding: 2px 10px;
	font-size: 14px;
	color: #fff;
	line-height: 20px;
	cursor: pointer;
	display: flex;
}

.select-list-item.is-active {
	font-weight: 400;
}

.is-desktop .select-list-item:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.select-list-item-icon {
	flex-shrink: 0;
	width: 24px;
	font-size: 16px;
}

.select-list-item-text {
	flex-grow: 1;
}

</style>
