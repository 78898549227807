<template>

<div class="select" v-on:click="$emit('toggle')">

	<div class="select-text">{{ text }}</div>

	<div class="select-value" v-on:click="onToggleClick">

		<template v-if="value || value === 0">{{ options[value] }}</template>
		<template v-if="!value && value !== 0">{{ placeholder }}</template>

		<app-icon icon="caret-down" class="select-value-icon" />
		
	</div>

	<app-panel v-if="isExpanded">
		
		<app-panel-title :text="placeholder" />

		<app-panel-check v-for="(option, key) in options" :active="key === value" :key="key" :text="option" v-on:toggle="onOptionClick(key)" />

		<app-panel-content :space="true">

			<app-button text="Back" v-on:click="isExpanded = false" />

		</app-panel-content>

	</app-panel>

</div>

</template>

<script>

export default {

	props: ['text', 'options', 'value', 'icons', 'placeholder'],

	data: function() {

		return {
			isExpanded: false
		}

	},

	methods: {

		onToggleClick: function() {

			this.isExpanded = !this.isExpanded

		},

		onOptionClick: function(value) {
			
			this.isExpanded = false

			this.$emit('input', value)

		}

	}

}

</script>

<style scoped>

.select {
	display: flex;
	flex-direction: row;
	min-height: 40px;
	align-items: center;
	color: #bfc4cb;
	padding: 0px 20px;
	cursor: pointer;
	margin-bottom: 4px;
}

.select:first-child {
	margin-top: 20px;
}

.select-text {
	font-size: 14px;
	font-weight: 300;
	margin-right: 10px;
	width: 100px;
	flex-shrink: 0;
	letter-spacing: 1px;
}

.select-value {
	line-height: 32px;
	flex-grow: 1;
	font-size: 14px;
	flex-grow: 1;
	padding: 0px 10px;
	color: #333;
	background-color: #fff;
	border-radius: 12px;
	cursor: pointer;
}

.select.is-expanded .select-value {
	border-color: #4082d3;
	border-radius: 12px 12px 0px 0px;
}

.select-value-icon {
	color: #999;
	position: absolute;
	right: 10px;
	font-size: 16px;
	top: 1px;
}

.select.is-expanded .select-value-icon,
.select-value:hover .select-value-icon {
	color: #333;
}

.select-list {
	position: absolute;
	z-index: 2;
	left: 0px;
	padding: 4px 0px;
	width: 100%;
	background-color: #4082d3;
	border-radius: 0px 0px 12px 12px;
}

.select-list-item {
	padding: 2px 10px;
	font-size: 14px;
	color: #fff;
	line-height: 20px;
	cursor: pointer;
	display: flex;
}

.select-list-item.is-active {
	font-weight: 400;
}

.select-list-item-icon {
	flex-shrink: 0;
	width: 24px;
	font-size: 16px;
}

.select-list-item-text {
	flex-grow: 1;
}

</style>
