import _ from 'underscore'
import live from '@/live'
import api from '@/api'
import util from '@/util'
import Vue from 'vue'

export default {

	namespaced: true,

	state: {
		users: []
	},

	getters: {

		get: function(state) {

			return state.users

		},

		count: function(state) {

			return state.users.length

		},

		is: function(state) {

			return function(id) {

				return _.contains(state.users, id)

			}

		}

	},

	mutations: {

		set: function(state, value) {

			Vue.set(state, 'users', value)

		},

		add: function(state, value) {

			util.array.add(state.users, value)
				
		},

		remove: function(state, value) {

			util.array.remove(state.users, value)
				
		}
		
	},

	actions: {

		init: function(context) {

			live.$on('user/buddies/add', function(params) {

				context.commit('add', params.id)

			})

			live.$on('user/buddies/remove', function(params) {

				context.commit('remove', params.id)

			})

		},

		deinit: function(context) {

			live.$off('user/buddies/add', function(params) {

				context.commit('add', params.id)

			})

			live.$off('user/buddies/remove', function(params) {

				context.commit('remove', params.id)

			})

		},

		toggle: function(context, id) {

			if (context.getters['is'](id)) {

				return api.delete('session/buddies/' + id, {
					user: id
				})

			} else {

				return api.post('session/buddies', {
					user: id
				})

			}

		}

	}

}
