<template>

<div class="text" :class="{'is-error': danger}">

	<span v-html="text" />

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['text', 'danger']

}

</script>

<style scoped>

.text {
	color: #bfc4cb;
	font-weight: 300;
	font-size: 14px;
	line-height: 20px;
	padding: 0px 20px;
	margin-bottom: 20px;
}

.text:first-child {
	margin-top: 20px;
}

.text.is-error {
	color: #fff;
	background-color: #895454;
	border-radius: 4px;
	padding: 10px 20px;
}

.theme-green .text {
	color: #fff;
}

</style>
