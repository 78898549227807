<template>

<div class="item" :data-width="width" :data-theme="theme" :data-height="height">
			
	<slot></slot>

</div>
	
</template>

<script>

export default {

	props: ['width', 'height', 'theme']

}

</script>

<style scoped>

.item {
	padding: 10px;
    border: 1px solid #eee;
    box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px;
    background-color: #fff;
    border-radius: 4px;
}

.item[data-theme="red"] {
	background-color: #c55b5b;
	color: #fff;
}

.item[data-theme="green"] {
	background-color: #4faa80;
	color: #fff;
}

.item[data-theme="blue"] {
	background-color: #4082d3;
	color: #fff;
}

.item[data-theme="yellow"] {
	background-color: #ffbe7a;
	color: #fff;
}

.item[data-width="2"] {
	grid-column: span 2;
}

.is-mobile .item[data-width="2"] {
	grid-column: span 1;
}

.item[data-height="2"] {
	grid-row: span 2;
}

.item[data-width="4"] {
	grid-column: span 4;
}

.is-mobile .item[data-width="4"] {
	grid-column: span 1;
}

</style>