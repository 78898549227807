<template>

<div class="item" :class="{'is-disabled': disabled}" :data-theme="theme" v-on:click="$emit('click')">

	<app-icon :icon="icon" class="item-icon" v-if="icon" />
	
	<div class="item-text">{{ text }}<small v-if="subtext">{{ subtext }}</small></div>

	<div class="item-count" v-if="count !== undefined" :class="{'is-highlight': countHighlight || countError, 'is-success': countSuccess}">{{ count }}</div>

	<app-icon icon="caret-right" class="item-caret" v-if="caret" />

	<app-icon :icon="iconAfter" class="item-icon is-after" v-if="iconAfter" />

</div>

</template>

<script>

export default {

	props: ['text', 'disabled', 'subtext', 'route', 'theme', 'icon', 'iconAfter', 'count', 'caret', 'count-highlight', 'count-success', 'count-error']

}

</script>

<style scoped>

.item {
	display: flex;
	flex-direction: row;
	min-height: 40px;
	align-items: center;
	color: #bfc4cb;
	padding: 0px 20px;
	cursor: pointer;
	margin-bottom: 4px;
}

.item.is-disabled {
	opacity: 0.25;
	pointer-events: none;
}

.item:first-child {
	margin-top: 20px;
}

.is-mobile .item {
	padding: 0px 10px;
}

.is-desktop .item:hover {
	background-color: #626e7f;
	color: #fff;
    border-radius: 4px;
    margin-left: 10px;
    margin-right: 10px;
	padding: 0px 10px;
}

.item.is-active {
	background-color: #ffbe7a;
    color: #4d5b6e;
    border-radius: 4px;
    margin-left: 10px;
    margin-right: 10px;
	padding: 0px 10px;
}

.item-caret {
	text-align: center;
	flex-shrink: 0;
	font-size: 14px;
	margin-left: 10px;
}

.item-icon {
	width: 32px;
	text-align: center;
	flex-shrink: 0;
	font-size: 20px;
	margin-right: 10px;
}

.item[data-theme="green"] .item-icon {
	color: #4faa80;
}

.item-icon.is-after {
	margin: 0px 0px 0px 10px;
}

.is-mobile .item-icon {
	font-size: 20px;
}

.item-text {
	font-size: 16px;
	color: #bfc4cb;
	font-weight: 300;
	flex-grow: 1;
}

.item-text small {
	display: block;
	font-size: 12px;
	margin-top: 2px;
}

.is-mobile .item-text {
	font-size: 14px;
}

.is-mobile .item-text small {
	font-size: 11px;
}

.item-count {
	min-width: 24px;
	height: 24px;
	flex-shrink: 0;
	border-radius: 4px;
	text-align: center;
	background-color: #687589;
	font-weight: 400;
	padding: 0px 4px;
	line-height: 24px;
	color: #b4b8bc;
}

.item-count.is-highlight {
	background-color: #dd4848;
	color: #fff;
}

.item-count.is-success {
	background-color: #2cc276;
	color: #fff;
}

.item.is-active .item-count {
	background-color: #fff;
	color: #333;
}

</style>
