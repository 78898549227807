<template>
    
<app-input :label="label" :sublabel="sublabel" :theme="theme" :stacked="!unstacked">

    <div class="slider">

        <div ref="bar" class="slider-bar" :class="{'is-hollow': isRange || isThreshold, [classDragging]: true}" v-on:mousedown="onBarClick">

			<div class="slider-bar-range is-current" :style="{left: styleCurrent.from, right: styleCurrent.to}" v-if="isRange || isThreshold"></div>
			<div class="slider-bar-range is-dragging" :style="{left: styleDragging.from, right: styleDragging.to}" v-if="is.dragging && (isRange || isThreshold)"></div>
            
            <div class="slider-bar-handle" v-drag="{name: 'from', config: configDrag.from}" v-on:finished="onDragFinished" v-on:moving="onDragMoving" v-on:started="onDragStarted" :style="{left: styleHandle.from}" v-tooltip="tooltipHandle.from"></div>
            <div class="slider-bar-handle" v-drag="{name: 'to', config: configDrag.to}" v-on:finished="onDragFinished" v-on:moving="onDragMoving" v-on:started="onDragStarted" :style="{left: styleHandle.to}" v-tooltip="tooltipHandle.to" v-if="isRange"></div>
            
        </div>

        <div class="slider-pips">

            <div class="slider-pips-item" v-for="(pip, index) in pips" :key="index" :style="{left: pip.position + '%'}">
                {{ pip.text }}
            </div>

        </div>

    </div>

</app-input>

</template>

<script>

import dirDrag from '@/directive/drag'

export default {
	
	directives: {
		drag: dirDrag
	},

    props: {
		value: [Number, Object],
		unstacked: Boolean,
		theme: String,
        min: Number,
        max: Number,
        pip: Number,
		label: String,
		sublabel: String,
		step: Number,
		decimals: Number,
		type: String,
		minText: String,
		maxText: String,
		texts: Object,
		inverse: Boolean
	},

	data: function() {
		return {
			is: {
				dragging: false
			},
			override: {
				from: 0,
				to: 0,
				value: 0
			}
		}
	},

    computed: {

		isRange: function() {

			return this.type === 'range'

		},

		isThreshold: function() {

			return this.type === 'threshold'

		},

		configDrag: function() {

			return {
				from: {
					name: 'from',
					min: this.min,
					max: this.max,
					step: this.step,
					valueMax: (this.isRange) ? this.currentValue.to : false
				},
				to: {
					name: 'to',
					min: this.min,
					max: this.max,
					step: this.step,
					valueMin: (this.isRange) ? this.currentValue.from : false
				}
			}

		},

        currentValue: function() {

			return this.value
           
        },

        length: function() {

            return this.max - this.min

        },

        pips: function() {

			var pips = []
			var pip = this.pip || 1
			var text

            for (var value = this.min; value <= this.max; value += pip) {

				text = value.toString()

				if (value === this.min && this.minText) text = this.minText
				if (value === this.max && this.maxText) text = this.maxText

				if (this.texts) {

					text = this.texts[value]

				}

                pips.push({
                    text: text,
                    position: (100 / this.length) * (value - this.min)
                })

            }

            return pips

		},
		
		styleCurrent: function() {

			var style = {
				from: '0%',
				to: '0%'
			}

			if (this.isRange) {

				style.from = Math.round((100 / this.length) * (this.currentValue.from - this.min)).toString() + '%'
				style.to = (100 - Math.round((100 / this.length) * (this.currentValue.to - this.min))).toString() + '%'
			
			} else {

				style.from = Math.round((100 / this.length) * (this.currentValue - this.min)).toString() + '%'

			}

			return style

		},

		classDragging: function() {

			if (this.isRange) {

				if (this.override.from < this.currentValue.from) {

					return 'is-dragging-from-down'

				} else if (this.override.from > this.currentValue.from) {

					return 'is-dragging-from-up'

				} else if (this.override.to < this.currentValue.to) {

					return 'is-dragging-to-down'

				} else if (this.override.to > this.currentValue.to) {

					return 'is-dragging-to-up'

				}

			} else {

				if (this.override.value < this.currentValue) {

					return 'is-dragging-from-down'

				} else if (this.override.value > this.currentValue) {

					return 'is-dragging-from-up'

				}

			}

			return false 

		},
		
		styleDragging: function() {

			var style = {
				from: '0%',
				to: '0%'
			}

			if (this.isRange) {

				if (this.override.from < this.currentValue.from) {

					style.from = Math.round((100 / this.length) * (this.override.from - this.min)).toString() + '%'
					style.to = (100 - Math.round((100 / this.length) * (this.currentValue.from - this.min))).toString() + '%'

				} else if (this.override.from > this.currentValue.from) {

					style.from = Math.round((100 / this.length) * (this.currentValue.from - this.min)).toString() + '%'
					style.to = (100 - Math.round((100 / this.length) * (this.override.from - this.min))).toString() + '%'

				} else if (this.override.to < this.currentValue.to) {

					style.from = Math.round((100 / this.length) * (this.override.to - this.min)).toString() + '%'
					style.to = (100 - Math.round((100 / this.length) * (this.currentValue.to - this.min))).toString() + '%'

				} else if (this.override.to > this.currentValue.to) {

					style.from = Math.round((100 / this.length) * (this.currentValue.to - this.min)).toString() + '%'
					style.to = (100 - Math.round((100 / this.length) * (this.override.to - this.min))).toString() + '%'

				} else {

					style.from = Math.round((100 / this.length) * (this.currentValue.from - this.min)).toString() + '%'
					style.to = (100 - Math.round((100 / this.length) * (this.currentValue.from - this.min))).toString() + '%'

				}
			
			} else {

				if (this.override.value < this.currentValue) {

					style.from = Math.round((100 / this.length) * (this.override.value - this.min)).toString() + '%'
					style.to = (100 - Math.round((100 / this.length) * (this.currentValue - this.min))).toString() + '%'

				} else if (this.override.value > this.currentValue) {

					style.from = Math.round((100 / this.length) * (this.currentValue - this.min)).toString() + '%'
					style.to = (100 - Math.round((100 / this.length) * (this.override.value - this.min))).toString() + '%'

				} else {

					style.from = Math.round((100 / this.length) * (this.currentValue - this.min)).toString() + '%'
					style.to = (100 - Math.round((100 / this.length) * (this.override.value - this.min))).toString() + '%'

				}

			}

			return style

		},

        styleHandle: function() {

			var style = {
				from: '0%',
				to: '0%'
			}

			if (this.isRange) {

				style.from = Math.round((100 / this.length) * (this.currentValue.from - this.min)).toString() + '%'
				style.to = Math.round((100 / this.length) * (this.currentValue.to - this.min)).toString() + '%'
			
			} else {

				style.from = Math.round((100 / this.length) * (this.currentValue - this.min)).toString() + '%'

			}

			return style

		},
		
		tooltipHandle: function() {

			var tooltip = {
				from: {
					content: '',
					show: false,
					classes: ['is-small'],
					trigger: 'manual'
				},
				to: {
					content: '',
					show: false,
					classes: ['is-small'],
					trigger: 'manual'
				}
			}

			if (this.step !== this.pip) {

				if (this.isRange) {

					var from = (this.is.dragging) ? this.override.from : this.currentValue.from
					var to = (this.is.dragging) ? this.override.to : this.currentValue.to

					tooltip.from.content = from
					tooltip.from.show = (from !== this.min && from !== this.max) ? true : false

					tooltip.to.content = to
					tooltip.to.show = (to !== this.min && to !== this.max) ? true : false

				} else {

					var value = (this.is.dragging) ? this.override.value : this.currentValue

					tooltip.from.content = value
					tooltip.from.show = (value !== this.min && value !== this.max) ? true : false

				}
				
			}

			return tooltip

		}

    },

    methods: {

		updateValue: function(value) {

            this.$emit('input', value)

        },

		onDragFinished: function(e) {

			this.updateValues(e.detail.name, this.calculateFromPercent(e.detail.name, e.detail.value))

			this.is.dragging = false
			
		},

		onDragStarted: function() {

			if (this.isRange) {

				this.override = {
					from: this.currentValue.from,
					to: this.currentValue.to
				}

			} else {

				this.override.value = this.currentValue

			}

			this.is.dragging = true

		},

		onDragMoving: function(e) {

			if (this.isRange) {

				this.override = {
					from: (e.detail.name === 'from') ? this.calculateFromPercent(e.detail.name, e.detail.value) : this.currentValue.from,
					to: (e.detail.name === 'to') ? this.calculateFromPercent(e.detail.name, e.detail.value) : this.currentValue.to
				}

			} else {

				this.override.value = this.calculateFromPercent(e.detail.name, e.detail.value)

			}

		},

        onBarClick: function(e) {

			console.log('c')

			var value = this.calculateFromPercent('from', (100 / this.$refs.bar.offsetWidth) * e.offsetX)

			if (this.isRange) {

				this.updateValues((value < this.currentValue.to) ? 'from' : 'to', value)

			} else {

				this.updateValues('from', value)

			}

		},

		updateValues: function(name, value) {

			if (this.isRange) {

				this.updateValue({
					from: (name === 'from') ? value : this.currentValue.from,
					to: (name === 'to') ? value : this.currentValue.to
				})

			} else {

				this.updateValue(value)

			}

		},

		calculateFromPercent: function(name, percent) {

			var value = ((this.length / 100) * percent) + this.min

			if (this.step < 1) {

				var decimals = this.step.toString().split('.')[1].length

				value = value.toFixed(decimals)

				value = (value === this.min.toFixed(decimals)) ? this.min : value
				value = (value === this.max.toFixed(decimals)) ? this.max : value

			} else if (this.step > 1) {

				value = (Math.floor(value / this.step) * this.step) + ((value % this.step > this.step / 2) ? this.step : 0)

			} else {

				value = Math.round(value)

			}

			return value

		}

    }

}

</script>

<style scoped>

.slider {
	user-select: none;
}

.theme-blue .slider {
	padding: 0px 12px;
}

.slider-bar {
    background-color: #2f3a4a;
    height: 20px;
    border-radius: 4px;
    margin-top: 4px;
    z-index: 2;
    cursor: pointer;
	border: 1px solid #2f3a4a;
}

.theme-blue .slider-bar {
    background-color: #eee;
	height: 20px;
	border: 1px solid #4082d3;
}

.slider-bar.is-hollow {
    background-color: transparent;
}

.slider-bar-range {
    position: absolute;
    background-color: #2f3a4a;
	left: 0%;
	right: 0%;
	border-radius: 4px;
	top: 0px;
	height: 18px;
	pointer-events: none;
}

.theme-blue .slider-bar-range {
    background-color: #4082d3;
	height: 18px;
}

.slider-bar-range.is-current {
    background-color: #2f3a4a;
	z-index: 1;
}

.theme-blue .slider-bar-range.is-current {
    background-color: #4082d3;
}

.slider-bar-range.is-dragging {
    background: repeating-linear-gradient(
		315deg,
		#2f3a4a,
		#2f3a4a 5px,
		#8896aa 5px,
		#8896aa 10px
	);
	z-index: 2;
}

.theme-blue .slider-bar-range.is-dragging {
    background: repeating-linear-gradient(
		315deg,
		#4082d3,
		#4082d3 5px,
		#75a8e7 5px,
		#75a8e7 10px
	);
}

.slider-bar.is-dragging-from-down .slider-bar-range.is-dragging,
.slider-bar.is-dragging-to-up .slider-bar-range.is-dragging,
.slider-bar.is-dragging-from-down .slider-bar-range.is-current,
.slider-bar.is-dragging-from-up .slider-bar-range.is-current,
.slider-bar.is-dragging-to-up .slider-bar-range.is-current,
.slider-bar.is-dragging-to-down .slider-bar-range.is-current {
	border-radius: 0px;
}

.slider-bar.is-dragging-from-up .slider-bar-range.is-dragging {
	border-radius: 4px 0px 0px 4px;
}

.slider-bar.is-dragging-to-down .slider-bar-range.is-dragging {
	border-radius: 0px 4px 4px 0px;
}

.slider-bar-handle {
    position: absolute;
    width: 16px;
    height: 26px;
    left: 0px;
    margin-left: -8px;
    top: -4px;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
	z-index: 2;
}

.theme-blue .slider-bar-handle {
    background-color: #2f3a4a;
	width: 24px;
	height: 24px;
    margin-left: -12px;
    top: -2px;
}

.slider-pips {
    height: 11px;
    margin-top: 5px;
    font-size: 11px;
    color: #8896aa;
    font-weight: 300;
    z-index: 1;
}

.slider-pips-item {
    position: absolute;
    transform: translateX(-50%);
}

.slider-pips-item:first-child {
    transform: translateX(0%);
}

.slider-pips-item:last-child {
    transform: translateX(-50%);
}

</style>