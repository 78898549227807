<template>

<div class="message" :class="{'is-mentioned': isMentioned}">

	<div class="message-avatar" v-if="message.badge" v-bgimage="message.badge.user.avatar">

		<app-icon icon="user" v-if="!message.badge.user.avatar" />

	</div>

	<div class="message-avatar" v-if="!message.badge">

		<app-icon icon="assistant.small" />

	</div>

	<div class="message-text">

		<div class="message-text-user" v-if="message.badge">{{ message.badge.name }} <small>{{ message.date | fromNow }}</small></div>
		<div class="message-text-user" v-if="!message.badge">{{ message.author }} <small>{{ message.date | fromNow }}</small></div>
		<div class="message-text-message" v-html="text" />

	</div>

</div>

</template>

<script>

export default {

	props: ['message'],
	
	computed: {

		isMentioned: function() {

			if (this.$loggedin) {

				return this.$_.contains(this.message.mentions, this.$store.getters['session/id'])
 
			} else {

				return false

			}

		},

		text: function() {

			return this.message.text.replace(/@([a-zA-Z_0-9]+)/gi, function(match) {

				return '<span class="message-text-message-mention">' + match + '</span>'

			})

		}

	}

}

</script>

<style scoped>

.message {
	display: flex;
	flex-direction: row;
	padding: 5px 10px;
}

.message.is-mentioned {
	background-color: #feffb1;
}

.message-avatar {
	width: 40px;
	height: 40px;
	border-radius: 4px;
	background-color: #eee;
	background-position: 50% 50%;
	background-size: cover;
	margin-right: 10px;
	text-align: center;
	flex-shrink: 0;
	line-height: 40px;
	color: #999;
	font-size: 16px;
}

.message-text-user {
	font-weight: 500;
	color: #687589;
	font-size: 16px;
	line-height: 20px;
}

.message-text-user small {
	font-weight: 300;
	font-size: 12px;
	margin-left: 2px;
}

.message-text-message {
	font-weight: 300;
	color: #687589;
	font-size: 16px;
	line-height: 20px;
}

.message-text-message >>> a {
	color: #4082d3;
}

.message-text-message >>> a:hover {
	text-decoration: underline;
}

.is-mobile .message-text-message,
.is-tablet .message-text-message {
	font-size: 14px;
}

.message-text-message >>> .message-text-message-mention {
	color: #4082d3;
	font-weight: bold;
}

</style>
