<template>

<div class="section-wrapper">

	<div v-if="!link" class="section" v-on:click="onClick" :data-theme="theme" :class="{'is-loading': loading, 'is-danger': danger, 'is-success': success, 'is-disabled': disabled}">

		<div class="section-icon" v-if="icon"><app-icon :icon="icon" /></div>

		<div class="section-title">{{ title }}<small v-if="description">{{ description }}</small></div>

		<div class="section-count" v-if="count || forceCount" :data-value="count">{{ count }}</div>

		<app-icon v-if="!loading && !tick" icon="chevron.right" class="section-chevron" />
		<app-icon v-if="!loading && tick" icon="tick" class="section-chevron is-green" />
		<app-icon v-if="loading" icon="loading" class="section-chevron" />
		
	</div>

	<a :href="link" target="_blank" v-if="link" class="section" :data-theme="theme" :class="{'is-loading': loading, 'is-danger': danger, 'is-success': success, 'is-disabled': disabled}">

		<div class="section-icon" v-if="icon"><app-icon :icon="icon" /></div>

		<div class="section-title">{{ title }}<small v-if="description">{{ description }}</small></div>

		<div class="section-count" v-if="count || forceCount" :data-value="count">{{ count }}</div>

		<app-icon v-if="!loading && !tick" icon="chevron.right" class="section-chevron" />
		<app-icon v-if="!loading && tick" icon="tick" class="section-chevron is-green" />
		<app-icon v-if="loading" icon="loading" class="section-chevron" />
		
	</a>

</div>

</template>

<script>

export default {

	props: ['title', 'route', 'link', 'danger', 'meta', 'success', 'icon', 'theme', 'disabled', 'tick', 'params', 'description', 'loading', 'count', 'forceCount', 'manual'],

	methods: {

		onClick: function() {

			if (this.manual) {

				this.$emit('click')

			} else {

				this.$router.push({
					name: this.route,
					params: this.params,
					meta: this.meta
				})

			}

		}

	}

}

</script>

<style scoped>

.section {
	padding: 20px 20px;
	border: 1px solid #eee;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px;
	background-color: #fff;
	border-radius: 4px;
	display: flex;
	align-items: center;
	cursor: pointer;
	color: #687589;
	margin-bottom: 20px;
}

.section.is-disabled {
	pointer-events: none;
}

.section.is-success {
	border-color: #4faa80;
	background-color: #4faa80;
    color: #fff;
}

.section[data-theme="red"],
.section.is-danger {
	background-color: #c55b5b;
	border-color: #c55b5b;
	color: #fff;
}

.section.is-warning {
	background-color: #ffbe7a;
	border-color: #ffbe7a;
	color: #fff;
}

.section.is-loading {
	pointer-events: none;
}

.section:hover {
	background-color: #ffbe7a;
}

.section[data-theme="red"]:hover,
.section.is-danger:hover {
	background-color: #c94141;
}

.section.is-warning:hover {
	background-color: #d49959;
}

.section-icon {
	font-size: 32px;
	margin-right: 20px;
	width: 48px;
	text-align: center;
}

.section-title {
    font-size: 16px;
    font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 1px;
	flex-grow: 1;
}

.section-title small {
	display: block;
	font-size: 14px;
	line-height: 18px;
	opacity: 0.75;
	letter-spacing: 0px;
	text-transform: none;
	margin-top: 4px;
	font-weight: 400;
}

.section-count {
	flex-shrink: 0;
	min-width: 40px;
	height: 40px;
	min-width: 40px;
	line-height: 40px;
	text-align: center;
	padding: 0px 10px;
	/*margin: 0px 20px;*/
	background-color: #4082d3;
	color: #fff;
	font-weight: bold;
	font-size: 16px;
	border-radius: 10px;
}

.section-count[data-value="0"] {
	background-color: #ddd;
}

.section.is-danger .section-count[data-value="0"] {
	color: #c55b5b;
	background-color: #fff;
}

.section-chevron {
	font-size: 20px;
	line-height: 40px;
	/*margin-left: 20px;*/
	text-align: right;
	width: 40px;
}

.section-chevron.is-green {
	color: #4faa80;
	font-size: 32px;
}

.section.is-success .section-chevron.is-green {
	color: #fff;
}

</style>
