<template>

<div class="notification" v-on:click="onClick" v-tooltip="(isLinked) ? 'Click to view' : false" :class="{'is-notification': asNotification, 'is-activity': !asNotification, 'is-official': item.type === $constants.notification.type.announcement, 'is-highlighted': isHighlighted, 'is-linked': isLinked}">

	<div class="notification-avatar" :style="{backgroundImage: 'url(' + item.avatar + ')'}" v-if="asNotification && item.avatar">

		<app-icon :icon="$constants.notification.icon[item.type]" class="notification-icon" />

	</div>

	<app-icon :icon="$constants.notification.icon[item.type]" class="notification-icon" v-if="!asNotification || !item.avatar" />
	<app-icon v-if="$constants.notification.mini[item.type] && (!asNotification || !item.avatar)" :icon="$constants.notification.mini[item.type]" :theme="$constants.notification.theme[item.type]" class="notification-icon-mini" />

	<div class="notification-inner">

		<div class="notification-text" :class="{'is-message': item.type === $constants.notification.type.message}">

			<div class="notification-new" v-if="asNotification && lastRead < item.date"></div>

			<i class="notification-message fas fa-quote-left" v-if="item.type === $constants.notification.type.message"></i>

			<div class="notification-text-log" v-html="text"></div>

			<div class="notification-text-date" v-if="!asNotification || item.subtext"><template v-if="!asNotification">{{ item.date | fromNow }}</template><template v-if="item.subtext"><template v-if="!asNotification"> &middot; </template><span v-html="item.subtext" /></template></div>

		</div>

		<div class="notification-date" v-if="asNotification">{{ item.date | fromNow }}</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['item', 'asNotification', 'lastRead', 'isLinked'],

	computed: {

		isHighlighted: function() {

			return (this.item.type === this.$constants.notification.type.message) ? true : false

		},

		text: function() {

			return (this.item.type === this.$constants.notification.type.message) ? this.item.text.replace(/@([a-zA-Z_0-9]+)/gi, function(match) {

				return '<span class="mention">' + match + '</span>'

			}) : this.item.text

		}

	},

	methods: {

		onClick: function() {

			if (this.isLinked) {
			
				if (this.item.type === this.$constants.notification.type.buddies.suggestions) { 

					this.$router.push({
						name: 'Convention.Buddies.Suggested'
					})

				} else if (this.item.type === this.$constants.notification.type.interest.suggestion) { 

					this.$router.push({
						name: 'Convention.Schedule.Discover'
					})

				} else if (this.item.type === this.$constants.notification.type.interest.match) { 

					this.$router.push({
						name: 'Convention.Schedule.Discover.Matchmaker'
					})

				} else if (this.item.type === this.$constants.notification.type.announcement) { 

					window.open(this.item.meta.url, '_blank').focus()

				} else if (this.item.type === this.$constants.notification.type.badge.registered) {

					this.$router.push({
						name: 'Convention.Registration.Badge'
					})

				} else if (this.item.type === this.$constants.notification.type.badge.assigned) {

					this.$router.push({
						name: 'Convention.Registration.Badge'
					})

				} else if (this.item.event) {

					this.$router.push({
						name: 'Convention.Schedule.Event',
						params: {
							id: this.item.event
						}
					})

				} else if (this.item.lobby) {

					this.$router.push({
						name: 'Convention.Schedule.Lobby',
						params: {
							id: this.item.lobby
						}
					})

				} else if (this.item.contest) {

					this.$router.push({
						name: 'Convention.Schedule.Contest',
						params: {
							id: this.item.contest
						}
					})

				}

			}

		}

	}

}

</script>

<style scoped>

.notification {
	display: flex;
}

.notification.is-activity {
	padding: 10px;
	border-bottom: 1px solid #eee;
}

.notification.is-linked {
	cursor: pointer;
}

.notification.is-notification {
	margin-bottom: 10px;
}

.notification.is-official.is-activity {
	background-color: #4082d3;
	border-bottom-color: #fff;
}

.notification-new {
	width: 16px;
	height: 16px;
	border-radius: 50%;
	position: absolute;
	right: -8px;
	top: -8px;
	background-color: #dd4848;
	z-index: 2;
}

.notification-avatar {
	width: 48px;
	height: 48px;
	border-radius: 24px;
	background-position: 50% 50%;
	flex-shrink: 0;
	background-size: cover;
	background-color: #999;
}

.is-mobile .notification-avatar {
	margin-left: 0px;
}

.notification-icon {
	color: #fff;
	z-index: 2;
	text-align: center;
	flex-shrink: 0;
	font-size: 20px;
}

.notification.is-activity > .notification-icon {
	background-color: #4082d3;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	color: #fff;
	font-size: 16px;
	line-height:32px;
	text-align: center;
}

.notification.is-activity.is-official > .notification-icon {
	background-color: #fff;
	color: #4082d3;
}

.notification.is-notification > .notification-icon {
	width: 48px;
	height: 48px;
	line-height: 48px;
	border-radius: 24px;
	background-position: 50% 50%;
	flex-shrink: 0;
	background-size: cover;
	background-color: #4082d3;
}

.notification.is-notification > .notification-icon-mini,
.notification.is-notification > .notification-avatar .notification-icon {
	border-bottom: 0px;
	width: 24px;
	position: absolute;
	right: -4px;
	bottom: -4px;
	height: 24px;
	min-height: 24px;
	border-radius: 50%;
	margin: 0px;
	font-size: 12px;
	line-height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.notification.is-notification > .notification-avatar .notification-icon {
	background-color: #4082d3;
	color: #fff;
}

.notification-icon-mini {
	right: auto;
	left: 28px;
	top: 28px;
	z-index: 2;
}

.notification-inner {
	flex-grow: 1;
}

.notification-text {
	padding-top: 5px;
	margin-left: 10px;
}

.notification-text.is-message {
	padding-left: 58px!important;
}

.notification-text >>> .mention {
	font-weight: bold;
	color: #4082d3;
}

.notification-message {
	position: absolute;
	left: 10px;
	top: 10px;
	font-size: 40px;
	margin-right: 10px;
	color: #4082d3;
}

.notification.is-notification .notification-text {
	background-color: #fff;
	margin-left: 20px;
	border-radius: 4px;
	padding: 14px;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 4px;
}

.notification.is-highlighted .notification-text {
	background-color: #feffb1;
}

.notification.is-official.is-notification .notification-text {
	background-color: #4082d3;
}

.notification.is-official:not(.is-notification) .notification-text {
	margin-left: 0px;
	padding-left: 10px;
}

.notification.is-notification .notification-text:before {
	content: '';
	position: absolute;
	left: -8px;
	top: 16px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 8px 8px 8px 0;
	border-color: transparent #ffffff transparent transparent;
}

.notification.is-official.is-highlighted .notification-text:before {
	border-color: transparent #feffb1 transparent transparent;
}

.notification.is-official.is-notification .notification-text:before {
	border-color: transparent #4082d3 transparent transparent;
}

.notification-text-log {
	flex-grow: 1;
}

.notification-text-log,
.notification-text-log >>> p {
	color: #687589;
	font-size: 14px;
	line-height: 20px;
}

.notification-text-log >>> p {
	margin-bottom: 10px!important;
}

.notification-text-log >>> p:last-child {
	margin-bottom: 0px!important;
}

.is-official.is-activity .notification-text-log,
.is-official.is-activity .notification-text-log >>> p {
	color: #fff;
}

.is-official.is-notification .notification-text-log,
.is-official.is-notification .notification-text-log >>> p {
	color: #fff;
}

.is-mobile .notification-text-log,
.is-tablet .notification-text-log {
	font-size: 14px;
}

.notification.is-notification .notification-text-log {
	margin-top: 0px;
}

.notification-text-log >>> small {
	font-size: 12px;
	color: #999;
	margin-left: 5px;
}

.notification-text-date {
	font-weight: 300;
	font-size: 12px;
	color: #999;
}

.notification.is-activity.is-official .notification-text-date {
	margin-top: 10px;
	text-align: right;
	color: #fff;
}

.notification.is-notification .notification-text-date {
	padding-bottom: 0px;
}

.notification-date {
	font-weight: 300;
	font-size: 12px;
	text-align: right;
	margin-top: 10px;
	padding-right: 4px;
	color: #687589;
}

</style>
