<template>

<app-table-cell class="column" :border="border" :align="align">

	{{ text }}

	<app-icon class="column-icon" :icon="icon" v-if="icon" />
	<app-icon class="column-sort" icon="caret-down" v-if="sorted" />

</app-table-cell>

</template>

<script>

export default {

	props: ['text', 'icon', 'border', 'align', 'sorted']

}

</script>

<style scoped>

.column {
	font-weight: 400;
}

.column-icon {
	font-size: 12px;
}

.column-sort {
	margin-left: 10px;
	font-size: 12px;
}

</style>
