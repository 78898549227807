<template>

<div class="tabs" v-if="tabs.length">

	<app-content-head-tabs-item v-for="(tab, index) in tabs" :key="index" :tab="tab" />
		
</div>

</template>

<script>

export default {

	props: ['tabs']

}

</script>

<style scoped>

.tabs {
	display: flex;
	flex-direction: row;
	flex-shrink: 0;
	padding-left: 20px;
}

.is-tablet .tabs {
	padding-left: 20px;
}

.is-mobile .tabs {
	padding-left: 0px;
	position: absolute;
	left: 0px;
	top: 48px;
	height: 32px;
	border-top: 1px solid #eee;
	width: 100%;
}

</style>
