<template>

<div class="modal" v-if="active">

	<div class="modal-box">

		<div class="modal-box-head"><app-icon icon="modal" /></div>

		<div class="modal-box-content">
		
			<div class="modal-box-content-text" v-html="active.text" />

			<div class="modal-box-content-date">{{ active.date | fromNow }}</div>

			<div class="modal-box-content-button">

				<app-button text="OK" v-on:click="onCloseClick" />

			</div>
		
		</div>

	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			read: []
		}

	},

	computed: {

		announcements: function() {

			return this.$store.getters['session/announcements']

		},

		unread: function() {

			return this.$_.filter(this.$store.getters['session/announcements'], function(item) {

				return !this.$_.contains(this.read, item.id)

			}.bind(this))

		},

		active: function() {

			return (this.unread.length) ? this.unread[0] : false

		}

	},

	methods: {

		onCloseClick: function() {

			this.$api.post('session/announcement', {
				id: this.active.id
			})

			this.read.push(this.active.id)

		}

	}

}

</script>

<style>

.modal {
	position: fixed;
	left: 0px;
	top: 0px;
	bottom: 0px;
	right: 0px;
	background-color: rgba(0, 0, 0, 0.75);
	z-index: 10000;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-box {
	max-width: 480px;
	width: calc(100% - 20px);
}

.modal-box-head {
	height: 160px;
	color: #fff;
	border-radius: 4px 4px 0px 0px;
	background-color: #4082d3;
	line-height: 160px;
	text-align: center;
	font-size: 96px;
}

.modal-box-content {
	padding: 20px;
	background-color: #fff;
	border-radius: 0px 0px 4px 4px;
}

.modal-box-content-text {
	margin-bottom: 20px;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
}

.modal-box-content-text a {
	color: #387DD8;
	font-weight: 700;
}

.modal-box-content-text a:hover {
	text-decoration: underline;
}

.modal-box-content-text p {
	margin-bottom: 20px;
}

.modal-box-content-text p:last-child {
	margin-bottom: 0px;
}

.modal-box-content-date {
	text-align: center;
	margin: 20px 0px;
	font-size: 12px;
	color: #666;
}

</style>
