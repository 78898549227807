<template>

<div class="text" :class="{'is-center': isRegister}" v-html="item.text || item.content" />
	
</template>

<script>

export default {

	props: ['item'],

	computed: {

		isRegister: function() {

			return process.env.VUE_APP_TYPE === 'register'

		}

	}

}

</script>

<style scoped>

.text.is-center >>> * {
	text-align: center;
}

.text >>> h1 {
	font-size: 32px!important;
	line-height: 40px;
	font-weight: 700!important;
	margin-bottom: 20px;
	color: #343434!important;
}

.text >>> h2 {
	font-size: 24px;
	line-height: 32px;
	font-weight: 500;
	margin-bottom: 20px;
	color: #343434;
}

.text >>> p {
	font-size: 16px!important;
	line-height: 20px!important;
	font-weight: 300!important;
	margin-bottom: 20px;
	color: #343434!important;
}

.text >>> a {
	color: #387DD8;
	font-weight: 700;
}

.text >>> a:hover {
	text-decoration: underline;
}

.text >>> *:last-child {
	margin-bottom: 0px;
}

.text >>> em,
.text >>> i {
	font-style: italic;
}

</style>