import cookies from 'js-cookie'
import _ from 'underscore'
import Store from '../store'
import Constants from '../constants'

export default async function handler(to, from, next) {

	if (to.query.launch) {

		cookies.set(process.env.VUE_APP_COOKIE, to.query.launch)

	}

	if (to.meta.auth && cookies.get(process.env.VUE_APP_COOKIE) === undefined) {

		next({
			name: 'Login',
			query: (to.path != '/' && to.path != '/logout') ? {
				redirect: to.path
			} : {}
		})

	} else if (to.meta.auth === false && cookies.get(process.env.VUE_APP_COOKIE) !== undefined && to.name === 'Login') {

		next({
			name: 'Dashboard'
		})

	} else {

		await Store.dispatch('init', to.params)

		if (!to.meta.isAccount) {

			if (process.env.VUE_APP_TYPE === 'assistant') {

				if (to.name !== 'Login') {

					Store.commit('context/convention', Store.getters['session/conventions'][0])
					Store.commit('context/organisation', Store.getters['session/organisations'][0])
						
				}

			} else {

				await Store.dispatch('context/analyse', to.params)

			}
			
		} else {

			await Store.dispatch('context/analyse', to.params)

		}

		if (to.name === 'Dashboard' && process.env.VUE_APP_TYPE === 'manager') {

			if (Store.getters['session/teams'].length === 1) {

				if (Store.getters['session/teams'][0].type === Constants.team.members.type.convention) {

					return next({
						name: 'Convention.Dashboard',
						params: {
							organisation: Store.getters['session/organisations'][0].slug,
							convention: Store.getters['session/conventions'][0].slug,
						}
					})

				} else {

					return next({
						name: 'Organisation.Dashboard',
						params: {
							organisation: Store.getters['session/organisations'][0].slug
						}
					})

				}
	
			} 

		} else if (to.name === 'Dashboard' && process.env.VUE_APP_TYPE === 'app') {

			if (Store.getters['session/conventions'].length === 1) {

				return next({
					name: 'Convention.Dashboard',
					params: {
						organisation: Store.getters['session/organisations'][0].slug,
						convention: Store.getters['session/conventions'][0].slug
					}
				})
	
			} else if (Store.getters['session/conventions'].length === 0) {
				
				return next({
					name: 'Profile'
				})

			}

		}

		if (to.meta.authority) {

			_.each(to.meta.authority, function(permission) {
				
				if(!Store.getters['context/authority/permission'](permission)) {

					console.log('Authority', 'Failed', permission)

					return next({
						name: 'Dashboard'
					})

				} else {

					console.log('Authority', 'Passed', permission)

				}

			})

		}

		next()

	}

}