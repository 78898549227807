<template>

<app-content-box class="summary" :not-padded="true" :icon="icon" :title="title" :class="{'is-grow': grow, 'is-fixed': fixed}">

	<div class="summary-items">

		<slot></slot>

	</div>

</app-content-box>

</template>

<script>

export default {

	props: ['title', 'icon', 'fixed', 'grow']

}

</script>

<style scoped>

.is-desktop .summary.is-fixed {
	min-width: 1000px;
}

.summary-items {
	display: flex;
	flex-direction: row;
	flex-shrink: 0;
}

.is-mobile .summary-items {
	flex-direction: column;
}

.summary.is-grow {
	flex-grow: 1;
	margin-right: 10px;
	flex-basis: 0;
}

</style>