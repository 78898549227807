import axios from 'axios'
import pubsub from '@/pubsub'
import store from '@/store'
import live from '@/live'
import _ from 'underscore'

const CancelToken = axios.CancelToken

export default {

	api: process.env.VUE_APP_API + process.env.VUE_APP_TYPE + '/',

	source: false,

	cancel: function() {

		if (this.source) this.source.cancel()

	},

	get: function(url, params, page) {

		params = params || {}
		page = page || false

		if (page) params['page'] = page

		this.source = CancelToken.source()

		if (_.isArray(url)) url = url.join('/')

		return new Promise(function(resolve, reject) {

			console.log('api', 'get', url, params)

			axios.get(this.api + url + '.json', {
				cancelToken: this.source.token,
				headers: {
					'Cache-Control': 'no-cache',
					'Pragma': 'no-cache',
					'Expires': '0',
				},
				params: params
			}).then(function(response) {

				if (_.isObject(response.data)) {

					this.response(resolve, response)

				} else {

					reject(null)

				}

			}.bind(this), function(json) {

				reject((json.response) ? json.response.data.data : null)

			})

		}.bind(this))

	},

	post: function(url, params) {

		params = params || {}

		if (_.isArray(url)) url = url.join('/')

		return new Promise(function(resolve, reject) {

			console.log('api', 'post', url, params)

			axios.post(this.api + url + '.json', params).then(function(response) {

				if (_.isObject(response.data)) {

					this.response(resolve, response)

				} else {

					reject({})

				}

			}.bind(this), function(json) {

				reject(json.response.data.data)

			})

		}.bind(this))

	},

	patch: function(url, params) {

		params = params || {}

		if (_.isArray(url)) url = url.join('/')

		return new Promise(function(resolve, reject) {

			console.log('api', 'patch', url, params)

			axios.patch(this.api + url + '.json', params).then(function(response) {

				if (_.isObject(response.data)) {

					this.response(resolve, response)

				} else {

					reject(null)

				}

			}.bind(this), function(json) {

				reject(json.response.data.data)

			})

		}.bind(this))

	},

	put: function(url, params) {

		params = params || {}

		if (_.isArray(url)) url = url.join('/')

		return new Promise(function(resolve, reject) {

			console.log('api', 'put', url, params)

			axios.put(this.api + url + '.json', params).then(function(response) {

				if (_.isObject(response.data)) {

					this.response(resolve, response)

				} else {

					reject(null)

				}

			}.bind(this), function(json) {

				reject(json.response.data.data)

			})

		}.bind(this))

	},

	delete: function(url) {

		if (_.isArray(url)) url = url.join('/')

		return new Promise(function(resolve, reject) {

			console.log('api', 'delete', url)

			axios.delete(this.api + url + '.json').then(function(response) {

				if (_.isObject(response.data)) {

					this.response(resolve, response)

				} else {

					reject(null)

				}

			}.bind(this), function(json) {

				reject(json.response.data.data)

			})

		}.bind(this))

	},

	response: function(resolve, response) {

		if (response.data.platform) {

			store.commit('platform/set', response.data.platform)

		}

		if (response.data.session) {

			store.commit('session/set', response.data.session)
			if (response.data.session.buddies) store.commit('session/buddies/set', response.data.session.buddies)

		}

		var events = response.data.events

		_.each(events, function(event) {

			if (!live.$received(event.id)) pubsub.$emit(event.name, event.params)

		})

		resolve(response.data.data)

	}

}