<template>

<app-table-cell-text>

	{{ item.duration | hours }} hour<template v-if="item.duration !== 3600">s</template>

</app-table-cell-text>

</template>

<script>

export default {

	props: ['item']

}

</script>

<style scoped>

</style>