<template>

<div class="screen" :class="{'is-loading': loading, 'is-desktop': window.is.desktop, 'is-tablet': window.is.tablet, 'is-mobile': window.is.mobile, 'is-device': window.is.tablet || window.is.mobile}">

	<app-icon icon="loading" v-if="loading" class="screen-loading" />

	<template v-if="!loading">

	<com-head v-if="!plain && !noHead && (window.is.desktop || (window.is.mobile && !offset))" :noProfile="noProfile" :noContext="noContext" v-on:toggleMenu="onToggleMenu" :is-account="is.account" v-on:toggleAccount="onToggleAccount" :offset="offset" />

	<com-panel v-if="!plain" :iconMenu="iconMenu" :alwaysHidden="alwaysHidden" :noHead="noHead" :noProfile="noProfile" :is-account="is.account" :is-expanded="is.expanded" :offset="offset" v-on:toggleAccount="onToggleAccount">

		<slot name="menu"></slot>

	</com-panel>

	<com-content :iconMenu="iconMenu" :noHead="noHead" :alwaysHidden="alwaysHidden && !is.expanded" :plain="plain" :offset="offset" :isNatural="isNatural">

		<slot></slot>
	
	</com-content>

	<slot name="content"></slot>

	</template>

</div>

</template>

<script>

export default {

	props: ['loading', 'iconMenu', 'noHead', 'alwaysHidden', 'plain', 'noProfile', 'noContext', 'isNatural'],

	components: {
		'com-head': () => import('./Head'),
		'com-panel': () => import('./Panel'),
		'com-content': () => import('./Content')
	},

	data: function() {

		return {
			is: {
				account: false,
				expanded: false
			},
			offset: 0
		}

	},

	computed: {

		route: function() {

			return this.$route.name

		}

	},
	
	watch: {

		route: function() {

			this.is.account = this.$route.meta.isAccount
			this.is.expanded = false
			this.offset = 0

		}

	},

	created: function() {

		this.$pubsub.$on('offset', this.onOffset.bind(this))
		this.$pubsub.$on('toggle.menu', this.onToggleMenu.bind(this))

	},

	methods: {

		onToggleAccount: function() {

			this.is.account = !this.is.account

		},
		
		onToggleMenu: function() {

			this.is.expanded = !this.is.expanded

		},

		onOffset: function(open) {

			this.offset = (open) ? this.offset + 1 : this.offset - 1

		}

	}

}

</script>

<style>

.screen {
	--head-height: 80px;
	--panel-width: 320px;
}

.screen-loading {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 48px;
	color: #ffbe7a;
}

</style>