<template>

<div class="checklist">

	<div class="checklist-button" v-tooltip="tooltip" v-on:click="onButtonClick">

		<div class="checklist-button-text">{{ text }}</div>

		<div class="checklist-button-count" v-if="active">{{ value.length }}</div>

		<app-icon class="checklist-button-icon" icon="caret-down" />

	</div>

	<app-panel v-if="expanded" v-on:close="onButtonClick">

		<app-panel-title :text="text" />

		<app-panel-content>
		
			<app-input-suggest :multiple="true" :below="true" :dark="true" placeholder="Search..." v-model="selected" :api="api" />

		</app-panel-content>

	</app-panel>

</div>

</template>

<script>

import { validationMixin } from 'vuelidate'

export default {

	mixins: [validationMixin],

	props: ['value', 'text', 'tooltip', 'api'],

	data: function() {

		return {
			expanded: false,
			selected: []
		}

	},

	watch: {

		selected: function(n) {

			this.$emit('input', n)

		}

	},

	computed: {

		active: function() {

			return this.value.length

		}

	},

	methods: {

		onButtonClick: function() {

			this.expanded = !this.expanded

			this.$pubsub.$emit('offset', this.expanded)

		}

	}

}

</script>

<style scoped>

.checklist-button {
	height: 32px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 10px;
	cursor: pointer;
	color: #687589;
}

.checklist-button-count {
	border-radius: 4px;
	background-color: #ffbe7a;
    color: #4d5b6e;
	font-size: 10px;
	height: 14px;
	min-width: 14px;
	text-align: center;
	line-height: 14px;
	margin-left: 10px;
}

.checklist-button:hover {
	color: #333;
}

.checklist-button-text {
	font-size: 12px;
}

.checklist-button-icon {
	font-size: 12px;
	margin-left: 10px;
}

.checklist-items[data-columns="2"] {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 10px;
}

.checklist-items[data-columns="2"] >>> .check {
	width: 50%;
	padding: 0px 10px;
}

.checklist-items[data-columns="2"] >>> .check:hover {
	margin: 0px 0px 4px 0px;
	padding: 0px 10px;
}

.checklist-items[data-columns="2"] >>> .check:hover .check-box {
	color: #4d5b6e;
}

.checklist-items[data-columns="2"] >>> .check:first-child {
	margin-top: 0px;
}

</style>
