<template>

<app-content-box :span="span" :data-span="span" :title="(headless) ? false : 'Summary'" :icon="(headless) ? false : 'summary'" class="summary" :not-padded="true">

	<div class="summary-description" :class="{'has-text': text || cancelled}">
		
		<div class="summary-description-background" v-bgimage="image" />

		<div class="summary-description-text" v-if="cancelled">
			
			<div class="summary-description-text-cancelled">Cancelled <small v-if="text">{{ text }}</small></div>
			
		</div>

		<div class="summary-description-text" v-if="!cancelled">
			
			<app-icon icon="quote" class="summary-description-icon" v-if="text && !noIcon" /><div v-html="text" />

		</div>

	</div>

	<div class="summary-terms">

		<template v-for="(metric, index) in metrics">

			<div v-if="!metric.disabled" class="summary-terms-item" :class="{'is-tooltip': metric.tooltip}" :data-type="(metric.type) ? metric.type : 'number'" :key="index">
				<div class="summary-terms-item-avatar" v-bgimage="metric.avatar" v-if="metric.forceAvatar"><app-icon icon="user" v-if="!metric.avatar" /></div>
				<div class="summary-terms-item-text">
					<div class="summary-terms-item-label">{{ metric.text }}</div>
					<div class="summary-terms-item-value" v-tooltip="metric.tooltip">{{ metric.value }}</div>
				</div>
			</div>

		</template>

	</div>

</app-content-box>

</template>

<script>

export default {

	props: ['image', 'text', 'span', 'metrics', 'headless', 'noIcon', 'cancelled']

}

</script>

<style scoped>

.summary {
	display: flex;
	flex-direction: column;
}

.summary[data-span="3"] {
	grid-column: 1 / 4;
}

.is-mobile .summary {
	grid-column: 1 / 5;
}

.summary-description {
	padding: 40px;
	border-bottom: 1px solid #eee;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.is-tablet .summary-description {
	padding: 20px;
}

.is-mobile .summary-description {
	padding: 20px;
	min-height: 200px;
}

.summary-description-background {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-position: 50% 50%;
	background-size: cover;
}

.summary-description.has-text .summary-description-background {
	filter: grayscale(100%);
	opacity: 0.2;
}

.summary-description-text {
	font-size: 24px;
	font-weight: 400;
	line-height: 32px;
	color: #687589;
	height: 100%;
}

.is-tablet .summary-description-text {
	font-size: 20px;
	line-height: 24px;
}

.is-mobile .summary-description-text {
	font-size: 20px;
	line-height: 24px;
}

.summary-description >>> a {
	color: #4082d3;
}

.summary-description >>> a:hover {
	text-decoration: underline;
}

.summary-description-text-cancelled,
.summary-description-text-proposal {
	color: #c55b5b;
    font-size: 64px;
	line-height: 64px;
    font-weight: 500;
    position: absolute;
    left: 50%;
    top: 50%;
	text-align: center;
    transform: translate(-50%, -50%);
}

.is-device .summary-description-text-cancelled,
.is-device .summary-description-text-proposal {
	font-size: 48px;
	line-height: 48px;	
}

.summary-description-text-cancelled small,
.summary-description-text-proposal small {
	font-size: 16px;
	line-height: 20px;
	margin-top: 20px;
	display: block;
}

.summary-description-icon {
    margin-right: 10px;
	font-size: 64px;
	float: left;
}

.summary-description-host {
	align-self: flex-end;
	display: flex;
	flex-direction: row;
}

.summary-description-host-avatar {
	width: 36px;
	height: 36px;
	border-radius: 4px;
	background-color: #eee;
}

.summary-description-host-name {
	font-size: 20px;
	color: #687589;
	margin-left: 12px;
}

.summary-description-host-name small {
	font-size: 14px;
	display: block;
	margin-bottom: 2px;
}

.summary-terms {
	display: flex;
}

.is-tablet .summary-terms {
	flex-wrap: wrap;
}

.is-mobile .summary-terms {
	flex-wrap: wrap;
}

.summary-terms-item {
	display: flex;
	flex-grow: 1;
	flex-shrink: 0;
}

.summary-terms-item.is-tooltip .summary-terms-item-value {
	border-bottom: 1px dashed #4d5b6e;
	cursor: pointer;
	padding: 2px 0px;
}

.summary-terms-item[data-type="info"] {
	padding: 10px;
}

.is-tablet .summary-terms-item {
	width: 50%;
	flex-grow: 0;
}

.is-mobile .summary-terms-item {
	width: 50%;
	flex-grow: 0;
}

.summary-terms-item-avatar {
	width: 36px;
	height: 36px;
	border-radius: 4px;
	background-color: #eee;
	line-height: 36px;
	background-position: 50% 50%;
	background-size: cover;
	text-align: center;
	font-size: 16px;
	color: #999;
	margin-right: 10px;
}

.summary-terms-item[data-type="info"]  .summary-terms-item-text {
	flex-direction: column;
	display: flex;
}

.summary-terms-item[data-type="number"]  .summary-terms-item-text {
	display: flex;
	width: 100%;
}

.summary-terms-item[data-type="number"] .summary-terms-item-label {
	flex-grow: 1;
	font-size: 16px;
	line-height: 40px;
	height: 40px;
	padding-left: 10px;
	color: #687589;
}

.summary-terms-item[data-type="info"] .summary-terms-item-label {
	font-size: 14px;
	color: #687589;
	margin-bottom: 2px;
	color: #687589;
}

.summary-terms-item[data-type="number"] .summary-terms-item-value {
	flex-shrink: 0;
	background-color: #4082d3;
	color: #fff;
	width: 30px;
	height: 30px;
	font-size: 16px;
	line-height: 30px;
	text-align: center;
	border-radius: 4px;
	margin: 5px;
}

.summary-terms-item[data-type="info"] .summary-terms-item-value {
	font-size: 20px;
	color: #687589;
}

</style>