<template>

<app-content-filters class="filters">

	<app-content-filters-search v-model="filter.search" />
	<app-content-filters-select v-model="filter.layout" :alwaysLabel="true" text="Layout" tooltip="Change layout" :options="filterLayout" />
	<app-content-filters-checklist v-model="filter.lobbies" text="Lobbies" tooltip="Select lobbies" :options="filterLobbies" />
	<app-content-filters-checklist v-model="filter.times" :columns="2" text="Time" tooltip="Select start times" :options="filterTimes" />
	<app-content-filters-checklist v-model="filter.days" text="Day" tooltip="Select schedule days" :options="filterDays" />
	<app-content-filters-select v-model="filter.sort" :alwaysLabel="true" text="Sort" tooltip="Change sort" :options="filterSort" />
	<app-content-filters-button v-on:click="onButtonClick" text="More filters" tooltip="Expand for many more filters" />

	<app-panel v-if="expanded" v-on:close="onButtonClick">
		
		<app-panel-title text="Event" />

		<app-panel-content>

			<app-input-slider label="Duration" sublabel="(minutes)" type="range" :min="0" :max="360" :step="30" :pip="30" v-model="filter.setup.duration" />
			<app-input-slider type="range" label="Total seats" :min="1" :max="10" maxText="Any" v-model="filter.setup.seats.total" />
			<app-input-slider type="range" label="Available seats" :min="0" :max="9" maxText="Any" v-model="filter.setup.seats.available" />
			<app-input-slider type="threshold" label="Minimum age" :min="9" minText="Any" :max="21" :step="3" :pip="3" v-model="filter.setup.age" />
			<app-input-slider type="threshold" label="Minimum experience" :texts="$constants.schedule.experienceSlider" :min="0" :max="4" v-model="filter.setup.experience" />

		</app-panel-content>

		<app-panel-title text="Game" />

		<app-panel-content>

			<app-input-slider type="range" label="Recommended count" :min="1" :max="10" maxText="Any" v-model="filter.game.count.recommended" />
			<app-input-slider type="range" label="Best count" :min="1" :max="10" maxText="Any" v-model="filter.game.count.best" />
			<app-input-slider label="Weight" type="range" :min="0" :max="5" :step="0.1" :pip="0.5" v-model="filter.game.weight" />
			<app-input-slider label="Published year" type="range" :min="1972" :max="2022" :step="1" :pip="10" v-model="filter.game.year" />
			<app-input-slider type="range" label="Language independence" :inverse="true" :min="1" :max="5" :texts="$constants.schedule.languageSlider" v-model="filter.game.language" />

		</app-panel-content>

		<app-panel-title text="DT Seals & BGG Ratings" />

		<app-panel-content>

			<app-input-toggle :stacked="true" :row="true" :fullWidth="true" label="Seal of Excellence" v-model="filter.rating.seal.excellence" />
			<app-input-toggle :stacked="true" :row="true" :fullWidth="true" label="Seal of Approval" v-model="filter.rating.seal.approval" />
			<app-input-toggle :stacked="true" :row="true" :fullWidth="true" label="Recommended" v-model="filter.rating.recommended" />
			<app-input-slider type="range" label="Average rating" :min="0" :max="10" v-model="filter.rating.community" />
			<app-input-slider type="range" label="Rank" :min="0" :max="1000" :step="25" :pip="100" maxText="Any" v-model="filter.rating.rank" />

		</app-panel-content>

		<app-panel-title text="Other filters" />

		<app-panel-content>

			<app-input-suggest :stacked="true" v-model="filter.other.designers" api="bgg/designer" placeholder="Search designers" />
			<app-input-suggest :stacked="true" v-model="filter.other.publishers" api="bgg/publisher" placeholder="Search publishers" />
			<app-input-suggest :stacked="true" v-model="filter.other.categories" api="bgg/category" placeholder="Search categories" />
			<app-input-suggest :stacked="true" v-model="filter.other.mechanics" api="bgg/mechanic" placeholder="Search mechanics" />
			<app-input-suggest :stacked="true" v-model="filter.other.families" api="bgg/family" placeholder="Search families" />
		
		</app-panel-content>

	</app-panel>

</app-content-filters>

</template>

<script>

export default {

	props: ['filter', 'references'],

	data: function() {

		return {
			expanded: false,
			filterSort: [
				{ value: 'created', text: 'Recently created' },
				{ value: 'name', text: 'Event name' },
				{ value: 'start', text: 'Start time' }
			],
			filterLayout: [
				{ value: 0, text: 'List view'},
				{ value: 1, text: 'Card view'}
			]
		}

	},

	computed: {

		filterTimes: function() {

			var options = []

			this.$_.each(this.references.times, function(text, value) {

				options.push({
					value: value,
					text: text
				})

			})

			return options

		},

		filterLobbies: function() {

			var options = []

			this.$_.each(this.references.lobbies, function(lobby) {

				options.push({
					value: lobby.id,
					text: lobby.name
				})

			})

			return options

		},

		filterDays: function() {

			return this.references.days

		}

	},

	methods: {

		onButtonClick: function() {

			this.expanded = !this.expanded

			this.$pubsub.$emit('offset', this.expanded)

		}

	}

}

</script>

<style scoped>

.is-mobile .filters {
	flex-wrap: wrap;
	height: auto;
}

.filters >>> .toggle {
	height: 30px;
}

.filters >>> .toggle-item {
	line-height: 28px;
	opacity: 0.25;
}

.filters >>> .toggle-item:hover {
	opacity: 1;
}

.filters >>> .toggle-item.is-active {
	background-color: #2f3a4a;
	color: #fff;
	border-color: #2f3a4a;
	opacity: 1;
}

</style>