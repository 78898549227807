<template>

<app-page-outer class="registration">

	<com-heading text="Your badges" />

	<com-holders :inputs="item.inputs" :badges="item.badges" :holders="registrants" v-on:click="onHolderClick" v-on:add="onAddClick" />

	<template v-if="isEditing">

		<com-form is-fullscreen="true" :forceInvalid="!registrants[selected].badge.id" :canRemove="canRemove" :canCancel="canCancel" :id="registrants[selected].id" :data="registrants[selected].model" :inputs="item.inputs" v-on:confirm="onFormConfirm" v-on:delete="onDeleteClick" v-on:cancel="onCancelClick">

			<com-heading text="Add badge" />

			<com-badges :badges="item.badges" :selected="selectedBadges" v-on:change="onBadgeConfirm" />

		</com-form>

	</template>

</app-page-outer>
	
</template>

<script>

import comBadges from './registration/Badges'
import comHolders from './registration/Holders'
import comForm from './registration/Form'
import comHeading from './common/Heading'

import Vue from 'vue'

export default {

	props: ['item'],

	components: {
		'com-badges': comBadges,
		'com-holders': comHolders,
		'com-form': comForm,
		'com-heading': comHeading
	},

	data: function() {

		return {
			selected: false,
			registrants: []
		}

	},

	watch: {

		registrants: {

			deep: true,

			handler: function() {
				
				this.$store.commit('register/badges', this.$_.filter(this.registrants, function(registrant) {

					return registrant.model !== false

				}))

			}

		},

		selected: function(n) {

			this.$store.commit('register/editing', n !== false)

		}

	},

	computed: {

		canStack: function() {

			return this.$store.getters['register/convention'].settings['badges.stacking.enabled']

		},	

		selectedBadges: function() {

			if (this.canStack) {

				return this.$_.pluck(this.registrants[this.selected].stacked, 'id')

			} else {

				return (this.registrants[this.selected].badge) ? [this.registrants[this.selected].badge.id] : []

			}

		},

		canCancel: function() {

			return this.registrants[this.selected].model === false

		},

		canRemove: function() {

			return this.registrants[this.selected].model !== false

		},

		isEditing: function() {

			return this.selected !== false

		}

	},

	created: function() {

		this.registrants = this.$store.getters['register/badges']

	},

	methods: {

		onAddClick: function() {

			this.registrants.push({
				id: this.$util.generateKey(),
				badge: false,
				stacked: [],
				model: false,
				name: false,
				firstname: false,
				lastname: false,
				email: false
			})

			this.selected = this.registrants.length - 1

		},

		onCancelClick: function() {

			this.registrants.splice(this.selected, 1)

			this.selected = false

		},

		onDeleteClick: function() {

			this.registrants.splice(this.selected, 1)

			this.selected = false

		},

		onHolderClick: function(index) {

			this.selected = index

		},

		onBadgeConfirm: function(badge) {

			if (this.canStack) {

				if(this.$_.contains(this.selectedBadges, badge.id)) {

					this.$util.collection.remove(this.registrants[this.selected].stacked, badge.id)

				} else {

					this.registrants[this.selected].stacked.push(badge)

				}

				if(this.registrants[this.selected].stacked.length) {

					if (!this.registrants[this.selected].badge) Vue.set(this.registrants[this.selected], 'badge', this.registrants[this.selected].stacked[0])

				} else {

					Vue.set(this.registrants[this.selected], 'badge', false)

				}

			} else {

				Vue.set(this.registrants[this.selected], 'badge', badge)
				this.registrants[this.selected].stacked = (badge) ? [badge] : []

			}

		},

		onFormConfirm: function(model) {

			this.registrants[this.selected].model = model
			this.registrants[this.selected].firstname = this.$util.registration.getRoleValue(this.item.inputs, model, 'firstname')
			this.registrants[this.selected].lastname = this.$util.registration.getRoleValue(this.item.inputs, model, 'lastname') 
			this.registrants[this.selected].name = this.$util.registration.getRoleValue(this.item.inputs, model, 'firstname') + ' ' + this.$util.registration.getRoleValue(this.item.inputs, model, 'lastname') 
			this.registrants[this.selected].email = this.$util.registration.getRoleValue(this.item.inputs, model, 'email').toLowerCase()

			this.selected = false

		}

	}
	
}

</script>

<style scoped>

.registration {
	flex-direction: column;
	padding: 10px 40px;
}

.is-mobile .registration {
	padding: 10px 10px;
}

</style>