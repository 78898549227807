<template>

<app-panel v-on:close="$emit('close')">
		
	<app-panel-title text="Image" />

	<app-panel-content>

		<app-input-image v-model="model.image" :validation="$v.model.image" />

	</app-panel-content>
		
	<app-panel-title text="Optional extras" />

	<app-panel-content>

		<app-input-text v-model="model.title" :validation="$v.model.title" placeholder="Enter title..." />
		<app-input-text :autogrow="true" v-model="model.text" :validation="$v.model.text" placeholder="Enter text..." />

	</app-panel-content>

	<app-panel-content :space="true">

		<app-button text="Confirm" :disabled="$v.invalid" v-on:click="$emit('save', model)" />

	</app-panel-content>

</app-panel>

</template>

<script>

import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Vue from 'vue'

export default {

	mixins: [validationMixin],

	props: ['item'],

	data: function() {

		return {
			model: {
				image: '',
				title: '',
				text: ''
			}
		}

	},

	created: function() {

		if (this.$_.isObject(this.item)) Vue.set(this, 'model', this.item)

	},

	validations: {
		model: {
			image: {
				required
			}
		}
	}

}

</script>

<style scoped>

</style>
