<template>

<app-table-cell v-tooltip="(active) ? 'Deselect' : 'Select'" class="select" :class="{'is-active': active}" :align="align" v-on:click.stop.native="$emit('click')">

	<div class="select-button">

		<app-icon icon="check" />

	</div>

</app-table-cell>

</template>

<script>

export default {

	props: ['align', 'active']

}

</script>

<style scoped>

.select {
	text-align: right;
	padding: 0px;
}

.select-button {
	border: 2px solid #eee;
	color: #ddd;
	height: 24px;
	width: 24px;
	border-radius: 4px;
	line-height: 22px;
	text-align: center;
	font-size: 12px;
	cursor: pointer;
	background-color: #fff;
}

.select-button:hover {
	color: #ccc;
}

.select.is-active .select-button,
.select.is-active .select-button:hover {
	background-color: #236dc9;
	border-color: #236dc9;
	color: #fff;
}

</style>
