<template>

<div class="panel" :class="{'is-loading': loading, [clsTheme]: theme}" v-click-outside="onCloseClick">

	<slot></slot>

	<app-icon icon="loading" v-if="loading" class="panel-loading" />
	<app-icon icon="close" v-if="!loading && window.is.mobile" class="panel-close" v-on:click.native="onCloseClick" />

</div>

</template>

<script>

export default {

	props: ['loading', 'theme'],

	computed: {

		clsTheme: function() {

			return 'theme-' + this.theme

		}

	},

	methods: {

		onCloseClick: function() {

			this.$emit('close')

		}

	}

}

</script>

<style scoped>

.panel {
	position: fixed;
	background-color: #4d5b6e;
	color: #fff;
	right: -320px;
	top: 0px;
	bottom: 0px;
	width: 320px;
	overflow-y: auto;
	z-index: 5000;
}

.is-mobile .panel {
	right: -100%;
	width: 100%;
	top: -48px;
}

.panel.theme-green {
	background-color: #4faa80;
}

.panel.is-loading {
	pointer-events: none;
}

.panel.is-loading >>> *:not(.icon) {
	opacity: 0.5;
}

.panel-loading {
	position: absolute;
	left: 50%;
	top: 50%;
	font-size: 32px;
	color: #fff;
	transform: translate(-50%, -50%);
}

.panel-close {
	position: absolute;
	right: 0px;
	top: 0px;
	font-size: 24px;
	width: 40px;
	height: 40px;
	line-height: 44px;
	text-align: center;
	color: #fff;
	background-color: rgba(0, 0, 0, 0.25);
}

</style>
