<template>

<div class="grid">

	<slot></slot>

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 10px;
}

.is-mobile .grid {
	grid-template-columns: 1fr;
}

</style>
