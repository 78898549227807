<template>

<app-input class="toggle-wrapper" :note="note" :warning="warning" :class="{'has-descriptions': descriptions, 'is-maxed': max && max <= value.length, 'is-collapsed': isCollapsed, 'is-locked': locked}" :wide="wide" v-on:toggle="$emit('toggle')" :label="label" :stacked="stacked" :no-line="true" :collapsed="collapsed" :toggle="toggle" :locked="locked" :notes="notes" :disabled="disabled" :validation="validation">

	<div v-if="isKeyed" v-tooltip="(isCollapsed) ? 'Click to change' : false" class="toggle" :class="{'is-slim': slim, 'has-descriptions': descriptions, 'is-fullwidth': fullWidth, 'is-stacked': (stacked || column) && !row}" :data-columns="columns">
		<template v-for="option in finalOptions">
			<div class="toggle-item" :key="option[valueProperty]" :class="{'is-active': isSelected(option[valueProperty])}" v-on:click="onClick(option[valueProperty])" v-if="(!locked && !isCollapsed) || isSelected(key)">
				<div class="toggle-item-text">{{ option.text }}<div class="toggle-item-description" v-if="descriptions">{{ descriptions[option.value] }}</div></div>
				<app-icon icon="tick" v-if="!isCollapsed && !locked" class="toggle-item-icon" />
				<app-icon icon="chevron.down.circle" v-if="isCollapsed" class="toggle-item-icon" />
			</div>
		</template>
	</div>

	<div v-if="!isKeyed" v-tooltip="(isCollapsed) ? 'Click to change' : false" class="toggle" :class="{'is-slim': slim, 'has-descriptions': descriptions, 'is-fullwidth': fullWidth, 'is-stacked': (stacked || column) && !row}" :data-columns="columns">
		<template v-for="(option, key) in finalOptions">
			<div class="toggle-item"  :key="key" :class="{'is-active': isSelected(key)}" v-on:click="onClick(key)" v-if="(!locked && !isCollapsed) || isSelected(key)">
				<div class="toggle-item-text">{{ option }}<div class="toggle-item-description" v-if="descriptions">{{ descriptions[key] }}</div></div>
				<app-icon icon="tick" v-if="!isCollapsed && !locked" class="toggle-item-icon" />
				<app-icon icon="chevron.down.circle" v-if="isCollapsed" class="toggle-item-icon" />
			</div>
		</template>
	</div>

	<slot></slot>

</app-input>

</template>

<script>

export default {

	props: ['validation', 'note', 'max', 'wide', 'warning', 'singular', 'property', 'label', 'collapsed', 'descriptions', 'locked', 'value', 'toggle', 'stacked', 'notes', 'column', 'columns', 'row', 'fullWidth', 'slim', 'placeholder', 'options', 'disabled'],

	data: function() {

		return {
			expanded: false
		}

	},

	computed: {

		isCollapsed: function() {

			return this.collapsed && !this.expanded

		},

		valueProperty: function() {

			return this.property || 'value'

		},

		isKeyed: function() {

			return (this.options) ? this.$_.isObject(this.options[this.$_.keys(this.options)[0]]) : false

		},

		finalOptions: function() {
			
			return (this.options) ? this.options : {
				1: 'Yes',
				0: 'No'
			}

		}

	},

	methods: {

		onClick: function(value) {

			if (this.isCollapsed) {
				
				this.expanded = true

			} else {

				this.expanded = false

				if (this.singular) {

					this.$emit('input', parseInt(value))

				} else {

					var original = this.$_.clone(this.value)

					if (this.$_.contains(original, value)) {

						original.splice(original.indexOf(value), 1)

					} else {

						if (original.length < this.max || !this.max) {

							original.push(value)

						}

					}

					this.$emit('input', original)

				}

			}

		},

		isSelected: function(value) {

			if (this.singular) {

				return value.toString() === this.value.toString()

			} else {

				return this.$_.contains(this.value, value)

			}

		}

	}

}

</script>

<style scoped>

.toggle {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}

.toggle[data-columns="1"] {
	grid-template-columns: 1fr;
}

.toggle[data-columns="2"] {
	grid-template-columns: 2fr;
}

.toggle.has-descriptions {
	padding: 0px;
	border: 0px;
	border-radius: 0px;
}

.is-mobile .toggle {
	grid-template-columns: 1fr;
}

.toggle.is-stacked,
.is-mobile .toggle {
	height: auto;
}

.toggle-item {
	font-size: 14px;
	color: #343434;
	padding: 0px 8px;
	min-height: 40px;
	margin: 2px;
	border-radius: 4px;
	cursor: pointer;
	line-height: 16px;
	user-select: none;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	border-radius: 10px;
	border: 2px solid #eee;
}

.toggle-wrapper.is-maxed .toggle-item:not(.is-active) {
	opacity: 0.25;
	pointer-events: none;
}

.toggle[data-columns="1"] .toggle-item,
.toggle.is-stacked .toggle-item {
	border: 0px;
	border-left: 2px solid #eee;
	border-right: 2px solid #eee;
}

.toggle.has-descriptions .toggle-item {
	border-bottom: 1px solid #eee;
	margin: 0px;
	padding: 11px 8px;
	border-radius: 0px;
	flex-direction: row-reverse;
}

.toggle-wrapper.is-locked >>> .toggle-item,
.toggle-wrapper.is-collapsed >>> .toggle-item {
	flex-direction: row;
	padding-left: 10px;
}

.toggle.has-descriptions .toggle-item:first-child {
	border-radius: 10px 10px 0px 0px;
	border-top: 2px solid #eee;
}

.toggle.has-descriptions .toggle-item:last-child {
	border-bottom: 2px solid #eee;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
}

.toggle.is-fullwidth .toggle-item {
	flex-grow: 1;
	flex-shrink: 0;
}

.toggle.is-stacked .toggle-item {
	text-align: left;
	justify-content: space-between;
}

.toggle.is-stacked .toggle-item,
.is-mobile .toggle-item {
	border-width: 2px 2px 0px 2px;
}

.toggle.is-stacked .toggle-item,
.is-device .toggle-item {
	font-size: 14px;
}

.toggle.is-slim .toggle-item {
	width: auto;
	padding: 0px 20px;
}

.toggle:not(.has-descriptions) .toggle-item.is-active {
	background-color: #4082d3;
	border-color: #4082d3;
	color: #fff;
}

.toggle-item:first-child:last-child {
	border-radius: 10px;
}

.toggle-item-text {
	flex-grow: 1;
}

.toggle.has-descriptions .toggle-item-text {
	font-weight: 500;
}

.toggle.has-descriptions .toggle-item-description {
	font-weight: 300;
	margin-top: 2px;
}

.toggle-item-icon {
	margin-left: 10px;
	color: #eee;
	flex-shrink: 0;
	font-size: 16px;
}

.toggle.has-descriptions .toggle-item-icon {
	font-size: 24px;
}

.toggle-item-icon.is-active {
	color: #fff;
}

.toggle-item:hover:not(.is-active) .toggle-item-icon {
	color: #999;
}

.toggle.has-descriptions .toggle-item .toggle-item-icon {
	margin: 0px 20px 0px 10px;
}

.toggle.has-descriptions .toggle-item.is-active .toggle-item-icon {
	color: #4082d3;
}

.toggle-wrapper.has-descriptions >>> .input-label {
	padding-top: 22px;
}

.toggle-wrapper.has-descriptions >>> .input-label-locked,
.toggle-wrapper.has-descriptions >>> .input-label-collapsed {
	line-height: 60px;
}

.toggle-wrapper.is-locked >>> .toggle-item-icon,
.toggle-wrapper.is-collapsed >>> .toggle-item-icon {
	color: #eee!important;
	margin: 0px 0px 0px 20px!important;
}

.toggle-wrapper.is-collapsed >>> .toggle:hover .toggle-item-icon {
	color: #4082d3!important;
}

.toggle-wrapper.is-collapsed >>> .toggle:hover .toggle-item {
	border-color: #4082d3!important;
}

</style>
