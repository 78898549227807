<template>

<app-content-body :is-grid="filter.layout" :no-grow="noGrow" :loading="loading" :is-empty="!collection.length" :placeholder="placeholder">

	<template v-if="filter.layout">

		<app-widget-event v-for="item in collection" :key="item.id" :event="item" :is-grid="true" />

	</template>

	<div class="title" v-if="title">{{ title }}</div>

	<app-table v-if="!filter.layout">

		<app-table-head :layout="layout">

			<app-table-column :text="columnText[key]" v-for="(column, key) in columns" :key="key" />

		</app-table-head>

		<app-table-body>

			<app-table-row :success="item.session.is.sitting && !noSuccess" :danger="item.cancelled" v-on:click="onClick(item.id)" :layout="layout" v-for="item in collection" :key="item.id">

				<component v-bind:is="'app-events-row-' + key" v-for="(column, key) in columns" :key="key" :item="item" v-on:action="onAction" />

			</app-table-row>

		</app-table-body>

		<app-table-foot :pagination="pagination" :total="total" />

	</app-table>

</app-content-body>

</template>

<script>

export default {

	props: ['collection', 'title', 'loading', 'filter', 'noGrow', 'columns', 'pagination', 'total', 'placeholder', 'noSuccess'],

	data: function() {

		return {
			columnText: {
				game: 'Game',
				experience: 'Experience',
				seats: 'Seats',
				date: 'When',
				duration: 'Duration',
				location: 'Where',
				actions: ''
			}
		}

	},

	computed: {

		layout: function() {

			var layout = []

			this.$_.each(this.columns, function(value) {

				layout.push((value === 'auto') ? value : value.toString() + 'px')

			})

			return layout.join(' ')

		}

	},

	methods: {

		onAction: function(e) {

			this.$emit(e.name, e)

		},

		onClick: function(id) {

			this.$router.push({
				name: 'Convention.Schedule.Event',
				params: {
					id: id
				}
			})

		}

	}

}

</script>

<style scoped>

.title {
	font-size: 18px;
	margin-bottom: 15px;
	font-weight: 400;
	color: #687589;
	padding: 0px 10px;
}

</style>