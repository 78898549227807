<template>

<app-input v-on:toggle="$emit('toggle')" :toggle="toggle" class="image" :validation="validation" :class="{'is-empty': !value}" :locked="locked" :label="label" :stacked="stacked" :no-line="noLine" :notes="notes" :disabled="disabled">

	<div class="image-preview" :class="{'is-previewing': value}" v-bgimage="value">

		<template v-if="!value">No image uploaded</template>

	</div>

	<div class="image-button-remove" v-if="value" v-tooltip="'Remove image'" v-on:click="onRemoveClick">

		<app-icon icon="remove" />

	</div>

	<div class="image-button-upload" v-tooltip="(value) ? 'Change image' : 'Select image'" v-on:click="onSelectClick">

		<app-icon icon="upload" />

	</div>

	<input ref="file" type="file" accept=".png,.jpg,.jpeg" v-on:change="onFileSelected" class="image-file" />

</app-input>

</template>

<script>

export default {

	props: ['validation', 'toggle', 'label', 'locked', 'disabled', 'value', 'noLine', 'stacked', 'notes'],

	methods: {

		onRemoveClick: function() {

			this.$emit('input', '')
				
		},

		onSelectClick: function() {

			this.$refs.file.click()
				
		},

		onFileSelected: function(e) {

			var file = e.target.files[0]

			var reader = new FileReader()

			reader.onloadend = function() {

				this.$emit('input', reader.result)

			}.bind(this)

			reader.readAsDataURL(file)
			
		}

	}

}

</script>

<style scoped>

.image >>> .input-content {
	display: flex;
}

.image-preview {
	border: 2px solid #eee;
	width: 100%;
	min-height: 40px;
	background-color: #eee;
	border-radius: 12px 0px 0px 12px;
	flex-grow: 1;
}

.image-preview.is-previewing {
	height: 120px;
	background-size: contain;
	background-position: 50% 50%;
	border-width: 10px;
	background-repeat: no-repeat;
	border-radius: 10px;
}

.image.is-empty .image-preview {
	color: #333;
	padding: 0px 10px;
	line-height: 36px;
	font-size: 14px;
}

.image-button-remove,
.image-button-upload {
	flex-shrink: 0;
	border: 2px solid #eee;
	border-radius: 0px 12px 12px 0px;
	border-left-width: 0px;
	background-color: #fff;
	width: 36px;
	line-height: 36px;
	text-align: center;
	color: #4082d3;
	cursor: pointer;
	font-size: 16px;
	transition: all 100ms linear;
	background-color: #4082d3;
	border-color: #4082d3;
	color: #fff;
}

.image:not(.is-empty) .image-button-remove {
	position: absolute;
	border-radius: 50%;
	width: 36px;
	height: 36px;
	border-width: 0px;
	line-height: 36px;
	right: 4px;
	bottom: 4px;
}

.image:not(.is-empty) .image-button-upload {
	position: absolute;
	border-radius: 50%;
	width: 36px;
	height: 36px;
	border-width: 0px;
	line-height: 36px;
	right: 44px;
	bottom: 4px;
}

.image-file {
	display: none;
}

</style>
