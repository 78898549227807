<template>

<div class="logo">cardboard<span>{{ termText }}</span></div>

</template>

<script>

export default {

	props: ['term'],

	computed: {

		termText: function() {

			return this.term || 'events'

		}

	}

}

</script>

<style scoped>

.logo {
	line-height: 48px;
	font-size: 28px;
	color: #fff;
	font-weight: 400;
	background-image: url(~@/assets/cell.svg);
	background-size: 32px auto;
	background-repeat: no-repeat;
	background-position: 0px 50%;
	padding-left: 40px;
}

.is-mobile .logo {
	font-size: 20px;
	background-size: 24px auto;
	background-position: 10px 50%;
	z-index: 2;
}

.logo span {
	color: #ffbe7a;
}

</style>
