<template>

<div class="item" v-tooltip="tooltip" :data-theme="theme" :class="{'is-disabled': disabled, 'is-faded': faded, 'is-danger': danger, 'is-active': active}" v-on:click.stop="onClick">

	<app-icon :icon="(loading) ? 'loading' : icon" />

</div>

</template>

<script>

export default {

	props: ['toName', 'toId', 'icon', 'disabled', 'active', 'faded', 'theme', 'tooltip', 'loading', 'danger'],

	methods: {

		onClick: function() {

			if (this.toName) {

				this.$router.push({
					name: this.toName,
					params: {
						id: this.toId
					}
				})

			} else {

				this.$emit('click')

			}

		}

	}

}

</script>

<style scoped>

.item {
	flex-grow: 1;
	flex-shrink: 0;
	text-align: center;
	line-height: 40px;
	height: 40px;
	cursor: pointer;
	font-size: 20px;
	border-right: 1px solid #eee;
	color: #687589;
	transition: all 100ms ease-in-out;
}

.item.is-faded {
	opacity: 0.25;
}

.item.is-faded:hover {
	opacity: 1;
}

.item:last-child {
	border-right: 0px;
}

.row:hover .item {
	color: #fff;
}

.item.is-active {
	color: #ffbe7a;
}

.item.is-danger {
	color: #c55b5b;
}

.item[data-theme="green"] {
	color: #3e8865;
}

.item.is-disabled {
	pointer-events: none;
	opacity: 0.25;
}

.item .icon {
	transition: all 100ms ease-in-out;
}

.item:hover .icon {
	color: #333;
}

</style>
