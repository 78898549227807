<template>

<app-panel v-on:close="$emit('close')">
		
	<app-panel-title text="Option" />

	<app-panel-content>

		<app-input-text label="Text" placeholder="Enter text" :stacked="true" v-model="model.text" :validation="$v.model.text" />

	</app-panel-content>

	<app-panel-content :space="true">

		<app-button text="Confirm" :disabled="$v.invalid" v-on:click="$emit('save', model)" />

	</app-panel-content>

</app-panel>

</template>

<script>

import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Vue from 'vue'

export default {

	mixins: [validationMixin],

	props: ['item'],

	data: function() {

		return {
			model: {
				id: 0,
				text: ''
			}
		}

	},

	validations: {
		model: {
			text: {
				required
			}
		}
	},

	created: function() {

		if (this.$_.isObject(this.item)) Vue.set(this, 'model', this.item)

		if (!this.model.id) this.model.id = this.$util.generateKey()

	}

}

</script>

<style scoped>

</style>
