<template>

<div class="map">

	<iframe :src="item.url" />

</div>
	
</template>

<script>

export default {

	props: ['item']

}

</script>

<style scoped>

.map {
	height: 480px;
}

.map iframe {
	width: 100%;
	height: 480px;
}

</style>