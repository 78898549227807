<template>

<app-page-outer class="bar">

	<app-page-inner class="bar-inner">

		<div class="bar-item is-first">

			<app-icon class="bar-item-icon" icon="basket.badge" />

			<div class="bar-item-text">x {{ badgeCount }}</div>

		</div>

		<div class="bar-item is-grow">

			<app-icon class="bar-item-icon" icon="basket.addon" />

			<div class="bar-item-text">x {{ addonCount }}</div>

		</div>

		<div class="bar-item is-cost">

			<div class="bar-item-text is-total">
				{{ totalCost | formatMoney }}
			</div>

			<div v-for="addon in defaultAddons" :key="addon.key" class="bar-item-text is-addon">
				<div>{{ (addon.price * badgeCount) | formatMoney }}</div>
				<div>{{ addon.name }}</div>
			</div>

		</div>

		<div v-if="!expanded" class="bar-item is-proceed" v-on:click="$emit('review')">

			<div class="bar-item-text">Review & Pay</div>

		</div>

		<div v-if="expanded" class="bar-item is-proceed" v-on:click="$emit('close')">

			<div class="bar-item-text">Back</div>

		</div>

	</app-page-inner>

</app-page-outer>
		
</template>

<script>

export default {

	props: ['expanded'],

	computed: {

		defaultAddons: function() {

			return []

		},

		defaultAddonsTotal: function() {

			var cost = 0

			this.$_.each(this.defaultAddons, function(addon) {

				cost += addon.price * this.badgeCount

			}.bind(this))

			return cost

		},

		badges: function() {

			return this.$store.getters['basket/badges']

		},

		badgeCount: function() {

			var count = 0

			this.$_.each(this.badges, function(holder) {

				count += holder.quantity

			})

			return count


		},

		badgeCost: function() {

			var cost = 0

			this.$_.each(this.badges, function(holder) {

				cost += holder.item.price * holder.quantity

			})

			return cost

		},

		addons: function() {

			return this.$store.getters['basket/addons']

		},

		addonCount: function() {

			var count = 0

			this.$_.each(this.addons, function(addon) {

				count += addon.quantity

			})

			return count

		},

		addonCost: function() {

			var cost = 0

			this.$_.each(this.addons, function(addon) {

				cost += ((addon.variant) ? addon.variant.price : addon.item.price) * addon.quantity

			})

			return cost

		},

		totalCost: function() {

			return this.badgeCost + this.addonCost

		}

	}
	
}

</script>

<style scoped>

.bar {
	width: 100%;
	height: 64px;
	background-color: #1e3054;
	flex-shrink: 0;
	padding-left: 20px;
}

.is-mobile .bar {
	height: 80px;
	padding-left: 0px;
}

.bar-inner {
	display: flex;
	padding-left: 20px;
}

.is-mobile .bar-inner {
	padding-left: 0px;
	flex-wrap: wrap;
}

.bar-item {
	display: flex;
	align-items: center;
	margin-right: 40px;
	flex-shrink: 0;
}

.is-mobile .bar-item {
	margin-right: 10px;
}

.is-mobile .bar-item.is-first {
	margin-left: 10px;
}

.is-mobile .bar-item.is-cost {
	margin-right: 10px;
}

.bar-item-icon {
	font-size: 32px;
	color: rgba(255, 255, 255, 0.8);
	margin-right: 10px;
}

.is-mobile .bar-item-icon {
	font-size: 16px;
	margin-right: 4px;
}

.bar-item-text {
	color: #fff;
	font-size: 24px;
}

.is-mobile .bar-item-text {
	font-size: 16px;
}

.bar-item-text.is-total {
	font-size: 28px;
	line-height: 28px;
}

.is-mobile .bar-item-text.is-total {
	font-size: 20px;
}

.bar-item-text.is-addon:before {
	content: '+';
	position: absolute;
	font-size: 16px;
	left: -14px;
	top: 50%;
	margin-top: -8px;
}

.bar-item-text.is-addon {
	margin-left: 18px;
	line-height: 14px;
	font-size: 14px;
	display: flex;
	flex-direction: column;
}

.bar-item-text.is-addon div:nth-child(2) {
	font-size: 11px;
	opacity: 0.7;
}

.bar-item.is-grow {
	flex-grow: 1;
}

.bar-item.is-proceed {
	margin: 0px;
	cursor: pointer;
	background-color: #387dd8;
	text-transform: uppercase;
	width: 200px;
	justify-content: center;
}

.is-mobile .bar-item.is-proceed {
	width: 100%;
}

.bar-item.is-proceed .bar-item-text {
	font-size: 20px;
}

.is-mobile .bar-item.is-proceed .bar-item-text {
	font-size: 16px;
}

.bar-item.is-proceed.is-disabled {
	pointer-events: none;
	opacity: 0.25;
}

</style>