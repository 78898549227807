<template>

<app-table-cell class="icon" :border="border" :class="{'is-framed': framed}" :data-theme="theme" :align="align">

	<div class="icon-wrapper"><app-icon :icon="icon" /></div>

</app-table-cell>

</template>

<script>

export default {

	props: ['icon', 'align', 'border', 'theme', 'framed']

}

</script>

<style scoped>

.icon {
	font-size: 16px;
	line-height: 16px;
}

.icon.is-framed .icon-wrapper {
	width: 24px;
	height: 24px;
	border-radius: 4px;
	text-align: center;
}

.icon.is-framed .icon-wrapper >>> .icon {
	line-height: 24px;
	font-size: 12px;
}

.icon[data-theme="green"] {
	color: #4faa80;
}

.icon.is-framed[data-theme="green"] .icon-wrapper {
	background-color: #4faa80;
	color: #fff;
}

.icon[data-theme="red"] {
	color: #c55b5b;
}

.icon.is-framed[data-theme="red"] .icon-wrapper {
	background-color: #c55b5b;
	color: #fff;
}

.icon[data-theme="yellow"] {
	color: #ffbe7a;
}

.icon.is-framed[data-theme="yellow"] .icon-wrapper {
	background-color: #ffbe7a;
	color: #fff;
}

.icon[data-theme="faded"] {
	color: #ccc;
}

.icon.is-framed[data-theme="faded"] .icon-wrapper {
	background-color: #efefef;
	color: #ddd;
}

</style>
