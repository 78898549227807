<template>

<div class="content" :class="{'is-centralise': centralise, 'is-fullheight': fullheight, 'is-wrap': wrap, 'is-basket-active': isBasketActive}">

	<template v-if="(!loading && !pending) || allowDOM">
		
		<slot></slot>

	</template>

	<div class="content-pending" v-if="pending">

		<app-icon icon="pending" class="content-pending-icon" />

		<div class="content-pending-text">The dashboard is will launched soon, we're adding features every day!</div>

	</div>

	<app-icon icon="loading" class="content-loading" v-if="loading" />

</div>

</template>

<script>

export default {

	props: ['centralise', 'loading', 'pending', 'fullheight', 'wrap', 'allowDOM'],

	computed: {

		isBasketActive: function() {

			return this.$store.getters['basket/count']

		}

	}

}

</script>

<style scoped>

.content {
	display: flex;
	flex-direction: column;
	height: calc(100vh - var(--vh-offset, 0px) - 112px);
}

.content.is-basket-active {
	height: calc(100vh - var(--vh-offset, 0px) - 144px);
}

.is-mobile .content {
	height: calc(100vh - var(--vh-offset, 0px) - 48px);
}

.screen-content.is-empty .content {
	height: calc(100vh - var(--vh-offset, 0px));
}

.content.is-centralise {
	justify-content: center;
	flex-direction: row;
	align-items: center;
}

.content.is-fullheight {
	height: calc(100vh - var(--vh-offset, 0px));
}

.content.is-wrap {
	flex-wrap: wrap;
}

.content-loading {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 48px;
	color: #ffbe7a;
}

.content-pending {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #a7b6c6;
}

.content-pending-icon {
	font-size: 96px;
}

.content-pending-text {
	font-size: 24px;
	line-height: 32px;
	font-weight: 400;
	padding: 20px;
	text-align: center;
}

</style>
