import _ from 'underscore'
import api from '@/api'
import Vue from 'vue'
import constants from '@/constants'

export default {

	namespaced: true,

	state: {
		items: [],
		status: false,
		sale: false,
		options: {
			single: false,
			redirect: false,
			type: false,
			params: {}
		}
	},

	getters: {

		count: function(state) {

			return state.items.length
			
		},

		addons: function(state) {

			return _.filter(state.items, function(item) {

				return item.item.type === constants.registration.item.type.merchandise || item.item.type === constants.registration.item.type.ticket

			})

		},

		badges: function(state) {

			return _.filter(state.items, function(item) {

				return item.item.type === constants.registration.item.type.badge

			})

		},

		items: function(state) {

			return state.items

		},

		intent: function(state) {

			var intent = {}
			var id

			_.each(state.items, function(item) {

				id = (item.variant) ? item.variant.id : item.item.id

				intent[id] = intent[id] || 0
				intent[id] += item.quantity

			})

			return intent

		},

		status: function(state) {

			return state.status

		},

		sale: function(state) {

			return state.sale

		},

		options: function(state) {

			return state.options

		}

	},

	mutations: {

		reset: function(state, options) {

			options = options || {}

			options.single = options.single || false
			options.redirect = options.redirect || false
			options.type = options.type || false
			options.params = options.params || {}

			state.items = []
			state.status = false
			state.sale = false
			state.options = options

		},

		add: function(state, value) {

			state.items.push(value)

		},

		remove: function(state, value) {

			Vue.set(state, 'items', _.filter(state.items, function(addon) {

				if (addon.item.id === value.item.id) {

					if (value.variant && addon.variant) {

						return value.variant.id !== addon.variant.id

					} else {

						return false

					}

				} else {

					return true

				}

			}))

		},

		set: function(state, value) {

			Vue.set(state, 'items', value)

		},

		status: function(state, data) {

			state.status = data

		},

		sale: function(state, data) {

			state.sale = data

		}

	},

	actions: {

		retrieve: function(context, secret) {

			return api.get('registration/store/intent', {
				secret: secret
			}).then(function(json) {

				context.commit('status', json.status)
				context.commit('sale', json.sale)

				var items = []

				_.each(json.data.badges, function(item) {

					items.push(item)

				})

				_.each(json.data.addons, function(item) {

					items.push(item)

				})

				context.commit('set', items)

			})

		}

	}

}
