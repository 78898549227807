<template>

<div class="box" :data-span="span" :class="{'is-grow': flexGrow, 'is-disabled': disabled, 'is-readonly': readonly, 'is-danger': danger, 'no-title': !title}">

	<div class="box-head" v-if="title && !tabs" :class="{'has-icon': icon}">
		<app-icon class="box-head-icon" :icon="icon" v-if="icon" />
		<div class="box-head-title">{{ title }}</div>
		<div class="box-head-subtitle" v-if="subtitle">/ {{ subtitle }}</div>
	</div>

	<div class="box-tabs" v-if="tabs">
		<div class="box-tabs-item" v-for="tab in finalTabs" :key="tab.name" v-tooltip="(tab.name === currentTab) ? false : 'Click to switch tab'" :class="{'is-active': tab.name === currentTab}" v-on:click="currentTab = tab.name">
			<span>{{ tab.text }}</span>
			<app-icon icon="warning" v-tooltip="'Attention required'" v-if="tab.warning" class="box-tabs-item-icon" />
		</div>
	</div>

	<div class="box-content" :class="{'is-notpadded': notPadded, 'is-transparent': transparent, 'is-grid': isGrid, 'is-scroll': scroll, 'is-blue': isBlue, 'is-blank': blank, 'is-loading': loading}">

		<template v-for="tab in finalTabs">

			<slot :name="tab.name" v-if="currentTab === tab.name">

			</slot>

		</template>

		<app-icon icon="loading" v-if="loading" class="box-content-loading" />

		<template v-if="!isEmpty">

			<slot></slot>

		</template>

		<div class="box-content-empty" v-if="isEmpty">

			<div class="box-content-empty-text">{{ emptyMessage }}</div>

		</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['title', 'subtitle', 'tabs', 'notPadded', 'span', 'disabled', 'readonly', 'loading', 'transparent', 'isGrid', 'icon', 'isBlue', 'blank', 'scroll', 'danger', 'flexGrow', 'isEmpty', 'emptyMessage'],

	data: function() {

		return {
			currentTab: ''
		}

	},

	created: function() {

		if (this.tabs) this.currentTab = this.tabs[0].name

	},

	watch: {

		tabs: {

			deep: true,

			handler: function() {

				var selectedTab = this.$_.findWhere(this.tabs, {
					name: this.currentTab
				})

				if (!selectedTab.enabled) {

					this.currentTab = this.tabs[0].name

				}

			}

		}

	},

	computed: {

		finalTabs: function() {

			return this.$_.filter(this.tabs, function(tab) {

				return tab.enabled === undefined || tab.enabled === true

			})

		}

	}

}

</script>

<style scoped>

.box {
	margin-top: 10px;
	margin-bottom: 20px;
}

.body.is-grid .box {
	margin-bottom: 0px;
}

.box[data-span="2"] {
	grid-column: 1 / 3;
}

.box.is-disabled {
	opacity: 0.25;
	pointer-events: none;
}

.box.is-readonly {
	pointer-events: none;
}

.box.no-title {
	margin-top: 0px;
}

.is-mobile .box,
.is-tablet .box {
	margin-bottom: 20px;
}

.box.is-grow {
	display: flex;
	flex-direction: column;
}

.box-tabs {
	display: flex;
	margin-bottom: -1px;
	user-select: none;
	z-index: 2;
}

.box-tabs-item {
	font-size: 16px;
	color: #687589;
	font-weight: 500;
	cursor: pointer;
	padding: 15px 20px;
	border-radius: 4px 4px 0px 0px;
	display: flex;
}

.box-tabs-item-icon {
	margin-left: 10px;
	color: #c55b5b;
}

.box-tabs-item.is-active {
	background-color: #fff;
	border: 1px solid #eee;
	border-bottom: 0px;
}

.box-tabs-item:not(.is-active):hover {
	background-color: rgba(0, 0, 0, 0.05);
}

.box-head {
	padding: 0px 40px 10px 34px;
	font-size: 16px;
	color: #687589;
	font-weight: 500;
	display: flex;
	align-items: flex-end;
	flex-shrink: 0;
}

.is-mobile .box-head {
	font-size: 16px;
	line-height: 20px;
	padding: 0px 10px 10px 10px;
}

.is-tablet .box-head {
	padding: 0px 20px 20px 20px;
}

.is-tablet .box-head.has-icon {
	padding: 0px 20px 20px 40px;
}

.is-mobile .box-head.has-icon {
	padding: 0px 10px 20px 40px;
}

.box-head-icon {
	position: absolute;
	left: 8px;
	font-size: 16px;
	top: 0px;
}

.box-head-subtitle {
	font-size: 14px;
	font-weight: 300;
	margin-left: 5px;
}

.box-content {
	padding: 10px;
	border: 1px solid #eee;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px;
	background-color: #fff;
	border-radius: 4px;
}

.box-content >>> h1 {
	font-size: 18px;
	font-weight: 400;
	color: #687589;
	margin-bottom: 20px;
}

.box-content >>> p {
	font-size: 14px;
	line-height: 18px;
	font-weight: 400;
	color: #687589;
	margin-bottom: 20px;
}

.box-content >>> p:last-child {
	margin-bottom: 0px!important;
}

.is-tablet .box-content {
	padding: 20px;
}

.is-mobile .box-content {
	padding: 10px;
}

.box-content.is-scroll {
	overflow: auto;
}

.box-content.is-blue {
	background-color: #4082d3;
	border-color: #4082d3;
}

.box-content.is-blue >>> p {
	color: #fff;
}

.box-content.is-blank {
	padding: 0px;
	border-width: 0px;
	box-shadow: none;
	border-radius: 0px;
	background-color: transparent;
}

.box-content.is-transparent {
	padding: 0px 10px;
	border-width: 0px;
	box-shadow: none;
	border-radius: 0px;
	background-color: transparent;
}

.box.is-grow .box-content {
	display: -webkit-box;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.box-content.is-grid {
	display: grid;
	grid-gap: 20px;
	grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
}

.is-mobile .box-content.is-grid {
	grid-template-columns: minmax(0, 1fr);
}

.box-content.is-notpadded {
	padding: 0px;
}

.box.is-danger .box-content {
	background-color: #f65d5d;
}

.box-content.is-loading >>> *:not(.icon):not(i) {
	visibility: hidden;
}

.box-content-loading {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 32px;
	color: #4082d3;
}

.box-content-empty {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.box-content-empty-text {
	font-size: 24px;
	font-weight: 400;
    color: #bac5d7;
	padding: 20px 0px;
}

</style>
