<template>

<div class="inner">

	<slot></slot>

</div>
	
</template>

<script>

export default {
	
}

</script>

<style scoped>

.inner {
	width: calc(100% - 40px);
	max-width: 1280px;
}

.is-mobile .inner {
	width: 100%;
}

</style>