<template>

<div class="holders">

	<div v-tooltip="'Click to edit or remove'" class="holders-item" v-for="(holder, index) in holders" :key="index" v-on:click="$emit('click', index)">

		<div class="holders-item-holder">{{ getRoleValue(holder, 'firstname') }} {{ getRoleValue(holder, 'lastname') }}</div>
		<div class="holders-item-name">{{ name(holder) }}</div>
		<div class="holders-item-cost">{{ price(holder) | formatMoney }}</div>
		<div class="holders-item-primary" v-if="index === 0 && holders.length > 1" v-tooltip="'The primary badge holder is the user under which the purchase will be recorded.'">Primary badge</div>

	</div>

	<div class="holders-item is-empty" :key="'empty'" v-on:click="$emit('add')">

		Add badge

	</div>

</div>
	
</template>

<script>

export default {

	props: ['inputs', 'badges', 'holders'],

	methods: {

		price: function(holder) {

			var price = 0
			
			this.$_.each(holder.stacked, function(badge) {

				price += badge.price

			})

			return price

		},

		name: function(holder) {

			var name = []
			
			this.$_.each(holder.stacked, function(badge) {

				name.push(badge.name)

			})

			return name.join(', ')

		},

		findRoleID: function(role) {

			return this.$_.findWhere(this.inputs, {
				role: this.$constants.registration.input.role[role]
			}).id

		},

		getRoleValue: function(holder, role) {

			return holder.model[this.findRoleID(role)]

		}

	}

}

</script>

<style scoped>

.holders {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.is-mobile .holders {
	flex-direction: column;
}

.holders-item {
	padding: 10px;
	margin: 10px;
	width: calc(33.3% - 20px);
	height: 100px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	cursor: pointer;
	border: 2px solid #387DD8;
	background-color: #387DD8;
	color: #fff;
}

.is-mobile .holders-item {
	width: 100%;
	margin: 0px 0px 10px 0px;
}

.holders-item.is-empty {
	background-color: #fff;
	border: 2px dashed #ccc;
	color: #999;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	font-weight: 700;
}

.holders-item.is-empty:hover {
	border-color: #999;
	color: #666;
}

.holders-item-holder {
	font-size: 20px;
	font-weight: 700;
	text-transform: uppercase;
}

.holders-item-name {
	font-size: 16px;
	font-weight: 500;
	text-transform: uppercase;
	flex-grow: 1;
}

.holders-item-cost {
	text-align: right;
	font-size: 32px;
}

.holders-item-primary {
	position: absolute;
	left: 10px;
	bottom: 10px;
	font-size: 12px;
	text-transform: uppercase;
	background-color: #fff;
	color: #387DD8;
	padding: 4px;
	border-radius: 4px;
}

</style>