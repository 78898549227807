<template>

<div class="form">

	<template v-for="input in inputs">

		<app-input-text :disabled="is.saving || is.saved" v-model="model[input.id]" :messages="messages[input.id]" :wide="window.is.desktop" :key="input.id" :validation="$v.model[input.id]" :label="input.label" :notes="input.description" v-if="input.type === $constants.input.type.textbox" />
		<app-input-toggle :disabled="is.saving || is.saved" v-model="model[input.id]" :asString="true" valueField="id" :wide="window.is.desktop" :key="input.id" :validation="$v.model[input.id]" :label="input.label" :notes="input.description" :options="input.options" v-if="input.type === $constants.input.type.toggle" />
		<app-input-checklist :disabled="is.saving || is.saved" v-model="model[input.id]" :wide="window.is.desktop" property="id" :key="input.id" :validation="$v.model[input.id]" :label="input.label" :notes="input.description" :options="input.options" v-if="input.type === $constants.input.type.checklist" />
		<app-input-select :disabled="is.saving || is.saved" v-model="model[input.id]" :wide="window.is.desktop" property="id" :key="input.id" :validation="$v.model[input.id]" :label="input.label" :notes="input.description" :placeholder="input.placeholder || 'Select an option...'" :options="input.options" v-if="input.type === $constants.input.type.select" />

	</template>

	<div class="form-buttons" v-if="!skipConfirm">	

		<app-button v-if="!is.saved" :disabled="!isValid" :loading="is.saving" text="Confirm" v-on:click="onConfirmClick" />
		<app-button v-if="is.saved" :locked="true" text="Submitted" theme="green" />
		
	</div>

</div>
	
</template>

<script>

import Vue from 'vue'

import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

export default {

	mixins: [validationMixin],

	props: ['item', 'endpoint', 'skipConfirm', 'params'],

	data: function() {

		return {
			is: {
				saving: false,
				saved: false
			},
			model: {},
			messages: {}
		}

	},

	validations: function() {

		var validations = {
			model: {}
		}

		this.$_.each(this.inputs, function(input) {

			Vue.set(validations.model, input.id, {})

			if (input.required) {

				Vue.set(validations.model[input.id], 'required', required)

			}

			if (input.validation === this.$constants.input.validation.email) {

				Vue.set(validations.model[input.id], 'email', email)

			}

		}.bind(this))

		return validations

	},

	created: function() {

		this.$_.each(this.inputs, function(input) {

			var defaultValue = ''

			if (input.type === this.$constants.input.type.toggle || input.type === this.$constants.input.type.select) {

				defaultValue = 0

			} else if (input.type === this.$constants.input.type.checklist) {

				defaultValue = []

			} else if (input.type === this.$constants.input.type.textbox) {

				if (input.role === this.$constants.input.role.firstname) {

					defaultValue = this.$store.getters['session/firstname']

				} else if (input.role === this.$constants.input.role.lastname) {

					defaultValue = this.$store.getters['session/lastname']

				} else if (input.role === this.$constants.input.role.email) {

					defaultValue = this.$store.getters['session/email']

				}

			}

			Vue.set(this.model, input.id, defaultValue)

		}.bind(this))

		if (this.data) Vue.set(this, 'model', this.$util.copy(this.data))

	},

	computed: {

		inputs: function() {

			return this.item.inputs

		},

		isValid: function() {

			return !this.$v.$invalid 

		}

	},

	watch: {

		model: {

			deep: true,

			handler: function() {

				this.onChange()

			}

		}

	},

	methods: {

		onChange: function() {

			this.$emit('change', {
				model: this.model,
				valid: this.isValid
			})

		},

		onConfirmClick: function() {

			if(this.isValid && !this.skipConfirm) {
				
				this.is.saving = true

				var params = this.$util.copy(this.params || {})

				params.model = this.model

				this.$api.post(this.endpoint, params).then(function() {

					this.is.saving = false
					this.is.saved = true

				}.bind(this), function() {

					this.is.saving = false

				}.bind(this))

			}

		}

	}

}

</script>

<style scoped>

</style>