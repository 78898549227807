<template>

<com-page :loading="is.loading">

	<router-view :key="$keyPath" />

</com-page>

</template>

<script>

export default {

	name: 'App',

	components: {

		'com-page': () => import('./screen/public/Page')

	},

	data: function() {

		return {
			is: {
				initiated: false,
				loading: true
			}
		}

	},

	created: function() {

		this.load()

	},

	methods: {

		load: async function() {

			if (!this.is.initiated) {

				await this.$api.get('check')

				this.is.initiated = true

				this.is.loading = false
					
			}

		}

	}

}

</script>

<style>

</style>
