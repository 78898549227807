<template>

<app-content-box title="Latest events" :is-grid="4" icon="lobbyEvents" :blank="true" :not-padded="true" class="events" :class="{'is-loading': is.loading}">

	<app-widget-event v-for="event in amendedCollection" :loading="is.loading" :key="event.id" :event="event" :is-grid="true" />

</app-content-box>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {
	
	mixins: [mixCollection],

	props: ['id'],

	data: function() {

		return {
			pagination: false,
			inline: true,
			is: {
				loading: true
			},
			filter: {
				recent: true
			},
			live: 'convention/schedule/event'
		}

	},

	computed: {

		endpoint: function() {

			return 'convention/schedule/events/' + this.id

		},

		amendedCollection: function() {

			var events = []

			this.$_.each(this.collection, function(event) {

				events.push(event)
			
			}.bind(this))

			var filler = this.collection.length || 0

			for (var i=filler+ 1; i <= 4; i++ ) {

				events.push(false)

			}

			return events

		}

	}

}

</script>

<style scoped>

.events {
	grid-column: 1 / span 2;
}

.is-mobile .events {
	grid-column: 1 / span 4;
}

@keyframes fade { 
	0% { opacity: 1; }
	25% { opacity: 0.25; }
	50% { opacity: 1; }
	100% { opacity: 1; }
}

.events.is-loading >>> .event {
	opacity: 1;
	animation: fade 2000ms ease-in-out;
	animation-iteration-count: infinite;
}

.events.is-loading >>> .event:nth-child(1) {
	animation-delay: 300ms;
}

.events.is-loading >>> .event:nth-child(2) {
	animation-delay: 600ms;
}

.events.is-loading >>> .event:nth-child(3) {
	animation-delay: 900ms;
}

.events.is-loading >>> .event:nth-child(4) {
	animation-delay: 1200ms;
}

</style>
