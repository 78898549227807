<template>

<app-input :wide="wide" v-on:toggle="$emit('toggle')" :warning="warning" :locked="locked" :label="label" :stacked="stacked" :no-line="true" :toggle="toggle" :notes="notes" :disabled="disabled" :validation="validation">

	<div v-if="isKeyed && optionCount" class="toggle" :class="{'is-slim': slim, 'is-fullwidth': fullWidth, 'is-stacked': (stacked || column) && !row}">
		<div v-tooltip="(tooltips) ? tooltips[index] : false" class="toggle-item" v-for="(option, index) in finalOptions" :key="option[optionValueField]" :class="{'is-active': isSelected(option[optionValueField])}" v-on:click="onClick(option[optionValueField])">
			{{ option.text }}
			<app-icon icon="tick" class="toggle-item-icon" v-if="multiple" />
		</div>
	</div>

	<div v-if="!isKeyed && optionCount" class="toggle" :class="{'is-slim': slim, 'is-fullwidth': fullWidth, 'is-stacked': (stacked || column) && !row}">
		<div v-tooltip="(tooltips) ? tooltips[key] : false" class="toggle-item" v-for="(option, key) in finalOptions" :key="key" :class="{'is-active': isSelected(key)}" v-on:click="onClick(key)">
			{{ option }}
			<app-icon icon="tick" class="toggle-item-icon" v-if="multiple" />
		</div>
	</div>

	<div v-if="!optionCount" class="toggle is-empty">

		<div class="toggle-item">
		
			No options available.

		</div>

	</div>

</app-input>

</template>

<script>

export default {

	props: ['validation', 'wide', 'valueField', 'warning', 'label', 'asString', 'locked', 'value', 'toggle', 'tooltips', 'multiple', 'descriptions', 'stacked', 'notes', 'column', 'row', 'fullWidth', 'slim', 'placeholder', 'options', 'disabled'],

	computed: {

		optionCount: function() {

			if (this.$_.isObject(this.finalOptions)) {

				return this.$_.keys(this.finalOptions).length

			} else {

				return this.finalOptions.length

			}

		},

		isKeyed: function() {

			return (this.options) ? this.$_.isObject(this.options[this.$_.keys(this.options)[0]]) : false

		},

		optionValueField: function() {

			return this.valueField || 'value'

		},

		finalOptions: function() {

			if (this.options) {

				if (this.$_.isObject(this.options)) {

					return this.options

				} else {

					return this.options

				}

			}

			return {
				1: 'Yes',
				0: 'No'
			}

		}

	},

	methods: {

		onClick: function(value) {

			if (this.multiple) {

				var original = (this.asString) ? this.$util.array.string(this.$_.clone(this.value)) : this.$util.array.int(this.$_.clone(this.value))

				if (this.$_.contains(original, (this.asString) ? value.toString() : parseInt(value))) {

					original.splice(original.indexOf((this.asString) ? value.toString() : parseInt(value)), 1)

				} else {

					original.push((this.asString) ? value.toString() : parseInt(value))

				}

				this.$emit('input', original)

			} else {

				this.$emit('input', (this.asString) ? value.toString() : parseInt(value))

			}

		},

		isSelected: function(value) {

			if (this.multiple) {

				return (this.asString) ? this.$_.contains(this.$util.array.string(this.value), value.toString()) : this.$_.contains(this.$util.array.int(this.value), parseInt(value))

			} else {

				return (this.asString) ? this.value.toString() === value.toString() : parseInt(this.value) === parseInt(value)

			}

		}

	}

}

</script>

<style scoped>

.toggle {
	display: flex;
	flex-direction: row;
	height: 40px;
	border-radius: 10px;
}

.toggle.is-stacked,
.is-mobile .toggle {
	flex-direction: column;
	height: auto;
}

.toggle-item {
	font-size: 14px;
	color: #eee;
	border: 4px solid #eee;
	border-width: 2px 2px 2px 0px;
	color: #333;
	min-width: 100px;
	padding: 0px 10px;
	cursor: pointer;
	text-align: center;
	background-color: #fff;
	line-height: 36px;
	user-select: none;
	display: flex;
	justify-content: center;
}

.toggle.is-empty .toggle-item {
	justify-content: flex-start;
	pointer-events: none;
	color: #666;
	width: 100%;
}

.toggle-item:not(.is-active):hover {
	background-color: #eee;
}

.toggle.is-fullwidth .toggle-item {
	flex-grow: 1;
	flex-shrink: 0;
	min-width: 0px;
}

.toggle.is-stacked .toggle-item {
	text-align: left;
	justify-content: space-between;
}

.toggle.is-stacked .toggle-item,
.is-mobile .toggle-item {
	border-width: 2px 2px 0px 2px;
}

.toggle.is-stacked .toggle-item,
.is-device .toggle-item {
	font-size: 14px;
	border-radius: 0px;
}

.toggle.is-slim .toggle-item {
	width: auto;
	padding: 0px 20px;
	min-width: auto;
}

.input-content:not(.is-disabled) .toggle-item.is-active {
	background-color: #4082d3;
	color: #fff;
	border-color: #4083d3;
}

.toggle-item:first-child {
	border-left-width: 2px;
	border-radius: 12px 0px 0px 12px;
}

.toggle.is-stacked .toggle-item:first-child,
.is-mobile .toggle-item:first-child {
	border-radius: 12px 12px 0px 0px;
}

.toggle-item:last-child {
	border-radius: 0px 12px 12px 0px;
	border-bottom-width: 2px;
}

.toggle.is-stacked .toggle-item:last-child,
.is-mobile .toggle-item:last-child {
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
	border-bottom-width: 2px;
}

.toggle-item:first-child:last-child {
	border-radius: 10px;
}

.toggle-item-icon {
	margin-left: 10px;
	color: #eee;
	font-size: 16px;
}

.toggle-item:not(.is-active):hover .toggle-item-icon {
	color: #ccc;
}

.toggle-item-icon.is-active {
	color: #fff;
}

</style>
