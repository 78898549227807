<template>

<div class="addons">
	
	<div class="addons-items">

		<div class="addons-items-item" v-for="(addon, index) in item.items" :key="addon.id">
			
			<div class="addons-items-item-image" v-tooltip="'Click to enlarge'" v-bgimage="addon.image" v-on:click="onEnlargeClick(index)">
				
				<app-icon icon="enlarge" class="addons-items-item-image-enlarge" />

			</div>

			<div class="addons-items-item-info">

				<div class="addons-items-item-name">{{ addon.name }}</div>
				<div class="addons-items-item-price" v-if="addon.count.variants !== false &&  addon.count.variants > 1">From {{ addon.price | formatMoney }} ({{ addon.count.variants }} options)</div>
				<div class="addons-items-item-price" v-if="addon.count.variants === false || addon.count.variants === 1">{{ addon.price | formatMoney }}</div>
				<div class="addons-items-item-description">{{ addon.description }}</div>

				<app-button v-if="addon.count.variants" :text="hasAdded(addon.id) ? 'Added' : 'Add'" :theme="hasAdded(addon.id) ? 'green' : 'blue'" class="addons-items-item-button" :options="generateOptions(addon.id, addon.variants)" optionValue="value" optionText="text" v-on:option="onVariantClick(addon, $event)" />
				<app-button v-if="!addon.count.variants" :text="hasAdded(addon.id) ? 'Added' : 'Add'" :theme="hasAdded(addon.id) ? 'green' : 'blue'" class="addons-items-item-button" v-on:click="onItemClick(addon)" />

			</div>

		</div>

	</div>

	<div class="addons-assign" v-if="is.assigning">
		
		<div class="addons-assign-box">

			<div class="addons-assign-box-head">

				<div class="addons-assign-box-head-image" v-bgimage="is.assigning.item.image" />

				<div class="addons-assign-box-head-text">
					<div class="addons-assign-box-head-text-name">{{ is.assigning.item.name }}</div>
					<div class="addons-assign-box-head-text-variant" v-if="is.assigning.variant">{{ is.assigning.variant.name }}</div>
				</div>
				
			</div>

			<div class="addons-assign-box-content">

				<div class="addons-assign-box-content-item">
					<div class="addons-assign-box-content-item-count">Quantity x {{ quantity }}</div>
					<app-icon v-on:click.native.stop="onAssignRemove" icon="minus" class="addons-assign-box-content-item-action" v-if="quantity" />
					<app-icon v-on:click.native.stop="onAssignAdd" icon="plus" class="addons-assign-box-content-item-action" />
				</div>

			</div>

			<div class="addons-assign-box-foot">

				<app-button text="Confirm" v-on:click="onAssignConfirmClick" />
				<app-button text="Cancel" theme="red" v-on:click="is.assigning = false" />

			</div>

		</div>

	</div>

	<div class="addons-enlarged" v-if="is.enlarged">
		
		<div class="addons-enlarged-background" v-bgimage="item.items[is.enlarged - 1].image" />
		<div class="addons-enlarged-close" v-on:click="onCloseClick"><app-icon icon="close" /></div>

	</div>

</div>
	
</template>

<script>

export default {

	props: ['item'],

	data: function() {

		return {
			is: {
				enlarged: false,
				assigning: false
			},
			quantity: 0,
			selected: [],
			items: []
		}

	},

	watch: {

		items: {

			deep: true,

			handler: function(n) {

				this.$store.commit('register/addons', n)

			}

		}

	},

	created: function() {

		this.items = this.$store.getters['register/addons']

	},

	methods: {

		hasAdded: function(id, variant) {

			var added = this.$_.filter(this.items, function(addon) {

				return addon.item.id === id && (variant === undefined || addon.variant.id === variant)

			})

			return (added.length) ? added[0] : false

		},

		generateOptions: function(id, variants) {

			var options = []

			this.$_.each(variants, function(variant) {

				options.push({
					value: variant.id,
					text: variant.name + ' - ' + this.$options.filters.formatMoney(variant.price),
					status: (this.hasAdded(id, variant.id)) ? 'green' : 'blue'
				})

			}.bind(this))

			return options

		},

		onItemClick: function(item) {
			
			if (this.hasAdded(item.id)) {

				this.quantity = this.hasAdded(item.id).quantity

				this.is.assigning = {
					id: item.id,
					item: item,
					variant: false
				}

			} else {

				this.items.push({
					id: item.id,
					item: item,
					variant: false,
					quantity: 1
				})

			}

		},

		onVariantClick: function(item, variant_id) {

			var variant = false

			this.$_.each(item.variants, function(itemVariant) {

				if (itemVariant.id === variant_id) variant = itemVariant

			})

			if (!variant) return false

			if (this.hasAdded(item.id, variant.id)) {

				this.quantity = this.hasAdded(item.id, variant.id).quantity

				this.is.assigning = {
					id: variant.id,
					item: item,
					variant: variant
				}

			} else {

				this.items.push({
					id: variant.id,
					item: item,
					variant: variant,
					quantity: 1
				})

			}

		},

		onCloseClick: function() {

			this.is.enlarged = false

		},

		onEnlargeClick: function(index) {

			this.is.loading = true

			this.is.enlarged = index + 1

		},

		onAssignConfirmClick: function() {

			while (this.$util.collection.remove(this.items, this.is.assigning.id) !== false) {

				//

			}

			if (this.quantity) {

				this.items.push({
					id: this.is.assigning.id,
					item: this.is.assigning.item,
					variant: this.is.assigning.variant,
					quantity: this.quantity
				})

			}

			this.is.assigning = false

		},

		onAssignAdd: function() {

			this.quantity++

		},

		onAssignRemove: function() {

			if (this.quantity > 0) this.quantity--

		}

	}
	
}

</script>

<style scoped>

.addons {
	flex-direction: column;
	padding: 10px 40px 0px 40px;
}

.is-mobile .addons {
	padding: 10px 10px 0px 10px;
}

.addons-items {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
}

.is-mobile .addons-items {
	flex-direction: column;
}

.addons-items-item {
	width: calc(50% - 20px);
	margin: 10px;
	display: flex;
	flex-direction: row;
}

.is-mobile .addons-items-item {
	width: 100%;
	margin: 0px 0px 20px 0px;
}

.addons-items-item-image {
	width: 240px;
	cursor: pointer;
	height: 240px;
	border: 2px dashed #ccc;
	flex-shrink: 0;
	border-radius: 10px;
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.is-mobile .addons-items-item-image {
	width: 120px;
	height: 120px;
}

.addons-items-item-image:hover {
	border-color: #666;
}

.addons-items-item-image-enlarge {
	position: absolute;
	left: 0px;
	top: 0px;
	font-size: 24px;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	color: #387DD8;
}

.is-mobile .addons-items-item-image-enlarge {
	font-size: 16px;
	width: 24px;
	height: 24px;
	line-height: 24px;
}

.addons-items-item-info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 10px 0px 10px 10px;
	flex-grow: 1;
}

.is-mobile .addons-items-item-info {
	padding: 0px 0px 0px 10px;
}

.addons-items-item-name {
	font-size: 16px;
	color: #343434;
	font-weight: 700;
}

.addons-items-item-price {
	font-size: 14px;
	color: #343434;
	font-weight: 400;
	margin-top: 4px;
}

.addons-items-item-description {
	flex-grow: 1;
	font-size: 14px;
	line-height: 16px;
	color: #343434;
	font-weight: 400;
	margin-top: 10px;
}

.addons-items-item-button {
	margin: 10px 0px 0px 0px;
}

.addons-assign,
.addons-enlarged {
	position: fixed;
	background-color: rgba(0, 0, 0, 0.8);
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;
}

.addons-enlarged-background {
	width: calc(100% - 128px);
	height: calc(100% - 128px);
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.is-mobile .addons-enlarged-background {
	width: calc(100% - 20px);
	height: calc(100% - 20px);
}

.addons-enlarged-close {
	position: absolute;
	top: 50%;
	width: 40px;
	height: 40px;
	background-color: #4082d3;
	margin-top: -20px;
	z-index: 2;
	line-height: 40px;
	text-align: center;
	color: #fff;
	font-size: 24px;
	cursor: pointer;
	transition: width 100ms linear;
	top: 0px;
	margin-top: 0px;
	right: 0px;
}

.addons-assign-box {
	background-color: #fff;
	border-radius: 4px;
	max-width: 480px;
	width: calc(100% - 20px);
}

.addons-assign-box-head {
	border-bottom: 1px solid #eee;
	padding: 10px;
	display: flex;
}

.addons-assign-box-head-image {
	width: 40px;
	height: 40px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	margin-right: 10px;
}

.addons-assign-box-head-text {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
}

.addons-assign-box-head-text-name {
	font-size: 16px;
	color: #343434;
	font-weight: 700;
}

.addons-assign-box-head-text-variant {
	font-size: 14px;
	color: #343434;
	font-weight: 400;
	margin-top: 4px;
}

.addons-assign-box-content-text {
	font-size: 14px;
	color: #343434;
	font-weight: 400;
	padding: 10px;
}

.addons-assign-box-content-item {
	border-top: 1px solid #eee;
	font-size: 14px;
	color: #343434;
	font-weight: 400;
	padding: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

.addons-assign-box-content-item-count {
	margin-left: 10px;
	margin-right: 10px;
	flex-grow: 1;
	flex-basis: 0;
}

.addons-assign-box-content-item-action {
	margin-left: 10px;
}

.addons-assign-box-content-item:hover {
	background-color: #387DD8;
	color: #fff;
}

.addons-assign-box-content-item >>> .icon {
	font-size: 16px;
	color: #ccc;
}

.addons-assign-box-content-item:hover >>> .icon {
	color: rgba(255, 255, 255, 0.25);
}

.addons-assign-box-content-item.is-active >>> .icon {
	font-size: 16px;
	color: #387DD8;
}

.addons-assign-box-content-item.is-active:hover >>> .icon {
	color: #fff;
}

.addons-assign-box-foot {
	border-top: 1px solid #eee;
	padding: 10px;
	display: flex;
	justify-content: center;
}

.addons-assign-box-foot >>> .button {
	margin: 0px 5px;
}

</style>