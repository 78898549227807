<template>

<div class="title">

	{{ text }}

</div>

</template>

<script>

export default {

	props: ['text']

}

</script>

<style scoped>

.title {
	color: #8896aa;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-size: 14px;
	font-weight: 400;
	margin: 30px 0px 10px 0px;
	padding: 0px 20px;
}

.title:first-child {
	margin-top: 20px;
}

.theme-green .title {
	color: #fff;
}

</style>
