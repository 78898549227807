<template>

<div class="badges">

	<div class="badges-item" :class="{'is-selected': isSelected(badge.id)}" v-for="(badge, index) in badges" :key="index" v-on:click="$emit('change', badge)">

		<div class="badges-item-name">{{ badge.name }}<small v-if="badge.description">{{ badge.description }}</small></div>
		<app-icon class="badges-item-icon" icon="tick" v-if="isSelected(badge.id)" />
		<div class="badges-item-cost">{{ badge.price | formatMoney }}</div>

	</div>

</div>
	
</template>

<script>

export default {

	props: ['badges', 'selected'],

	methods: {

		isSelected: function(id) {

			return this.$_.contains(this.selected, id)

		}
		
	}

}

</script>

<style scoped>

.badges {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 20px;
	padding-top: 20px;
}

.is-mobile .badges {
	flex-direction: column;
	padding-top: 0px;
}

.badges-item {
	background-color: #fff;
	border: 2px dashed #ccc;
	padding: 10px;
	margin: 10px;
	width: calc(33.3% - 20px);
	min-height: 100px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	cursor: pointer;
	color: #343434;
}

.is-mobile .badges-item {
	width: 100%;
	margiN: 0px 0px 10px 0px;
}

.badges-item:hover {
	border-color: #999;
}

.badges-item.is-selected {
	border: 2px solid #387DD8;
	background-color: #387DD8;
	color: #fff;
}

.badges-item-name {
	font-size: 16px;
	padding-right: 32px;
	font-weight: 700;
	text-transform: uppercase;
	flex-grow: 1;
}

.badges-item-icon {
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 24px;
	color: #fff;
}

.badges-item-name small {
	font-size: 14px;
	font-weight: 400;
	display: block;
	opacity: 0.8;
	line-height: 14px;
	margin-top: 4px;
	text-transform: initial;
}

.badges-item-cost {
	text-align: right;
	font-size: 32px;
	margin-top: 10px;
}

</style>