<template>

<app-input class="content-wrapper" :class="{'as-textbox': asTextbox}" v-on:toggle="onToggle" :prefix="prefix" :toggle="toggle" :label="label" :locked="locked" :stacked="stacked" :no-line="noLine" :notes="notes" :disabled="disabled" :validation="validation">

	<div class="toolbar">
		<app-icon icon="toolbar.bold" :class="{'is-active': editor.isActive('bold')}" v-on:click.native="onActionClick('bold')" class="toolbar-item" />
		<app-icon icon="toolbar.italic" v-on:click.native="onActionClick('italic')" class="toolbar-item" />
		<app-icon icon="toolbar.underline" v-on:click.native="onActionClick('underline')" class="toolbar-item" />
		<app-icon icon="toolbar.link" v-on:click.native="onActionClick('link')" class="toolbar-item" />
		<app-icon icon="toolbar.format" v-on:click.native="onActionClick('format')" class="toolbar-item" />
	</div>

	<div ref="editor" class="content" :data-theme="theme || 'default'">
		<editor-content :editor="editor" />
	</div>

	<com-link v-if="panel === 'link'" :editor="editor" :selectedText="selectedText" v-on:close="panel = false" />
	<com-format v-if="panel === 'format'" :editor="editor" :selectedText="selectedText" v-on:close="panel = false" />

</app-input>

</template>

<script>

import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'
import Heading from '@tiptap/extension-heading'

import comLink from './content/Link'
import comFormat from './content/Format'

export default {

	props: ['label', 'password', 'toggle', 'theme', 'asTextbox', 'locked', 'prefix', 'validation', 'numeric', 'autogrow', 'disabled', 'value', 'noLine', 'stacked', 'placeholder', 'notes', 'maxlength'],

	components: {
		EditorContent,
		comLink,
		comFormat
	},

	data: function() {

		return {
			panel: false,
			editor: null
		}

	},

	created: function() {

		this.editor = new Editor({
			
			content: this.value,

			extensions: [
				StarterKit,
				Link.configure({
					autolink: true,
					openOnClick: false
				}),
				Heading,
				Underline,
				Placeholder.configure({
					placeholder: this.placeholder
				})
			],

			onCreate: function() {

				this.$refs.editor.addEventListener('click', this.onEditorClick.bind(this))

			}.bind(this),

			onDestory: function() {

				this.$refs.editor.removeEventListener('click', this.onEditorClick.bind(this))

			}.bind(this),

			onUpdate: function() {

				this.$emit('input', this.editor.getHTML())

			}.bind(this)

		})

	},

	beforeDestroy: function() {

		this.editor.destroy()

	},

	watch: {

		value: function(n) {

			if (this.editor.getHTML() === n) return false

			this.editor.commands.setContent(n, false)

		}

	},

	computed: {

		selectedText: function() {

			return this.editor.state.doc.textBetween(this.editor.state.selection.from, this.editor.state.selection.to, ' ')

		}

	},

	methods: {

		onEditorClick: function(e) {

			e.stopPropagation()

			if (e.target.tagName === 'A') {

				this.panel = 'link'

			}

		},

		onActionClick: function(action) {

			if (action === 'bold') {

				this.editor.chain().focus().toggleBold().run()

			} else if (action === 'italic') {

				this.editor.chain().focus().toggleItalic().run()

			} else if (action === 'underline') {

				this.editor.chain().focus().toggleUnderline().run()

			} else if (action === 'link') {

				this.panel = action

			} else if (action === 'format') {

				this.panel = action

			}


		},

		onToggle: function() {

			this.$emit('toggle')
			if (!this.toggle) this.$refs.input.focus()

		}

	}

}

</script>

<style scoped>

.toolbar {
	display: flex;
	width: 100%;
	justify-content: center;
	overflow: hidden;
	border: 2px solid #eee;
	border-radius: 10px 10px 0px 0px;
	border-bottom: 0px;
}

.content {
	border: 2px solid #eee;
	padding: 20px;
	border-radius: 0px 0px 10px 10px;
}

.content.is-focus {
	border-color: #4082d3;
}

.content-wrapper.as-textbox .toolbar {
	border-radius: 10px 10px 0px 0px;
	margin-bottom: 0px;
	background-color: #eee;
	justify-content: flex-start;
}

.toolbar-item {
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	cursor: pointer;
	border-radius: 4px;
}

.content-wrapper.as-textbox .toolbar-item {
	border-top: 0px;
	line-height: 42px;
	border-bottom: 0px;
}

.toolbar-item:first-child {
}

.content-wrapper.as-textbox .toolbar-item:first-child {
	border-radius: 0px;
}

.toolbar-item:last-child {
}

.content-wrapper.as-textbox .toolbar-item:last-child {
	border-right: 0px;
	border-radius: 0px;
}

.toolbar-item.is-active {
	color: #4082d3;
}

.toolbar-item:hover {
	background-color: #4082d3;
	border-right: 1px solid #4082d3;
	color: #fff;
}

.content-wrapper.as-textbox .content {
	background-color: #fff;
	border-radius: 0px 0px 10px 10px;
	border: 2px solid #eee;
	padding: 10px;
	border-top: 0px;
}

.content >>> .ProseMirror {        
	min-height: 200px;
}

.content-wrapper.as-textbox .content >>> .ProseMirror {   
	min-height: 20px;
}

.content >>> .ProseMirror p.is-editor-empty {
	height: 100%;
	display: block;
	position: absolute;
	left: 0px;
	width: 100%;
}

.content >>> .ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: 400;
  color: #ddd;
}

.content-wrapper.as-textbox .content >>> .ProseMirror p.is-editor-empty:first-child::before {
	font-size: 16px;
	font-weight: 300;
	left: 0px;
	color: #ccc;
	transform: translate(0%, -50%);
}

.content[data-theme="default"] >>> h1 {
	font-size: 32px;
	line-height: 40px;
	font-weight: 700;
	margin-bottom: 20px;
	color: #343434;
	text-align: center;
}

.content[data-theme="default"] >>> h2 {
	font-size: 24px;
	line-height: 32px;
	font-weight: 500;
	margin-bottom: 20px;
	color: #343434;
	text-align: center;
}

.content[data-theme="default"] >>> p {
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 20px;
	color: #343434;
	text-align: center;
}

.content[data-theme="information"] >>> h1 {
	font-size: 32px;
	line-height: 40px;
	font-weight: 700;
	margin-bottom: 20px;
	color: #343434;
	text-align: left;
}

.content[data-theme="information"] >>> h2 {
	font-size: 24px;
	line-height: 32px;
	font-weight: 500;
	margin-bottom: 20px;
	color: #343434;
	text-align: left;
}

.content[data-theme="information"] >>> p {
	font-size: 16px;
	line-height: 20px;
	font-weight: 300;
	margin-bottom: 20px;
	color: #343434;
	text-align: left;
}

.content >>> a {
	color: #387DD8;
	font-weight: 700;
}

.content >>> a:hover {
	text-decoration: underline;
	cursor: pointer;
}

.content >>> *:last-child {
	margin-bottom: 0px;
}

.content >>> em,
.content >>> i {
	font-style: italic;
}

</style>
