<template>

<div class="menu" :class="{'is-submenu': isActiveSubmenu}">

	<template v-if="!isActiveSubmenu && $organisation && !$convention">

		<com-item v-if="$authorised('org')" route="Organisation.Dashboard" text="Dashboard" icon="dashboard" />
		<com-item v-if="$authorised('org/conventions')" route="Organisation.Conventions" text="Conventions" icon="conventions" />
		<com-item v-if="$authorised('org/library')" route="Organisation.Library" text="Library" icon="library" />
		<com-item v-if="$authorised('org/billing')" route="Organisation.Billing" text="Billing" icon="billing" />
		<com-item v-if="$authorised('org/team')" route="Organisation.Team.Members" text="Team" icon="team" />
		<com-item v-if="$authorised('org/settings')" route="Organisation.Settings" text="Settings" icon="settings" />

		<com-divide :fill="true" v-if="window.is.desktop" />

		<com-external url="https://docs.cardboardevents.com" iconAfter="external" text="User Guide" />

	</template>

	<template v-if="!isActiveSubmenu && $convention">

		<com-item v-if="$authorised('con')" route="Convention.Dashboard" text="Dashboard" icon="dashboard" />

		<com-title text="Convention" v-if="$authorised(['con/announcements', 'con/badges', 'con/library'])" />

		<com-item v-if="$authorised('con/announcements') && $convention.setup.service.schedule" route="Convention.Announcements.Messages" text="Announcements" icon="announcements" :count="$convention.count.announcements" />
		<com-item v-if="$authorised('con/badges')" route="Convention.Badges" text="Badges" icon="badges" :count="$convention.count.badges.holders" />
		<com-item v-if="$authorised('con/volunteers') && $convention.setup.service.schedule" route="Convention.Volunteers.Roles" text="Volunteers" icon="volunteers" :count="$convention.count.volunteers.assignments" />
		<com-item v-if="$authorised('con/pages') && $convention.setup.service.schedule" route="Convention.Pages" text="Pages" icon="pages" :count="$convention.count.pages.pages" />
		<com-item v-if="$authorised('con/library') && !$convention.setup.service.library && $convention.setup.service.checkout" route="Convention.Library" text="Games" icon="games" :count="$convention.count.games" />

		<com-title text="Services" v-if="$authorised(['con/registration', 'con/schedule', 'con/checkout', 'con/library']) && $setup(['service/registration', 'service/schedule', 'service/checkout', 'service/library'])" />

		<com-item v-if="$authorised('con/registration') && $setup('service/registration')" route="Convention.Registration" text="Registration" icon="registration" :count="$convention.count.registration.sales" />
		<com-item v-if="$authorised('con/schedule') && $setup('service/schedule')" route="Convention.Schedule" text="Schedule" icon="schedule" :count="$convention.count.schedule.events" />
		<com-item v-if="$authorised('con/checkout') && $setup('service/checkout')" route="Convention.Checkout" text="Checkout" icon="checkout" :count="$convention.count.checkout.active" />
		<com-item v-if="$authorised('con/library') && $setup('service/library')" route="Convention.Library" text="Library" icon="library" :count="$convention.count.games" />

		<com-title text="Other" v-if="$authorised(['con/billing', 'con/team', 'con/settings'])" />

		<com-item v-if="$authorised('con/assistant')" route="Convention.Assistant.Logins" text="Assistant" icon="assistant" />
		<com-item v-if="$authorised('con/billing')" route="Convention.Billing" text="Billing" icon="billing" />
		<com-item v-if="$authorised('con/settings')" route="Convention.Settings" text="Settings" icon="settings" />

		<com-divide :fill="true" />

		<com-external url="https://docs.cardboardevents.com" iconAfter="external" text="User Guide" />

		<com-divide />

		<com-item v-if="$authority.type !== $constants.team.members.type.convention" route="Organisation.Dashboard" :params="{organisation: $organisation.slug}" :text="'Back to ' + $organisation.name" icon="back" />

	</template>
	
	<template v-if="isActiveSubmenu">

		<com-submenu-registration v-if="submenu === 'Convention.Registration'" />
		<com-submenu-badges v-if="submenu === 'Convention.Badges'" />
		<com-submenu-volunteers v-if="submenu === 'Convention.Volunteers'" />
		<com-submenu-pages v-if="submenu === 'Convention.Pages'" />
		<com-submenu-checkout v-if="submenu === 'Convention.Checkout'" />
		<com-submenu-library v-if="submenu === 'Convention.Library' || submenu === 'Organisation.Library'" />
		<com-submenu-schedule v-if="submenu === 'Convention.Schedule'" />
		<com-submenu-billing v-if="submenu === 'Convention.Billing' || submenu === 'Organisation.Billing'" />

	</template>

	<div class="menu-sidebar" :class="{'is-active': isActiveSubmenu}" v-if="$convention">

		<com-icon v-if="$authorised('con')" route="Convention.Dashboard" text="Dashboard" icon="dashboard" :divide="true" />
		<com-icon v-if="$authorised('con/announcements') && $convention.setup.service.schedule" route="Convention.Announcements.Messages" text="Announcements" icon="announcements" />
		<com-icon v-if="$authorised('con/badges')" route="Convention.Badges" text="Badges" icon="badges" />
		<com-icon v-if="$authorised('con/volunteers') && $convention.setup.service.schedule" route="Convention.Volunteers.Roles" text="Volunteers" icon="volunteers" />
		<com-icon v-if="$authorised('con/pages') && $convention.setup.service.schedule" route="Convention.Pages" text="Pages" icon="pages" />
		<com-icon v-if="$authorised('con/library') && !$convention.setup.service.library && $convention.setup.service.checkout" route="Convention.Library" text="Games" icon="games" :divide="true" />
		<com-icon v-if="$authorised('con/registration') && $setup('service/registration')" route="Convention.Registration" text="Registration" icon="registration" />
		<com-icon v-if="$authorised('con/schedule') && $setup('service/schedule')" route="Convention.Schedule" text="Schedule" icon="schedule" />
		<com-icon v-if="$authorised('con/checkout') && $setup('service/checkout')" route="Convention.Checkout" text="Checkout" icon="checkout" />
		<com-icon v-if="$authorised('con/library') && $setup('service/library')" route="Convention.Library" text="Library" icon="library" :divide="true" />
		<com-icon v-if="$authorised('con/assistant')" route="Convention.Assistant.Logins" text="Assistant" icon="assistant" />
		<com-icon v-if="$authorised('con/billing')" route="Convention.Billing" text="Billing" icon="billing" />
		<com-icon v-if="$authorised('con/settings')" route="Convention.Settings" text="Settings" icon="settings" />

		<com-divide :fill="true" />

		<com-icon v-if="$authority.type !== $constants.team.members.type.convention" route="Organisation.Dashboard" :params="{organisation: $organisation.slug}" :text="'Back to ' + $organisation.name" icon="back" />

	</div>

	<div class="menu-sidebar" :class="{'is-active': isActiveSubmenu}" v-if="$organisation && !$convention">

		<com-icon v-if="$authorised('org')" route="Organisation.Dashboard" text="Dashboard" icon="dashboard" />
		<com-icon v-if="$authorised('org/conventions')" route="Organisation.Conventions" text="Conventions" icon="conventions" />
		<com-icon v-if="$authorised('org/library')" route="Organisation.Library" text="Library" icon="library" />
		<com-icon v-if="$authorised('org/billing')" route="Organisation.Billing" text="Billing" icon="billing" />
		<com-icon v-if="$authorised('org/team')" route="Organisation.Team.Members" text="Team" icon="team" />
		<com-icon v-if="$authorised('org/settings')" route="Organisation.Settings" text="Settings" icon="settings" />

	</div>

	<slot></slot>

</div>

</template>

<script>

export default {

	components: {

		'com-submenu-registration': () => import('./submenu/Registration'),
		'com-submenu-badges': () => import('./submenu/Badges'),
		'com-submenu-checkout': () => import('./submenu/Checkout'),
		'com-submenu-library': () => import('./submenu/Library'),
		'com-submenu-volunteers': () => import('./submenu/Volunteers'),
		'com-submenu-schedule': () => import('./submenu/Schedule'),
		'com-submenu-billing': () => import('./submenu/Billing'),
		'com-submenu-pages': () => import('./submenu/Pages'),

		'com-title': () => import('../panel/common/Title'),
		'com-divide': () => import('../panel/common/Divide'),
		'com-external': () => import('../panel/common/External'),
		'com-icon': () => import('../panel/common/Icon'),
		'com-item': () => import('../panel/common/Item')
		
	},

	computed: {

		organisations: function() {

			return this.$store.getters['session/organisations']

		},

		isActiveSubmenu: function() {

			return this.submenu

		},

		submenu: function() {

			return this.$route.meta.submenu

		}

	}

}

</script>

<style scoped>

.menu {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	padding-bottom: 5px;
}

.menu.is-submenu {
	padding-left: 40px;
}

.menu-sidebar {
	position: absolute;
	left: -40px;
	top: 0px;
	bottom: 0px;
	display: flex;
	flex-direction: column;
	background-color: #2f3a4a;
	width: 40px;
	transition: left 100ms linear;
}

.menu-sidebar.is-active {
	left: 0px;
}

</style>
