<template>

<a class="feature" target="_blank" :href="item.url">

	<div class="feature-title">{{ item.title }}<small v-if="item.description">{{ item.description }}</small></div>

	<app-icon icon="chevron.right" class="feature-chevron" />
	
</a>
	
</template>

<script>

export default {

	props: ['item']

}

</script>

<style scoped>

.feature {
	padding: 20px 20px;
	border: 1px solid #eee;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px;
	background-color: #fff;
	border-radius: 4px;
	display: flex;
	align-items: center;
	cursor: pointer;
	color: #687589;
}

.feature:hover {
	background-color: #ffbe7a;
}

.feature-title {
    font-size: 18px;
    font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 1px;
	flex-grow: 1;
}

.feature-title small {
	display: block;
	font-size: 14px;
	line-height: 16px;
	opacity: 0.75;
	letter-spacing: 0px;
	text-transform: none;
	margin-top: 2px;
	font-weight: 400;
}

.feature-chevron {
	font-size: 20px;
	line-height: 40px;
	margin-left: 20px;
	text-align: right;
	width: 40px;
}

</style>