<template>

<app-dashboard-item class="metric" :theme="theme" :width="width" :height="height">
			
	<div class="metric-value">{{ value }}<small v-if="unit">{{ unit }}</small></div>
	<div class="metric-label">{{ label }}</div>

</app-dashboard-item>
	
</template>

<script>

export default {

	props: ['width', 'height', 'theme', 'label', 'unit', 'value']

}

</script>

<style scoped>

.metric {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 160px;
}

.metric-value {
	font-size: 64px;
	font-weight: 400;
	color: #333;
}

.metric-value small {
	font-size: 20px;
	margin-left: 4px;
}

.item[data-theme="green"] .metric-value,
.item[data-theme="red"] .metric-value {
	color: #fff;
}

.metric-label {
	font-size: 14px;
	color: #333;
	margin-top: 10px;
}

.item[data-theme="green"] .metric-label,
.item[data-theme="red"] .metric-label {
	color: #fff;
}

</style>