import _ from 'underscore'
import deepmerge from 'deepmerge'
import constants from '@/constants'
import Vue from 'vue'

export default {

	count: function(value) {

		return _.keys(value).length

	},

	scanClean: function(code) {

		if (code.indexOf('?') > 0) {
			
			var params = new URLSearchParams(code.slice(code.indexOf('?')))

			if (params.get('i')) code = params.get('i')
			if (params.get('u')) code = params.get('u') 

		} else if (code.indexOf('https://') === 0) {

			var parts = code.split('/')

			code = parts.pop()

		} else {

			code = code.replace('httpsqrdtwlibrarycomi', '')
			code = code.replace('httpsbigboxngroioqr', '')
			code = code.replace('httpsapibigboxdevqr', '')
			code = code.replace('httpsapicardboardeventscomqr', '')
			code = code.replace('httpscheckoutdicetowerwestcomqri', '')

		}

		return code

	},

	options: function(values, field) {

		var options = []

		_.each(values, function(value, key) {

			options.push({
				value: key,
				text: (field) ? value[field] : value
			})

		})

		return options

	},

	in: function(value, array, keys) {

		keys = keys || false

		if(keys) {

			return _.contains(_.filter(array, function(value, key) {

				return _.contains(keys, key)

			}), value)

		} else {

			return _.contains(array, value)

		}

	},

	registration: {

		findRoleID: function(inputs, role) {

			var input = _.findWhere(inputs, {
				role: constants.registration.input.role[role]
			})

			return (input) ? input.id : false
	
		},

		findValidationID: function(inputs, validation) {

			var input = _.findWhere(inputs, {
				validation: constants.registration.input.validation[validation]
			})

			return (input) ? input.id : false
	
		},

		getRoleValue: function(inputs, model, role) {

			var id = this.findRoleID(inputs, role)

			return (id) ? model[id] : false

		}

	},

	generateKey: function() {

		var length = 8
		var result = ''
		var characters = 'abcdefghijklmnopqrstuvwxyz0123456789'
		var charactersLength = characters.length

		for ( var i = 0; i < length; i++ ) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength))
		}

		return result

	},

    copy: function(value) {

        return JSON.parse(JSON.stringify(value))
        
    },

	array: {

		string: function(dest) {

			for(var i=0; i<dest.length; i++) {

				dest[i] = dest[i].toString()

			}

			return dest

		},

		int: function(dest) {

			for(var i=0; i<dest.length; i++) {

				dest[i] = parseInt(dest[i])

			}

			return dest

		},

		add: function(dest, value) {

			if (!_.contains(dest, value)) dest.push(value)

		},

		remove: function(dest, value) {

			if (_.contains(dest, value)) {

				dest.splice(dest.indexOf(value), 1)

			}

		},

		toggle: function(dest, value) {

			if (_.contains(dest, value)) {

				this.remove(dest, value)

			} else {

				this.add(dest, value)

			}

		}

	},

	structure: {

		copy: function(value) {
	
			return JSON.parse(JSON.stringify(value))
			
		},

		poke: function(data, name, value, type) {
	
			var parts = name.split('.')
	
			if (parts.length > 1) {
	
				this.poke(data[parts[0]], parts.slice(1).join('.'), value, type)
	
			} else {
	
				if (type === constants.store.commit.update) {
					
					data[name] = value
	
				} else if (type === constants.store.commit.add) {
	
					if (!_.contains(data[name], value)) {
	
						data[name].push(value)
	
					}
	
				} else if (type === constants.store.commit.remove) {
	
					if (_.contains(data[name], value)) {
	
						data[name].splice(data[name].indexOf(value), 1)
	
					}
	
				}
	
			}
	
		},

		merge: function(value, full) {

			value = this.copy(value)
			full = this.copy(full)

			return deepmerge(full, value)

		},

		flatten: function(data, path, flat) {

			flat = flat || {}
			path = path || ''
	
			_.each(data, function(value, key) {
	
				if (_.isObject(value) && !_.isArray(value)) {
	
					flat = this.flatten(value, path + key + '.', flat)
	
				} else {
	
					flat[path + key] = value
	
				}
	
			}.bind(this))
	
			return flat
	
		}

	},

	collection: {
	
		$find: function(collection, value) {

			var id = (typeof value === 'object') ? value.id : value

			var index = _.findIndex(collection, {
				id: id
			})

			return (index === -1) ? false : index

		},

		get: function(collection, value) {

			return collection[this.$find(collection, value)]

		},

		add: function(collection, value) {

			if (!this.$find(collection, value)) collection.push(value)

		},

		remove: function(collection, value) {

			var index = this.$find(collection, value)

			if (index !== false) collection.splice(index, 1)

			return index

		},

		update: function(collection, data) {

			var index = this.$find(collection, data)

			if (index !== false) Vue.set(collection, index, data)

			return index

		}

	}

}