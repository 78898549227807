<template>

<app-content-box :span="span" :data-span="span" title="Activity" :scroll="true" icon="activity" class="activities" :not-padded="true" :flex-grow="true" :is-empty="!activities.length" empty-message="No activity.">

	<app-notification v-for="activity in activities" :key="activity.id" :item="activity" :is-linked="true" />

</app-content-box>

</template>

<script>

export default {

	props: ['id', 'span'],

	data: function() {

		return {
			is: {
				loading: false,
				initial: true
			},
			activities: []
		}

	},

	created: function() {

		this.$live.$on('activities/' + this.id + '/activity', this.onActivity.bind(this))

		this.load()

	},

	beforeDestroy: function() {

		this.$live.$off('activities/' + this.id + '/activity', this.onActivity.bind(this))

	},

	methods: {

		load: function() {

			this.is.loading = true

			this.$api.get('convention/activities/' + this.id).then(function(response) {

				this.activities = response.collection
				this.is.loading = false
				this.is.initial = false

			}.bind(this))

		},

		onActivity: function(activity) {

			this.activities.push(activity)

		}

	}

}

</script>

<style scoped>

.activities {
	max-height: 400px;
	overflow-y: auto;
}

.activities[data-span="2"] {
	grid-column: 3 / 5;
}

.is-mobile .activities {
	grid-column: 1 / 5;
}

</style>