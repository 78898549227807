<template>

<app-table-cell-text :tooltip="tooltip" :text="text" :class="{'experience': this.item.experience}" />

</template>

<script>

export default {

	props: ['item'],

	computed: {

		tooltip: function() {

			return this.$constants.experience.tooltip[this.item.experience]

		},

		text: function() {

			return this.$constants.experience.text[this.item.experience]

		}

	}

}

</script>

<style scoped>

.experience >>> span {
	border-bottom: 1px dashed #4d5b6e;
	cursor: pointer;
	padding: 2px 0px;
}

</style>