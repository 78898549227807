<template>

<app-table-cell class="image">

	<div class="image-thumb" v-bgimage="image" />
	
</app-table-cell>

</template>

<script>

export default {

	props: ['image']

}

</script>

<style scoped>

.image-thumb {
	width: 48px;
	height: 48px;
	background-position: 50% 50%;
	background-size: cover;
	border-radius: 4px;
}

</style>
