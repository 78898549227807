import Vue from 'vue'

export default {

	namespaced: true,

	state: {
		data: {}
	},

	getters: {

		get: function(state) {

			return state.data

		}

	},

	mutations: {

		set: function(state, value) {

			Vue.set(state, 'data', value)

		}

	}

}
