<template>

<com-screen :no-profile="true" id="app" :loading="is.loading">

	<div class="context">

		<div class="context-logo" v-bgimage="$organisation.logo"><app-icon v-if="!$organisation.logo" icon="organisation" /></div>

		<div class="context-text">
			{{ $organisation.name }}<template v-if="$convention"> {{ $convention.name }}</template><small>Library</small>
		</div>

	</div>

	<router-view :key="$path" v-if="!is.loading" />

</com-screen>

</template>

<script>

import comScreen from './screen/Wrapper'

export default {

	name: 'App',

	components: {
		'com-screen': comScreen
	},

	data: function() {

		return {
			is: {
				initiated: false,
				loading: true
			}
		}

	},

	watch: {

		$path: function() {

			if (this.$route.name) this.load()

		}

	},

	created: function() {

		if (this.$route.name) this.load()

	},

	methods: {

		load: function() {

			if (!this.is.initiated) {

				this.$api.get('check', {
					url: window.location.pathname.slice(1)
				}).then(async function(json) {

					if (json.redirect) {

						window.location.href = json.redirect

					} else {

						this.$store.commit('context/organisation', json.organisation)
						if (json.convention) this.$store.commit('context/convention', json.convention)

						this.is.initiated = true

						this.is.loading = false

					}

				}.bind(this))
					
			}

		}

	}

}

</script>

<style scoped>

body {
	overflow: hidden;
}

.context {
	color: #fff;
	font-size: 32px;
	padding: 0px 20px;
	font-weight: 400;
	height: var(--head-height);
	display: flex;
	align-items: center;
	flex-grow: 1;
	user-select: none;
	background-color: #2f3a4a;
	position: fixed;
	left: 0px; 
	top: 0px;
	width: calc(100% + 320px);
	padding-right: 320px;
	background-color: #2f3a4a;
	height: var(--head-height);
	display: flex;
	z-index: 4;
	align-items: center;
	transition: transform 100ms ease-in-out;
}

.is-tablet .context {
	font-size: 24px;
	height: 64px;
	padding: 0px 10px;
}

.is-mobile .context {
	font-size: 20px;
	height: 48px;
	padding: 0px 0px;
}

.context-logo {
	width: 64px;
	height: 64px;
	background-size: contain;
	background-color: #fff;
	border-radius: 10px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	border: 4px solid #ffffff;
	margin-right: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.context-logo >>> i {
	font-size: 40px;
	color: #ffbe7a;
}

.is-mobile .context-logo {
	width: 32px;
	height: 32px;
}

.context-text {
	flex-grow: 1;
}

.context-text small {
	font-weight: 300;
	font-size: 16px;
	margin-top: 4px;
	display: block;
}

.is-tablet .context-text small {
	font-size: 14px;
}

.is-mobile .context-text small {
	font-size: 12px;
}

</style>
