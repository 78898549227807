<template>

<div class="item" :class="{'is-grow': grow}">

	<div class="item-label">{{ label }}</div>

	<div class="item-value">

		<div class="item-tag" v-if="tag" :data-status="status">{{ tag }}</div>

		<app-icon class="item-icon" :icon="icon" v-if="icon" />

		<a :href="'mailto:' + value" v-if="isEmail">{{ value }}</a>
		
		<template v-if="!isEmail">{{ (alt) ? value || alt : value }}</template>

		<div class="item-note">{{  note  }}</div>

		<slot></slot>

	</div>

</div>

</template>

<script>

export default {

	props: ['label', 'tag', 'status', 'alt', 'note', 'value', 'icon', 'grow', 'isEmail']

}

</script>

<style scoped>

.item {
	flex-shrink: 0;
	flex-basis: 0;
	padding: 20px;
	border-right: 1px solid #ddd;
}

.is-mobile .item {
	border-right: 0px;
	padding: 10px;
	border-bottom: 1px solid #ddd;
}

.item.is-grow {	
	flex-grow: 1;
}

.item:last-child {
	border-right-width: 0px;
	border-bottom-width: 0px;
}

.item-label {
	font-size: 12px;
	color: #666;
	white-space: nowrap;
}

.item-value {
	font-size: 16px;
	margin-top: 10px;
	line-height: 20px;
	display: flex;
	color: #343434;
	align-items: center;
	white-space: nowrap;
}

.item-value a:hover {
	color: #4082d3;
}

.is-mobile .item-value {
	margin-top: 5px;
}

.item-tag {
	color: #fff;
	height: 20px;
	font-size: 12px;
	line-height: 20px;
	padding: 0px 4px;
	border-radius: 4px;
}

.item-tag[data-status="0"] {
	background-color: #c55b5b;
}

.item-tag[data-status="1"] {
	background-color: #4faa80;
}

.item-tag[data-status="2"] {
	background-color: #c55b5b;
}

.item-tag[data-status="3"] {
	background-color: #ffbe7a;
}

.item-icon {
	font-size: 20px;
	margin-right: 10px;
	color: #4082d3;
}

</style>