<template>

<app-panel v-on:close="$emit('close')">
		
	<app-panel-title text="Link" />

	<app-panel-content>

		<app-input-text label="Text" placeholder="Enter text" :stacked="true" v-model="model.text" :validation="$v.model.text" />
		<app-input-text label="URL" placeholder="Enter url" :stacked="true" v-model="model.url" :validation="$v.model.url" />

	</app-panel-content>

	<app-panel-content :space="true">

		<app-button text="Confirm" :disabled="$v.invalid" v-on:click="onSaveClick" />

	</app-panel-content>

</app-panel>

</template>

<script>

import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [validationMixin],

	props: ['selectedText', 'editor'],

	data: function() {

		return {
			model: {
				text: '',
				url: ''
			}
		}

	},

	validations: {
		model: {
			text: {
				required
			},
			url: {
				required
			}
		}
	},

	created: function() {

		this.model.text = this.selectedText

		var existingLink = this.editor.getAttributes('link')

		if (existingLink.href) {

			this.model.url = existingLink.href
			this.editor.chain().focus().extendMarkRange('link').run()
			this.model.text = this.editor.state.doc.textBetween(this.editor.state.selection.from, this.editor.state.selection.to, ' ')

		}

		this.$pubsub.$emit('offset', true)

	},

	beforeDestroy: function() {

		this.$pubsub.$emit('offset', false)

	},

	methods: {

		onSaveClick: function() {

			var url = this.model.url

			if (url.indexOf('http') !== 0) {

				url = 'https://' + url

			}

			this.editor.chain().focus().extendMarkRange('link').setLink({ href: url }).command(function(tr){

				tr.commands.insertContent(this.model.text)

				return true

			}.bind(this)).run()

			this.$emit('close')

		}

	}

}

</script>

<style scoped>

</style>
