<template>

<div class="call" :data-theme="themeOverride" :class="{'is-danger': danger, 'is-success': success}">

	<app-icon :icon="icon" class="call-icon" :class="{'is-loading': icon === 'loading'}" />
				
	<div class="call-title">{{ title }}<small v-if="subtitle">{{ subtitle }}</small></div>
	<div class="call-text">{{ text }}</div>

	<slot></slot>

	<div class="call-buttons" v-if="button || hasCancel">

		<app-button v-if="button" :disabled="disabled || loading" :loading="loading" :theme="theme" :text="button" v-on:click="$emit('click')" />
		<app-button v-if="hasCancel" :disabled="disabled || loading" theme="plain" text="Cancel" v-on:click="$emit('cancel')" />

	</div>

	<div class="call-search" v-if="search">

		<app-input-text v-model="model.text" v-on:enter="onSearch" :placeholder="search" />

	</div>

</div>

</template>

<script>

export default {

	props: ['icon', 'title', 'subtitle', 'text', 'theme', 'hasCancel', 'disabled', 'loading', 'success', 'danger', 'button', 'search'],

	data: function() {

		return {
			model: {
				text: ''
			}
		}

	},

	computed: {

		themeOverride: function() {

			if (this.danger) {

				return 'red'

			} else if (this.success) {

				return 'green'

			} else {

				return this.theme

			}

		}

	},

	methods: {

		onSearch: function() {

			this.$emit('search', this.model.text)

		}

	}

}

</script>

<style scoped>

.call {
	max-width: 600px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.call-icon {
	font-size: 80px;
	color: #fff;
	margin-bottom: 20px;
	background-color: #236dc9;
	width: 160px;
	height: 160px;
	text-align: center;
	line-height: 160px;
	border-radius: 50%;
}

.call[data-theme="red"] .call-icon,
.call.is-danger .call-icon {
	background-color: #c55b5b;
}

.call[data-theme="green"] .call-icon,
.call.is-success .call-icon {
	background-color: #4faa80;
}

.call-icon.is-loading {
	color: #236dc9;
	background-color: transparent;
}

.call[data-theme="green"] .call-icon.is-loading,
.call[data-theme="red"] .call-icon.is-loading,
.call.is-danger .call-icon.is-loading,
.call.is-success .call-icon.is-loading {
	color: #fff;
}

.is-device .call-icon {
	font-size: 64px;
	width: 128px;
	height: 128px;
	line-height: 128px;
}

.call-title {
	font-size: 24px;
	font-weight: 400;
	color: #4d5b6e;
	padding: 20px 0px 5px 0px;
	text-align: center;
}

.call-title small {
	font-size: 16px;
	margin-top: 4px;
	display: block;
	margin-bottom: 10px;
}

.call[data-theme="red"] .call-title,
.call.is-danger .call-title {
	color: #c55b5b;
}

.call[data-theme="green"] .call-title,
.call.is-success .call-title {
	color: #4faa80;
}

.is-mobile .call-title {
	font-size: 20px;
}

.call-text {
	font-size: 16px;
	color: #707070;
	text-align: center;
	line-height: 20px;
}

.is-mobile .call-text {
	font-size: 14px;
	line-height: 18px;
	padding: 0px 10px;
}

.call-search {
	margin-top: 30px;
	width: 400px;
	max-width: 100%;
}

.call-search >>> input {
	text-align: center;
}

.call-buttons {
	display: flex;
	margin-top: 40px;
	justify-content: center;
}

.call-buttons >>> .button {
	margin: 0px 5px;
}

</style>
