<template>

<div class="icon" :data-theme="theme" :class="{'is-disabled': disabled}" v-on:click="$emit('click')">

	<i :class="{[clsIcon]: true}"></i>

	<i class="fa fa-caret-down icon-caret" v-if="caret"></i>

	<slot></slot>

	<div class="icon-text" v-if="text">{{ text }}</div>

</div>

</template>

<script>

export default {

	props: ['icon', 'caret', 'loading', 'theme', 'text', 'disabled'],

	data: function() {

		return {
			shortcut: {
				closed: 'fas fa-door-closed',
				'volunteers.assignments': 'fa fa-users',
				'volunteers.slots': 'fa fa-clock',
				'volunteers.info': 'fa fa-circle-info',
				'volunteers.clash': 'fa fa-exclamation-triangle',
				'volunteers.join': 'fa fa-user-plus',
				'volunteers.leave': 'fa fa-user-minus',
				volunteers: 'fa-duotone fa-people-carry-box',
				paperwork: 'fa-solid fa-scroll',
				alert: 'fa-solid fa-circle-exclamation',
				external: 'fa-solid fa-arrow-up-right-from-square',
				weight: 'fa-solid fa-weight-hanging',
				collapsed: 'fa-solid fa-pencil',
				lock: 'fa-solid fa-lock',
				approve: 'fa-solid fa-thumbs-up',
				'reject.thumb': 'fa-solid fa-thumbs-down',
				rates: 'fa-solid fa-percent',
				email: 'fa-solid fa-envelope',
				modal: 'fa-solid fa-message-exclamation',
				'admin.team': 'fa-solid fa-key',
				'assistant.scope.checkin': 'fa-solid fa-user-check',
				'assistant.scope.checkout': 'fa-solid fa-hand-holding-box',
				'assistant.scope.tickets': 'fa-solid fa-ticket-simple',
				'assistant.scope.reports': 'fa-solid fa-puzzle',
				'assistant.scope.badges': 'fa-solid fa-print',
				assistant: 'fa-solid fa-user-headset',
				'assistant.small': 'fa-solid fa-headset',
				'assistant.logo': 'fa-duotone fa-user-headset',
				'manager.logo': 'fa-duotone fa-user-tie',
				'admin.logo': 'fa-duotone fa-screwdriver-wrench',
				'mode.manager': 'fa-solid fa-user-tie',
				'mode.my': 'fa-solid fa-id-card-clip',
				ticket: 'fa-solid fa-ticket-simple',
				tickets: 'fa-solid fa-ticket-simple',
				transfer: 'fa-solid fa-people-arrows',
				'transfer.from': 'fa-solid fa-user-slash',
				users: 'fa-solid fa-users',
				'transfer.to': 'fa-solid fa-user-pen',
				information: 'fa-solid fa-file-circle-info',
				blocks: 'fa-solid fa-cubes',
				pages: 'fa-solid fa-file',
				eligible: 'fa-solid fa-user-check',
				contests: 'fa-solid fa-ranking-star',
				'library.none': 'fa-solid fa-circle-xmark',
				'library.available': 'fa-solid fa-circle-check',
				'library.unavailable': 'fa-solid fa-hourglass-clock',
				'checkout.notify': 'fa-solid fa-hourglass-clock',
				geolocation: 'fa-solid fa-location-dot',
				geolocated: 'fa-solid fa-location-check fa-beat',
				organisation: 'fa-solid fa-building',
				convention: 'fa-solid fa-calendar-days',
				conventions: 'fa-solid fa-calendar-days',
				clock: 'fa-solid fa-clock',
				'checkout.dashboard': 'fa-solid fa-hand-holding-box',
				'checkout.scan': 'fa-solid fa-camera',
				'scan': 'fa-solid fa-camera',
				'checkout.players': 'fa-solid fa-users',
				'page.info': 'fa-solid fa-circle-info',
				'badges.managed': 'fa-solid fa-user-check',
				'badges.stacked': 'fa-solid fa-layer-group',
				'badges.stack': 'fa-solid fa-layer-plus',
				purchases: 'fa-solid fa-bag-shopping',
				pin: 'fa-solid fa-thumbtack',
				unpin: 'fa-solid fa-thumbtack',
				plays: 'fa-solid fa-dice',
				addons: 'fa-solid fa-cart-shopping',
				store: 'fa-solid fa-store',
				add: 'fa-solid fa-circle-plus',
				plus: 'fa-solid fa-circle-plus',
				minus: 'fa-solid fa-circle-minus',
				bank: 'fa-solid fa-building-columns',
				identity: 'fa-solid fa-passport',
				payouts: 'fa-solid fa-hand-holding-dollar',
				billing: 'fa-solid fa-coins',
				countdown: 'fa-solid fa-hourglass-clock',
				palette: 'fa-solid fa-palette',
				'toolbar.bold': 'fa-solid fa-bold',
				'toolbar.italic': 'fa-solid fa-italic',
				'toolbar.underline': 'fa-solid fa-underline',
				'toolbar.link': 'fa-solid fa-link',
				'toolbar.format': 'fa-solid fa-text-size',
				preview: 'fa-solid fa-eye',
				'announcements': 'fa fa-bullhorn',
				'pending': 'fa-solid fa-face-beam-hand-over-mouth',
				'refund': 'fa-solid fa-arrow-turn-down-left fa-flip-vertical',
				'box': 'fa-solid fa-box',
				'method-visa': 'fa-brands fa-cc-visa',
				key: 'fa-solid fa-key',
				'receipt': 'fa-solid fa-receipt',
				'enlarge': 'fa-solid fa-magnifying-glass-plus',
				'basket.badge': 'fa-light fa-address-card',
				'basket.addon': 'fa-light fa-cart-circle-plus',
				'addon': 'fa-solid fa-cart-circle-plus',
				'summary.countdown': 'fa-light fa-alarm-clock',
				'summary.location': 'fa-light fa-signs-post',
				'summary.website': 'fa-light fa-globe',
				'summary.calendar': 'fa-light fa-calendar-day',
				'summary.people': 'fa-light fa-users',
				'page.messages': 'fa-solid fa-message-lines',
				'block.text': 'fa-solid fa-font-case',
				'text': 'fa-solid fa-message-text',
				'block.gallery': 'fa-solid fa-images',
				'block.video': 'fa-solid fa-video',
				'block.map': 'fa-solid fa-map-location',
				'block.payment': 'fa-solid fa-credit-card',
				'block.registration': 'fa-solid fa-id-card',
				'block.banner': 'fa-solid fa-image-landscape',
				'block.addons': 'fa-solid fa-cart-circle-plus',
				'block.capture': 'fa-solid fa-input-text',
				'block.feature': 'fa-solid fa-square-up-right',
				'block.image': 'fa-solid fa-image',
				'block.thirdparty': 'fa-solid fa-user-tie',
				'block.items': 'fa-solid fa-shop',
				tick: 'fa-solid fa-circle-check',
				tickOnly: 'fa-solid fa-check',
				cross: 'fa-solid fa-circle-xmark',
				crossOnly: 'fa-solid fa-xmark',
				crossCalendar: 'fa-solid fa-calendar-xmark',
				returned: 'fa-solid fa-inbox-in',
				'create-circle': 'fas fa-plus-circle',
				customise: 'fas fa-pen',
				'caret-up': 'fa-solid fa-caret-up',
				'caret-down': 'fa-solid fa-caret-down',
				'caret-right': 'fa-solid fa-caret-right',
				'caret-left': 'fa-solid fa-caret-left',
				sync: 'fa-solid fa-sync',
				label: 'fa-solid fa-sticky-note',
				qrcode: 'fa-solid fa-qrcode',
				badge: 'fa fa-address-card',
				empty: 'fa-solid fa-face-anguished',
				design: 'fa-solid fa-pen-ruler',
				warning: 'fa-solid fa-exclamation-triangle',
				warningOnly: 'fa-solid fa-exclamation',
				undo: 'fa-solid fa-undo',
				registration: 'fa-regular fa-cart-shopping',
				team: 'fa-regular fa-people-group',
				badges: 'fa fa-address-card',
				checkout: 'fa fa-barcode-read',
				guide: 'fa fa-graduation-cap',
				subscribe: 'fa fa-bell',
				unsubscribe: 'fa fa-bell-slash',
				mute: 'fa fa-bell',
				unmute: 'fa fa-bell-slash',
				leaders: 'fa fa-ranking-star',
				play: 'fa fa-gift',
				prize: 'fa fa-gift',
				check: 'fa fa-check',
				clone: 'fa fa-copy',
				upload: 'fa fa-file-upload',
				library: 'fa fa-books',
				lobbies: 'fa fa-comments',
				avatar: 'fa fa-user-circle',
				exists: 'fa-solid fa-user-check',
				switch: 'fa fa-people-arrows',
				received: 'fa fa-check-circle',
				notreceived: 'fa fa-times-circle',
				accept: 'fa fa-check-circle',
				reject: 'fa fa-times-circle',
				'accept.plain': 'fa fa-check',
				'reject.plain': 'fa fa-times',
				close: 'fa fa-times',
				'proposals.sent': 'fa fa-paper-plane',
				'proposals.received': 'fa fa-inbox',
				games: 'fa fa-game-board',
				delete: 'fa fa-trash',
				matchmaker: 'fa fa-handshake',
				interests: 'fa fa-star',
				save: 'fa-solid fa-floppy-disk',
				create: 'fa-solid fa-plus-square',
				'interests.add': 'fa fa-plus-square',
				'buddy.suggest': 'fa fa-user-plus',
				'buddy.add': 'fa fa-user-plus',
				'buddy.remove': 'fa fa-user-minus',
				moderators: 'fa-solid fa-user-gear',
				import: 'fa fa-cloud-upload-alt',
				export: 'fa fa-cloud-download-alt',
				unlimited: 'fa fa-infinity',
				account: 'fa fa-user',
				settings: 'fa fa-cog',
				connect: 'fa fa-plug',
				public: 'fa fa-address-card',
				password: 'fa fa-key',
				back: 'fa fa-chevron-left',
				x: 'fa fa-remove',
				dashboard: 'fa fa-grid-2',
				schedule: 'far fa-calendar-clock',
				notifications: 'fa fa-bell',
				events: 'fa fa-clipboard-check',
				buddies: 'fa fa-users',
				matchmaking: 'fa-solid fa-telescope',
				activity: 'fa fa-bell',
				search: 'fas fa-search',
				shortlist: 'far fa-star',
				shortlisted: 'fa-solid fa-star',
				filter: 'fa fa-filter',
				login: 'fa fa-sign-in-alt',
				register: 'fa fa-user-plus',
				sort: 'fa fa-sort',
				help: 'fa fa-info-circle',
				info: 'fa fa-info-circle',
				time: 'fa fa-clock',
				edit: 'fa fa-pencil',
				day: 'fa fa-calendar',
				list: 'fa fa-th-list',
				join: 'fa fa-user-plus',
				leave: 'fa fa-user-minus',
				more: 'fa fa-info-circle',
				'chevron.right': 'fa fa-chevron-right',
				'chevron.down': 'fa fa-chevron-down',
				'chevron.down.circle': 'fa-solid fa-circle-chevron-down',
				user: 'fa fa-user',
				profile: 'fa fa-user-edit',
				seats: 'fa fa-users',
				grid: 'fa fa-th',
				logout: 'fa fa-sign-out',
				loading: 'fas fa-spinner fa-spin',
				clash: 'fa fa-exclamation-triangle',
				messages: 'fa fa-comments',
				question: 'fa fa-question-circle',
				duration: 'fa fa-hourglass-start',
				quote: 'fa fa-quote-left',
				location: 'fa fa-signs-post',
				insights: 'fa fa-chart-pie',
				compose: 'fa fa-paper-plane',
				winner: 'fa-solid fa-medal',
				participants: 'fa-solid fa-users',
				playtowin: 'fa fa-gift',
				'notification.checkout.available': 'fa-solid fa-hourglass-clock',
				'notification.checkout.unavailable': 'fa-solid fa-hourglass-clock',
				'notification.play.winner': 'fa fa-gift',
				'notification.event.create': 'fa fa-calendar-plus',
				'notification.event.join': 'fa fa-user-plus',
				'notification.event.leave': 'fa fa-user-minus',
				'notification.event.cancel': 'fa fa-calendar-times',
				'notification.event.time': 'fa fa-clock',
				'notification.event.location': 'fa fa-map-marker-alt',
				'notification.event.propose': 'fa fa-inbox',
				'notification.event.accept': 'fa fa-check',
				'notification.event.reject': 'fa fa-times',
				'notification.buddies.suggestions': 'fa fa-user-friends',
				'notification.interest.suggestion': 'fa-solid fa-telescope',
				'notification.interest.match': 'fa fa-handshake',
				'notification.announcement': 'fa fa-bullhorn',
				'notification.message': 'fa fa-comment',
				'notification.badge.registered': 'fa fa-address-card',
				'notification.badge.assigned': 'fa fa-address-card',
				'notification.badge.transferred':  'fa fa-address-card',
				'notification.play.won': 'fa fa-gift',
				'notification.contest.scan': 'fa fa-barcode-read',
				'notification.contest.message': 'fa-sharp fa-solid fa-scroll-old',
				'notification.contest.register': '',
				'notification.contest.unregister': '',
				'notification.contest.award': 'fa fa-gift',
				'notification.volunteer.accept': 'fa-duotone fa-people-carry-box',
				'notification.volunteer.reject': 'fa-duotone fa-people-carry-box',
				'notification.volunteer.approve': 'fa-duotone fa-people-carry-box',
				assigned: 'fa-solid fa-user-check',
				unassigned: 'fa-solid fa-robot',
				assign: 'fa-solid fa-user-plus',
				remove: 'fa fa-times',
				removeCircle: 'fa fa-times-circle',
				submit: 'fa fa-pencil',
				success: 'far fa-check-circle',
				bookmark: 'far fa-bookmark',
				unbookmark: 'fas fa-bookmark',
				lobbyEvents: 'fa fa-calendar-day',
				summary: 'fa fa-info-circle',
				guest: 'fa fa-user-secret',
				manage: 'fa fa-pen-square',
				cancel: 'fa fa-minus-square',
				hide: 'fas fa-eye-slash',
				menu: 'fa fa-bars',
				'rule-undo': 'fa fa-undo',
				'rule-save': 'fa fa-check',
				'rule-add': 'fa fa-plus',
				'rule-remove': 'fa fa-minus'
			}
		}

	},

	computed: {

		clsIcon: function() {

			return this.shortcut[(this.loading) ? 'loading' : this.icon]

		}

	}

}

</script>

<style scoped>

.icon.is-disabled {
	pointer-events: none;
}

.icon.is-disabled {
	opacity: 0.2;
}

.icon-caret {
	font-size: 12px!important;
	margin-left: 4px;
}

.icon-text {
	font-size: 14px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	color: #333;
}

.icon[data-theme="green"],
.icon[data-theme="success"] {
	background-color: #4faa80;
	color: #fff;
}

.icon[data-theme="red"],
.icon[data-tythemepe="danger"] {
	background-color: #c55b5b;
	color: #fff;
}

.icon[data-theme="yellow"],
.icon[data-theme="warning"] {
	background-color: #ffbe7a;
	color: #333;
}

</style>
