<template>

<app-table-cell class="rating" align="center">

	<div class="rating-box" :data-band="valueBand">
	
		<div class="rating-box-value">{{ valueRound }}</div>
		<div class="rating-box-count">{{ countText }}</div>

	</div>
	
</app-table-cell>

</template>

<script>

export default {

	props: ['value', 'count'],

	computed: {

		valueRound: function() {

			return Math.round(this.value * 10) / 10

		},

		valueBand: function() {

			return Math.floor(this.value)

		},

		countText: function() {

			if (this.count >= 1000) {

				return Math.floor(this.count / 1000).toString() + 'k'

			} else {
				
				return this.count

			}

		}

	}

}

</script>

<style scoped>

.rating-box {
	width: 48px;
	height: 48px;
	border-radius: 4px;
}

.rating-box[data-band="1"] { background-color: #db303b; }
.rating-box[data-band="2"] { background-color: #db303b; }
.rating-box[data-band="3"] { background-color: #df4751; }
.rating-box[data-band="4"] { background-color: #df4751; }
.rating-box[data-band="5"] { background-color: #5369a2; }
.rating-box[data-band="6"] { background-color: #5369a2; }
.rating-box[data-band="7"] { background-color: #1d8acd; }
.rating-box[data-band="8"] { background-color: #2fc482; }
.rating-box[data-band="9"] { background-color: #249563; }
.rating-box[data-band="10"] { background-color: #249563; }

.rating-box-value {
	width: 48px;
	height: 48px;
	text-align: center;
	line-height: 48px;
	color: #fff;
	font-size: 16px;
	font-weight: bold;
}

.rating-box-count {
    padding: 0 2px;
    min-width: 14px;
    height: 14px;
    font-weight: 400;
    font-size: 8px;
    text-align: center;
    color: #fff;
    line-height: 14px;
    background-color: rgba(0,0,0,.5);
    position: absolute;
    right: 0;
    bottom: 0;
}

</style>
