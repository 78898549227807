import Vue from 'vue'
import Router from 'vue-router'

import routes from '@/router/routes.js';

Vue.use(Router)

export default new Router({
	mode: 'history',
	base: process.env.VUE_APP_BASE,
	routes
})