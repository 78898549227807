<template>

<app-input class="colour" :validation="validation" :class="{'is-focus': isFocus}" :label="label" :stacked="stacked" :no-line="noLine" :locked="locked" :is-ready="value.length" :notes="notes" :disabled="disabled">

	<div class="colour-prefix">#</div>

	<input type="text" class="colour-text" v-on:focus="isFocus = true" v-on:blur="isFocus = false" v-bind:value="value" :maxlength="6" v-on:input="$emit('input', $event.target.value)" :placeholder="placeholder" />

	<div class="colour-preview">

		<div class="colour-preview-bar" v-tooltip="'Click to open colour picker'" :style="{backgroundColor: '#' + value}" v-on:click="is.picking = true">

			<app-icon icon="palette" v-if="!value" />

		</div>

	</div>

	<com-picker v-if="is.picking" :value="value" v-on:save="onPickerSave" />

</app-input>

</template>

<script>

import comPicker from './colour/Picker'

export default {

	props: ['validation', 'label', 'password', 'disabled', 'locked', 'value', 'noLine', 'stacked', 'placeholder', 'notes'],

	components: {
		comPicker
	},

	data: function() {

		return {
			is: {
				picking: false
			},
			isFocus: false
		}

	},

	methods: {

		onPickerSave: function(hex) {

			this.$emit('input', hex)
			this.is.picking = false

		}

	}

}

</script>

<style scoped>

.colour >>> .input-content {
	display: flex;
	flex-direction: row;
}

.colour-prefix {
	border: 4px solid #eee;
	border-width: 2px 0px 2px 2px;
	border-radius: 12px 0px 0px 12px;
	font-size: 14px;
	padding: 0px 10px 0px 10px;
	flex-shrink: 0;
	background-color: #eee;
	color: #333;
	line-height: 36px;
}

.colour-text {
	line-height: 36px;
	font-size: 14px;
	width: 80px;
	padding: 0px 10px;
	color: #333;
	border: 4px solid #eee;
	flex-grow: 1;
	border-width: 2px 0px 2px 0px;
}

.colour-preview {
	border: 4px solid #eee;
	background-color: #fff;
	display: flex;
	flex-shrink: 0;
	justify-content: flex-end;
	border-width: 2px 2px 2px 0px;
	border-radius: 0px 12px 12px 0px;
}

.colour-preview-bar {
	height: 32px;
	width: 32px;
	margin: 2px;
	border-radius: 10px;
	line-height: 32px;
	text-align: center;
	color: #4082d3;
	cursor: pointer;
	font-size: 20px;
}

.colour.is-focus .colour-prefix {
	background-color: #4082d3;
	color: #fff;
}

.colour.is-focus .colour-prefix,
.colour.is-focus .colour-preview,
.colour.is-focus .colour-text {
	border-color: #4082d3;
}

</style>
