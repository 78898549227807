<template>

<div class="card" :class="{'is-empty': !event, 'is-loading': loading, [clsStatus]: true, 'is-cancelled': isCancelled}">

	<div class="card-empty" v-if="!event && !loading">No event</div>

	<app-icon icon="loading" class="card-loading" v-if="loading" />

	<template v-if="event">

		<router-link v-tooltip="'Click to read more details'" :to="{name: 'Convention.Schedule.Event', params: { id: event.id } }" class="card-image" v-bgimage="event.image">

			<div class="card-image-date">
				<div class="card-image-date-day">{{ event.date | formatDate('ddd') }}</div>
				<div class="card-image-date-time">{{ event.date | formatDate($hourFormat) }}</div>
			</div>

			<div class="card-image-brand" data-theme="green" v-if="isTicketed">
				Ticketed Event
			</div>

		</router-link>

		<div class="card-name">{{ event.name }}</div>

		<div class="card-seats">
			
			<app-widget-seats :center="true" :indexed="event.seats.indexed" :seats="event.seats.total" :taken="event.seats.taken" :joined="isSeated" />

		</div>

		<div class="card-actions">

			<app-card-action tooltip="Read more details" icon="more" v-on:click="onMoreClick" />
			<app-card-action v-if="isCancelled" tooltip="Hide event" :loading="is.hiding" icon="hide" v-on:click="onHideClick" />

			<template v-if="!isCancelled">
				
				<app-card-action tooltip="Add to shortlist" icon="shortlist" v-on:click="onShortlistClick" :loading="is.shortlisting" v-if="event.session.can.shortlist" />
				<app-card-action tooltip="Remove from shortlist" icon="shortlisted" :active="true" v-on:click="onUnshortlistClick" :loading="is.shortlisting" v-if="event.session.can.unshortlist" />
				
				<template v-if="!isTicketed">

					<app-card-action tooltip="Take a seat" icon="join" v-on:click="onSitClick" :loading="is.seating" v-if="event.session.can.join && !event.session.is.clashing" />
					<app-card-action tooltip="Schedule clash" :danger="true" icon="clash" v-on:click="onClashClick" v-if="event.session.can.join && event.session.is.clashing" />
					<app-card-action tooltip="Leave your seat" icon="leave" v-on:click="onStandClick" :loading="is.seating" v-if="event.session.can.leave" />

				</template>
				
				<template v-if="isTicketed">

					<app-card-action tooltip="Get ticket" icon="ticket" v-on:click="onSitClick" v-if="event.session.can.join && !event.session.is.clashing" />
					<app-card-action tooltip="Schedule clash" :danger="true" icon="clash" v-on:click="onClashClick" v-if="event.session.can.join && event.session.is.clashing" />
					<app-card-action tooltip="Cancel ticket" icon="ticket" theme="green" v-on:click="onStandClick"  v-if="event.session.can.leave"/>

				</template>

			</template>

		</div>

	</template>

</div>

</template>

<script>

export default {

	props: ['event', 'loading'],

	data: function() {

		return {
			is: {
				shortlisting: false,
				seating: false,
				hiding: false
			}
		}

	},

	computed: {
		
		clsStatus: function() {

			var cls = ''

			if (this.isCancelled) cls = 'is-danger'
			else if (this.isClashing) cls = 'is-warning'
			else if (this.isSeated) cls = 'is-success'

			return cls

		},

		isTicketed: function() {

			return this.event.ticket

		},

		isCancelled: function() {

			return this.event.cancelled

		},

		isHost: function() {

			return (this.event) ? this.event.session.is.hosting : false

		},

		isSeated: function() {

			return (this.event) ? this.event.session.is.sitting : false

		},

		isClashing: function() {

			return (this.event) ? this.event.session.is.clashing : false

		},

		isShortlisted: function() {

			return (this.event) ? this.event.session.is.shortlisting : false

		}

	},

	methods: {

		onHideClick: function() {

			this.is.hiding = 'hide'

			this.$api.post(['convention/schedule/event', this.event.id, 'hide']).then(function() {

				this.is.hiding = false

				this.$notify({
					message: ['You have successfully hidden the event.']
				})

			}.bind(this))

		},

		onMoreClick: function() {

			this.$router.push({
				name: 'Convention.Schedule.Event',
				params: {
					id: this.event.id
				}
			})

		},

		onClashClick: function() {

			this.$router.push({
				name: (this.isTicketed) ? 'Convention.Schedule.Event.Ticket' : 'Convention.Schedule.Event.Join',
				params: {
					id: this.event.id
				}
			})

		},

		onSitClick: function() {

			if (this.event.session.is.clashing || this.isTicketed) {

				this.$router.push({
					name: (this.isTicketed) ? 'Convention.Schedule.Event.Ticket' : 'Convention.Schedule.Event.Join',
					params: {
						id: this.event.id
					}
				})

			} else {

				this.is.seating = true

				this.$api.post(['convention/schedule/event', this.event.id, 'seat']).then(function() {

					this.is.seating = false

					this.$notify({
						message: ['You have successfully taken a seat for the event.']
					})

				}.bind(this))

			}

		},

		onStandClick: function() {

			if (this.isTicketed) {

				this.$router.push({
					name:'Convention.Schedule.Event.Ticket',
					params: {
						id: this.event.id
					}
				})

			} else {

				this.is.seating = true

				this.$api.delete(['convention/schedule/event', this.event.id, 'seat']).then(function() {

					this.is.seating = false

					this.$notify({
						message: ['You have successfully left your seat for the event.']
					})

				}.bind(this))

			}

		},

		onShortlistClick: function() {

			this.is.shortlisting = true

			this.$api.post(['convention/schedule/event', this.event.id, 'shortlist']).then(function() {

				this.is.shortlisting = false

				this.$notify({
					message: ['You have successfully added the event to your shortlist.']
				})

			}.bind(this))

		},

		onUnshortlistClick: function() {

			this.is.shortlisting = true

			this.$api.delete(['convention/schedule/event', this.event.id, 'shortlist']).then(function() {

				this.is.shortlisting = false

				this.$notify({
					message: ['You have successfully removed the event from your shortlist.']
				})

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.card {
	border-radius: 12px;
	height: auto;
	border: 1px solid #eee;
	background-color: #fff;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.is-tablet .card {
	width: calc(33% - 3px);
}

.is-mobile .card {
	width: 100%;
	margin-right: 0px;
}

.is-desktop .card:nth-child(4n) {
	margin-right: 0px;
}

.is-tablet .card:nth-child(3n) {
	margin-right: 0px;
}

.card.is-empty,
.card.is-loading {
	min-height: 240px;
}

.card-loading {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 24px;
	color: #ccc;
}

.card-image {
	height: 192px;
	background-size: cover;
	background-position: 50% 50%;
	display: block;
	border-bottom: 1px solid #eee;
	/*background-image: url('~@/assets/logo.png');*/
}

.card-image-date {
	position: absolute;
	right: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	top: 0px;
	width: 64px;
	height: 64px;
	background-color: #4082d3;
	color: #fff;
	border-radius: 0px 0px 4px 4px;
}

.card-image-date-day {
	font-size: 22px;
	text-transform: uppercase;
}

.card-image-date-time {
	font-size: 14px;
}

.card-name {
	padding: 10px 10px 5px 10px;
	font-size: 16px;
	color: #707070;
	width: 100%;
	text-align: center;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.card.is-cancelled .card-name {
	text-decoration: line-through;
}

.card-seats {
	padding: 0px 10px 10px 10px;
	border-bottom: 1px solid #eee;
}

.card-empty {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 20px;
	font-weight: 500;
	text-align: center;
	width: 100%;
	padding: 20px;
	color: rgba(0, 0, 0, 0.15);
}

.card-actions {
	display: flex;
	flex-direction: row;
}

.card-image-brand {
	position: absolute;
	left: 0%;
	width: 100%;
	bottom: 0px;
	font-weight: 500;
	font-size: 16px;
	text-align: center;
	letter-spacing: 1px;
	padding: 10px 0px;
}

.card-image-brand[data-theme="green"] {
	background-color: #3e8865;
	color: #fff;
}

.card-image-brand[data-theme="blue"] {
	background-color: #4082d3;
	color: #fff;
}

.card-image-brand[data-theme="red"] {
	background-color: #dd4848;
	color: #fff;
}

</style>
