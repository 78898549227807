import _ from 'underscore'

const requireRoute = require.context('./' + process.env.VUE_APP_TYPE, false, /\.js$/)
const routes = []

requireRoute.keys().forEach((filename) => {

	var definition = requireRoute(filename).default

	filename = filename.replace('.js', '').replace('./', '')

	var path = '/' + ((definition.path === undefined) ? definition.name.replace(/\./g, '/').toLowerCase() : definition.path)

	path = (path === '/convention') ? '/:organisation/:convention' : path
	path = path.replace('/convention/', '/:organisation/:convention/')
	path = path.replace('/organisation', '/:organisation')

	definition.meta = definition.meta || {}

	var root = {
		name: definition.name,
		path: path,
		component: '@/components/page/' + process.env.VUE_APP_TYPE + '/' + (definition.component || definition.name.replace(/\./g, '')),
		parts: definition.name.split('.'),
		props: function(route) {

			return {
				convention: route.params.convention || 'x'
			}

		}
	}

	if (definition.meta && !definition.overview && !definition.collection) {

		routes.push({
			name: root.name,
			path: root.path,
			component: () => import('@/components/page/' + process.env.VUE_APP_TYPE + '/' + (definition.component || definition.name.replace(/\./g, ''))),
			meta: definition.meta,
			props: root.props
		})

	}

	if (definition.overview) {

		if (definition.overview === true) {

			routes.push({
				name: root.name,
				path: root.path,
				component: () => import('@/components/page/' + process.env.VUE_APP_TYPE + '/' + definition.name.replace(/\./g, '') + 'Overview'),
				meta: {
					authority: definition.authority,
					auth: true,
					submenu: root.name,
					default: root.name + '.Overview'
				},
				props: root.props
			})

			routes.push({
				name: root.name + '.Overview',
				path: root.path,
				component: () => import('@/components/page/' + process.env.VUE_APP_TYPE + '/' + definition.name.replace(/\./g, '') + 'Overview'),
				meta: {
					authority: definition.authority,
					auth: true,
					submenu: root.name,
					parents: [root.parts[0], definition.grandparent, definition.parent, root.name]
				},
				props: root.props
			})

		} else {

			routes.push({
				name: root.name,
				path: root.path,
				component: () => import('@/components/page/' + process.env.VUE_APP_TYPE + '/' + definition.name.replace(/\./g, '') + 'Overview'),
				meta: {
					authority: definition.authority,
					auth: true,
					submenu: root.name,
					default: root.name + '.Overview'
				},
				props: root.props,
				redirect: {
					name: definition.overview
				}
			})

		}

	}

	if (definition.settings) {

		routes.push({
			name: root.name + '.Settings',
			path: root.path + '/settings',
			component: () => import('@/components/page/' + process.env.VUE_APP_TYPE + '/' + definition.name.replace(/\./g, '') + 'Settings'),
			meta: {
				authority: _.union(definition.authority, (definition.settings) ? definition.settings.authority : []),
				auth: true,
				api: definition.settings.api,
				submenu: root.name,
				parents: [root.parts[0], definition.grandparent, definition.parent, root.name]
			},
			props: root.props
		})

	}

	if (definition.collection) {

		routes.push({
			name: root.name,
			path: (definition.collection.path) ? '/' + definition.collection.path : root.path,
			component: () => import('@/components/page/' + process.env.VUE_APP_TYPE + '/' + (definition.collection.component || definition.name.replace(/\./g, ''))),
			meta: {
				authority: _.union(definition.authority, (definition.collection) ? definition.collection.authority : []),
				parentKey: definition.parentKey,
				title: definition.collection.title,
				description: definition.collection.description,
				back: definition.parent,
				submenu: (definition.topLevel) ? false : root.parts[0] + '.' + root.parts[1],
				parents: [root.parts[0], definition.grandparent, definition.parent, root.name],
				page: root.name + '.Page',
				isAccount: definition.meta.isAccount,
				auth: true,
				keyPath: (definition.collection.path) ? '/' + definition.collection.path : root.path,
				edit: root.name + '.Edit',
				params: definition.collection.params,
				backParams: definition.collection.backParams
			},
			props: root.props
		})

		routes.push({
			name: root.name + '.Page',
			path: ((definition.collection.path) ? '/' + definition.collection.path : root.path) + '/page/:pagination?',
			component: () => import('@/components/page/' + process.env.VUE_APP_TYPE + '/' + (definition.collection.component || definition.name.replace(/\./g, ''))),
			meta: {
				authority: _.union(definition.authority, (definition.collection) ? definition.collection.authority : []),
				parentKey: definition.parentKey,
				title: definition.collection.title,
				description: definition.collection.description,
				back: definition.parent,
				submenu: (definition.topLevel) ? false : root.parts[0] + '.' + root.parts[1],
				parents: [root.parts[0], definition.grandparent, definition.parent, root.name],
				page: root.name + '.Page',
				isAccount: definition.meta.isAccount,
				auth: true,
				keyPath: (definition.collection.path) ? '/' + definition.collection.path : root.path,
				edit: root.name + '.Edit',
				params: definition.collection.params,
				backParams: definition.collection.backParams
			},
			props: root.props
		})

	}

	if (definition.delete) {

		routes.push({
			name: root.name + '.Delete',
			path: root.path + '/delete',
			component: () => import('@/components/page/' + process.env.VUE_APP_TYPE + '/Delete'),
			meta: {
				authority: _.union(definition.authority, (definition.delete) ? definition.delete.authority : []),
				stash: root.name,
				back: root.name,
				complete: definition.delete.complete,
				api: definition.delete.api,
				apiMultiple: definition.delete.apiMultiple || definition.delete.api,
				auth: true,
				isAccount: definition.meta.isAccount,
				noun: definition.delete.noun,
				plural: definition.delete.plural || definition.delete.noun + 's', 
				submenu: false,
				parents: [root.parts[0], definition.grandparent, definition.parent, root.name]
			},
			props: root.props
		})

	}

	if (definition.form) {

		routes.push({
			name: root.name + '.Edit',
			path: definition.form.path || root.path + '/:id',
			component: () => import('@/components/page/' + process.env.VUE_APP_TYPE + '/' + definition.form.component),
			meta: {
				authority: _.union(definition.authority, (definition.form) ? definition.form.authority : []),
				api: definition.form.api,
				back: root.name,
				auth: true,
				isAccount: definition.meta.isAccount,
				noun: definition.form.noun,
				plural: definition.form.plural || definition.form.noun + 's', 
				submenu: (definition.topLevel) ? false : root.parts[0] + '.' + root.parts[1],
				parents: [root.parts[0], definition.grandparent, definition.parent, root.name]
			},
			props: root.props
		})

		routes.push({
			name: root.name + '.Delete',
			path: root.path + '/:id/delete',
			component: () => import('@/components/page/' + process.env.VUE_APP_TYPE + '/Delete'),
			meta: {
				noedit: definition.form.noedit || false,
				authority: _.union(definition.authority, (definition.delete) ? definition.delete.authority : []),
				stash: root.name + '.Edit',
				back: root.name + '.Edit',
				complete: root.name,
				api: definition.form.api,
				apiMultiple: definition.form.apiMultiple || definition.form.api + 's',
				auth: true,
				noun: definition.form.noun,
				plural: definition.form.plural || definition.form.noun + 's', 
				submenu: (definition.topLevel) ? false : root.parts[0] + '.' + root.parts[1],
				parents: [root.parts[0], definition.grandparent, definition.parent, root.name]
			},
			props: root.props
		})

		routes.push({
			name: root.name + '.Unsaved',
			path: root.path + '/:id/unsaved',
			component: () => import('@/components/page/' + process.env.VUE_APP_TYPE + '/Unsaved'),
			meta: {
				authority: _.union(definition.authority, (definition.form) ? definition.form.authority : []),
				stash: root.name + '.Edit',
				back: root.name + '.Edit',
				complete: root.name,
				api: definition.form.api,
				apiMultiple: definition.form.apiMultiple || definition.form.api + 's',
				auth: true,
				noun: definition.form.noun,
				plural: definition.form.plural || definition.form.noun + 's', 
				submenu: (definition.topLevel) ? false : root.parts[0] + '.' + root.parts[1],
				parents: [root.parts[0], definition.grandparent, definition.parent, root.name]
			},
			props: root.props
		})

	}

})

export default routes