<template>

<com-screen id="app" class="assistant" :alwaysHidden="true" :noHead="true" :no-context="true" :no-profile="true" :loading="is.loading">

	<router-view :key="$keyPath" />

	<template v-slot:menu>

		<com-menu />

	</template>

</com-screen>

</template>

<script>

import onScan from 'onscan.js'

import comScreen from './screen/Wrapper'
import comMenu from './screen/assistant/Menu'

export default {

	name: 'App',

	components: {
		'com-screen': comScreen,
		'com-menu': comMenu
	},

	data: function() {

		return {
			is: {
				loading: true
			}
		}

	},

	created: async function() {

		await this.$store.dispatch('init')

		this.is.loading = false

		onScan.attachTo(document)

		document.addEventListener('scan', this.onScanDetected.bind(this))

	},
	
	beforeDestroy: function() {

		document.removeEventListener('scan', this.onScanDetected.bind(this))

		onScan.detachFrom(document)

	},

	methods: {

		onScanDetected: function(e) {

			this.$pubsub.$emit('scan', this.$util.scanClean(e.detail.scanCode))

		}

	}

}

</script>

<style scoped>

body {
	overflow: hidden;
}

#app.checkout .screen-content {
	background-color: #fff!important;
}

#app.checkout .content {
	height: calc(100vh - var(--vh-offset, 0px))!important;
}

</style>
