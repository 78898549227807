import Pusher from 'pusher-js'
import pubsub from '@/pubsub'
import store from '@/store'
import _ from 'underscore'

Pusher.logToConsole = true

var connection = false
var channels = {}
var logs = []
var parked = {}

var live = {

	init: function() {

		connection = new Pusher(process.env.VUE_APP_PUSHER, {
			cluster: process.env.VUE_APP_CLUSTER
		})

		store.watch(function() {
		
			return {
				organisation: store.getters['context/organisation'],
				convention: store.getters['context/convention'],
				session: store.getters['session/id']
			}
		
		}, function() {

			live.$reconnect()
		
		}, {
			deep: true
		})

	},

	$received: function(id) {

		var received = _.contains(logs, id)

		if (!received) logs.push(id)

		return received

	},

	isValid: function(name) {

		if (name.indexOf('user/') === 0 && store.getters['session/id'] === undefined) {

			return false

		} else if (name.indexOf('convention/') === 0 && store.getters['context/convention'].id === undefined) {

			return false

		} else if (name.indexOf('organisation/') === 0 && store.getters['context/organisation'].id === undefined) {

			return false

		}
		
		return true

	},

	buildName: function(name) {

		name = name.replace(/user\//, 'user/' + store.getters['session/id'] + '/')
		name = name.replace(/convention\//, 'convention/' + store.getters['context/convention'].id + '/')
		name = name.replace(/organisation\//, 'organisation/' + store.getters['context/organisation'].id + '/')
		
		// name = process.env.VUE_APP_TYPE + '/' + name
		name = name.replace(/\//g, '-')

		return name

	},

	$reconnect: function() {

		_.each(parked, function(value) {

			if (this.isValid(value.name)) {

				if (this.buildName(value.name) !== value.channel) {

					this.$off(value.name, true)

					this.$on(value.name, value.handler)

				}
 
			} else {

				this.$off(value.name, true)

			}

		}.bind(this))

	},

	$on: function(name, handler, uid) {

		if (!connection) this.init()

		var saveName = (uid) ? uid + '/' + name : name
		
		parked[saveName] = {
			handler: handler,
			name: name,
			channel: false
		}

		if (this.isValid(name)) {

			var channelName = this.buildName(name)

			parked[saveName].channel = channelName

			channels[saveName] = connection.subscribe(channelName)

			channels[saveName].bind('event', function(e) {

				if (!this.$received(e.params.id)) pubsub.$emit(name, e.params.params)

			}.bind(this))

			pubsub.$on(name, handler)
				
		}

	},

	$listen: function(name, handler) {
		
		pubsub.$on(name, handler)
				
	},

	$unlisten: function(name, handler) {
		
		pubsub.$off(name, handler)
				
	},

	$off: function(name, keepParked, uid) {

		if (!connection) this.init()

		var saveName = (uid) ? uid + '/' + name : name

		keepParked = keepParked || false

		if (!keepParked) delete parked[saveName]
		
		if (channels[saveName]) {

			channels[saveName].unbind('event')

			channels[saveName] = false

			if (uid) {
				
				pubsub.$off(name, keepParked)

			} else {

				pubsub.$off(name)

			}

			connection.unsubscribe(this.buildName(name))
				
		}

	}

}

export default live