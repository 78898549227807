<template>

<div class="menu">

	<div class="menu-head">

		{{ $store.getters['session/name'] }}

	</div>

	<com-item route="Dashboard" text="Dashboard" icon="dashboard" v-if="scopes.length > 1" />

	<com-title text="Tools" v-if="scopes.length > 1" />

	<com-item v-for="scope in scopes" :key="scope" :route="$constants.assistant.scopesRoute[scope]" :text="$constants.assistant.scopes[scope]" :icon="$constants.assistant.scopesIcon[scope]" />

	<com-divide :fill="true" />

	<com-item route="Logout" text="Logout" icon="logout" />

</div>

</template>

<script>

export default {

	components: {

		'com-item': () => import('../panel/common/Item'),
		'com-title': () => import('../panel/common/Title'),
		'com-divide': () => import('../panel/common/Divide')
		
	},

	computed: {

		scopes: function() {

			return this.$store.getters['session/scope']

		}

	}

}

</script>

<style scoped>

.menu {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	padding-bottom: 5px;
}

.menu-divide {
	flex-grow: 1;
}

.account-logo {
	margin: 0px auto;
}

.menu-head {
	background-color: #21262e;
	height: 48px;
	color: #fff;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 400;
	line-height: 48px;
	padding: 0px 10px;
	margin-bottom: 10px;
}

</style>
