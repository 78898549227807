<template>

<div class="outer">

	<slot></slot>

</div>
	
</template>

<script>

export default {
	
}

</script>

<style scoped>

.outer {
	width: 100%;
	display: flex;
	justify-content: center;
}

</style>