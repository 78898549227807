<template>

<app-panel v-on:close="$emit('close')">
		
	<app-panel-title text="Format" />

	<app-panel-check v-for="(format, id) in formats" :key="id" :text="format" :active="model.format === id" v-on:toggle="model.format = id" />

	<app-panel-content :space="true">

		<app-button text="Confirm" :disabled="$v.invalid" v-on:click="onSaveClick" />

	</app-panel-content>

</app-panel>

</template>

<script>

import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [validationMixin],

	props: ['selectedText', 'editor'],

	data: function() {

		return {
			formats: {
				'h1': 'Large header',
				'h2': 'Small header',
				'p': 'Paragraph'
			},
			model: {
				format: 'p'
			}
		}

	},

	validations: {
		model: {
			format: {
				required
			}
		}
	},

	created: function() {

		var heading = this.editor.getAttributes('heading')

		if (heading.level) {

			if (heading.level == 1) this.model.format = 'h1'
			if (heading.level == 2) this.model.format = 'h2'

		}

		this.$pubsub.$emit('offset', true)

	},

	beforeDestroy: function() {

		this.$pubsub.$emit('offset', false)

	},

	methods: {

		onSaveClick: function() {

			if (this.model.format === 'h1') {

				this.editor.chain().focus().setHeading({ level: 1 }).run()

			} else if (this.model.format === 'h2') {

				this.editor.chain().focus().setHeading({ level:2 }).run()

			} else if (this.model.format === 'p') {

				this.editor.chain().focus().setParagraph().run()

			}

			this.$emit('close')

		}

	}

}

</script>

<style scoped>

</style>
