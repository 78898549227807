<template>

<div class="menu" :class="{'is-submenu': submenu}">

	<template v-if="$convention">

	<template v-if="!submenu">

		<com-item route="Convention.Dashboard" text="Dashboard" icon="dashboard" />
		<com-item route="Convention.Notifications" text="Notifications" icon="notifications" :count-highlight="$store.getters['session/count/notifications']" :count="$store.getters['session/count/notifications']" />
		<com-item route="Convention.Buddies" text="Buddies" icon="buddies" v-if="$setup('service/schedule')" />

		<com-title text="Convention" />

		<com-item route="Convention.Announcements" text="Announcements" icon="announcements" :count-highlight="$store.getters['session/count/announcements']" :count="$store.getters['session/count/announcements']" />
		<com-item route="Convention.Information" text="Information" icon="information" />
		<com-item route="Convention.Volunteers.Roles" text="Volunteering" icon="volunteers" :count="$store.getters['session/count'].volunteers ? $store.getters['session/count'].volunteers.total : 0" v-if="canShowVolunteers" />

		<com-title text="Registration" />

		<com-item route="Convention.Registration.Badge" text="Your Badge" icon="badge" v-if="$store.getters['session/count/managed']" :alert="$store.getters['session/count/wildcards']" />
		<com-item route="Convention.Registration.Badge" text="Badge" icon="badge" v-if="!$store.getters['session/count/managed']" :alert="$store.getters['session/count/wildcards']" />
		<com-item route="Convention.Registration.Managed" text="Managed Badges" icon="badges.managed" v-if="$store.getters['session/count/managed']" :count="$store.getters['session/count/managed']" />
		<com-item route="Convention.Registration.Tickets" text="Tickets" icon="tickets" :count="$badge ? $badge.count.tickets : 0" v-if="$badge.count.tickets" />
		<com-item route="Convention.Registration.Purchases" v-if="$setup('service/registration')" text="Purchases" icon="purchases" :count="$store.getters['session/count/purchases']" />
		<com-item route="Convention.Registration.Store" v-if="$setup('service/registration')" text="Store" icon="store" />

		<template v-if="$isBadgeActive">

			<template v-if="$setup('service/schedule')">

			<com-title text="Schedule" />

			<com-search placeholder="Quick search events..." onSubmit="Convention.Schedule.Browse" />

			<com-item route="Convention.Schedule.Browse" text="Browse" icon="schedule" />
			<com-item route="Convention.Schedule.Contests" text="Contests" icon="contests" v-if="showContests" />
			<com-item route="Convention.Schedule.Lobbies" text="Lobbies" icon="lobbies" v-if="showLobbies" />
			<com-item route="Convention.Schedule.Discover" text="Discover" icon="matchmaking" />
			<com-item route="Convention.Schedule.Personal" text="My Events" icon="events" :count="$store.getters['session/count/schedule/personal']" :forceCount="true" />
			<com-item route="Convention.Schedule.Shortlist" text="Shortlist" icon="shortlisted" :count="$store.getters['session/count/schedule/shortlisted']" :forceCount="true" />

			<com-button route="Convention.Schedule.Submit" text="Submit event" icon="submit" v-if="showSubmit" />

			</template>

			<template v-if="$setup('service/checkout')">

			<com-title text="Checkout" />

			<com-search placeholder="Quick check availability..." onSubmit="Convention.Checkout.Availability" />

			<com-item v-if="!$organisation.settings.library.external.enabled && $setup('service/library')" route="Convention.Library.Browse" text="Browse Library" icon="library" />
			<com-external v-if="$organisation.settings.library.external.enabled && $setup('service/library')" :url="$organisation.settings.library.external.url" text="Browse Library" icon="library" />

			<com-item route="Convention.Checkout.Loans" text="My Plays" icon="plays" />
			<com-item route="Convention.Checkout.Wins" text="Play to Win" icon="play" />
			<com-item route="Convention.Checkout.Notifications" text="Waiting Lists" icon="checkout.notify" :count="$store.getters['session/count/checkout/notify']" :forceCount="true" />

			</template>

			<template v-if="!$setup('service/checkout') && $setup('service/library')">

			<com-title text="Library" />

			<com-item v-if="!$organisation.settings.library.external.enabled" route="Convention.Library.Browse" text="Browse Library" icon="library" />
			<com-external v-if="$organisation.settings.library.external.enabled" url="Convention.Library.Browse" :text="$organisation.settings.library.external.url" icon="library" />

			</template>

		</template>

	</template>

	</template>

	<slot></slot>

</div>

</template>

<script>

export default {

	components: {

		'com-title': () => import('../panel/common/Title'),
		'com-item': () => import('../panel/common/Item'),
		'com-external': () => import('../panel/common/External'),
		'com-search': () => import('../panel/common/Search'),
		'com-button': () => import('../panel/common/Button')
		
	},

	computed: {

		showContests: function() {

			return this.$settings.schedule.contests.enabled === this.$constants.schedule.settings.contests.enabled.show || this.$settings.schedule.contests.enabled === this.$constants.schedule.settings.contests.enabled.auto && this.$convention.count.schedule.contests
		},

		showLobbies: function() {

			return this.$settings.schedule.lobbies.enabled === this.$constants.schedule.settings.lobbies.enabled.show || this.$settings.schedule.lobbies.enabled === this.$constants.schedule.settings.lobbies.enabled.auto && this.$convention.count.schedule.lobbies

		},

		showSubmit: function() {

			return this.$isAdmin || this.$isModerator || this.$settings.schedule.events.submit.enabled !== this.$constants.schedule.settings.events.submit.enabled.hidden

		},

		submenu: function() {

			return false //this.$route.meta.submenu

		},

		canShowVolunteers: function() {

			return (!this.$session.volunteers) ? this.$settings.volunteers.visibility === this.$constants.volunteers.settings.visibility.any || this.$settings.volunteers.signups : this.$settings.volunteers.visibility === this.$constants.volunteers.settings.visibility.any || this.$settings.volunteers.signups || (this.$settings.volunteers.visibility === this.$constants.volunteers.settings.visibility.whitelisted && this.$session.volunteers.whitelisted)

		}

	}

}

</script>

<style scoped>

.menu {
	flex-grow: 1;
}

.menu.is-submenu {
	padding-left: 40px;
}

.menu-sidebar {
	position: absolute;
	left: -40px;
	top: 0px;
	bottom: 0px;
	background-color: #2f3a4a;
	width: 40px;
	transition: left 100ms linear;
}

.menu-sidebar.is-active {
	left: 0px;
}

.contact {
	color: #bfc4cb;
	font-weight: 300;
	font-size: 12px;
	line-height: 16px;
	padding: 20px 20px;
}

.contact a {
	font-weight: bold;
}

.contact a:hover {
	text-decoration: underline;
}

</style>
