<template>

<div class="offline">

	<div class="offline-box">

		<div class="offline-title"><span>cardboard</span><span>events</span></div>

		<p>The website is currently being updated and will be back shortly. We apologize for the inconvenience.</p>

	</div>

</div>

</template>

<script>

export default {

	name: 'App'

}

</script>

<style>

body {
	overflow: hidden;
}

.offline {
	position: fixed;
	left: 0px;
	top: 0px;
	bottom: 0px;
	right: 0px;
	background-color: #1e3054;
	display: flex;
	align-items: center;
	justify-content: center;
}

.offline-box {
	max-width: 300px;
}

.offline-title {
	text-align: right;
	color: #fff;
	margin-bottom: 20px;
}

.offline-title span {
	font-family: 'Courier New';
	font-size: 32px;
	line-height: 32px;
}

.offline-title span:nth-child(2) {
	opacity: 0.5;
	font-size: 32px;
	line-height: 32px;
}

.offline-title small {
	display: inline-block;
	background-color: #4082d3;
	font-size: 16px;
	padding: 4px 10px;
	font-weight: 400;
}

.offline-box p {
	color: #fff;
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	text-align: center;
}

</style>
