<template>

<div class="form" :class="{'is-disabled': disabled, 'is-fullscreen': isFullscreen}">

	<app-page-inner>

		<slot></slot>

		<template v-for="input in inputs">

			<app-input-text v-model="model[input.id]" :messages="messages[input.id]" :wide="window.is.desktop" :key="input.id" :validation="$v.model[input.id]" :label="input.label" :notes="input.description" v-if="input.type === $constants.registration.input.type.textbox" />
			<app-input-toggle v-model="model[input.id]" :wide="window.is.desktop" :asString="true" valueField="id" :key="input.id" :validation="$v.model[input.id]" :label="input.label" :notes="input.description" :options="input.options" v-if="input.type === $constants.registration.input.type.toggle" />
			<app-input-checklist v-model="model[input.id]" :wide="window.is.desktop" property="id" :key="input.id" :validation="$v.model[input.id]" :label="input.label" :notes="input.description" :options="input.options" v-if="input.type === $constants.registration.input.type.checklist" />
			<app-input-select v-model="model[input.id]" :wide="window.is.desktop" property="id" :key="input.id" :validation="$v.model[input.id]" :label="input.label" :notes="input.description" :placeholder="input.placeholder || 'Select an option...'" :options="input.options" v-if="input.type === $constants.registration.input.type.select" />

		</template>

		<div class="form-buttons">	

			<app-button :disabled="!isValid" :loading="saving" text="Confirm" v-on:click="onConfirmClick" />
			<app-button v-if="canCancel" text="Cancel" theme="red" v-on:click="$emit('cancel')" />
			<app-button v-if="canRemove" text="Remove" theme="red" v-on:click="$emit('delete')" />

		</div>

	</app-page-inner>

</div>

</template>

<script>

import Vue from 'vue'

import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators'

const uniqueEmail = function() {

	return this.uniqueEmail

}

export default {

	mixins: [validationMixin],

	props: ['id', 'inputs', 'isFullscreen', 'saving', 'forceInvalid', 'data', 'disabled', 'canCancel', 'canRemove', 'emailValid'],

	data: function() {

		return {
			model: {},
			messages: {},
			uniqueEmail: false,
			lastEmailCheck: false
		}

	},

	validations: function() {

		var validations = {
			model: {}
		}

		this.$_.each(this.inputs, function(input) {

			Vue.set(validations.model, input.id, {})

			if (input.required) {

				Vue.set(validations.model[input.id], 'required', required)

			}

			if (input.validation === this.$constants.registration.input.validation.email) {

				Vue.set(validations.model[input.id], 'email', email)

			}

			if (input.validation === this.$constants.registration.input.validation.uniqueemail) {

				Vue.set(validations.model[input.id], 'email', email)
				Vue.set(validations.model[input.id], 'uniqueEmail', uniqueEmail)

			}

		}.bind(this))

		return validations

	},

	created: function() {

		this.$_.each(this.inputs, function(input) {

			var defaultValue = ''

			if (input.type === this.$constants.registration.input.type.toggle || input.type === this.$constants.registration.input.type.select) {

				defaultValue = 0

			} else if (input.type === this.$constants.registration.input.type.checklist) {

				defaultValue = []

			}

			Vue.set(this.model, input.id, defaultValue)

		}.bind(this))

		if (this.data) Vue.set(this, 'model', this.$util.copy(this.data))

	},

	watch: {

		model: {

			deep: true,

			handler: function() {

				this.onChange()

			}

		}

	},

	computed: {

		isValid: function() {

			return !this.$v.$invalid && !this.forceInvalid

		},

		badges: function() {

			return this.$_.filter(this.$store.getters['register/badges'], function(badge) {

				return badge.id !== this.id

			}.bind(this))

		}

	},

	methods: {

		onChange: async function() {

			var id = this.$util.registration.findValidationID(this.inputs, 'uniqueemail')

			if (id) {

				var email = this.model[id]
				var localValid = this.$v.model[id]

				if (localValid.email && localValid.required && email !== this.lastEmailCheck) {

					this.uniqueEmail = false
					this.lastEmailCheck = email

					Vue.set(this.messages, id, [])
					
					this.$api.cancel()

					if (this.badges.length) {

						var emails = this.$_.pluck(this.badges, 'email')

						if (this.$_.contains(emails, email.toLowerCase())) {

							this.messages[id].push({
								type: 'red',
								icon: 'warning',
								text: 'Another badge you have added is already using this e-mail address. Please use a different e-mail address.'
							})

							return false

						}

					}

					await this.$api.get('exists', {
						page: this.$store.getters['register/page'].id,
						email: email
					}).then(function(json) {

						Vue.set(this.messages, id, [])

						if (!json.registered) this.uniqueEmail = true

						if (json.registered) {

							this.messages[id].push({
								type: 'red',
								icon: 'warning',
								text: 'This e-mail address has already been registered with another badge for this convention. Please use a different e-mail address for a new badge.'
							})

						} else {

							if (json.account) {

								this.messages[id].push({
									type: 'yellow',
									icon: 'exists',
									text: 'This e-mail address is already associated with an existing Cardboard Events account and this badge will automatically be assigned to that account.'
								})

							} else {

								this.messages[id].push({
									type: 'blue',
									icon: 'register',
									text: 'A Cardboard Events login will be generated for this e-mail address and this badge will automatically be assigned to it. The login credentials will automatically be e-mailed immediately after completion of registration.'
								})

							}

						}

					}.bind(this))

				}

			}

		},

		onConfirmClick: function() {

			if(!this.disabled && this.isValid) this.$emit('confirm', this.$util.copy(this.model))

		}
	
	}
	
}

</script>

<style scoped>

.form.is-fullscreen {
	position: fixed;
	left: 0px;
	top: 0px;
	right: 0px;
	background-color: #fff;
	bottom: 0px;
	z-index: 1000;
	overflow-y: auto;
}

.form.is-fullscreen .inner {
	margin: 0px auto;
	padding: 40px 10px;
}

.form.is-disabled {
	opacity: 0.45;
	pointer-events: none;
}

.form-buttons {
	margin-top: 40px;
	display: flex;
	justify-content: center;
}

.form-buttons >>> .button {
	margin: 0px 5px;
}

</style>